import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from '../../providers/gen/gen';
import { AlertService } from '../services/alert.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs-compat/Subscription';
import { ServiceProvider } from 'src/providers/service/service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-painelControlTicket',
  templateUrl: './painelControlTicket.component.html',
  styleUrls: ['./painelControlTicket.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class PainelControlTicketComponent implements OnInit {
  @ViewChild('dtFila') dtFila: Table;
  @ViewChild('dtAtendimento') dtAtendimento: Table;
  @ViewChild('dtTicksts') dtTicksts: Table;
  @ViewChild('audio') audio: ElementRef;
  filaAgente: any;
  path: any;
  parametros: any;
  mostraUsuario: any;
  key: any;
  loading: boolean;
  chatAtendimento: any;
  fila: any = [];
  percFila: number = 0;
  percAtendimento: number = 0;
  percDevolvida: number = 0;
  percPendente: number = 0;
  percVisualizadas : number = 0;
  conversas: any;
  atendimento: any = 0;
  devolvida: any = 0;
  pendente: any = 0;
  chat_msg: any[];
  mostraMgs: boolean;
  chat: any;
  msgResp: any;
  msgPendente: any;
  ponto: any = [];
  mostraMgsFila: boolean;
  descricao: any;
  novaMsg: any = 0;
  percNovaMsg: number = 0;
  detalhe: any;
  key_agente: any;
  dados: any = [];
  midia: { type: any; file: any; lat: any; lng: any; };
  mostraMidia: boolean;
  agente: any = [];
  distribuida: any = [];
  percDistribuida: number = 0;
  emAtendimento: any = [];
  data: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };
  protocolo: any;
  dataChart: any;
  isStart: any;
  unSub: Subscription[] = [];
  unMsg: Subscription;
  unChat$: Subscription;
  unSubLoad$: Subscription;
  days: any;
  encerrado: any;
  denuncias: any = [];
  inicio: any;
  final: any;
  ticket: any;
  mostraMgsAvaliada: boolean;
  situacao: any;
  canal: any;
  denunciaProtocolo: any;
  ticket2: any;
  log: any;
  mdAcaoUsuario !: any;
  usuario: any;
  listaAcao: any = [];
  acaoUsuario: any;
  startLoad: boolean;
  nivel: string;
  lista: any;
  lotacao: any = [];
  item: any = [];
  key_lotacao: string;
  meus_lista: any;
  nova_lista: any;
  pendente_lista: any;
  atrasado_lista: any;
  espera_lista: any;
  solicitante_lista: any;
  todos: number = 0;
  meus: any;
  nova: any;
  atrasado: any;
  espera: any;
  sousolicitante: any;
  qtd_upload: number;
  qtd_tickts: { nome: string; qtd: number; }[];
  cols: { header: string; field: string; width: string; }[];
  exportColumns: { title: string; dataKey: string; }[];
  catalogo: any = [];
  unSub$ = new Subject();
  key_pessoa: any = [];
  pessoa_lotacao: any = [];
  usuarios: any = [];
  pessoa: any = [];
  key_lotacao_principal: any = [];
  puc: any = [];
  cols_fila: { header: string; field: string; width: string; }[];
  notificacaoSonora: boolean = true;
  visualizadas: any = [];
  nome_lotacao: any;
  modal_tools: boolean;
  myBtnFiltro: boolean;
  progressBar: string;
  key_minhas_lotacao: any = [];
  login: any = [];
  tickets: any;
  constructor(
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private service: ServiceProvider) {
    this.midia = {
      type: '',
      file: '',
      lat: 0,
      lng: 0
    }
    this.filaAgente = this.genProvider.parametros.filaAgente
    this.path = this.genProvider.parametros.path;
    this.parametros = this.genProvider.parametros;
    this.mostraUsuario = this.genProvider.parametros.mostraUsuario
    this.key_pessoa = this.genProvider.parametros.key_pessoa;
  }
  onClose() {
    this.router.navigate(['']);
  }

  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unMsg != undefined) {
      this.unMsg.unsubscribe();
    }
    if (this.unChat$ != undefined) {
      this.unChat$.unsubscribe();
    }
    this.unSub$.next()
    this.unSub$.complete()

  }
  onCombos() {

    this.cols_fila = [
      { header: 'Código', field: 'key', width: '15%' },
      { header: 'Criação', field: 'data_criacaoM', width: '12%' },
      { header: 'Título', field: 'titulo_ticket', width: '19%' },
      { header: 'solicitante', field: 'nome_solicitante', width: '18%' },
      { header: 'Canal', field: 'canal', width: '10%' },
      { header: 'Sub-Item', field: 'subitem', width: '15%' },
      { header: 'Unidade Operacional', field: 'nome_lotacao', width: '11%' },
      { header: 'Agente', field: 'agente', width: '15%' },
      { header: 'Prioridade', field: 'prioridade', width: '10%' }
    ];
    this.cols = [
      { header: 'Código', field: 'key', width: '15%' },
      { header: 'Status', field: 'status_ticket', width: '10%' },
      { header: 'Criação', field: 'data_criacaoM', width: '12%' },
      { header: 'Últ.Interação', field: 'ultima_interacaoM', width: '15%' },
      { header: 'Qtd', field: 'qtd_interacao', width: '5%' },
      { header: 'Título', field: 'titulo_ticket', width: '19%' },
      { header: 'solicitante', field: 'nome_solicitante', width: '18%' },
      { header: 'Canal', field: 'canal', width: '10%' },
      { header: 'Sub-Item', field: 'subitem', width: '15%' },
      { header: 'Unidade Operacional', field: 'nome_lotacao', width: '11%' },
      { header: 'Responsável', field: 'nome_responsavel', width: '15%' },
      { header: 'Agente', field: 'agente', width: '15%' },
      { header: 'Prioridade', field: 'prioridade', width: '10%' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  ngOnInit() {
    this.onCombos();
    this.primengConfig.ripple = true;
    this.qtd_upload = 9;
    this.onLoadAllItem();
    this.onLoadLotacao();
    this.onLoadUsuarios();
    this.onLoadPessoa(this.key_pessoa);
  }
  onLoadPessoa(key) {
    this.genProvider.onGetKey('dados/' + this.path + '/pessoa', key).pipe(take(1)).subscribe(data => {
      this.pessoa = JSON.parse(JSON.stringify(data));
      this.key_lotacao = this.pessoa.key_lotacao
      this.key_lotacao_principal = this.pessoa.key_lotacao
      this.nome_lotacao = this.genProvider.onGetTableKey(this.lotacao, this.key_lotacao).nome;
      this.onLoadInit()
    })
  }
  onLoadInit() {
    this.onLoadAgente();
    this.onLoadUsuarioLotacao();
    let startIntervalo = setInterval(() => {
        console.log(this.lotacao)
      if (this.lotacao) {
        console.log ('ok')
        this.onLoad();
        this.onLoadLotacaoPuc(this.key_lotacao);
        clearInterval(startIntervalo);
      }
    }, 3000);
  }
  onLoadLotacaoPuc(key) {
    let joins = [
      { key: "key_puc", table: "puc" }
    ]
    this.genProvider.onGetAll('dados/' + this.path + '/lotacao/' + key + '/puc', "key_puc", joins).pipe(take(1)).subscribe(data => {
      let puc = JSON.parse(JSON.stringify(data));
      if (puc.length > 0) {
        this.puc = puc.map(res => {
          res.nome = res.puc.nome
          return res;
        });
        this.catalogo = [];
      } else {
        alert("Atenção, não existe central de atendimento para o usuário!")
      }
    })
  }
  onLoadAgente() {
    this.genProvider.onGetAll('login', 'nome').pipe(take(1)).subscribe(data => {
      this.agente = JSON.parse(JSON.stringify(data));
      //  console.log (this.agente)
      let res = this.agente.filter(obj => {
        return obj.key == this.parametros.key_usuario
      });
    })
  }
  onLoadUsuarios() {
    let unSub = this.genProvider.onGetAll('login', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.usuarios = JSON.parse(JSON.stringify(data));
    })
  }
  onLoadLotacao() {
    this.genProvider.onGetAll('dados/' + this.path + '/lotacao', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
      let lotacao = JSON.parse(JSON.stringify(data));
      //    this.lotacao = lotacao;
      this.lotacao = lotacao.map(res => {
        res.nomeSigla = res.nome + " " + res.sigla + " " + res.codigo
        return res;
      });
      this.onLoadPessoa_lotacao(this.key_pessoa);
    },
      err => console.log(err)
    );
  }
  onLoadPessoa_lotacao(key) {
    if (this.parametros.nivel != '99') {
      this.genProvider.onGet('dados/' + this.path + "/pessoa/" + key, 'lotacao').pipe(take(1)).subscribe(data => {
        let pessoa_lotacao = JSON.parse(JSON.stringify(data));
        if (pessoa_lotacao.length > 0) {
          pessoa_lotacao.forEach(res => {
            if ((res.data_expira) && (res.data_expira <= Date.now())) {
              var lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao);
              res.nome = lotacao.nome;
              if (res.data_expira) {
                res.data = new DatePipe("pt-BR").transform(res.data_expira, "dd/MM/yyyy - HH:mm");
              }
              res.nomeData = res.nome + ' - ' + res.data
              this.pessoa_lotacao.push(res);
            } else if (!res.data_expira) {
              var lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao);
              res.nome = lotacao.nome;
              res.data = 'Indeterminado'
              res.nomeData = res.nome + ' - ' + res.data
              res.nomeSigla = res.nome + ' - ' + res.sigla + ' - ' + res.codigo
              this.pessoa_lotacao.push(res)
            }
          });
        }
      },
        err => console.log(err)
      );
    } else {
      this.lotacao.forEach(res => {
        let dados = {
          nome: res.nome,
          data: 'Indeterminado',
          nomeData: res.nome,
          nomeSigla: res.nome + ' - ' + res.sigla,
          key_lotacao: res.key
        }
        this.pessoa_lotacao.push(dados)
      })
    }
  }
  onLoadAllItem() {
    this.loading = true
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.catalogo = JSON.parse(JSON.stringify(data));
      this.catalogo.forEach(e => {
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item', "nome").subscribe(data => {
          unSub.unsubscribe();
          let item = JSON.parse(JSON.stringify(data));
          item.forEach(element => {
            this.item.push(element)
          });
        });
      })
      this.loading = false;
    })
  }
  onLoadUsuario() {
    this.unSub.push(this.genProvider.onGetFilterOrder('login', 'nome', 'path', this.parametros.path).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      this.agente = [];
      lista.forEach(e => {
   //     if (e.nivel != "99") {
          this.agente.push(e)
   //     }
      });
    },
      err => console.log(err)
    ));

  }
  onLoadParamentro() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/parametros', 'token').subscribe(
      data => {
        let dados = JSON.parse(JSON.stringify(data));
        if (dados.length > 0) {
          this.key = dados[0].key;
        }
      },
      err => console.log(err)
    ));
  }
  onLoadAtendimentos() {
    this.loading = true;
    if ((!this.ticket) && (this.inicio) && (this.final)) {
      if (this.unChat$ != undefined) {
        this.unChat$.unsubscribe();
      }
      let inicio = new Date(this.inicio).getTime()
      let fim = new Date(this.final).getTime()
      let unSub = this.genProvider.onGetPeriodo('dados/' + this.path + '/ticket', 'data_criacao', inicio, fim).subscribe(data => {
        unSub.unsubscribe()
        let temp = JSON.parse(JSON.stringify(data));
        let dados = temp.filter(obj => {
          return obj.status != "registrando" && obj.canal
        })
        console.log (dados)
        if (dados.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo para consulta.' });
          this.loading = false;
        } else {
          this.tickets = dados.map(res => {
            if (res.status) {
              res.status = res.status.toLowerCase()
            }
            if (res.prioridade== null){
              res.prioridade = 'Nenhuma'
            }
            if (res.status_ticket) {
              res.status_ticket = res.status_ticket.toLowerCase()
            }
            if (res.key_lotacao) {
              let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (lotacao) {
                res.nome_lotacao = lotacao.nome
              } else {
                res.nome_lotacao = ''
              }
            } else {
              res.nome_lotacao = '';
            }
            let item = this.genProvider.onGetTableKey(this.item, res.key_item);
            if (item) {
              res.item = item.nome
              if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                try {
                  let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                    unSub.unsubscribe();
                    let subitem = JSON.parse(JSON.stringify(data));
                    if (subitem) {
                      res.subitem = subitem.nome;
                    }
                  })
                } catch (e) {
                  console.log(e);
                }
              }
            }
            if (res.key_usuario) {
              let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
              if (agente) {
                res.agente = agente.nome
              } else {
                res.agente = '';
              }
            } else {
              res.agente = '';
            }
            if (res.enviar_resposta_solicitante_email) {
              res.resposta = "email"
            } else {
              res.resposta = "whatsapp"
            }
            if (res.qtd_interacao) {
              res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
            }
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
            return res;
          });
          this.loading = false;
        }
      });
    } else if (this.ticket) {
      this.unChat$.unsubscribe()
      let unSub = this.genProvider.onGetfilter('dados/' + this.path + '/ticket', 'ticket',this.ticket).subscribe(data => {
        unSub.unsubscribe()
        let temp = JSON.parse(JSON.stringify(data));
        let dados = temp.filter(obj => {
          return obj.status != "registrando"
        })
        if (dados.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este protocolo.' });
          this.loading = false;
        } else {
          this.tickets = dados.map(res => {
            if (res.status) {
              res.status = res.status.toLowerCase()
            }
            if (res.prioridade== null){
              res.prioridade = 'Nenhuma'
            }
            if (res.status_ticket) {
              res.status_ticket = res.status_ticket.toLowerCase()
            }
            if (res.key_lotacao) {
              let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (lotacao) {
                res.nome_lotacao = lotacao.nome
              } else {
                res.nome_lotacao = ''
              }
            } else {
              res.nome_lotacao = '';
            }
            let item = this.genProvider.onGetTableKey(this.item, res.key_item);
            if (item) {
              res.item = item.nome
              if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                try {
                  let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                    unSub.unsubscribe();
                    let subitem = JSON.parse(JSON.stringify(data));
                    if (subitem) {
                      res.subitem = subitem.nome;
                    }
                  })
                } catch (e) {
                  console.log(e);
                }
              }
            }
            if (res.key_usuario) {
              let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
              if (agente) {
                res.agente = agente.nome
              } else {
                res.agente = '';
              }
            } else {
              res.agente = '';
            }
            if (res.enviar_resposta_solicitante_email) {
              res.resposta = "email"
            } else {
              res.resposta = "whatsapp"
            }
            if (res.qtd_interacao) {
              res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
            }
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
            return res;
          });
          this.loading = false;
        }
      });
    }

  }

  onLoadMsg(key) {
    this.chat_msg = [];
    this.unMsg = this.genProvider.onGetAll('dados/' + this.path + '/ticket/' + key + '/ticket_body', 'time').subscribe(data => {
      let chat_msg = JSON.parse(JSON.stringify(data));
      let i = 0
      this.unMsg.unsubscribe();
      this.chat_msg = chat_msg.map(res => {
        i++
        if (!res.status){
          res.status = 'Recebida'
        }
        if (res.type == "location") {
          let coor = res.value.split(/\s*;\s*/);
          res.lat = parseFloat(coor[0]);
          res.lng = parseFloat(coor[1]);
        }
        return res;
      });
    },
      err => {
        console.log(err);
      }
    );
  }
  encId(str) {
    var encoded = "";
    for (let i = 0; i < str.length; i++) {
      var a = (i);
      var b = a ^ 1;    // bitwise XOR with any number, e.g. 123
      encoded = encoded + (b);
    }
    return encoded;
  }
  onMostraMgsFila(dados) {
    this.onLoadMsg(dados.ticket);
    this.dados = dados;
    this.protocolo = dados.ticket
    this.detalhe = dados.value
    this.mostraMgsFila = true

  }
  onMostraMgsAvaliada(dados) {
    this.dados = dados;
    this.protocolo = dados.ticket
    this.detalhe = dados.detalhe
    this.situacao = dados.situacao
    this.mostraMgsAvaliada = true
    this.canal = dados.canal
  }
  onMostraMgs(dados) {
    this.onLoadMsg(dados.ticket);
    this.dados = dados;
    this.protocolo = dados.ticket
    this.detalhe = dados.value
    this.mostraMgs = true
  }
  onDistribuicao(e) {
    this.unSubLoad$.unsubscribe()
    let res = this.login.filter(obj=>{
      return obj.key == this.key_agente
    })
    let dados = {
      key: e.ticket,
      key_responsavel: this.key_agente,
      nome_responsavel: res[0].nome,
      status: "Distribuido"
  //    novo : '0'
    }
    this.genProvider.onSaveObjeto('dados', this.path, "ticket", dados);
    /*
    let unSub = this.genProvider.onGetfilter('dados/' + this.path + '/ticket', "protocolo", e.ticket).subscribe(data => {
      unSub.unsubscribe()
      let res = JSON.parse(JSON.stringify(data));
      let dados = {
        key: res[0].key,
        key_usuario: this.key_agente,
        status_ticket: "Distribuido"
      }
      this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat', dados)
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Distribuição concluida.' });
    });
    */
    this.onLoad();
    this.mostraMgsFila = false;
  }
  openMidia(res) {
    console.log (res)
    this.midia = {
      type: res.type,
      file: res.value,
      lat: res.lat || 0,
      lng: res.lng || 0
    }
    this.mostraMidia = true;
  }
  onFinalizaAtendimento(event) {
    let dados_ponto = {};
    if (this.acaoUsuario == 'Finalizar Atendimento') {
      dados_ponto = {
        key: event.key,
        protocolo: '',
        atendimento_status: "Livre",
        canal: ''
      }
    } else {
      var dtInicio = new DatePipe("pt-BR").transform(event.entrada, "dd/MM/yyyy - HH:mm:ss"); "20/06/2017 16:40:00";
      var dtAtual = new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 11:20:00";
      var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      let totalTempo = s;
      dados_ponto = {
        key: event.key,
        saida: Date.now(),
        status: "Encerrado",
        atendimento_status: "Encerrado",
        canal: '',
        atendimento_time: Date.now(),
        isSaida: true,
        total: totalTempo,
        protocolo: '',
      }
    }
    this.confirmationService.confirm({
      message: 'Confirma a liberação de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
          this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
          this.mdAcaoUsuario = false;
        })
      },
      reject: () => {
        this.messageService.add({ severity: "error", summary: 'Rejected', detail: 'Registro cancelado' });
        this.mdAcaoUsuario = false;
      }
    });

  }
  onUsuario() {
    let unSub = this.genProvider.onGetFilterOrder('login', 'nome', 'path', this.parametros.path).subscribe(data => {
      unSub.unsubscribe()
      let query = JSON.parse(JSON.stringify(data));
      query.forEach(e => {
        console.log(e.nome, "=>", e.login)

      });
    })
  }

  onAcaoUsuario(user) {
    this.acaoUsuario = null;
    this.usuario = user;
    this.mdAcaoUsuario = true;
  }
  /* nova funcionalidade p/ ticket */

  onLoad() {
    console.log ('aki')
    this.loading = true;
    let filtro = [
      "Novo",
      "Pendente",
      "Visualizado"
    ]
    if (this.nivel == '99') {
      this.unSubLoad$ = this.genProvider.onGetFilterOrderAll('dados/' + this.path + '/ticket', "time", "tramitacao", "1", "==", "status", filtro, "in", 9999, 'desc').pipe(take(1)).subscribe(data => {
//        this.genProvider.onGetfilter('dados/' + this.path + '/ticket', "status_ticket","Em Atendimento").pipe(take(1)).subscribe(data => {
        let lista = JSON.parse(JSON.stringify(data));
        console.log (this.lista)

        this.lista = lista.map(res => {
          if (res.status.toLowerCase() == "novo") {
         //   this.onNotificacao();
          }
          if (res.status) {
            res.status = res.status.toLowerCase()
          }
          if (res.prioridade== null){
            res.prioridade = 'Nenhuma'
          }
          if (res.status_ticket) {
            res.status_ticket = res.status_ticket.toLowerCase()
          }
          if (res.key_lotacao) {
            let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
            if (lotacao) {
              res.nome_lotacao = lotacao.nome
            } else {
              res.nome_lotacao = ''
            }
          } else {
            res.nome_lotacao = '';
          }
          let item = this.genProvider.onGetTableKey(this.item, res.key_item);
          if (item) {
            res.item = item.nome
            if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
              try {
                let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                  unSub.unsubscribe();
                  let subitem = JSON.parse(JSON.stringify(data));
                  if (subitem) {
                    res.subitem = subitem.nome;
                  }
                })
              } catch (e) {
                console.log(e);
              }
            }
          }
          if (res.key_usuario) {
            let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
            if (agente) {
              res.agente = agente.nome
            } else {
              res.agente = '';
            }
          } else {
            res.agente = '';
          }
          if (res.enviar_resposta_solicitante_email) {
            res.resposta = "email"
          } else {
            res.resposta = "whatsapp"
          }
          if (res.qtd_interacao) {
            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
          }
          res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
          res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
          return res;
        });

        this.onLoadOneAdmin();

        this.loading = false;
      },
        err => console.log(err)
      );
    } else {
      this.startLoad = false;
      //      this.genProvider.onGetFilterThree('dados/' + this.path + '/ticket', "time", "key_lotacao", this.key_lotacao, "==", "tramitacao", "1", "==", "status", "Finalizado", "!=", this.qtd_upload + 1, 'desc').pipe(take(1)).subscribe(data => {
      this.unSubLoad$ = this.genProvider.onGetFilterThree('dados/' + this.path + '/ticket', "time", "key_lotacao", this.key_lotacao, "==", "tramitacao", "1", "==", "status", "Finalizado", "!=", 9999, 'desc').subscribe(data => {
        let lista = JSON.parse(JSON.stringify(data));
        console.log (lista)
        if (lista.length > 0) {
          this.lista = lista.map(res => {
            if (res.status.toLowerCase() == "novo") {
             // this.onNotificacao();
            }
            if (res.prioridade== null){
              res.prioridade = 'Nenhuma'
            }
            if (res.status) {
              res.status = res.status.toLowerCase()
            }
            if (res.status_ticket) {
              res.status_ticket = res.status_ticket.toLowerCase()
            }
            if (res.key_lotacao) {
              let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (lotacao) {
                res.nome_lotacao = lotacao.nome
              } else {
                res.nome_lotacao = ''
              }
            } else {
              res.nome_lotacao = 'Indefinida';
            }
            let item = this.genProvider.onGetTableKey(this.item, res.key_item);
            if (item) {
              res.item = item.nome
              if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                try {
                  let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                    unSub.unsubscribe();
                    let subitem = JSON.parse(JSON.stringify(data));
                    if (subitem) {
                      res.subitem = subitem.nome;
                    }
                  })
                } catch (e) {
                  console.log(e);
                }
              }
            }
            if (res.key_usuario) {
              let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
              if (agente) {
                res.agente = agente.nome
              } else {
                res.agente = '';
              }
            } else {
              res.agente = '';
            }
            if (res.enviar_resposta_solicitante_email) {
              res.resposta = "email"
            } else {
              res.resposta = "whatsapp"
            }
            if (res.qtd_interacao) {
              res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
            }
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
            return res;
          });
          this.dados = this.lista;
          this.onLoadOne();
        }
        this.loading = false;
      },
        err => console.log(err)
      );
    }
  }
  onLoadOneAdmin() {
    let filtro = ["1"]
    this.unChat$ = this.genProvider.onGetArray('dados/' + this.path + '/ticket', 'time', 'tramitacao', 'in', filtro, "desc", 1).subscribe(data => {
      let listaAll = JSON.parse(JSON.stringify(data));
      if (listaAll.length > 0) {
        listaAll = listaAll.map(res => {
          if (res.status) {
            res.status = res.status.toLowerCase()
          }
          if (res.status_ticket) {
            res.status_ticket = res.status_ticket.toLowerCase()
          }
          if (res.key_lotacao) {
            let nome = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
            if (nome) {
              res.nome_lotacao = nome.nome
            }
          } else {
            res.nome_lotacao = '';
          }
          if (res.key_usuario) {
            let nome = this.genProvider.onGetTableKey(this.agente, res.key_usuario);
            if (nome) {
              res.agente = nome.nome
            }
          } else {
            res.agente = '';
          }
          if (res.enviar_resposta_solicitante_email) {
            res.resposta = "email"
          } else {
            res.resposta = "whatsapp"
          }
          if (res.qtd_interacao) {
            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
          }
          res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
          res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
          return res;
        });
        this.onUpdateDados(listaAll)
      }
    },
      err => console.log(err)
    );

  }
  onLoadOne() {
    let filtro = ["1"]
    this.unChat$ = this.genProvider.onGetFilterOrderAll('dados/' + this.path + '/ticket', "time", "key_lotacao", this.key_lotacao, "==", "tramitacao", filtro, "in", 1, 'desc').subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      if ((lista.length > 0) && (this.startLoad)) {
        lista = lista.map(res => {
          if (res.status) {
            res.status = res.status.toLowerCase()
          }
          if (res.status_ticket) {
            res.status_ticket = res.status_ticket.toLowerCase()
          }
          if (res.key_lotacao) {
            let nome = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
            if (nome) {
              res.nome_lotacao = nome.nome
            }
          } else {
            res.nome_lotacao = '';
          }
          if (res.key_usuario) {
            let nome = this.genProvider.onGetTableKey(this.agente, res.key_usuario);
            if (nome) {
              res.agente = nome.nome
            }
          } else {
            res.agente = '';
          } if (res.enviar_resposta_solicitante_email) {
            res.resposta = "email"
          } else {
            res.resposta = "whatsapp"
          }
          if (res.qtd_interacao) {
            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
          }
          res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
          res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
          return res;
        });
        this.onUpdateDados(lista)
      } else {
        this.startLoad = true;
      }
    },
      err => console.log(err)
    );
  }
  onUpdateDados(result) {

    if (result.length > 0) {
      let res = this.lista.filter(obj => {
        return obj.key == result[0].key
      })
      if (res.length > 0) {
        const index = this.lista.indexOf(res[0]);
        this.lista.splice(index, 1);
      }
      if (result[0].status_ticket != "finalizado") {
        let lista = result.map(res => {
          if (res.qtd_interacao) {
            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
          }
          res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
          res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
          return res;
        });
        this.lista.push(lista[0])
      }
    }
    this.lista.sort(function (a, b) {
      if (a.time < b.time) {
        return 1;
      }
      if (a.time > b.time) {
        return -1;
      }
      return 0;
    });

    this.dados = this.lista;
    this.emAtendimento = this.lista.filter(obj => {
       return obj.status_ticket != "novo"
    })
    this.fila = this.lista.filter(obj => {
      return obj.status_ticket == "novo";
    });
    this.pendente_lista = this.lista.filter(obj => {
      return obj.status_ticket == "pendente";
    });
    this.distribuida = this.lista.filter(obj => {
      return obj.status == "distribuido";
    });
    // nao existe estes dois, deve ser implementado posteriormente
    this.visualizadas = this.lista.filter(obj => {
      return obj.status_ticket == "visualizado";
    });
    this.pendente = this.pendente_lista.length;
    this.todos = this.lista.length
    let total = this.todos
    this.percDistribuida = (this.distribuida.length / total * 100)
    this.percAtendimento = (this.emAtendimento.length / total * 100)
    this.percPendente = (this.pendente / total * 100)
    this.percFila = (this.fila.length / total * 100)

  }
  onNotificaOnOff() {
    this.notificacaoSonora = !this.notificacaoSonora;
    this.onNotificacao();
  }
  onNotificacao() {
    if (this.notificacaoSonora) {
      this.audio.nativeElement.play();
    }
  }
  showFiltro() {
    this.key_minhas_lotacao = null;
    this.myBtnFiltro = false;
    this.modal_tools = true;
  }
  onGetPessoa_lotacao(key) {
    if (this.unSubLoad$ != undefined) {
      this.unSubLoad$.unsubscribe();
    }
    this.emAtendimento = [];
    this.distribuida = [];
    this.visualizadas = [];
    this.pendente = 0;
    this.fila = [];
    this.todos = 0;
    if (!key) {
      this.nivel = '99';
      this.key_lotacao = this.key_lotacao_principal;
      this.lista = [];
      this.onLoad()
    } else {
      this.key_lotacao = key;
      this.nivel = '0';
      this.lista = [];
      this.onLoad()
    }
    this.nome_lotacao = this.genProvider.onGetTableKey(this.lotacao, this.key_lotacao).nome;
    this.onLoadUsuarioLotacao();
  }
  onLoadUsuarioLotacao() {
    this.genProvider.onGetAllFilterPageDesc('login', 'nome', "key_lotacao", this.key_lotacao, 9999).pipe(take(1)).subscribe(data => {
      let temp = JSON.parse(JSON.stringify(data));
      this.login = temp.filter(obj=>{
        return obj.path == this.parametros.path
      })
    },
      err => console.log(err)
    );
  }
}

/*

  ionViewDidLoad() {
  this.onLoadMap();
  this.onLoadFb(25);

  }
  onLoadFb(page?:number){
    this.genProvider.onGetAllPageDesc('colabore','data', page).subscribe(data =>{
      this.lista = JSON.parse(JSON.stringify(data));
       console.log(this.lista)
    },
      err => {
        console.log(err);
        //      loader.dismiss();
      })
  }
  doInfinite(infiniteScroll) {
    this.onLoadFb(this.lista.length+5);
  }

*/
