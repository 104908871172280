/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-departamento',
  templateUrl: './departamento.component.html',
  styleUrls: ['./departamento.component.scss'],
  providers: [ConfirmationService]
})
export class DepartamentoComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  unSub: Subscription[] = [];
  puc: any;
  formulario_puc: FormGroup;
  departamento_puc: any;
  permissao: any = true;
  loading:any;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      sigla: '',
      telefone: '',
      email: '',
      status: [null, Validators.required],
      endereco: ''
    });
    this.formulario_puc = this.formBuilder.group({
      key: null,
      key_departamento: '',
      nome_departamento: '',
      key_puc:'',
      nome: [null, Validators.required]
    });
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnInit() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.cols = [
      { header: 'Nome', field: 'nome' },
      { header: 'Sigla', field: 'sigla' },
      { header: 'E-mail', field: 'email' },
      { header: 'Telefone', field: 'telefone' },
      { header: 'Status', field: 'status' }      
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.onLoad();
    this.onLoadPuc();
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }
  onLoad() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/departamento', "nome").subscribe(data => {
      this.lista = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    ));
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo';
    this.onMostraFrm();
    this.permissao = false;
    this.departamento_puc = [];

  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue({
      key: event.key,
      nome: event.nome,
      numero: event.numero,
      status: event.status,
      sigla: event.sigla,
      telefone: event.telefone,
      email: event.email,
      endereco: event.endereco
    });
    this.titulo = 'Editar';
    this.onLoadDepartamento_Puc(event.key)
    this.onMostraFrm();
    this.permissao = false;

  }

  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
        this.genProvider.remove('dados/' + this.path + '/departamento', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      this.onSubmit();
    }
  }
  onSubmit() {
    this.onMostraFrm();
    this.genProvider.onSaveIncrement("dados/" + this.path + "/departamento", this.formulario.value, "departamento").then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "Lotações")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
//      columnStyles: {
 //       0: { halign: 'left', fillColor: [220, 220, 220] }
 //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onLoadPuc() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/puc', 'nome').subscribe(data => {
      let puc = JSON.parse(JSON.stringify(data));
      this.puc = puc.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  onPuc(key){
    let res = this.puc.filter(obj => {
      return obj.key == key
    });
    if (res.length > 0) {
      this.formulario_puc.patchValue({
        nome : res[0].nome
      });
    }
    console.log (res,this.formulario_puc)
  }
  gravarPuc() {
    let res = this.departamento_puc.filter(obj => {
      return obj.key_puc == this.formulario_puc.value.key_puc
    });
    if (res.length > 0) {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Esta cetral de serviços já esta cadastrada para esta Unidade Operacional, por favor selecione outra.' });
    }else {
      if ((this.formulario.valid) && (this.formulario_puc.valid) && (this.formulario.value.key)) {
        this.onLoadDepartamento_Puc(this.formulario.value.key)
        this.formulario_puc.patchValue({
          key_departamento: this.formulario.value.key,
          nome_departamento: this.formulario.value.nome,
        })
        this.genProvider.onSaveIncrement('dados/' + this.path + '/departamento_puc', this.formulario_puc.value, "departamento_puc").then(t => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
          this.formulario_puc.reset()
        }).catch(e => {
          this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
          console.log(e);
        });
      } else if ((this.formulario.valid) && (this.formulario_puc.valid) && (!this.formulario.value.key)) {
        this.genProvider.onSaveIncrement("dados/" + this.path + "/catalogo", this.formulario.value, "catalogo").then(t => {
          this.onLoadDepartamento_Puc(t)
          this.formulario.patchValue({
            key: t
          })
          this.formulario_puc.patchValue({
            key_departamento: t,
            nome_departamento: this.formulario.value.nome,
          })
          this.genProvider.onSaveIncrement('dados/' + this.path + '/departamento_puc', this.formulario_puc.value, "departamento_puc").then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
            this.formulario_puc.reset()
          }).catch(e => {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
            console.log(e);
          });
        })
      }
    }

  }
  onEditPuc(event) {
    this.formulario_puc.patchValue(event)
  }
  onDeletePuc(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/departamento_puc', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });
  }
  onLoadDepartamento_Puc(key) {
    this.genProvider.onGetFilterOrder('dados/' + this.path + '/departamento_puc', "nome","key_departamento", key).subscribe(data => {
      this.departamento_puc = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    );
  }
}
