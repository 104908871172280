import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    public auth: AngularFireAuth,
  ) {
    
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "Unsaved modifications";
      return event;
    });
    
  }
  ngOnInit() {
    let user = {
      email: 'login@login.com',
      password: '123456'
    };
    this.auth.signInWithEmailAndPassword(user.email, user.password)
      .then(() => {
    //    console.log('Autenticado');
    //    this.auth.authState.subscribe(user => {
    //      console.log(user);
    //   });
      })
      .catch((error: any) => {
        alert('Erro na autenticação ' + error.code);
      });
  }
  ngOnDestroy() {
    this.auth.signOut();
  }
}
