import {Component, OnInit} from '@angular/core';
import { GenProvider } from 'src/providers/gen/gen';
import {AppMainComponent} from './app.main.component';
import { navigation } from './_nav';

@Component({
    selector: 'app-menu',
    template: `
		<ul class="navigation-menu">
         <div *ngFor="let item of model; let i = index;">
             <li app-menuitem *ngIf="isShow(item)" [item]="item" [index]="i" [root]="true"></li>
         </div>
		</ul>
    `
})
export class AppMenuComponent implements OnInit {
    public navigation : any;
    public model: any[];
    permissao: any;

    constructor(public app: AppMainComponent, public genProvider: GenProvider) {
        this.navigation = navigation;
     }
      ngOnInit() {
        setTimeout(() => {
            this.isMenu();
          }, 1000);
    }
      public isDivider(item) {
        return item.divider ? true : false
      }
      public isTitle(item) {
        return item.title ? true : false
      }
      public isShow(item) {
       return item.show ? true : false
      }
      public isMenu(){
        let parametro = this.genProvider.parametros;
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',parametro.key_login)
          .subscribe(data => {
            this.model = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigation.length; index++) {
                if (!this.isDivider(this.navigation[index]) && !this.isTitle(this.navigation[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigation[index].nome
                  });
                 if (res.length > 0) {
                   this.navigation[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigation[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigation[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigation[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigation[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (parametro.nivel=="99"){
                           this.navigation[index].itens[i].show = true
                         } else {
                           this.navigation[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (parametro.nivel=="99"){
                     this.navigation[index].show = true
                     let child :any
                     try {
                        child = this.navigation[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigation[index].itens.length; i++) {
                          if (parametro.nivel=="99"){
                            this.navigation[index].itens[i].show = true
                          } else {
                            this.navigation[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigation[index].show = false
                   }
                 }
                }
              }
               this.model = this.navigation;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (parametro.nivel=="99"){
            this.model = this.navigation;
            console.log (this.model)
          }
        }
    
      }
}
