/* tslint:disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { Subscription } from 'rxjs/Rx';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss'],
  providers: [ConfirmationService]
})
export class EmpresasComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @Input() closedBtn: boolean = false;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  lotacao: any;
  tipo: any[];
  unSub: Subscription[] = [];
  cargo: any;
  permissao: any = true;
  loading: any;
  page: any = [];
  qtd_upload: any;
  busca: string;
  unSub$: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validaCampo: ValidaCampoProvider
  ) {
 //   console.log (this.closedBtn)
    this.qtd_upload = 9;
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      contato: [null, Validators.required],
      path: [null, Validators.required],
      telefone: '',
      status: [null, Validators.required],
      email: '',
      facebook: '',
      instagram: '',
      time: ''
    });
    this.titulo = 'Empresas';
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  onCombo() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.tipo = [
      { nome: 'Interno' },
      { nome: 'Externo' }
    ]
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Nome', field: 'nome' },
      { header: 'Path/Sigla', field: 'path' },
      { header: 'Celular', field: 'celular' },
      { header: 'E-mail', field: 'email' },
      { header: 'Contato', field: 'contato' },
      { header: 'Status', field: 'status' }
    ];
    this.page = [
      { nome: '10', qtd: 9 },
      { nome: '50', qtd: 49 },
      { nome: '100', qtd: 99 },
      { nome: '200', qtd: 199 },
      { nome: '300', qtd: 299 },
      { nome: '400', qtd: 399 },
      { nome: '500', qtd: 499 },
      { nome: '1000', qtd: 999 },
      { nome: 'Todos', qtd: 5000 }
    ]
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  ngOnInit() {
    this.onCombo();
    this.onLoad();
  }
  ngOnDestroy() {
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unSub$) {
      this.unSub$.unsubscribe()
    }
  }
  onLoadEmpresas() {
    if (this.busca) {
      this.unSub$.unsubscribe()
      this.loading = true;
      this.lista = []
      this.genProvider.onSqlQuery('dados/' + this.path, "select * from empresas where nome like '" + this.busca.toUpperCase() + "%'").then(data => {
        this.lista = JSON.parse(JSON.stringify(data));
        if (this.lista.length > 0) {
          this.lista = this.lista.map(res => {
            res.codigo = parseInt(res.key)
            //        res.origem = res.lotacao.origem;
            if (res.telefone) {
              if (res.telefone.length == 15) {
                res.celular = res.telefone;
              } else {
                res.celular = this.validaCampo.validaTelefonePromo(res.telefone);
              }
            }
            return res;
          });
          this.loading = false;
        } else {
          this.loading = false;
          this.messageService.add({ severity: 'info', summary: 'Atenção', detail: 'Não foi encontrado nenhum resultado para: ' + this.busca.toUpperCase() });
        }
      })
    }
  }
  onLoad() {
    this.loading = true;
    this.unSub$ = this.genProvider.onGetAllPageDesc('empresas', "time", this.qtd_upload + 1).subscribe(data => {
      this.unSub$.unsubscribe()
      this.lista = data;
      this.lista = this.lista.map(res => {
        res.codigo = parseInt(res.key)
        if (res.telefone) {
          if (res.telefone.length == 15) {
            res.celular = res.telefone;
          } else {
            res.celular = this.validaCampo.validaTelefonePromo(res.telefone);
          }
        }
        //        res.telefone = this.validaCampo.formataTelefonePadrao(res.telefone);
        return res;
      });
      // console.log(this.lista)
      this.loading = false;
    },
      err => console.log(err)
    );
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo';
    this.onMostraFrm();
    this.formulario.patchValue({
      tipo: "Interno",
      origem: "Painel",
      time: Date.now()
    });
    this.permissao = false;

  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event);
    this.titulo = 'Editar';
    this.onMostraFrm();
    this.permissao = false;
  }

  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
        this.genProvider.remove('empresas', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      let data = this.formulario.value
      let busca = data.nome.toLowerCase();
      this.formulario.patchValue({
        busca: busca
      })
      this.onSubmit();
    }
  }
  onSubmit() {
    this.onMostraFrm();
    //console.log(this.formulario.value)
    this.genProvider.onSaveIncrementZeroRoot("empresas", this.formulario.value, "empresas", 6).then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
      this.onLoad();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      // console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "empresass")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }


  onNome() {
    if (this.formulario.value.nome) {
      this.formulario.patchValue({
        nome: this.formulario.value.nome.toUpperCase()
      });
    }
  }
  onPath() {
    if (this.formulario.value.path) {
      this.formulario.patchValue({
        path: this.formulario.value.path.toUpperCase()
      });
    }
  }

 }


