export const navigationTools = [
    {nome: 'Dash Bot', icon: 'pi pi-fw pi-sliders-h', routerLink: ['dashticket']},
    {nome: 'Call Center', icon: 'pi pi-fw pi-sliders-h', routerLink: ['callcenter']},
     {
        nome: 'Canais de Atendimento', icon: 'pi pi-share-alt',
        itens: [
            {nome: 'Bot de Atendimento', icon: 'pi pi-discord', routerLink: ['bot']},
            {nome: 'Intensão do Bot', icon: 'pi pi-comments', routerLink: ['lotacao']},
        ]
    },
    {
        nome: 'Configurações', icon: 'pi pi-fw pi-cog', routerLink: ['/pages'],
        itens: [
            {nome: 'Parametros', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
            {nome: 'Grupos', icon: 'fa fa-users', routerLink: ['grupo']},
            {nome: 'Usuários', icon: 'fa fa-user', routerLink: ['usuario']},
            {nome: 'Empresas', icon: 'fa fa-user', routerLink: ['empresas']},
            {nome: 'Importar Dados', icon: 'fa fa-user', routerLink: ['importData']},
            {nome: 'Minhas Lotações', icon: 'pi pi-fw pi-check-square', routerLink: ['pessoa_lotacao']},
        ]
    },
    {
        nome: 'Utilidades', icon: 'pi pi-fw pi-book', routerLink: ['/uikit'],
        itens: [
            {nome: 'Enquetes', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout']},
            {nome: 'Galeria de Mídias', icon: 'pi pi-fw pi-check-square', routerLink: ['galeria']},
            {nome: 'Publicidade', icon: 'pi pi-comments', routerLink: ['resposta']},
            {nome: 'Disparo de Mensagens', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
        ]
    },
    {
        nome: 'Business', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.hyperchannel.com.br']
    },
    {
        nome: 'Documentação', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
    }
];
