export const navigationRel = [
    {nome: 'Ticket´s por lotação ', icon: 'pi pi-fw pi-desktop', routerLink: ['relTicketLotacao']},
    {nome: 'Rel. Atendimento', icon: 'pi pi-fw pi-desktop', routerLink: ['relAtendimento']},
    {nome: 'Rel. Atendimento Diário', icon: 'pi pi-fw pi-desktop', routerLink: ['relAtendimentoDiario']},
    {nome: 'Rel. Ponto Agente', icon: 'pi pi-fw pi-clock', routerLink: ['relPonto']},
    {nome: 'Rel. Intervalos Agente', icon: 'pi pi-fw pi-search', routerLink: ['relIntervalo']},
    {nome: 'Rel. Ticket´s por lotação ', icon: 'pi pi-fw pi-desktop', routerLink: ['relTicketLotacao']},
    {nome: 'Listagem de Catálogo ', icon: 'pi pi-fw pi-desktop', routerLink: ['relCatalogo']},
    {nome: 'Rel Quantificação ', icon: 'pi pi-fw pi-desktop', routerLink: ['relQuantificacao']},
    {nome: 'Rel Ticket Geral', icon: 'pi pi-fw pi-desktop', routerLink: ['relTicketGeral']},
];
