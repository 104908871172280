
// tslint:disable
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable()
export class ValidaCampoProvider {
    formatado: any;
    options: RequestOptions;
    constructor(public http: Http) {
        let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded  ;charset=iso-8859-1' });
        this.options = new RequestOptions({ headers: headers, method: "get" });
    }
    validaCEP(valor) {
        let cep = valor.replace(/\D/g, '');
        cep = cep.replace('-', '');
        if (cep != '') {
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                let url = `https://viacep.com.br/ws/${cep}/json`
                return this.http.get(url, this.options).map(
                    dados => {
                        return dados.json();
                    },
                    error => {
                        console.log('erro no retorno ', error);
                        return 'CEP Invalido! ' + error;
                    }
                );
            }
        }
    }

    pesquisaCNPJ(cnpj) {

        let headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        let path = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;
        return this.http
            .post(path, this.options)
            .map((res: Response) => {
                return res.json();
            });

    }

    pesquisaCNAE(cnae) {


        // https://servicodados.ibge.gov.br/api/v2/cnae/classes/{classe}
        // https://servicodados.ibge.gov.br/api/docs/cnae?versao=2
        /* Exemplos:
       https://www.receitaws.com.br/v1/cnpj/01611666000149
       https://servicodados.ibge.gov.br/api/v2/cnae/classes/01113


           Obtém a classe pelo identificador 01113

       https://servicodados.ibge.gov.br/api/v2/cnae/classes/01113|01121

           Obtém as classes pelos identificadores 01113 e 01121
           */
        let path = `https://servicodados.ibge.gov.br/api/v2/cnae/classes/`;
        return this.http
            .post(path, this.options)
            .map((res: Response) => {
                return res.json();
            });

    }


    formataTelefone(v) {
        try {
            v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
            if (v.length == 8) {
                console.log("8 digitos")
                v = '00' + v
                v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
                v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
            } else if (v.length == 9) {
                console.log("9 digitos")
                v = '00' + v
                v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
                v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
            } else if (v.length == 10) {
                console.log("CELULAR") // ok
                v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
                v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
            } else if (v.length == 11) {
                if (v.substring(0, 1) == '0') {
                    console.log("0800")
                    //    v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
                    v = v.replace(/(\d)(\d{7})$/, '$1-$2'); //Coloca hífen entre o nono e o decimo dígitos
                } else {
                    console.log("celular") // ok
                    v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
                    v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
                }
            }
            return v;
        } catch (error) {
            v = '000000000000';
            return v;
        }



    }
    formataTelefoneZap(v) {
        try {
            v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
            v = v.substring(2, 20)
            v = v.replace(/^(\d{2})(\d)/g, '($1) 9$2'); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
        } catch (error) {
            v = '000000000000'
        }

        return v;
    }
    formataTelefoneZap2(v) {
        try {
            v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
            //    v = v.substring(2,20)
            v = v.replace(/^(\d{2})(\d)/g, ' $1  $2'); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{9})$/, '$1 $2'); //Coloca hífen entre o quarto e o quinto dígitos
            v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
        } catch (error) {
            v = '000000000000'
        }

        return v;
    }
    formataTelefoneDDD(v) {
        try {
            v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
            v = v.substring(2, 20)
            v = v.replace(/^(\d{2})(\d)/g, '($1) 9$2'); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
        } catch (error) {
            v = '(00) 0000-0000'
        }

        return v;
    }

    formataTelefonePadrao(v) {
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        v = v.substring(2, 20)
        v = v.replace(/^(\d{2})(\d)/g, '$1 9$2'); //Coloca parênteses em volta dos dois primeiros dígitos
        v = v.replace(/(\d)(\d{4})$/, '$1$2'); //Coloca hífen entre o quarto e o quinto dígitos
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        return v;
    }
    transPadraoWhatsapp(v) { // trabforma 5591980333070 p/ 559180333070
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        var v1 = v.substring(0, 2)
        var v2 = v.substring(3, 20)
        var v3 = "55" + v1 + v2
        return v3;
    }
    formataTelefoneFull(v) {
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        v = "55" + v.substring(0, 20)
        return v;
    }

    // cnpj e cpf
    public verifica_cpf_cnpj(valor) {
        valor = valor.toString();
        valor = valor.replace(/[^0-9]/g, '');
        if (valor.length === 11) {
            return 'CPF';
        } else if (valor.length === 14) {
            return 'CNPJ';
        } else {
            return false;
        }
    } // verifica_cpf_cnpj

    private calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
        digitos = digitos.toString();
        for (var i = 0; i < digitos.length; i++) {
            soma_digitos = soma_digitos + digitos[i] * posicoes;
            posicoes--;
            if (posicoes < 2) {
                posicoes = 9;
            }
        }
        soma_digitos = soma_digitos % 11;
        if (soma_digitos < 2) {
            soma_digitos = 0;
        } else {
            soma_digitos = 11 - soma_digitos;
        }
        var cpf = digitos + soma_digitos;
        return cpf;
    } // calc_digitos_posicoes

    public valida_cpf(valor) {
        return new Promise(resolve => {
            valor = valor.toString();
            valor = valor.replace(/[^0-9]/g, '');
            var digitos = valor.substr(0, 9);
            var novo_cpf = this.calc_digitos_posicoes(digitos);
            novo_cpf = this.calc_digitos_posicoes(novo_cpf, 11);
            if (novo_cpf === valor) {
                resolve(true);
            } else {
                resolve(false);
            }
        })
    } // valida_cpf

    public valida_cnpj(valor) {
        return new Promise(resolve => {
            valor = valor.toString();
            valor = valor.replace(/[^0-9]/g, '');
            var cnpj_original = valor;
            var primeiros_numeros_cnpj = valor.substr(0, 12);
            var primeiro_calculo = this.calc_digitos_posicoes(
                primeiros_numeros_cnpj,
                5
            );
            var segundo_calculo = this.calc_digitos_posicoes(primeiro_calculo, 6);
            var cnpj = segundo_calculo;
            if (cnpj === cnpj_original) {
                resolve(true);
            }
            resolve(false);
        })
    } // valida_cnpj

    public valida_cpf_cnpj(valor) {
        return new Promise(resolve => {
            var valida = this.verifica_cpf_cnpj(valor);
            valor = valor.toString();
            valor = valor.replace(/[^0-9]/g, '');
            if (valida === 'CPF') {
                resolve(this.valida_cpf(valor));
            } else if (valida === 'CNPJ') {
                resolve(this.valida_cnpj(valor));
            } else {
                return false;
            }
        })
    } // valida_cpf_cnpj

    public formata_cpf_cnpj(valor) {
        this.formatado = false;
        var valida = this.verifica_cpf_cnpj(valor);
        valor = valor.toString();
        valor = valor.replace(/[^0-9]/g, '');
        if (valida === 'CPF') {
            if (this.valida_cpf(valor)) {
                this.formatado = valor.substr(0, 3) + '.';
                this.formatado += valor.substr(3, 3) + '.';
                this.formatado += valor.substr(6, 3) + '-';
                this.formatado += valor.substr(9, 2) + '';
            }
        } else if (valida === 'CNPJ') {
            if (this.valida_cnpj(valor)) {
                this.formatado = valor.substr(0, 2) + '.';
                this.formatado += valor.substr(2, 3) + '.';
                this.formatado += valor.substr(5, 3) + '/';
                this.formatado += valor.substr(8, 4) + '-';
                this.formatado += valor.substr(12, 14) + '';
            }
        }
        return this.formatado;
    }

    public formata_cpf(valor) {
        this.formatado = false;
        var valida = this.valida_cpf(valor);
        valor = valor.toString();
        valor = valor.replace(/[^0-9]/g, '');
        if (valida) {
            this.formatado = valor.substr(0, 3) + '.';
            this.formatado += valor.substr(3, 3) + '.';
            this.formatado += valor.substr(6, 3) + '-';
            this.formatado += valor.substr(9, 2) + '';
        } else {
            this.formatado = false;
        }
        return this.formatado;
    }

    // fim cnpj e cpf
    validaCelular(v) {
        return new Promise(resolve => {
            v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
            if (v.length != 11) {
                resolve(null)
            } else {
                v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
                v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
                resolve(v);
            }
        })
    }

    validaTelefone(v) {
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        if (v.length != 11) {
            return false;
        } else {
            v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
            return v;
        }
    }
    validaTelefoneZap(v) {
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        if (v.length < 10) {
            return false;
        } else {
            v = v.replace(/^(\d{2})(\d)/g, '($1) 9$2'); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
            return v;
        }
    }
    validaTelefonePromo(v) {
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        if (v.length < 10) {
            return false;
        } else {
            v = v.substr(2, 20)
            v = v.replace(/^(\d{2})(\d)/g, '($1) 9$2'); //Coloca parênteses em volta dos dois primeiros dígitos
            v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
            return v;
        }
    }

    validaEmail(v) {
        return new Promise(resolve => {
            let valor = v.toLowerCase();
            let usuario = valor.substring(0, valor.indexOf('@'));
            let dominio = valor.substring(valor.indexOf('@') + 1, valor.length);
            let res = null
            if (
                usuario.length >= 1 &&
                dominio.length >= 3 &&
                usuario.search('@') == -1 &&
                dominio.search('@') == -1 &&
                usuario.search(' ') == -1 &&
                dominio.search(' ') == -1 &&
                dominio.search('.') != -1 &&
                dominio.indexOf('.') >= 1 &&
                dominio.lastIndexOf('.') < dominio.length - 1
            ) {
                resolve(valor);
            }
            resolve(res)
        })
    }
    pegaPrimeraPalavra(v) {
        var strg = v;
        var result = strg.split(' ')[0];// separar str por espaços
        return result;
    }

}
