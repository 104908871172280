import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from '../../providers/gen/gen';
import { AlertService } from '../services/alert.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs-compat/Subscription';
import { ServiceProvider } from 'src/providers/service/service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-painelControl',
  templateUrl: './painelControl.component.html',
  styleUrls: ['./painelControl.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class PainelControlComponent implements OnInit {
  @ViewChild('dtFila') dtFila: Table;
  @ViewChild('dtAtendimento') dtAtendimento: Table;
  @ViewChild('dtDenuncia') dtDenuncia: Table;
  filaAgente: any;
  path: any;
  parametros: any;
  mostraUsuario: any;
  key: any;
  chatFila: any = [];
  loading: boolean;
  chatAtendimento: any;
  fila: number = 0;
  percFila: number = 0;
  percAtendimento: number = 0;
  percDevolvida: number = 0;
  percPendente: number = 0;
  conversas: any;
  atendimento: any = 0;
  devolvida: any = 0;
  pendente: any = 0;
  chat_msg: any[];
  mostraMgs: boolean;
  chat: any;
  msgResp: any;
  msgPendente: any;
  ponto: any = [];
  mostraMgsFila: boolean;
  descricao: any;
  novaMsg: any = 0;
  percNovaMsg: number = 0;
  detalhe: any;
  key_agente: any;
  dados: any = [];
  midia: { type: any; file: any; lat: any; lng: any; };
  mostraMidia: boolean;
  agente: any = [];
  distribuida: number = 0;
  percDistribuida: number = 0;
  emAtendimento: any = [];
  data: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };
  protocolo: any;
  dataChart: any;
  isStart: any;
  unSub: Subscription[] = [];
  unMsg: Subscription;
  unChat$: Subscription;
  days: any;
  encerrado: any;
  denuncias: any = [];
  inicio: any;
  final: any;
  ticket: any;
  mostraMgsAvaliada: boolean;
  situacao: any;
  origem: any;
  denunciaProtocolo: any;
  ticket2: any;
  log: any;
  mdAcaoUsuario !: any;
  usuario: any;
  listaAcao: any = [];
  acaoUsuario: any;
  constructor(
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private service: ServiceProvider) {
    this.midia = {
      type: '',
      file: '',
      lat: 0,
      lng: 0
    }
    this.filaAgente = this.genProvider.parametros.filaAgente
    this.path = this.genProvider.parametros.path;
    this.parametros = this.genProvider.parametros;
    this.mostraUsuario = this.genProvider.parametros.mostraUsuario
  }
  onClose() {
    this.router.navigate(['']);
  }

  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unMsg != undefined) {
      this.unMsg.unsubscribe();
    }
    if (this.unChat$ != undefined) {
      this.unChat$.unsubscribe();
    }
  }
  onCombos() {
    this.listaAcao = [
      { nome: 'Finalizar Atendimento' },
      { nome: 'Finalizar Ponto' }
    ]
  }
  ngOnInit() {
    this.days = 1616183665867;
    this.onCombos();
    this.onLoadPonto();
    this.onLoadStatus();
    this.onLoadParamentro();
    this.onLoadUsuario()
    this.primengConfig.ripple = true;
  }
  onLoadUsuario() {
    this.unSub.push(this.genProvider.onGetFilterOrder('login', 'nome', 'path', this.parametros.path).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      this.agente = [];
      lista.forEach(e => {
   //     if (e.nivel != "99") {
          this.agente.push(e)
   //     }
      });
    },
      err => console.log(err)
    ));

  }
  onLoadParamentro() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/parametros', 'token').subscribe(
      data => {
        let dados = JSON.parse(JSON.stringify(data));
        if (dados.length > 0) {
          this.key = dados[0].key;
        }
      },
      err => console.log(err)
    ));
  }

  onLoadStatus() {
    let filtro = [
      "Novo",
      "Pendente",
      "Nova Mensagem",
      "Encerrado",
      "Em Atendimento",
      "Distribuido",
      "Devolvido"
    ]
    let unChatStatus = this.genProvider.onGetArray('dados/' + this.path + '/chat', 'time', 'status', 'in', filtro, "asc").subscribe(data => {
      unChatStatus.unsubscribe()
      let temp = JSON.parse(JSON.stringify(data));
      if (temp.length > 0) {
        temp.forEach(e => {
          if (e.origem) {
            this.dados.push(e)
          }          
        });
      }
      let filtro2 = [
        "Novo",
        "Finalizado",
        "Pendente",
        "Nova Mensagem",
        "Encerrado",
        "Em Atendimento",
        "Distribuido",
        "Devolvido"
      ]
      this.unChat$ = this.genProvider.onGetArray('dados/' + this.path + '/chat', 'time', 'status', 'in', filtro2, "desc", 1).subscribe(data => {
        let dados = JSON.parse(JSON.stringify(data));
        if (this.dados.length > 0) {
          let result = this.dados.filter(obj => {
            return obj.key == dados[0].key
          })
          if (result.length > 0) {
            const index = this.dados.indexOf(result[0]);
            this.dados.splice(index, 1);
          }
        }
        if (dados.length > 0) {
          if (dados[0].status != "Finalizado" && dados[0].origem) {
            this.dados.push(dados[0])
          }
        }
        if (this.dados.length > 0) {
          let resNova = this.dados.filter(obj => {
            return (obj.status == "Novo" || obj.status == "Devolvido") && obj.novo == '1' 
          })
          resNova.sort(function (a, b) {
            if (a.time > b.time) {
              return 1;
            }
            if (a.time < b.time) {
              return -1;
            }
            return 0;
          });
          let c = 0;
          this.chatFila = resNova.map(data => {
            c++
            data.count = c;
            if (data.nome == undefined) {
              data.nome = 'Sem Indentificacao'
            }
            if (data.origem == "Messenger") {
              data.image = 'assets/images/messenger.png';
            } else if (data.origem == "ChatBot") {
              data.image = 'assets/images/chat.png';
            } else if (data.origem == "Formulario") {
              data.image = 'assets/images/voz.png';
            } else if (data.origem == "Whatsapp") {
              data.image = 'assets/images/whatsapp.png';
            } else {
              data.image = 'assets/images/whatsapp.png';
            }
            data.origem
            data.idUser = data.telefone;
            data.id = data.telefone;
            data.last_time = "";
            if (!this.mostraUsuario) {
              data.nome = "Usuário anônimo"
              data.idUser = this.encId(data.telefone);
              data.id = data.telefone;
            }
            return data;
          });
          this.fila = resNova.length
          let res = this.dados.filter(obj => {
            return obj.status == "Em Atendimento"
          })
          this.atendimento = res.length
          res = this.dados.filter(obj => {
            return obj.status == "Devolvido" && obj.novo == '1'
          })
          this.devolvida = res.length

          res = this.dados.filter(obj => {
            return obj.status == "Pendente"
          })
          this.pendente = res.length
          res = this.dados.filter(obj => {
            return obj.status == "Distribuido"
          })
          this.distribuida = res.length
          res = this.dados.filter(obj => {
            return obj.status == "Nova Mensagem"
          })
          this.novaMsg = res.length

          this.emAtendimento = this.dados.filter(obj => {
            return obj.status != "Enviando" && obj.status != "Tramitado" && obj.status != "Novo" && obj.status != "Devolvido"  
          })
          console.log (this.emAtendimento)
          this.encerrado = res.filter(obj => {
            return obj.status == "Encerrado"
          })
          let total = this.fila + this.atendimento + this.devolvida + this.pendente + this.distribuida + this.novaMsg
          this.percNovaMsg = (this.novaMsg / total * 100)
          this.percDistribuida = (this.distribuida / total * 100)
          this.percDevolvida = (this.devolvida / total * 100)
          this.percAtendimento = (this.atendimento / total * 100)
          this.percPendente = (this.pendente / total * 100)
          this.percFila = (this.fila / total * 100)
        }

      });
    },
      err => console.log(err)
    );
  }
  onLoadAtendimentos() {
    this.denuncias =[];
    if ((!this.ticket) && (this.inicio) && (this.final)) {
      this.unChat$.unsubscribe()
      let inicio = new Date(this.inicio).getTime()
      let fim = new Date(this.final).getTime()
      let unSub = this.genProvider.onGetPeriodo('dados/' + this.path + '/chat', 'data_criacao', inicio, fim).subscribe(data => {
        unSub.unsubscribe()
        let temp = JSON.parse(JSON.stringify(data));
        let dados = temp.filter(obj => {
          return obj.status != "registrando" && obj.origem
        })
        if (dados.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo para consulta.' });
        } else {
          let c = 0;
          this.denuncias = dados.map(data => {
            c++
            data.count = c;
            if (data.nome == undefined) {
              data.nome = 'Sem Indentificacao'
            }
            if (data.origem == "Messenger") {
              data.image = 'assets/images/messenger.png';
            } else if (data.origem == "ChatBot") {
              data.image = 'assets/images/chat.png';
            } else if (data.origem == "Formulario") {
              data.image = 'assets/images/voz.png';
            } else if (data.origem == "Whatsapp") {
              data.image = 'assets/images/whatsapp.png';
            } else {
              data.image = 'assets/images/whatsapp.png';
            }
            return data;
          });
        }
      });
    //  this.onLoadStatus();
    } else if (this.ticket) {
      this.unChat$.unsubscribe()
      let unSub = this.genProvider.onGetfilter('dados/' + this.path + '/chat', 'protocolo', parseInt(this.ticket)).subscribe(data => {
        unSub.unsubscribe()
        let temp = JSON.parse(JSON.stringify(data));
        let dados = temp.filter(obj => {
          return obj.status != "registrando"
        })
        if (dados.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este protocolo.' });
        } else {
          let c = 0;
          this.denuncias = dados.map(data => {
            c++
            data.count = c;
            if (data.nome == undefined) {
              data.nome = 'Sem Indentificacao'
            }
            if (data.origem == "Messenger") {
              data.image = 'assets/images/messenger.png';
            } else if (data.origem == "ChatBot") {
              data.image = 'assets/images/chat.png';
            } else if (data.origem == "Formulario") {
              data.image = 'assets/images/voz.png';
            } else if (data.origem == "Whatsapp") {
              data.image = 'assets/images/whatsapp.png';
            } else {
              data.image = 'assets/images/whatsapp.png';
            }
            return data;
          });
        }


      });
   //   this.onLoadStatus();
    }

  }

  onLoadPonto() {
    this.unSub.push(this.genProvider.onGetFilterOrderDesc('dados/' + this.path + '/ponto', 'entrada', 'status', 'Aberto').subscribe(data => {
      this.ponto = JSON.parse(JSON.stringify(data));
      let c = 0;
      this.ponto = this.ponto.map(res => {
        c++
        res.count = c;
        res.nome = res.nome.split(' ').slice(0, 1).join(' ');
        res.cor = res.atendimento_status.replaceAll(" ", "");
        res.ultimo_atendimento = new DatePipe("pt-BR").transform(res.time, "dd/MM - HH:mm");
        res.msg = res.atendimento_status + this.service.ctrl + res.ultimo_atendimento;
        if (res.atendimento_status == 'Livre') {
          res.img = 'assets/images/dashboard/ATENDENTE-VERDE.png'
        } else {

        } if (res.atendimento_status == 'Em Atendimento') {
          res.img = 'assets/images/dashboard/ATENDENTE-VERMELHO.png'
        } if (res.atendimento_status == 'Intervalo') {
          res.img = 'assets/images/dashboard/ATENDENTE-AMARELO.png'
        }
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  onLoadMsg(key) {
    this.chat_msg = [];
    this.unMsg = this.genProvider.onGetAll('dados/' + this.path + '/chat/' + key + '/chat_body', 'time').subscribe(data => {
      let chat_msg = JSON.parse(JSON.stringify(data));
      let i = 0
      this.unMsg.unsubscribe();
      this.chat_msg = chat_msg.map(res => {
        i++
        if (!res.status){
          res.status = 'Recebida'
        }
        if (res.type == "location") {
          let coor = res.value.split(/\s*;\s*/);
          res.lat = parseFloat(coor[0]);
          res.lng = parseFloat(coor[1]);
        }
        return res;
      });
    },
      err => {
        console.log(err);
      }
    );
  }
  encId(str) {
    var encoded = "";
    for (let i = 0; i < str.length; i++) {
      var a = (i);
      var b = a ^ 1;    // bitwise XOR with any number, e.g. 123
      encoded = encoded + (b);
    }
    return encoded;
  }
  onMostraMgsFila(dados) {
    this.onLoadMsg(dados.protocolo);
    this.dados = dados;
    this.protocolo = dados.protocolo
    this.detalhe = dados.value
    this.mostraMgsFila = true

  }
  onMostraMgsAvaliada(dados) {
    this.onLoadMsg(dados.protocolo);
    this.dados = dados;
    this.protocolo = dados.ticket + " #" + dados.protocolo
    this.detalhe = dados.detalhe
    this.situacao = dados.situacao
    this.mostraMgsAvaliada = true
    this.origem = dados.origem
  }
  onMostraMgs(dados) {
    this.onLoadMsg(dados.protocolo);
    this.dados = dados;
    this.protocolo = dados.protocolo
    this.detalhe = dados.value
    this.mostraMgs = true
  }
  onDistribuicao(e) {
    this.unChat$.unsubscribe()
    let res = this.agente.filter(obj=>{
      return obj.key == this.key_agente
    })
    let dados = {
      key: e.protocolo,
      key_usuario: this.key_agente,
      nome_usuario: res[0].nome,
      key_lotacao: res[0].key_lotacao,
      status: "Distribuido",
      novo : '0'
    }
    this.genProvider.onSaveObjeto('dados', this.path, "chat", dados);
    let unSub = this.genProvider.onGetfilter('dados/' + this.path + '/chat', "protocolo", e.protocolo).subscribe(data => {
      unSub.unsubscribe()
      let res = JSON.parse(JSON.stringify(data));
      let dados = {
        key: res[0].key,
        key_usuario: this.key_agente,
        status: "Distribuido"
      }
      this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat', dados)
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Distribuição concluida.' });
    });
    this.onLoadStatus();
    this.mostraMgsFila = false
  }
  openMidia(res) {
    console.log (res)
    this.midia = {
      type: res.type,
      file: res.value,
      lat: res.lat || 0,
      lng: res.lng || 0
    }
    this.mostraMidia = true;
  }
  onFinalizaAtendimento(event) {
    let dados_ponto = {};
    if (this.acaoUsuario == 'Finalizar Atendimento') {
      dados_ponto = {
        key: event.key,
        protocolo: '',
        atendimento_status: "Livre",
        canal: ''
      }
    } else {
      var dtInicio = new DatePipe("pt-BR").transform(event.entrada, "dd/MM/yyyy - HH:mm:ss"); "20/06/2017 16:40:00";
      var dtAtual = new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 11:20:00";
      var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      let totalTempo = s;
      dados_ponto = {
        key: event.key,
        saida: Date.now(),
        status: "Encerrado",
        atendimento_status: "Encerrado",
        canal: '',
        atendimento_time: Date.now(),
        isSaida: true,
        total: totalTempo,
        protocolo: '',
      }
    }
    this.confirmationService.confirm({
      message: 'Confirma a liberação de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
          this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
          this.mdAcaoUsuario = false;
        })
      },
      reject: () => {
        this.messageService.add({ severity: "error", summary: 'Rejected', detail: 'Registro cancelado' });
        this.mdAcaoUsuario = false;
      }
    });

  }
  onUsuario() {
    let unSub = this.genProvider.onGetFilterOrder('login', 'nome', 'path', this.parametros.path).subscribe(data => {
      unSub.unsubscribe()
      let query = JSON.parse(JSON.stringify(data));
      query.forEach(e => {
        console.log(e.nome, "=>", e.login)

      });
    })
  }

  onAcaoUsuario(user) {
    this.acaoUsuario = null;
    this.usuario = user;
    this.mdAcaoUsuario = true;
  }
}

/*

  ionViewDidLoad() {
  this.onLoadMap();
  this.onLoadFb(25);

  }
  onLoadFb(page?:number){
    this.genProvider.onGetAllPageDesc('colabore','data', page).subscribe(data =>{
      this.lista = JSON.parse(JSON.stringify(data));
       console.log(this.lista)
    },
      err => {
        console.log(err);
        //      loader.dismiss();
      })
  }
  doInfinite(infiniteScroll) {
    this.onLoadFb(this.lista.length+5);
  }

*/