/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-puc',
  templateUrl: './puc.component.html',
  styleUrls: ['./puc.component.scss'],
  providers: [ConfirmationService]
})
export class PucComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @ViewChild('table') table: Table;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  formulario_sub: FormGroup;
  subpuc: any;
  unSub: Subscription[] = [];
  permissao: any = true;
  lotacao: any;
  tabMenuitens: { id: string; label: string; icon: string; }[];
  page: string;
  formulario_catalogo: FormGroup;
  catalogo: any;
  catalogoItem: any;
  loading:any;
  plataforma: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      descricao: [null, Validators.required],
      status: [null, Validators.required],
      responsavel: [null, Validators.required],
      telefone: [null, Validators.required],
      plataforma: [null, Validators.required],
      geoLocalizacao: false,
      assistenteAtendimento: false,
      key_lotacao: [null, Validators.required],
      nome_lotacao: ''
    });
    this.formulario_catalogo = this.formBuilder.group({
      key_catalogo: null
    })
  }
  onClose() {
    this.router.navigate(['']);
  }
  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnInit() {
    this.page = "1";
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.plataforma = [
      { key:'padrao', nome: 'Call Center' },
      { key:'anonima', nome: 'Anônima' }
    ]
    this.tabMenuitens = [
      { id: '1', label: 'Dados', icon: 'pi pi-fw pi-globe' },
      { id: '2', label: 'Catálogos', icon: 'pi pi-fw pi-align-justify' }
    ];
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Nome', field: 'nome' },
      { header: 'Descrição', field: 'descricao' },
      { header: 'Responsável', field: 'responsavel' },
      { header: 'Telefone', field: 'telefone' },
      { header: 'Central de Operação', field: 'nome_lotacao' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.onLoad();
    this.onLoadlotacao();
    this.onLoadCatalogo();
  }
  onTab(e) {
    this.page = e.activeItem.id
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }
  onLoad() {
    this.loading = true;
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/puc', "nome").subscribe(data => {
      this.lista = JSON.parse(JSON.stringify(data));
      this.loading = false;
    },
      err => console.log(err)
    ));
  }
  onLoadlotacao() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/lotacao', 'nome').subscribe(data => {
      this.lotacao = data;
    },
      err => console.log(err)
    ));
  }
  onlotacao(key) {
    let res = this.lotacao.filter(obj => {
      return obj.key == key
    });
    if (res.length > 0) {
      this.formulario.patchValue({
        nome_lotacao: res[0].nome,
        origem: 'Painel'
      });
    }
  }
  onNovo() {
    this.permissao = true;
    this.formulario.reset();
    this.titulo = 'Nova';
    this.onMostraFrm();
    this.permissao = false;
  }
  onEdit(event: any) {
    this.permissao = true;
    this.formulario.reset();
    this.formulario.patchValue(event);
    this.permissao = false;
    this.titulo = 'Editar puc';
    this.onMostraFrm();
    this.onLoadCatalogoItem(event.key)
  }
  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/puc', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      this.onSubmit();
    }
  }
  onSubmit() {
    this.onMostraFrm();
    this.genProvider.onSaveIncrement("dados/" + this.path + "/puc", this.formulario.value, "puc").then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "pucs")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onLoadCatalogo() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').subscribe(data => {
      this.catalogo = JSON.parse(JSON.stringify(data));
      /*
         this.catalogo = catalogo.map(res => {
           res.nomeDescricao = res.nome + " - " + res.descricao
           return res;
         });
      */
    },
      err => console.log(err)
    ));
  }
  onLoadCatalogoItem(key) {
    let joins = [
      { key: 'key_catalogo', table: 'catalogo' }
    ];
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/puc/' + key + '/catalogo', 'key_catalogo', joins).subscribe(data => {
      let catalogoItem = JSON.parse(JSON.stringify(data));
      console.log (catalogoItem)
      this.catalogoItem = catalogoItem.map(res => {
        if (res.catalogo){
          res.nomeCatalogo = res.catalogo.nome + " - " + res.catalogo.descricao
        } else {
          res.nomeCatalogo = 'sem nome'
        }

        return res;
      });
    },
      err => console.log(err)
    ));
  }
  onSaveCatalogo() {
    if (this.formulario_catalogo.valid) {
      this.onSubmitCatalogo();
    }
  }
  onSubmitCatalogo() {
    let res = this.catalogoItem.filter(obj => {
      return obj.key_catalogo == this.formulario_catalogo.value.key_catalogo
    });
    if (res.length > 0) {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Este catalogo já esta cadastrada para esta Central de serviços, por favor selecione outra.' });
    } else {
      let dados = this.formulario.value
      let dados_sub = this.formulario_catalogo.value
      if ((this.formulario.valid) && (this.formulario_catalogo.valid) && (this.formulario.value.key)) {
        this.onLoadCatalogoItem(this.formulario_catalogo.value.key)
        dados = this.formulario.value
        dados_sub = this.formulario_catalogo.value
        this.genProvider.onSaveIncrement('dados/' + this.path + '/puc/' + this.formulario.value.key + '/catalogo', dados_sub, "catalogoPuc").then(t => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
          this.formulario_catalogo.reset()
        }).catch(e => {
          this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
          console.log(e);
        });
      } else if ((this.formulario.valid) && (this.formulario_catalogo.valid) && (!this.formulario.value.key)) {
        this.genProvider.onSaveIncrement("dados/" + this.path + "/puc", dados, "puc").then(t => {
          this.onLoadCatalogoItem(t)
          this.formulario.patchValue({
            key: t
          })
          dados = this.formulario.value
          dados_sub = this.formulario_catalogo.value
          this.genProvider.onSaveIncrement('dados/' + this.path + '/puc/' + this.formulario.value.key + '/catalogo', dados_sub, "catalogoPuc").then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
            this.formulario_sub.reset()
          }).catch(e => {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
            console.log(e);
          });
        })
      }
    }
  }
  onDeleteCatalogo(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nomeCatalogo,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.genProvider.remove('dados/' + this.path + '/puc', event.key, 'key_puc').then(res=>{
          if (res!=null){
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada, este registro está relaciondo à '+ res +' tabelas!' });
          }else {
            this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
          }
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });
  }
}
