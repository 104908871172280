// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  FirebaseConfig : {
    apiKey: "AIzaSyDKif9Rj8kgtLMBhjU48BJ0T0DL95EU6nY",
    authDomain: "itnbusiness-618d5.firebaseapp.com",
    databaseURL: "https://itnbusiness-618d5.firebaseio.com",
    projectId: "itnbusiness-618d5",
    storageBucket: "itnbusiness-618d5.appspot.com",
    messagingSenderId: "789604222302",
    appId: "1:789604222302:web:c8b85f2fb1625373cefbcf",
    measurementId: "G-FV00SSG89R"
  }
}
