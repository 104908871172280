/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs';
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage";
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss'],
  providers: [ConfirmationService]
})
export class SlaComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  formulario_sub: FormGroup;
  subsla: any;
  unSub: Subscription[] =[];
  permissao: any = true;
  catalogo: any;
  anexarArquivo: boolean;
  fileSrc: any;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  progress: Observable<number>;
  snapshot: Observable<unknown>;
  downloadURL: any;
  anexarArquivo_sub: boolean;
  catalogoItem: any;
  loading:any;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public storage: AngularFirestore,
    private afStorage: AngularFireStorage

  ) {
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      key_catalogo : [null, Validators.required],
      key_item : [null, Validators.required],
      tmr: [null, Validators.required],
      status: [null, Validators.required],
    });
  }
  onClose() {
    this.router.navigate(['']);
  }
  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnInit() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'sla', field: 'nome' },
      { header: 'Tempo Médio p/ Reparo', field: 'tmr' },
      { header: 'Catálago', field: 'nomeCatalogo' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.onLoad();
    this.onLoadCatalogo()
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }
  onLoad() {
    this.loading = true;
    let joins = [
      { key: 'key_catalogo', table: 'catalogo'}
    ];
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/sla', "nome",joins).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      this.lista = lista.map(res => {
        res.nomeCatalogo = res.catalogo.nome + " - " + res.catalogo.descricao
        return res;
      });
      this.loading = false;
    },
      err => console.log(err)
    ));
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo';
    this.onMostraFrm();
    this.permissao = false;
  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event)
    this.titulo = 'Editar nível de serviço';
    this.onMostraFrm();
    this.permissao = false;
  }
  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/sla', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      this.onMostraFrm();
        this.onSubmit();
    }
  }
  onSubmit() {
    this.genProvider.onSaveIncrement("dados/" + this.path + "/sla", this.formulario.value, "sla").then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "slas")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onLoadCatalogo() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').subscribe(data => {
      this.catalogo = JSON.parse(JSON.stringify(data));
   /*
      this.catalogo = catalogo.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
   */
    },
      err => console.log(err)
    ));
  }
  onLoadCatalogoItem(key) {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo/'+key+'/item', 'nome').subscribe(data => {
      this.catalogoItem = JSON.parse(JSON.stringify(data));
      console.log (this.catalogoItem)
   /*
      this.catalogo = catalogo.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
   */
    },
      err => console.log(err)
    ));
  }


 }
