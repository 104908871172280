/* tslint:disable */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './login/app.login.component';
import { PainelControlComponent } from '../app/painelControl/painelControl.component'
import { PainelControlTicketComponent } from '../app/painelControlTicket/painelControlTicket.component'
import { RelPontoComponent } from './relPonto/relPonto.component';
import { CallComponent } from './call/call.component';
import { RelAtendimentoComponent } from './relAtendimento/relAtendimento.component';
import { RelAtendimentoDiarioComponent } from './relAtendimentoDiario/relAtendimentoDiario.component';
import { RelIntervaloComponent } from './relIntervalo/relIntervalo.component';
import { TicketComponent } from './ticket/ticket.component';
import { DepartamentoComponent } from './departamento/departamento.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { PessoaComponent } from './pessoa/pessoa.component';
import { CargoComponent } from './cargo/cargo.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { PucComponent } from './puc/puc.component';
import { SlaComponent } from './sla/sla.component';
import { LotacaoComponent } from './lotacao/lotacao.component';
import { ImportDataComponent } from './importData/importData.component';
import { RelTicketLotacaoComponent } from './relTicketLotacao/relTicketLotacao.component';
import { Pessoa_lotacaoComponent } from './pessoa_lotacao/pessoa_lotacao.component';
import { DashticketComponent } from './dashticket/dashticket.component';
import { MacroComponent } from './macro/macro.component';
import { TipoComponent } from './tipo/tipo.component';
import { EncerrarAtendimentoComponent } from './encerrarAtendimento/encerrarAtendimento.component';
import { BotComponent } from './bot/bot.component';
import { CallcenterComponent } from './callcenter/callcenter.component';
import { RelCatalogoComponent } from './relCatalogo/relCatalogo.component'
import { AtendimentoComponent } from './atendimento/atendimento.component';
import { HomeComponent } from './home/home.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { DashAtendimentoComponent } from './dashAtendimento/dashAtendimento.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RelQuantificacaoComponent } from './relQuantificacao/relQuantificacao.component';
import { RelTicketGeralComponent } from './relTicketGeral/relTicketGeral.component';
/*
{ path: 'painelControl',  canActivate: [AuthGuard], canLoad: [AuthGuard],   data: { title: 'Call' }, loadChildren: () => import('./painelControl/painelControl.module').then(m => m.PainelControlModule)},
*/
export const routes: Routes = [
    { path: 'login', component: AppLoginComponent },
    { path: 'dashticket', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DashticketComponent },
    { path: 'dashAtendimento', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DashAtendimentoComponent },
    { path: 'dashboard', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DashboardComponent },
    { path: 'call', canActivate: [AuthGuard], canLoad: [AuthGuard], component: CallComponent },
    { path: 'atendimento', canActivate: [AuthGuard], canLoad: [AuthGuard], component: AtendimentoComponent },
    {
        path: '', component: AppMainComponent, canActivate: [AuthGuard], data: { title: 'Main' },
        children: [
            { path: 'painelControl', component: PainelControlComponent },
            { path: 'painelControlTicket', component: PainelControlTicketComponent },
            { path: 'home', canActivate: [AuthGuard], canLoad: [AuthGuard], component: HomeComponent },
            { path: 'ticket', canActivate: [AuthGuard], canLoad: [AuthGuard], component: TicketComponent },
            { path: 'callcenter', canActivate: [AuthGuard], canLoad: [AuthGuard], component: CallcenterComponent },
            { path: 'departamento', canActivate: [AuthGuard], canLoad: [AuthGuard], component: DepartamentoComponent },
            { path: 'pessoa', canActivate: [AuthGuard], canLoad: [AuthGuard], component: PessoaComponent },
            { path: 'pessoa_lotacao', canActivate: [AuthGuard], canLoad: [AuthGuard], component: Pessoa_lotacaoComponent },
            { path: 'catalogo', canActivate: [AuthGuard], canLoad: [AuthGuard], component: CatalogoComponent },
            { path: 'cargo', canActivate: [AuthGuard], canLoad: [AuthGuard], component: CargoComponent },
            { path: 'puc', canActivate: [AuthGuard], canLoad: [AuthGuard], component: PucComponent },
            { path: 'lotacao', canActivate: [AuthGuard], canLoad: [AuthGuard], component: LotacaoComponent },
            { path: 'sla', canActivate: [AuthGuard], canLoad: [AuthGuard], component: SlaComponent },
            { path: 'usuario', canActivate: [AuthGuard], canLoad: [AuthGuard], component: UsuarioComponent},
            { path: 'importData', canActivate: [AuthGuard], canLoad: [AuthGuard], component: ImportDataComponent},
            { path: 'resposta', canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: 'Resposta' }, loadChildren: () => import('./resposta/resposta.module').then(m => m.RespostaModule) },
            { path: 'relAtendimento', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelAtendimentoComponent },
            { path: 'relAtendimentoDiario', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelAtendimentoDiarioComponent },
            { path: 'relChat', canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: 'Relatório de Chats' }, loadChildren: () => import('./relChat/relChat.module').then(m => m.RelChatModule) },
            { path: 'relPonto', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelPontoComponent },
            { path: 'relIntervalo', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelIntervaloComponent },
            { path: 'grupo', canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: 'Grupo' }, loadChildren: () => import('./grupo/grupo.module').then(m => m.GrupoModule) },
            { path: 'galeria', canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: 'Galeria' }, loadChildren: () => import('./galeria/galeria.module').then(m => m.GaleriaModule) },
            { path: 'intervalos', canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: 'Intervalos' }, loadChildren: () => import('./intervalos/intervalos.module').then(m => m.IntervalosModule) },
            { path: 'relTicketLotacao', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelTicketLotacaoComponent },
            { path: 'macro', canActivate: [AuthGuard], canLoad: [AuthGuard], component: MacroComponent },
            { path: 'tipo', canActivate: [AuthGuard], canLoad: [AuthGuard], component: TipoComponent },
            { path: 'encerrarAtendimento', canActivate: [AuthGuard], canLoad: [AuthGuard], component: EncerrarAtendimentoComponent },
            { path: 'bot', canActivate: [AuthGuard], canLoad: [AuthGuard], component: BotComponent },
            { path: 'relCatalogo', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelCatalogoComponent },
            { path: 'empresas', canActivate: [AuthGuard], canLoad: [AuthGuard], component: EmpresasComponent },
            { path: 'relQuantificacao', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelQuantificacaoComponent },
            { path: 'relTicketGeral', canActivate: [AuthGuard], canLoad: [AuthGuard], component: RelTicketGeralComponent },
        ]
    },
    { path: 'error', component: AppErrorComponent },
    { path: 'access', component: AppAccessdeniedComponent },
    { path: 'notfound', component: AppNotfoundComponent },
    { path: 'login', component: AppLoginComponent },
    { path: 'null', component: AppLoginComponent },
    { path: '**', redirectTo: '/notfound' }
]
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }


/*


import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
@NgModule({
   imports: [
       RouterModule.forRoot([
           {
               path: '', component: AppMainComponent,
               children: [
                   {path: '', component: DashboardDemoComponent},
                   {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                   {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
                   {path: 'uikit/input', component: InputDemoComponent},
                   {path: 'uikit/button', component: ButtonDemoComponent},
                   {path: 'uikit/table', component: TableDemoComponent},
                   {path: 'uikit/list', component: ListDemoComponent},
                   {path: 'uikit/tree', component: TreeDemoComponent},
                   {path: 'uikit/panel', component: PanelsDemoComponent},
                   {path: 'uikit/overlay', component: OverlaysDemoComponent},
                   {path: 'uikit/menu', component: MenusDemoComponent},
                   {path: 'uikit/media', component: MediaDemoComponent},
                   {path: 'uikit/message', component: MessagesDemoComponent},
                   {path: 'uikit/misc', component: MiscDemoComponent},
                   {path: 'uikit/charts', component: ChartsDemoComponent},
                   {path: 'uikit/file', component: FileDemoComponent},
                   {path: 'utilities/display', component: DisplayComponent},
                   {path: 'utilities/elevation', component: ElevationComponent},
                   {path: 'utilities/flexbox', component: FlexboxComponent},
                   {path: 'utilities/grid', component: GridComponent},
                   {path: 'utilities/icons', component: IconsComponent},
                   {path: 'utilities/widgets', component: WidgetsComponent},
                   {path: 'utilities/spacing', component: SpacingComponent},
                   {path: 'utilities/typography', component: TypographyComponent},
                   {path: 'utilities/text', component: TextComponent},
                   {path: 'pages/crud', component: AppCrudComponent},
                   {path: 'pages/calendar', component: AppCalendarComponent},
                   {path: 'pages/invoice', component: AppInvoiceComponent},
                   {path: 'pages/help', component: AppHelpComponent},
                   {path: 'pages/empty', component: EmptyDemoComponent},
                   {path: 'documentation', component: DocumentationComponent}
               ]
           },
           {path: 'error', component: AppErrorComponent},
           {path: 'access', component: AppAccessdeniedComponent},
           {path: 'notfound', component: AppNotfoundComponent},
           {path: 'login', component: AppLoginComponent},
           {path: '**', redirectTo: '/notfound'},
       ], {scrollPositionRestoration: 'enabled'})
   ],
   exports: [RouterModule]
})
export class AppRoutingModule {
}
*/
