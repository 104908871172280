export const emojis = [
  {
    emojis: [
      { icon: '😄',  label: 'smile'},
      { icon: '😃',  label: 'smiley'},
      { icon: '😀',  label: 'grinning'},
      { icon: '😊',  label: 'blush'},
      { icon: '☺',  label: 'relaxed'},
      { icon: '😉',  label: 'wink'},
      { icon: '😍',  label: 'heart eyes'},
      { icon: '😘',  label: 'kissing heart'},
      { icon: '😚',  label: 'kissing closed eyes'},
      { icon: '😗',  label: 'kissing'},
      { icon: '😙',  label: 'kissing smiling eyes'},
      { icon: '😜',  label: 'stuck out tongue winking eye'},
      { icon: '😝',  label: 'stuck out tongue closed eyes'},
      { icon: '😛',  label: 'stuck out tongue'},
      { icon: '😳',  label: 'flushed'},
      { icon: '😁',  label: 'grin'},
      { icon: '😔',  label: 'pensive'},
      { icon: '😌',  label: 'relieved'},
      { icon: '😒',  label: 'unamused'},
      { icon: '😞',  label: 'disappointed'},
      { icon: '😣',  label: 'persevere'},
      { icon: '😢',  label: 'cry'},
      { icon: '😂',  label: 'joy'},
      { icon: '😭',  label: 'sob'},
      { icon: '😪',  label: 'sleepy'},
      { icon: '😥',  label: 'disappointed relieved'},
      { icon: '😰',  label: 'cold sweat'},
      { icon: '😅',  label: 'sweat smile'},
      { icon: '😓',  label: 'sweat'},
      { icon: '😩',  label: 'weary'},
      { icon: '😫',  label: 'tired face'},
      { icon: '😨',  label: 'fearful'},
      { icon: '😱',  label: 'scream'},
      { icon: '😠',  label: 'angry'},
      { icon: '😡',  label: 'rage'},
      { icon: '😤',  label: 'triumph'},
      { icon: '😖',  label: 'confounded'},
      { icon: '😆',  label: 'laughing'},
      { icon: '😋',  label: 'yum'},
      { icon: '😷',  label: 'mask'},
      { icon: '😎',  label: 'sunglasses'},
      { icon: '😴',  label: 'sleeping'},
      { icon: '😵',  label: 'dizzy face'},
      { icon: '😲',  label: 'astonished'},
      { icon: '😟',  label: 'worried'},
      { icon: '😦',  label: 'frowning'},
      { icon: '😧',  label: 'anguished'},
      { icon: '👿',  label: 'imp'},
      { icon: '😮',  label: 'open mouth'},
      { icon: '😬',  label: 'grimacing'},
      { icon: '😐',  label: 'neutral face'},
      { icon: '😕',  label: 'confused'},
      { icon: '😯',  label: 'hushed'},
      { icon: '😏',  label: 'smirk'},
      { icon: '😑',  label: 'expressionless'},
      { icon: '👲',  label: 'man with gua pi mao'},
      { icon: '👳',  label: 'man with turban'},
      { icon: '👮',  label: 'cop'},
      { icon: '👷',  label: 'construction worker'},
      { icon: '💂',  label: 'guardsman'},
      { icon: '👶',  label: 'baby'},
      { icon: '👦',  label: 'boy'},
      { icon: '👧',  label: 'girl'},
      { icon: '👨',  label: 'man'},
      { icon: '👩',  label: 'woman'},
      { icon: '👴',  label: 'older man'},
      { icon: '👵',  label: 'older woman'},
      { icon: '👱',  label: 'person with blond hair'},
      { icon: '👼',  label: 'angel'},
      { icon: '👸',  label: 'princess'},
      { icon: '😺',  label: 'smiley cat'},
      { icon: '😸',  label: 'smile cat'},
      { icon: '😻',  label: 'heart eyes cat'},
      { icon: '😽',  label: 'kissing cat'},
      { icon: '😼',  label: 'smirk cat'},
      { icon: '🙀',  label: 'scream cat'},
      { icon: '😿',  label: 'crying cat face'},
      { icon: '😹',  label: 'joy cat'},
      { icon: '😾',  label: 'pouting cat'},
      { icon: '👹',  label: 'japanese ogre'},
      { icon: '👺',  label: 'japanese goblin'},
      { icon: '🙈',  label: 'see no evil'},
      { icon: '🙉',  label: 'hear no evil'},
      { icon: '🙊',  label: 'speak no evil'},
      { icon: '💀',  label: 'skull'},
      { icon: '👽',  label: 'alien'},
      { icon: '💩',  label: 'hankey'},
      { icon: '🔥',  label: 'fire'},
      { icon: '✨',  label: 'sparkles'},
      { icon: '🌟',  label: 'star2'},
      { icon: '💫',  label: 'dizzy'},
      { icon: '💥',  label: 'boom'},
      { icon: '💢',  label: 'anger'},
      { icon: '💦',  label: 'sweat drops'},
      { icon: '💧',  label: 'droplet'},
      { icon: '💤',  label: 'zzz'},
      { icon: '💨',  label: 'dash'},
      { icon: '👂',  label: 'ear'},
      { icon: '👀',  label: 'eyes'},
      { icon: '👃',  label: 'nose'},
      { icon: '👅',  label: 'tongue'},
      { icon: '👄',  label: 'lips'},
      { icon: '👍',  label: '+1'},
      { icon: '👎',  label: '-1'},
      { icon: '👌',  label: 'ok hand'},
      { icon: '👊',  label: 'facepunch'},
      { icon: '✊',  label: 'fist'},
      { icon: '✌',  label: 'v'},
      { icon: '👋',  label: 'wave'},
      { icon: '✋',  label: 'hand'},
      { icon: '👐',  label: 'open hands'},
      { icon: '👆',  label: 'point up 2'},
      { icon: '👇',  label: 'point down'},
      { icon: '👉',  label: 'point right'},
      { icon: '👈',  label: 'point left'},
      { icon: '🙌',  label: 'raised hands'},
      { icon: '🙏',  label: 'pray'},
      { icon: '☝',  label: 'point up'},
      { icon: '👏',  label: 'clap'},
      { icon: '💪',  label: 'muscle'},
      { icon: '🚶',  label: 'walking'},
      { icon: '🏃',  label: 'runner'},
      { icon: '💃',  label: 'dancer'},
      { icon: '👫',  label: 'couple'},
      { icon: '👪',  label: 'family'},
      { icon: '💏',  label: 'couplekiss'},
      { icon: '💑',  label: 'couple with heart'},
      { icon: '👯',  label: 'dancers'},
      { icon: '🙆',  label: 'ok woman'},
      { icon: '🙅',  label: 'no good'},
      { icon: '💁',  label: 'information desk person'},
      { icon: '🙋',  label: 'raising hand'},
      { icon: '💆',  label: 'massage'},
      { icon: '💇',  label: 'haircut'},
      { icon: '💅',  label: 'nail care'},
      { icon: '👰',  label: 'bride with veil'},
      { icon: '🙎',  label: 'person with pouting face'},
      { icon: '🙍',  label: 'person frowning'},
      { icon: '🙇',  label: 'bow'},
      { icon: '🎩',  label: 'tophat'},
      { icon: '👑',  label: 'crown'},
      { icon: '👒',  label: 'womans hat'},
      { icon: '👟',  label: 'athletic shoe'},
      { icon: '👞',  label: 'mans shoe'},
      { icon: '👡',  label: 'sandal'},
      { icon: '👠',  label: 'high heel'},
      { icon: '👢',  label: 'boot'},
      { icon: '👕',  label: 'shirt'},
      { icon: '👔',  label: 'necktie'},
      { icon: '👚',  label: 'womans clothes'},
      { icon: '👗',  label: 'dress'},
      { icon: '🎽',  label: 'running shirt with sash'},
      { icon: '👖',  label: 'jeans'},
      { icon: '👘',  label: 'kimono'},
      { icon: '👙',  label: 'bikini'},
      { icon: '💼',  label: 'briefcase'},
      { icon: '👜',  label: 'handbag'},
      { icon: '👝',  label: 'pouch'},
      { icon: '👛',  label: 'purse'},
      { icon: '👓',  label: 'eyeglasses'},
      { icon: '🎀',  label: 'ribbon'},
      { icon: '🌂',  label: 'closed umbrella'},
      { icon: '💄',  label: 'lipstick'},
      { icon: '💛',  label: 'yellow heart'},
      { icon: '💙',  label: 'blue heart'},
      { icon: '💜',  label: 'purple heart'},
      { icon: '💚',  label: 'green heart'},
      { icon: '❤',  label: 'heart'},
      { icon: '💔',  label: 'broken heart'},
      { icon: '💗',  label: 'heartpulse'},
      { icon: '💓',  label: 'heartbeat'},
      { icon: '💕',  label: 'two hearts'},
      { icon: '💖',  label: 'sparkling heart'},
      { icon: '💞',  label: 'revolving hearts'},
      { icon: '💘',  label: 'cupid'},
      { icon: '💌',  label: 'love letter'},
      { icon: '💋',  label: 'kiss'},
      { icon: '💍',  label: 'ring'},
      { icon: '💎',  label: 'gem'},
      { icon: '👤',  label: 'bust in silhouette'},
      { icon: '💬',  label: 'speech balloon'},
      { icon: '👣',  label: 'footprints'}
    ],
    name: 'People',
    icon: ['😄', 'smile']
  },
  {
    emojis: [
      { icon: '🐶',  label: 'dog'},
      { icon: '🐺',  label: 'wolf'},
      { icon: '🐱',  label: 'cat'},
      { icon: '🐭',  label: 'mouse'},
      { icon: '🐹',  label: 'hamster'},
      { icon: '🐰',  label: 'rabbit'},
      { icon: '🐸',  label: 'frog'},
      { icon: '🐯',  label: 'tiger'},
      { icon: '🐨',  label: 'koala'},
      { icon: '🐻',  label: 'bear'},
      { icon: '🐷',  label: 'pig'},
      { icon: '🐽',  label: 'pig nose'},
      { icon: '🐮',  label: 'cow'},
      { icon: '🐗',  label: 'boar'},
      { icon: '🐵',  label: 'monkey face'},
      { icon: '🐒',  label: 'monkey'},
      { icon: '🐴',  label: 'horse'},
      { icon: '🐑',  label: 'sheep'},
      { icon: '🐘',  label: 'elephant'},
      { icon: '🐼',  label: 'panda face'},
      { icon: '🐧',  label: 'penguin'},
      { icon: '🐦',  label: 'bird'},
      { icon: '🐤',  label: 'baby chick'},
      { icon: '🐥',  label: 'hatched chick'},
      { icon: '🐣',  label: 'hatching chick'},
      { icon: '🐔',  label: 'chicken'},
      { icon: '🐍',  label: 'snake'},
      { icon: '🐢',  label: 'turtle'},
      { icon: '🐛',  label: 'bug'},
      { icon: '🐝',  label: 'bee'},
      { icon: '🐜',  label: 'ant'},
      { icon: '🐞',  label: 'beetle'},
      { icon: '🐌',  label: 'snail'},
      { icon: '🐙',  label: 'octopus'},
      { icon: '🐚',  label: 'shell'},
      { icon: '🐠',  label: 'tropical fish'},
      { icon: '🐟',  label: 'fish'},
      { icon: '🐬',  label: 'dolphin'},
      { icon: '🐳',  label: 'whale'},
      { icon: '🐎',  label: 'racehorse'},
      { icon: '🐲',  label: 'dragon face'},
      { icon: '🐡',  label: 'blowfish'},
      { icon: '🐫',  label: 'camel'},
      { icon: '🐩',  label: 'poodle'},
      { icon: '🐾',  label: 'feet'},
      { icon: '💐',  label: 'bouquet'},
      { icon: '🌸',  label: 'cherry blossom'},
      { icon: '🌷',  label: 'tulip'},
      { icon: '🍀',  label: 'four leaf clover'},
      { icon: '🌹',  label: 'rose'},
      { icon: '🌻',  label: 'sunflower'},
      { icon: '🌺',  label: 'hibiscus'},
      { icon: '🍁',  label: 'maple leaf'},
      { icon: '🍃',  label: 'leaves'},
      { icon: '🍂',  label: 'fallen leaf'},
      { icon: '🌿',  label: 'herb'},
      { icon: '🌾',  label: 'ear of rice'},
      { icon: '🍄',  label: 'mushroom'},
      { icon: '🌵',  label: 'cactus'},
      { icon: '🌴',  label: 'palm tree'},
      { icon: '🌰',  label: 'chestnut'},
      { icon: '🌱',  label: 'seedling'},
      { icon: '🌼',  label: 'blossom'},
      { icon: '🌑',  label: 'new moon'},
      { icon: '🌓',  label: 'first quarter moon'},
      { icon: '🌔',  label: 'moon'},
      { icon: '🌕',  label: 'full moon'},
      { icon: '🌛',  label: 'first quarter moon with face'},
      { icon: '🌙',  label: 'crescent moon'},
      { icon: '🌏',  label: 'earth asia'},
      { icon: '🌋',  label: 'volcano'},
      { icon: '🌌',  label: 'milky way'},
      { icon: '🌠',  label: 'stars'},
      { icon: '⭐',  label: 'star'},
      { icon: '☀',  label: 'sunny'},
      { icon: '⛅',  label: 'partly sunny'},
      { icon: '☁',  label: 'cloud'},
      { icon: '⚡',  label: 'zap'},
      { icon: '☔',  label: 'umbrella'},
      { icon: '❄',  label: 'snowflake'},
      { icon: '⛄',  label: 'snowman'},
      { icon: '🌀',  label: 'cyclone'},
      { icon: '🌁',  label: 'foggy'},
      { icon: '🌈',  label: 'rainbow'},
      { icon: '🌊',  label: 'ocean'}
    ],
    name: 'Nature',
    icon: ['🌸', 'cherry blossom']
  },
  {
    emojis: [
      { icon: '🎍',  label: 'bamboo'},
      { icon: '💝',  label: 'gift heart'},
      { icon: '🎎',  label: 'dolls'},
      { icon: '🎒',  label: 'school satchel'},
      { icon: '🎓',  label: 'mortar board'},
      { icon: '🎏',  label: 'flags'},
      { icon: '🎆',  label: 'fireworks'},
      { icon: '🎇',  label: 'sparkler'},
      { icon: '🎐',  label: 'wind chime'},
      { icon: '🎑',  label: 'rice scene'},
      { icon: '🎃',  label: 'jack o lantern'},
      { icon: '👻',  label: 'ghost'},
      { icon: '🎅',  label: 'santa'},
      { icon: '🎄',  label: 'christmas tree'},
      { icon: '🎁',  label: 'gift'},
      { icon: '🎋',  label: 'tanabata tree'},
      { icon: '🎉',  label: 'tada'},
      { icon: '🎊',  label: 'confetti ball'},
      { icon: '🎈',  label: 'balloon'},
      { icon: '🎌',  label: 'crossed flags'},
      { icon: '🔮',  label: 'crystal ball'},
      { icon: '🎥',  label: 'movie camera'},
      { icon: '📷',  label: 'camera'},
      { icon: '📹',  label: 'video camera'},
      { icon: '📼',  label: 'vhs'},
      { icon: '💿',  label: 'cd'},
      { icon: '📀',  label: 'dvd'},
      { icon: '💽',  label: 'minidisc'},
      { icon: '💾',  label: 'floppy disk'},
      { icon: '💻',  label: 'computer'},
      { icon: '📱',  label: 'iphone'},
      { icon: '☎',  label: 'phone'},
      { icon: '📞',  label: 'telephone receiver'},
      { icon: '📟',  label: 'pager'},
      { icon: '📠',  label: 'fax'},
      { icon: '📡',  label: 'satellite'},
      { icon: '📺',  label: 'tv'},
      { icon: '📻',  label: 'radio'},
      { icon: '🔊',  label: 'loud sound'},
      { icon: '🔔',  label: 'bell'},
      { icon: '📢',  label: 'loudspeaker'},
      { icon: '📣',  label: 'mega'},
      { icon: '⏳',  label: 'hourglass flowing sand'},
      { icon: '⌛',  label: 'hourglass'},
      { icon: '⏰',  label: 'alarm clock'},
      { icon: '⌚',  label: 'watch'},
      { icon: '🔓',  label: 'unlock'},
      { icon: '🔒',  label: 'lock'},
      { icon: '🔏',  label: 'lock with ink pen'},
      { icon: '🔐',  label: 'closed lock with key'},
      { icon: '🔑',  label: 'key'},
      { icon: '🔎',  label: 'mag right'},
      { icon: '💡',  label: 'bulb'},
      { icon: '🔦',  label: 'flashlight'},
      { icon: '🔌',  label: 'electric plug'},
      { icon: '🔋',  label: 'battery'},
      { icon: '🔍',  label: 'mag'},
      { icon: '🛀',  label: 'bath'},
      { icon: '🚽',  label: 'toilet'},
      { icon: '🔧',  label: 'wrench'},
      { icon: '🔩',  label: 'nut and bolt'},
      { icon: '🔨',  label: 'hammer'},
      { icon: '🚪',  label: 'door'},
      { icon: '🚬',  label: 'smoking'},
      { icon: '💣',  label: 'bomb'},
      { icon: '🔫',  label: 'gun'},
      { icon: '🔪',  label: 'hocho'},
      { icon: '💊',  label: 'pill'},
      { icon: '💉',  label: 'syringe'},
      { icon: '💰',  label: 'moneybag'},
      { icon: '💴',  label: 'yen'},
      { icon: '💵',  label: 'dollar'},
      { icon: '💳',  label: 'credit card'},
      { icon: '💸',  label: 'money with wings'},
      { icon: '📲',  label: 'calling'},
      { icon: '📧',  label: 'e-mail'},
      { icon: '📥',  label: 'inbox tray'},
      { icon: '📤',  label: 'outbox tray'},
      { icon: '✉',  label: 'email'},
      { icon: '📩',  label: 'envelope with arrow'},
      { icon: '📨',  label: 'incoming envelope'},
      { icon: '📫',  label: 'mailbox'},
      { icon: '📪',  label: 'mailbox closed'},
      { icon: '📮',  label: 'postbox'},
      { icon: '📦',  label: 'package'},
      { icon: '📝',  label: 'memo'},
      { icon: '📄',  label: 'page facing up'},
      { icon: '📃',  label: 'page with curl'},
      { icon: '📑',  label: 'bookmark tabs'},
      { icon: '📊',  label: 'bar chart'},
      { icon: '📈',  label: 'chart with upwards trend'},
      { icon: '📉',  label: 'chart with downwards trend'},
      { icon: '📜',  label: 'scroll'},
      { icon: '📋',  label: 'clipboard'},
      { icon: '📅',  label: 'date'},
      { icon: '📆',  label: 'calendar'},
      { icon: '📇',  label: 'card index'},
      { icon: '📁',  label: 'file folder'},
      { icon: '📂',  label: 'open file folder'},
      { icon: '✂',  label: 'scissors'},
      { icon: '📌',  label: 'pushpin'},
      { icon: '📎',  label: 'paperclip'},
      { icon: '✒',  label: 'black nib'},
      { icon: '✏',  label: 'pencil2'},
      { icon: '📏',  label: 'straight ruler'},
      { icon: '📐',  label: 'triangular ruler'},
      { icon: '📕',  label: 'closed book'},
      { icon: '📗',  label: 'green book'},
      { icon: '📘',  label: 'blue book'},
      { icon: '📙',  label: 'orange book'},
      { icon: '📓',  label: 'notebook'},
      { icon: '📔',  label: 'notebook with decorative cover'},
      { icon: '📒',  label: 'ledger'},
      { icon: '📚',  label: 'books'},
      { icon: '📖',  label: 'book'},
      { icon: '🔖',  label: 'bookmark'},
      { icon: '📛',  label: 'name badge'},
      { icon: '📰',  label: 'newspaper'},
      { icon: '🎨',  label: 'art'},
      { icon: '🎬',  label: 'clapper'},
      { icon: '🎤',  label: 'microphone'},
      { icon: '🎧',  label: 'headphones'},
      { icon: '🎼',  label: 'musical score'},
      { icon: '🎵',  label: 'musical note'},
      { icon: '🎶',  label: 'notes'},
      { icon: '🎹',  label: 'musical keyboard'},
      { icon: '🎻',  label: 'violin'},
      { icon: '🎺',  label: 'trumpet'},
      { icon: '🎷',  label: 'saxophone'},
      { icon: '🎸',  label: 'guitar'},
      { icon: '👾',  label: 'space invader'},
      { icon: '🎮',  label: 'video game'},
      { icon: '🃏',  label: 'black joker'},
      { icon: '🎴',  label: 'flower playing cards'},
      { icon: '🀄',  label: 'mahjong'},
      { icon: '🎲',  label: 'game die'},
      { icon: '🎯',  label: 'dart'},
      { icon: '🏈',  label: 'football'},
      { icon: '🏀',  label: 'basketball'},
      { icon: '⚽',  label: 'soccer'},
      { icon: '⚾',  label: 'baseball'},
      { icon: '🎾',  label: 'tennis'},
      { icon: '🎱',  label: '8ball'},
      { icon: '🎳',  label: 'bowling'},
      { icon: '⛳',  label: 'golf'},
      { icon: '🏁',  label: 'checkered flag'},
      { icon: '🏆',  label: 'trophy'},
      { icon: '🎿',  label: 'ski'},
      { icon: '🏂',  label: 'snowboarder'},
      { icon: '🏊',  label: 'swimmer'},
      { icon: '🏄',  label: 'surfer'},
      { icon: '🎣',  label: 'fishing pole and fish'},
      { icon: '☕',  label: 'coffee'},
      { icon: '🍵',  label: 'tea'},
      { icon: '🍶',  label: 'sake'},
      { icon: '🍺',  label: 'beer'},
      { icon: '🍻',  label: 'beers'},
      { icon: '🍸',  label: 'cocktail'},
      { icon: '🍹',  label: 'tropical drink'},
      { icon: '🍷',  label: 'wine glass'},
      { icon: '🍴',  label: 'fork and knife'},
      { icon: '🍕',  label: 'pizza'},
      { icon: '🍔',  label: 'hamburger'},
      { icon: '🍟',  label: 'fries'},
      { icon: '🍗',  label: 'poultry leg'},
      { icon: '🍖',  label: 'meat on bone'},
      { icon: '🍝',  label: 'spaghetti'},
      { icon: '🍛',  label: 'curry'},
      { icon: '🍤',  label: 'fried shrimp'},
      { icon: '🍱',  label: 'bento'},
      { icon: '🍣',  label: 'sushi'},
      { icon: '🍥',  label: 'fish cake'},
      { icon: '🍙',  label: 'rice ball'},
      { icon: '🍘',  label: 'rice cracker'},
      { icon: '🍚',  label: 'rice'},
      { icon: '🍜',  label: 'ramen'},
      { icon: '🍲',  label: 'stew'},
      { icon: '🍢',  label: 'oden'},
      { icon: '🍡',  label: 'dango'},
      { icon: '🍳',  label: 'egg'},
      { icon: '🍞',  label: 'bread'},
      { icon: '🍩',  label: 'doughnut'},
      { icon: '🍮',  label: 'custard'},
      { icon: '🍦',  label: 'icecream'},
      { icon: '🍨',  label: 'ice cream'},
      { icon: '🍧',  label: 'shaved ice'},
      { icon: '🎂',  label: 'birthday'},
      { icon: '🍰',  label: 'cake'},
      { icon: '🍪',  label: 'cookie'},
      { icon: '🍫',  label: 'chocolate bar'},
      { icon: '🍬',  label: 'candy'},
      { icon: '🍭',  label: 'lollipop'},
      { icon: '🍯',  label: 'honey pot'},
      { icon: '🍎',  label: 'apple'},
      { icon: '🍏',  label: 'green apple'},
      { icon: '🍊',  label: 'tangerine'},
      { icon: '🍒',  label: 'cherries'},
      { icon: '🍇',  label: 'grapes'},
      { icon: '🍉',  label: 'watermelon'},
      { icon: '🍓',  label: 'strawberry'},
      { icon: '🍑',  label: 'peach'},
      { icon: '🍈',  label: 'melon'},
      { icon: '🍌',  label: 'banana'},
      { icon: '🍍',  label: 'pineapple'},
      { icon: '🍠',  label: 'sweet potato'},
      { icon: '🍆',  label: 'eggplant'},
      { icon: '🍅',  label: 'tomato'},
      { icon: '🌽',  label: 'corn'}
    ],
    name: 'Objects',
    icon: ['🔔', 'bell']
  },
  {
    emojis: [
      { icon: '🏠',  label: 'house'},
      { icon: '🏡',  label: 'house with garden'},
      { icon: '🏫',  label: 'school'},
      { icon: '🏢',  label: 'office'},
      { icon: '🏣',  label: 'post office'},
      { icon: '🏥',  label: 'hospital'},
      { icon: '🏦',  label: 'bank'},
      { icon: '🏪',  label: 'convenience store'},
      { icon: '🏩',  label: 'love hotel'},
      { icon: '🏨',  label: 'hotel'},
      { icon: '💒',  label: 'wedding'},
      { icon: '⛪',  label: 'church'},
      { icon: '🏬',  label: 'department store'},
      { icon: '🌇',  label: 'city sunrise'},
      { icon: '🌆',  label: 'city sunset'},
      { icon: '🏯',  label: 'japanese castle'},
      { icon: '🏰',  label: 'european castle'},
      { icon: '⛺',  label: 'tent'},
      { icon: '🏭',  label: 'factory'},
      { icon: '🗼',  label: 'tokyo tower'},
      { icon: '🗾',  label: 'japan'},
      { icon: '🗻',  label: 'mount fuji'},
      { icon: '🌄',  label: 'sunrise over mountains'},
      { icon: '🌅',  label: 'sunrise'},
      { icon: '🌃',  label: 'night with stars'},
      { icon: '🗽',  label: 'statue of liberty'},
      { icon: '🌉',  label: 'bridge at night'},
      { icon: '🎠',  label: 'carousel horse'},
      { icon: '🎡',  label: 'ferris wheel'},
      { icon: '⛲',  label: 'fountain'},
      { icon: '🎢',  label: 'roller coaster'},
      { icon: '🚢',  label: 'ship'},
      { icon: '⛵',  label: 'boat'},
      { icon: '🚤',  label: 'speedboat'},
      { icon: '⚓',  label: 'anchor'},
      { icon: '🚀',  label: 'rocket'},
      { icon: '✈',  label: 'airplane'},
      { icon: '💺',  label: 'seat'},
      { icon: '🚉',  label: 'station'},
      { icon: '🚄',  label: 'bullettrain side'},
      { icon: '🚅',  label: 'bullettrain front'},
      { icon: '🚇',  label: 'metro'},
      { icon: '🚃',  label: 'railway car'},
      { icon: '🚌',  label: 'bus'},
      { icon: '🚙',  label: 'blue car'},
      { icon: '🚗',  label: 'car'},
      { icon: '🚕',  label: 'taxi'},
      { icon: '🚚',  label: 'truck'},
      { icon: '🚨',  label: 'rotating light'},
      { icon: '🚓',  label: 'police car'},
      { icon: '🚒',  label: 'fire engine'},
      { icon: '🚑',  label: 'ambulance'},
      { icon: '🚲',  label: 'bike'},
      { icon: '💈',  label: 'barber'},
      { icon: '🚏',  label: 'busstop'},
      { icon: '🎫',  label: 'ticket'},
      { icon: '🚥',  label: 'traffic light'},
      { icon: '⚠',  label: 'warning'},
      { icon: '🚧',  label: 'construction'},
      { icon: '🔰',  label: 'beginner'},
      { icon: '⛽',  label: 'fuelpump'},
      { icon: '🏮',  label: 'izakaya lantern'},
      { icon: '🎰',  label: 'slot machine'},
      { icon: '♨',  label: 'hotsprings'},
      { icon: '🗿',  label: 'moyai'},
      { icon: '🎪',  label: 'circus tent'},
      { icon: '🎭',  label: 'performing arts'},
      { icon: '📍',  label: 'round pushpin'},
      { icon: '🚩',  label: 'triangular flag on post'}
    ],
    name: 'Places',
    icon: ['🚙',  'blue car']
  },
  {
    emojis: [
      { icon: '🔟',  label: 'keycap ten'},
      { icon: '🔢',  label: '1234'},
      { icon: '🔣',  label: 'symbols'},
      { icon: '⬆',  label: 'arrow up'},
      { icon: '⬇',  label: 'arrow down'},
      { icon: '⬅',  label: 'arrow left'},
      { icon: '➡',  label: 'arrow right'},
      { icon: '🔠',  label: 'capital abcd'},
      { icon: '🔡',  label: 'abcd'},
      { icon: '🔤',  label: 'abc'},
      { icon: '↗',  label: 'arrow upper right'},
      { icon: '↖',  label: 'arrow upper left'},
      { icon: '↘',  label: 'arrow lower right'},
      { icon: '↙',  label: 'arrow lower left'},
      { icon: '↔',  label: 'left right arrow'},
      { icon: '↕',  label: 'arrow up down'},
      { icon: '◀',  label: 'arrow backward'},
      { icon: '▶',  label: 'arrow forward'},
      { icon: '🔼',  label: 'arrow up small'},
      { icon: '🔽',  label: 'arrow down small'},
      { icon: '↩',  label: 'leftwards arrow with hook'},
      { icon: '↪',  label: 'arrow right hook'},
      { icon: 'ℹ',  label: 'information source'},
      { icon: '⏪',  label: 'rewind'},
      { icon: '⏩',  label: 'fast forward'},
      { icon: '⏫',  label: 'arrow double up'},
      { icon: '⏬',  label: 'arrow double down'},
      { icon: '⤵',  label: 'arrow heading down'},
      { icon: '⤴',  label: 'arrow heading up'},
      { icon: '🆗',  label: 'ok'},
      { icon: '🆕',  label: 'new'},
      { icon: '🆙',  label: 'up'},
      { icon: '🆒',  label: 'cool'},
      { icon: '🆓',  label: 'free'},
      { icon: '🆖',  label: 'ng'},
      { icon: '📶',  label: 'signal strength'},
      { icon: '🎦',  label: 'cinema'},
      { icon: '🈁',  label: 'koko'},
      { icon: '🈯',  label: 'u6307'},
      { icon: '🈳',  label: 'u7a7a'},
      { icon: '🈵',  label: 'u6e80'},
      { icon: '🈴',  label: 'u5408'},
      { icon: '🈲',  label: 'u7981'},
      { icon: '🉐',  label: 'ideograph advantage'},
      { icon: '🈹',  label: 'u5272'},
      { icon: '🈺',  label: 'u55b6'},
      { icon: '🈶',  label: 'u6709'},
      { icon: '🈚',  label: 'u7121'},
      { icon: '🚻',  label: 'restroom'},
      { icon: '🚹',  label: 'mens'},
      { icon: '🚺',  label: 'womens'},
      { icon: '🚼',  label: 'baby symbol'},
      { icon: '🚾',  label: 'wc'},
      { icon: '🅿',  label: 'parking'},
      { icon: '♿',  label: 'wheelchair'},
      { icon: '🚭',  label: 'no smoking'},
      { icon: '🈷',  label: 'u6708'},
      { icon: '🈸',  label: 'u7533'},
      { icon: '🈂',  label: 'sa'},
      { icon: 'Ⓜ',  label: 'm'},
      { icon: '🉑',  label: 'accept'},
      { icon: '㊙',  label: 'secret'},
      { icon: '㊗',  label: 'congratulations'},
      { icon: '🆑',  label: 'cl'},
      { icon: '🆘',  label: 'sos'},
      { icon: '🆔',  label: 'id'},
      { icon: '🚫',  label: 'no entry sign'},
      { icon: '🔞',  label: 'underage'},
      { icon: '⛔',  label: 'no entry'},
      { icon: '✳',  label: 'eight spoked asterisk'},
      { icon: '❇',  label: 'sparkle'},
      { icon: '❎',  label: 'negative squared cross mark'},
      { icon: '✅',  label: 'white check mark'},
      { icon: '✴',  label: 'eight pointed black star'},
      { icon: '💟',  label: 'heart decoration'},
      { icon: '🆚',  label: 'vs'},
      { icon: '📳',  label: 'vibration mode'},
      { icon: '📴',  label: 'mobile phone off'},
      { icon: '🅰',  label: 'a'},
      { icon: '🅱',  label: 'b'},
      { icon: '🆎',  label: 'ab'},
      { icon: '🅾',  label: 'o2'},
      { icon: '💠',  label: 'diamond shape with a dot inside'},
      { icon: '♻',  label: 'recycle'},
      { icon: '♈',  label: 'aries'},
      { icon: '♉',  label: 'taurus'},
      { icon: '♊',  label: 'gemini'},
      { icon: '♋',  label: 'cancer'},
      { icon: '♌',  label: 'leo'},
      { icon: '♍',  label: 'virgo'},
      { icon: '♎',  label: 'libra'},
      { icon: '♏',  label: 'scorpius'},
      { icon: '♐',  label: 'sagittarius'},
      { icon: '♑',  label: 'capricorn'},
      { icon: '♒',  label: 'aquarius'},
      { icon: '♓',  label: 'pisces'},
      { icon: '⛎',  label: 'ophiuchus'},
      { icon: '🔯',  label: 'six pointed star'},
      { icon: '🏧',  label: 'atm'},
      { icon: '💹',  label: 'chart'},
      { icon: '💲',  label: 'heavy dollar sign'},
      { icon: '💱',  label: 'currency exchange'},
      { icon: '❌',  label: 'x'},
      { icon: '‼',  label: 'bangbang'},
      { icon: '⁉',  label: 'interrobang'},
      { icon: '❗',  label: 'exclamation'},
      { icon: '❓',  label: 'question'},
      { icon: '❕',  label: 'grey exclamation'},
      { icon: '❔',  label: 'grey question'},
      { icon: '⭕',  label: 'o'},
      { icon: '🔝',  label: 'top'},
      { icon: '🔚',  label: 'end'},
      { icon: '🔙',  label: 'back'},
      { icon: '🔛',  label: 'on'},
      { icon: '🔜',  label: 'soon'},
      { icon: '🔃',  label: 'arrows clockwise'},
      { icon: '🕛',  label: 'clock12'},
      { icon: '🕐',  label: 'clock1'},
      { icon: '🕑',  label: 'clock2'},
      { icon: '🕒',  label: 'clock3'},
      { icon: '🕓',  label: 'clock4'},
      { icon: '🕔',  label: 'clock5'},
      { icon: '🕕',  label: 'clock6'},
      { icon: '🕖',  label: 'clock7'},
      { icon: '🕗',  label: 'clock8'},
      { icon: '🕘',  label: 'clock9'},
      { icon: '🕙',  label: 'clock10'},
      { icon: '🕚',  label: 'clock11'},
      { icon: '✖',  label: 'heavy multiplication x'},
      { icon: '➕',  label: 'heavy plus sign'},
      { icon: '➖',  label: 'heavy minus sign'},
      { icon: '➗',  label: 'heavy division sign'},
      { icon: '♠',  label: 'spades'},
      { icon: '♥',  label: 'hearts'},
      { icon: '♣',  label: 'clubs'},
      { icon: '♦',  label: 'diamonds'},
      { icon: '💮',  label: 'white flower'},
      { icon: '💯',  label: '100'},
      { icon: '✔',  label: 'heavy check mark'},
      { icon: '☑',  label: 'ballot box with check'},
      { icon: '🔘',  label: 'radio button'},
      { icon: '🔗',  label: 'link'},
      { icon: '➰',  label: 'curly loop'},
      { icon: '〰',  label: 'wavy dash'},
      { icon: '〽',  label: 'part alternation mark'},
      { icon: '🔱',  label: 'trident'},
      { icon: '◼',  label: 'black medium square'},
      { icon: '◻',  label: 'white medium square'},
      { icon: '◾',  label: 'black medium small square'},
      { icon: '◽',  label: 'white medium small square'},
      { icon: '▪',  label: 'black small square'},
      { icon: '▫',  label: 'white small square'},
      { icon: '🔺',  label: 'small red triangle'},
      { icon: '🔲',  label: 'black square button'},
      { icon: '🔳',  label: 'white square button'},
      { icon: '⚫',  label: 'black circle'},
      { icon: '⚪',  label: 'white circle'},
      { icon: '🔴',  label: 'red circle'},
      { icon: '🔵',  label: 'large blue circle'},
      { icon: '🔻',  label: 'small red triangle down'},
      { icon: '⬜',  label: 'white large square'},
      { icon: '⬛',  label: 'black large square'},
      { icon: '🔶',  label: 'large orange diamond'},
      { icon: '🔷',  label: 'large blue diamond'},
      { icon: '🔸',  label: 'small orange diamond'},
      { icon: '🔹',  label: 'small blue diamond'}
    ],
    name: 'Symbols',
    icon: ['🔠', 'capital abcd']
  }
];

