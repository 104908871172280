import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { GenProvider } from 'src/providers/gen/gen';
import { navigation } from './_nav';
import { navigationDesk } from './_navDesk';
import { navigationCad } from './_navCad';
import { navigationRel } from './_navRel';
import { navigationDash } from './_navDash';
import { navigationTools } from './_navTools';
import { OnInit } from '@angular/core';

@Component({
    selector: 'app-sidebar',
      templateUrl: './app.sidebar.component.html'
})
export class AppSideBarComponent implements OnInit {
    public navigation : any;
    public navigationDesk : any;
    public navigationTools : any;
    public navigationCad : any;
    public navigationRel : any;
    public navigationDash : any;
    public model: any[];
    public modelDesk: any[];
    public modelTools: any[];
    public modelCad: any[];
    public modelRel: any[];
    public modelDash: any[];
    permissao: any;
    parametro: any;
     constructor(public app: AppMainComponent, public genProvider: GenProvider) {
        this.navigation = navigation;
        this.navigationDesk = navigationDesk;
        this.navigationTools = navigationTools;
        this.navigationCad = navigationCad;
        this.navigationRel = navigationRel;
        this.navigationDash = navigationDash;
     }
       ngOnInit() {
        this.parametro = this.genProvider.parametros;
        setTimeout(() => {
            this.isMenu();
            this.isMenuDesk();
            this.isMenuCad();
            this.isMenuRel();
            this.isMenuDash();
            if (this.parametro.nivel == "99") {
              this.isMenuTools();
            }
          }, 1000);
      }

      public isDivider(item) {
        return item.divider ? true : false
      }
      public isTitle(item) {
        return item.title ? true : false
      }
      public isShow(item) {
       return item.show ? true : false
      }
      public isMenu(){
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',this.parametro.key_login)
          .subscribe(data => {
            this.model = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigation.length; index++) {
                if (!this.isDivider(this.navigation[index]) && !this.isTitle(this.navigation[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigation[index].nome
                  });
                 if (res.length > 0) {
                   this.navigation[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigation[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigation[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigation[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigation[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (this.parametro.nivel=="99"){
                           this.navigation[index].itens[i].show = true
                         } else {
                           this.navigation[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (this.parametro.nivel=="99"){
                     this.navigation[index].show = true
                     let child :any
                     try {
                        child = this.navigation[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigation[index].itens.length; i++) {
                          if (this.parametro.nivel=="99"){
                            this.navigation[index].itens[i].show = true
                          } else {
                            this.navigation[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigation[index].show = false
                   }
                 }
                }
              }
               this.model = this.navigation;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (this.parametro.nivel == "99") {
            for (let index = 0; index < this.navigation.length; index++) {
              if (!this.isDivider(this.navigation[index]) && !this.isTitle(this.navigation[index])) {
                 if (this.parametro.nivel == "99") {
                  this.navigation[index].show = true
                  let child: any
                  try {
                    child = this.navigation[index].itens || false
                  } catch (error) {
                    child = false;
                    console.log(error)
                  }
                  if (child) {
                    for (let i = 0; i < this.navigation[index].itens.length; i++) {
                      if (this.parametro.nivel == "99") {
                        this.navigation[index].itens[i].show = true
                      } else {
                        this.navigation[index].itens[i].show = false
                      }
                    }
                  }
                } else {
                  this.navigation[index].show = false
                }
              }
            }
            this.model = this.navigation;
          }
        }
    
      }
      public isMenuDesk(){
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',this.parametro.key_login)
          .subscribe(data => {
            this.modelDesk = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigationDesk.length; index++) {
                if (!this.isDivider(this.navigationDesk[index]) && !this.isTitle(this.navigationDesk[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigationDesk[index].nome
                  });
                 if (res.length > 0) {
                   this.navigationDesk[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigationDesk[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigationDesk[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigationDesk[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigationDesk[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (this.parametro.nivel=="99"){
                           this.navigationDesk[index].itens[i].show = true
                         } else {
                           this.navigationDesk[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (this.parametro.nivel=="99"){
                     this.navigationDesk[index].show = true
                     let child :any
                     try {
                        child = this.navigationDesk[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigationDesk[index].itens.length; i++) {
                          if (this.parametro.nivel=="99"){
                            this.navigationDesk[index].itens[i].show = true
                          } else {
                            this.navigationDesk[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigationDesk[index].show = false
                   }
                 }
                }
              }
               this.modelDesk = this.navigationDesk;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (this.parametro.nivel=="99"){
            this.modelDesk = this.navigationDesk;
          }
        }
    
      }
      public isMenuTools(){
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',this.parametro.key_login)
          .subscribe(data => {
            this.modelTools = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigationTools.length; index++) {
                if (!this.isDivider(this.navigationTools[index]) && !this.isTitle(this.navigationTools[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigationTools[index].nome
                  });
                 if (res.length > 0) {
                   this.navigationTools[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigationTools[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigationTools[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigationTools[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigationTools[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (this.parametro.nivel=="99"){
                           this.navigationTools[index].itens[i].show = true
                         } else {
                           this.navigationTools[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (this.parametro.nivel=="99"){
                     this.navigationTools[index].show = true
                     let child :any
                     try {
                        child = this.navigationTools[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigationTools[index].itens.length; i++) {
                          if (this.parametro.nivel=="99"){
                            this.navigationTools[index].itens[i].show = true
                          } else {
                            this.navigationTools[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigationTools[index].show = false
                   }
                 }
                }
              }
               this.modelTools = this.navigationTools;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (this.parametro.nivel=="99"){
            this.modelTools = this.navigationTools;
          }
        }
  
      }
      /* novas implementações */
      public isMenuCad(){
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',this.parametro.key_login)
          .subscribe(data => {
            this.modelCad = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigationCad.length; index++) {
                if (!this.isDivider(this.navigationCad[index]) && !this.isTitle(this.navigationCad[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigationCad[index].nome
                  });
                 if (res.length > 0) {
                   this.navigationCad[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigationCad[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigationCad[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigationCad[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigationCad[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (this.parametro.nivel=="99"){
                           this.navigationCad[index].itens[i].show = true
                         } else {
                           this.navigationCad[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (this.parametro.nivel=="99"){
                     this.navigationCad[index].show = true
                     let child :any
                     try {
                        child = this.navigationCad[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigationCad[index].itens.length; i++) {
                          if (this.parametro.nivel=="99"){
                            this.navigationCad[index].itens[i].show = true
                          } else {
                            this.navigationCad[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigationCad[index].show = false
                   }
                 }
                }
              }
               this.modelCad = this.navigationCad;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (this.parametro.nivel=="99"){
            this.modelCad = this.navigationCad;
          }
        }
    
      }
      public isMenuRel(){
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',this.parametro.key_login)
          .subscribe(data => {
            this.modelRel = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigationRel.length; index++) {
                if (!this.isDivider(this.navigationRel[index]) && !this.isTitle(this.navigationRel[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigationRel[index].nome
                  });
                 if (res.length > 0) {
                   this.navigationRel[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigationRel[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigationRel[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigationRel[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigationRel[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (this.parametro.nivel=="99"){
                           this.navigationRel[index].itens[i].show = true
                         } else {
                           this.navigationRel[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (this.parametro.nivel=="99"){
                     this.navigationRel[index].show = true
                     let child :any
                     try {
                        child = this.navigationRel[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigationRel[index].itens.length; i++) {
                          if (this.parametro.nivel=="99"){
                            this.navigationRel[index].itens[i].show = true
                          } else {
                            this.navigationRel[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigationRel[index].show = false
                   }
                 }
                }
              }
               this.modelRel = this.navigationRel;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (this.parametro.nivel=="99"){
            this.modelRel = this.navigationRel;
          }
        }
    
      }
      public isMenuDash(){
        try {
          this.genProvider.onGetFilterOrder('login_permissao','nome', 'key_login',this.parametro.key_login)
          .subscribe(data => {
            this.modelDash = [];
               this.permissao = JSON.parse(JSON.stringify(data))
               for (let index = 0; index < this.navigationDash.length; index++) {
                if (!this.isDivider(this.navigationDash[index]) && !this.isTitle(this.navigationDash[index])){
                  let res = this.permissao.filter(obj => {
                    return obj.nome == this.navigationDash[index].nome
                  });
                 if (res.length > 0) {
                   this.navigationDash[index].show = !res[0].per_bloqueio
                   let child :any
                   try {
                      child = this.navigationDash[index].itens || false
                   } catch (error) {
                     child = false;
                     console.log(error)
                   }
                   if (child){
                     for (let i = 0; i < this.navigationDash[index].itens.length; i++) {
                       let res = this.permissao.filter(obj => {
                          return obj.nome == this.navigationDash[index].itens[i].nome
                        });
                        if (res.length > 0) {
                        this.navigationDash[index].itens[i].show = !res[0].per_bloqueio
                        } else {
                         if (this.parametro.nivel=="99"){
                           this.navigationDash[index].itens[i].show = true
                         } else {
                           this.navigationDash[index].itens[i].show = false
                         }
                        }
                      }
                   }
    
                 }else {
                   if (this.parametro.nivel=="99"){
                     this.navigationDash[index].show = true
                     let child :any
                     try {
                        child = this.navigationDash[index].itens || false
                     } catch (error) {
                       child = false;
                       console.log(error)
                     }
                     if (child){
                      for (let i = 0; i < this.navigationDash[index].itens.length; i++) {
                          if (this.parametro.nivel=="99"){
                            this.navigationDash[index].itens[i].show = true
                          } else {
                            this.navigationDash[index].itens[i].show = false
                          }
                       }
                    }
                   } else {
                     this.navigationDash[index].show = false
                   }
                 }
                }
              }
               this.modelDash = this.navigationDash;
           },
           err =>{
            console.log(err)
          }
         );
        } catch (error) {
          if (this.parametro.nivel=="99"){
            this.modelDash = this.navigationDash;
          }
        }
    
      }
}
