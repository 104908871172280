import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs-compat';
import { take, takeUntil } from 'rxjs/operators';
import { GenProvider } from 'src/providers/gen/gen';
import { AuthService } from './auth.service';
import { Usuario } from './usuario';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss'],
})
export class AppLoginComponent implements OnInit, OnDestroy {
  lottieConfig: any;
  login: any;
  usuario: Usuario = new Usuario();
  release: number;
  showUpdate: boolean;
  unSub$ = new Subject();

  constructor(private authService: AuthService, private messageService: MessageService, private genProvider: GenProvider) {
    this.release = 18;
  }
  showResponse(event) {
    this.messageService.add({ severity: 'info', summary: 'Succees', detail: 'User Responded', sticky: true });
  }
  ngOnInit() {
    this.onCheckRelease()
  }
  ngOnDestroy() {
    this.unSub$.next()
    this.unSub$.complete()
  }
  onSubmit() {
    this.authService.fazerLogin(this.usuario);
  }
  resfesh(){
    window.document.location.reload();
  }
  onCheckRelease(){
    this.genProvider.onGetAll('versao','release').pipe(takeUntil(this.unSub$)).subscribe(res=>{
      if (res[0].release > this.release){
        this.showUpdate = true
      }
    })

  }
  onReload(){
    window.location.reload();
  }
}

