/* tslint:disable */
import { DatePipe } from '@angular/common';
import { ComponentFactoryResolver, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { GenProvider } from '../../providers/gen/gen';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';
import * as moment from 'moment';



@Component({
  selector: 'app-relTicketLotacao',
  templateUrl: './relTicketLotacao.component.html',
  styleUrls: ['./relTicketLotacao.component.scss'],
  providers: [MessageService]
})
export class RelTicketLotacaoComponent implements OnInit {
  inicio: any;
  final: any;
  @ViewChild('dtLista') dtLista: Table;

  filtro: any;
  lista: any = [];
  relacao: any;
  atuacao: any;
  membro: any;
  dados: any;
  qtdCad: any;
  path: any;
  datarelacao: any;
  dataatuacao: any;
  listaCoordenadores: any;

  isSexo: any;
  isTelefone: any;
  isDt_Nasc: any;
  isTipo: any;
  isRelacao: any;
  isEndereco: any;
  isZona: any;
  isSecao: any;
  isColegio: any;
  isBairro: any;
  membro_nome: any;
  key_coordenador: any;
  nome_coordenador: any;
  nome_lideranca: any;
  listaLideranca: any;
  key_lideranca: any;
  parametros: any;
  cols: any;
  exportColumns: any;
  usuarios: any;
  pessoas: any;
  lotacoes: any;
  puc: any;
  catalogo: any = [];
  item: any = [];
  loading: any;
  tipo: any = [];
  login: any = [];
  itemCatalogo: any = [];
  subItem: any = [];
  lista_atualizar: any;

  constructor(
    private router: Router,
    private genProvider: GenProvider,
    private validaCampoProvider: ValidaCampoProvider,
    private messageService: MessageService
  ) {
    this.parametros = this.genProvider.parametros;
    this.path = this.genProvider.parametros.path;
    this.listaCoordenadores = [];
    this.lista = [];
  }

  ngOnInit() {
    this.cols = [
      { header: 'Ticket', field: 'ticket', width: '20%' },
      { header: 'Canal', field: 'canal', width: '5%' },
      { header: 'Criação', field: 'data_criacao', width: '10%' },
      { header: 'Status', field: 'status_ticket', width: '10%' },
      { header: 'Título', field: 'titulo_ticket', width: '19%' },
      { header: 'Código', field: 'codigo', width: '5%' },
      { header: 'Solicitante', field: 'nome_solicitante', width: '18%' },
      { header: 'Email', field: 'email', width: '15%' },
      { header: 'Lotação Solicitante', field: 'lotacao_solicitante', width: '26%' },
      { header: 'Tipo Lotação Solicitante', field: 'tipo_lotacao_solicitante', width: '26%' },
      { header: 'Catálogo', field: 'catalogo', width: '15%' },
      { header: 'Item', field: 'item', width: '15%' },
      { header: 'SubItem', field: 'subitem', width: '15%' },
      { header: 'Conteúdo', field: 'conteudo', width: '15%' },
      { header: 'Últ.Interação/Qtd', field: 'ultima_interacao', width: '15%' },
      { header: 'Lotação Ticket', field: 'nome_lotacao', width: '26%' },
      { header: 'Tipo Lotação Ticket', field: 'tipo_lotacao', width: '26%' },
      { header: 'Agente', field: 'agente', width: '15%' },
      { header: 'CV', field: 'cvh', width: '10%' },
  //    { header: 'Time', field: 'time', width: '10%' }
    ];
    // this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportColumns = [{ title: 'Ticket#', dataKey: 'ticket' }, { title: 'Data', dataKey: 'data_criacao' }, { title: 'Canal', dataKey: 'canal' }];
    this.loading = true;
    this.onLoadLogin();
    this.onLoadUsuario();
    this.onLoadTipo();
    this.onLoadPuc();

  }
  onLoadUsuario() {
    let unSub = this.genProvider.onGetAll('login', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.usuarios = JSON.parse(JSON.stringify(data));
    })
  }
  onLoadPessoa() {
    let unSub = this.genProvider.onGetfilter('dados/' + this.path + '/pessoa', 'status', 'Ativo').subscribe(data => {
      unSub.unsubscribe();
      let pessoas = JSON.parse(JSON.stringify(data));
      this.pessoas = pessoas.map(res => {
        // res.key = this.validaCampoProvider.transPadraoWhatsapp(res.telefone);
        if (res.key_lotacao) {
          let lotacao = this.genProvider.onGetTableKey(this.lotacoes, res.key_lotacao)
          if (lotacao) {
            res.lotacao = lotacao.nome
            res.tipo_lotacao = lotacao.tipo_lotacao;
            res.codigo = lotacao.codigo;
          }
        }
        return res
      });
      this.onLoadCatalogo();
    })
  }
  onLoadPuc() {
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/puc', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.puc = JSON.parse(JSON.stringify(data));
    })
  }
  /*
  onLoadCatalogo() {
    this.loading = true
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.catalogo = JSON.parse(JSON.stringify(data));
      this.catalogo.forEach(e => {
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item', "nome").subscribe(data => {
          unSub.unsubscribe();
          let item = JSON.parse(JSON.stringify(data));
          item.forEach(element => {
            this.item.push(element)
          });
        });
      })
      this.loading = false;
    })
  }
  */
  onLoadTipo() {
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/tipo', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.tipo = JSON.parse(JSON.stringify(data));
      this.onLoadLotacao();
    })
  }
  onLoadLogin() {
    let unSub = this.genProvider.onGetAll('login', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.login = JSON.parse(JSON.stringify(data));
    })
  }
  onLoadLotacao() {
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/lotacao', 'nome').subscribe(data => {
      unSub.unsubscribe()
      let lotacoes = JSON.parse(JSON.stringify(data));
      this.lotacoes = lotacoes.map(res => {
        let tipo_lotacao = this.genProvider.onGetTableKey(this.tipo, res.key_tipo);
        if (tipo_lotacao) {
          res.tipo_lotacao = tipo_lotacao.nome;
        } else {
          res.tipo_lotacao = '';
        }
        return res
      });
      this.onLoadPessoa();
    })
  }
  onLoadCatalogo() {
      this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').pipe(take(1)).subscribe(data => {
      this.catalogo = JSON.parse(JSON.stringify(data));
      this.catalogo.forEach(e => {
        this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item', "nome").pipe(take(1)).subscribe(data => {
          let itemCatalogo = JSON.parse(JSON.stringify(data));
          itemCatalogo.forEach(i => {
            this.loading = true;
            this.itemCatalogo.push(i)
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item/' + i.key + '/subitem', 'nome').pipe(take(1)).subscribe(data => {
              let subItem = JSON.parse(JSON.stringify(data));
              subItem.forEach(s => {
                this.subItem.push(s)
              })
            });
            this.loading = false;
          });
        },
          err => console.log(err)
        );
      });
     
    },
      err => console.log(err)
    );
  }
  onLoad() {
    if ((this.inicio) && (this.final)) {
      this.loading = true;
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Gerando relatório.' });
      let inicio = new Date(this.inicio).getTime()
      let fim = new Date(this.final).getTime()
      let unSub = this.genProvider.onGetPeriodo('dados/' + this.path + '/ticket', 'data_criacao', inicio, fim).subscribe(data => {
        unSub.unsubscribe()
        let lista = JSON.parse(JSON.stringify(data));
     //   console.log(lista)
        let c = 0;
        let dados = lista.filter(obj => {
          return obj.status != "Cancelado";
        });

   //     this.lista_atualizar = dados; //remover
   //     console.log(this.lista_atualizar)
    //    this.loading = false;
      
       if (dados.length > 0) {
         dados = dados.map(res => {
          var dtInicio = new DatePipe("pt-BR").transform(res.data_criacao, "dd/MM/yyyy - HH:mm:ss");
          var dtAtual = new DatePipe("pt-BR").transform(res.time, "dd/MM/yyyy - HH:mm:ss"); 
          var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
          var d : any = moment.duration(ms);
//          var cvh = d.asDays+','+ Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

          var cvh = d._data.days +', '+d._data.hours +''+moment.utc(ms).format(":mm:ss")
        /*
          const now = new Date(res.time); // Data de hoje
          const past = new Date(res.data_criacao); // Outra data no passado
          const diff = Math.abs(now.getTime() - past.getTime());    
           // Mostra a diferença em dias
          const dias = Math.ceil(diff / (1000 * 60 * 60 * 24 ));
        */
           res.cvh = cvh;
           if (res.key_lotacao) {
             let lotacao = this.genProvider.onGetTableKey(this.lotacoes, res.key_lotacao)
             if (lotacao) {
               res.nome_lotacao = lotacao.nome
               res.tipo_lotacao = lotacao.tipo_lotacao;
             }
           }
           if (res.key_usuario) {
             let agente = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
             if (agente) {
               res.agente = agente.nome;
             }
           } else {
             res.agente = ''
           }
           if (res.key_solicitante) {
             let login = this.genProvider.onGetTableKey(this.login, res.key_solicitante);
             if (login) {
               if (res.canal == 'Painel') {
                 let pessoa = this.genProvider.onGetTableKey(this.pessoas, login.key_pessoa);
                 if (pessoa) {
               //    res.key_lotacao = pessoa.key_lotacao;
                   res.telefone_solicitante = pessoa.telefone;
                   res.email = pessoa.email;
                   res.codigo = pessoa.codigo;
                   res.tipo_solicitante = pessoa.tipo;
                   res.tipo_lotacao_solicitante = pessoa.tipo_lotacao;
                   res.lotacao_solicitante = pessoa.lotacao;
                 } else {
                   res.tipo_solicitante = "Externo"
                //   res.lotacao = '';
                 }
               } else {
                 let temp = this.pessoas.filter(obj => {
                   return obj.telefone = res.key_solicitante
                 });
                 if (temp.length > 0) {
                   let pessoa = temp[0];
                 //  res.key_lotacao = pessoa.key_lotacao;
                   res.telefone_solicitante = pessoa.telefone;
                   res.email = pessoa.email;
                   res.codigo = pessoa.codigo;
                   res.tipo_solicitante = pessoa.tipo;
                   res.tipo_lotacao_solicitante = pessoa.tipo_lotacao;
                   res.lotacao_solicitante = pessoa.lotacao;
                 } else {
                   res.tipo_solicitante = "Externo"
                //   res.lotacao = '';
                 }
               }
             }
           } 

           if (res.qtd_interacao) {
             res.ultima_interacao = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
           }
           res.data_criacaoT = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
           res.data_time = new DatePipe("pt-BR").transform(res.time, "d/MM/yyyy - HH:mm");
           if (res.conteudo) {
             res.conteudo = res.conteudo.replace(/<[^>]*>?/gm, '');
           }
           if (res.conteudoInterno) {
            res.conteudoInterno = res.conteudoInterno.replace(/<[^>]*>?/gm, '');
           }
           let puc = this.genProvider.onGetTableKey(this.puc, res.key_puc);
           if (puc) {
             res.puc = puc.nome
           }
           let catalogo = this.genProvider.onGetTableKey(this.catalogo, res.key_catalogo);
           if (catalogo) {
             res.catalogo = catalogo.nome
           } else {
             res.catalogo = ''
           }
           let item = this.genProvider.onGetTableKey(this.itemCatalogo, res.key_item);
           if (item) {
             res.item = item.nome
           } else {
             res.item = '';
           }
           let subitem = this.genProvider.onGetTableKey(this.subItem, res.key_subitem);
           if (subitem) {
             res.subitem = subitem.nome
           } else {
             res.subitem = '';
           }
           return res
         });
         this.loading = false;
         this.onAddLista(dados)
       } else {
         this.loading = false;
         this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não existe tickets para o periodo solicitado.' });
       }
   
      },
        err => {
          this.loading = false;
          console.log(err)
        }
      );
    }
  }

  onAddLista(data) {
    this.lista = data.map(res => ({
      ticket: res.key,
      canal: res.canal,
      data_criacao: res.data_criacaoT,
      status_ticket: res.status_ticket,
      titulo_ticket: res.titulo_ticket,
      codigo: res.codigo,
      nome_solicitante: res.nome_solicitante,
      email: res.email,
      lotacao_solicitante: res.lotacao_solicitante,
      tipo_lotacao_solicitante: res.tipo_lotacao_solicitante,
      catalogo: res.catalogo,
      item: res.item,
      subitem: res.subitem,
      conteudo: res.conteudo,
      ultima_interacao: res.ultima_interacao,
      nome_lotacao: res.nome_lotacao,
      tipo_lotacao: res.tipo_lotacao,
      agente: res.agente,
      cvh : res.cvh,
      conteudoInterno: res.conteudoInterno
    }));
  }
  onObterLista() {
    return this.lista
  }
  exportExcel() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, 'Ticket´s')
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      columnStyles: {
        0: { halign: 'left', fillColor: [220, 220, 220] }
      },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      setFontSize: 9,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onAtualizarTicket() {
    let c = 0;
    this.lista_atualizar = this.lista_atualizar.filter(obj => {
      return obj.status == "Finalizado";
    });
    this.lista_atualizar.sort(function (a, b) {
      if (a.time > b.time) {
        return 1;
      }
      if (a.time < b.time) {
        return -1;
      }
      return 0;
    });
    if (this.lista_atualizar.length> 0){
      let timeBot = setInterval(() => {
        this.genProvider.onGetAllPageDesc('dados/' + this.path + '/ticket/' + this.lista_atualizar[c].key + '/ticket_body', 'time', 1).pipe(take(1)).subscribe(data => {
          let tmp = JSON.parse(JSON.stringify(data));
          if (tmp.length > 0){
            console.log(tmp[0])
            if (tmp[0].key_lotacao== null){
              tmp[0].key_lotacao="1"
            }
            let dados = {
              key:this.lista_atualizar[c].key,
              key_usuario: tmp[0].key_usuario,
              key_lotacao: tmp[0].key_lotacao,
            }
            console.log (this.lista_atualizar[c].key_usuario, tmp[0].key_usuario)
            console.log (this.lista_atualizar[c].key_lotacao, tmp[0].key_lotacao)
            this.genProvider.onSaveObjeto('dados', this.parametros.path, "ticket", dados)
          }
          c++
          console.log ('Registro =>'+c);
          if (c >= this.lista_atualizar.length) {
            clearInterval(timeBot);
            alert ("Atualizacao finalizada.")
          }
        });
      }, 1000);
    }else {
      alert ("Sem dados")
    }

  }
}
