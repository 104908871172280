import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from '../../providers/gen/gen';
import { AlertService } from '../services/alert.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs-compat/Subscription';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { single } from '../dashticket/data';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs-compat';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'


@Component({
  selector: 'app-dashticket',
  templateUrl: './dashticket.component.html',
  styleUrls: ['./dashticket.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class DashticketComponent implements OnInit, OnDestroy {
  @ViewChild('dtLista') dtLista: Table;

  // chart
  productSales: any[]
  productSalesMulti: any[]
  formatString: any;
  formatNumber: any;

  view: any[] = [700, 370];
  progressBar: any;
  // options
  legendTitle: string = 'Dias';
  legendTitleMulti: string = 'Months';
  legendPosition: string = 'below'; // ['right', 'below']
  legend: boolean = true;

  xAxis: boolean = true;
  yAxis: boolean = true;

  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;

  maxXAxisTickLength: number = 30;
  maxYAxisTickLength: number = 30;
  trimXAxisTicks: boolean = false;
  trimYAxisTicks: boolean = false;
  rotateXAxisTicks: boolean = false;

  xAxisTicks: any[] = ['Genre 1', 'Genre 2', 'Genre 3', 'Genre 4', 'Genre 5', 'Genre 6', 'Genre 7']
  yAxisTicks: any[] = [100, 500, 1000, 1500, 2000, 2500, 3000]

  animations: boolean = true; // animations on load

  showGridLines: boolean = true; // grid lines

  showDataLabel: boolean = true; // numbers on bars



  schemeType: string = 'ordinal'; // 'ordinal' or 'linear'

  activeEntries: any[] = ['book']
  barPadding: number = 50;
  tooltipDisabled: boolean = false;

  yScaleMax: number = 9000;

  roundEdges: boolean = false;



  single: any[];


  // options
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#007BFF', '#28A745', '#17A2B8', '#FFC107', '#343A40', '#DC3545']
  };
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  yAxisLabel: string = 'Agentes';
  xAxisLabel: string = 'Atendimentos';





  key_lotacao: any;
  key_agente: any;
  key_item: any;
  key_subitem: any;
  filaAgente: any;
  path: any;
  parametros: any;
  mostraUsuario: any;
  key: any;
  chatFila: any;
  loading: boolean;
  chatAtendimento: any;
  fila: number = 0;
  percFila: number = 0;
  percAtendimento: number = 0;
  percEspera: number = 0;
  percPendente: number = 0;
  conversas: any;
  atendimento: any = 0;
  espera: any = 0;
  pendente: any = 0;
  chat_msg: any[];
  mostraMgs: boolean;
  chat: any;
  msgResp: any;
  msgPendente: any;
  ponto: any = [];
  mostraMgsFila: boolean;
  descricao: any;
  novaMsg: any = 0;
  percNovaMsg: number = 0;
  detalhe: any;
  dados: any;
  midia: { type: any; file: any; lat: any; lng: any; };
  mostraMidia: boolean;
  agente: any = [];
  distribuida: number = 0;
  percDistribuida: number = 0;
  emAtendimento: any;
  data: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };
  protocolo: any;
  dataChart: any;
  isStart: any;
  unSub: Subscription[] = [];
  unMsg: Subscription;
  days: any;
  encerrado: any;
  denuncias: any;
  inicio: any;
  final: any;
  mostraMgsAvaliada: boolean;
  situacao: any;
  origem: any;
  denunciaProtocolo: any;
  protocolo2: any;
  idTicket: any;
  dtInicio: any;
  dtFinal: any;
  tickets: any;
  lotacao: any = []
  puc: any;
  ticket_body: any;
  nome_responsavel: any;
  mostraTicket: boolean;
  sla: any;
  subItemCatalogo: any = [];
  itemCatalogo: any = [];
  tipoTicket: any;
  catalogo: any;
  formulario_consulta_ticket: FormGroup;
  formulario_pessoa: FormGroup;
  cols_ticket: any = [];
  usuarios: any;
  listaLotacao: any = [];
  listaCanal: any = [];
  listaAgente: any = [];
  listaPrimeiroNivel: any = [];
  modal_agente: any;
  modal_tools: any;
  dias: any = [];
  qtd_dias: any;
  temp: any = [];
  key_catalogo: string;
  unSub$ = new Subject();
  catalogoAll: any;
  titulo: string;
  listaItem: any = [];
  listaDia: any = [];
  listaStatus_ticket: any = [];
  myBtnFiltro: boolean;
  tempAll: any;
  key_usuario: any;
  nivel: any;
  key_lotacao_principal: any;
  key_pessoa: any;
  pessoa_lotacao: any = [];
  key_minhas_lotacao: any;
  nome_lotacao: any;
  modal_view: any;
  exportColumns: any;
  pessoa: any = [];
  ticket: any = [];
  listaSubitem: any = [];
  subItem: any = [];
  listaStatus24: any= [];
  listaArray24: any = [];
  temp24: any;
  
  constructor(
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private validaCampoProvider: ValidaCampoProvider,
  ) {
    Object.assign(this, { single });
    this.midia = {
      type: '',
      file: '',
      lat: 0,
      lng: 0
    }
    this.nome_lotacao = '';
    this.nivel = this.genProvider.parametros.nivel;
    this.key_lotacao = this.genProvider.parametros.key_lotacao;
    this.key_lotacao_principal = this.genProvider.parametros.key_lotacao;
    this.key_pessoa = this.genProvider.parametros.key_pessoa;
    this.progressBar = 'determinate'
    this.myBtnFiltro = false;
    this.filaAgente = this.genProvider.parametros.filaAgente
    this.path = this.genProvider.parametros.path;
    this.parametros = this.genProvider.parametros;
    this.mostraUsuario = this.genProvider.parametros.mostraUsuario

  }
  onClose() {
    this.router.navigate(['']);
  }

  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unMsg != undefined) {
      this.unMsg.unsubscribe();
    }
    this.unSub$.next();
    this.unSub$.complete();
  }
  onCombos() {
    this.dias = [
      { nome: '1 dia', qtd: 1 },
      { nome: '3 dias', qtd: 3 },
      { nome: '5 dias', qtd: 5 },
      { nome: 'Uma semana', qtd: 7 },
      { nome: 'Duas semanas', qtd: 14 },
      { nome: 'Três semanas', qtd: 21 },
      { nome: 'Um mês', qtd: 30 },
    ]
    this.cols_ticket = [
      { header: 'Ticket', field: 'key', width: '15%' },
      { header: 'Canal', field: 'canal', width: '10%' },
      { header: 'Criação', field: 'data_criacaoM', width: '10%' },
      { header: 'Status', field: 'status_ticket', width: '10%' },
      { header: 'Título', field: 'titulo_ticket', width: '19%' },
      { header: 'solicitante', field: 'nome_solicitante', width: '18%' },
      { header: 'Últ.Interação/Qtd', field: 'ultima_interacaoM', width: '15%' },
      { header: 'Prazo', field: 'prazo_resolucaoM', width: '15%' },
      // { header: 'Unidade Operacional', field: 'nome_lotacao', width: '11%' },
      { header: 'Responsável', field: 'nome_responsavel', width: '15%' },
      { header: 'Item', field: 'nome_item', width: '15%' },
      { header: 'SubItem', field: 'nome_subitem', width: '15%' },
    ];
    this.exportColumns = this.cols_ticket.map(col => ({ title: col.header, dataKey: col.field }));
  }
  ngOnInit() {
    this.onLoadLotacao();
    this.onCombos();
    this.onLoadCatalogo();
    this.onLoadLogin()
  }
  onLoadLogin() {
    let unSub = this.genProvider.onGetAll('login', 'nome').pipe(take(1)).subscribe(data => {
      unSub.unsubscribe();
      this.usuarios = JSON.parse(JSON.stringify(data));
      this.onLoadLastTickets();
    })
  }
  onLoadUsuarios(key) {
    this.genProvider.onGetFilterOrder('login', 'nome', 'key_lotacao', key).pipe(take(1)).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      this.agente = [];
      this.agente = lista.map(res => {
        res.busca = res.nome.toUpperCase();
        res.busca += res.login.toUpperCase();
        res.busca += res.path.toUpperCase();
        return res;
      });
    },
      err => console.log(err)
    );

  }
  onLoadCatalogoSubItemConsulta(key, catalogo) {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + catalogo + '/item/' + key + '/subitem', "nome").pipe(take(1)).subscribe(data => {
      this.subItemCatalogo = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    ));
  }
  onSelect(dados, filtro?: any): void {
    let data: any = [];
    if (!dados.name) {
      data = {
        name: dados
      }
    } else {
      data = {
        name: dados.name
      }
    }

    if (filtro == 'agente') {
      this.temp = this.tempAll.filter(obj => {
        return obj.nome_agente == data.name
      })
      this.onDataChart(this.temp)
    } else if (filtro == 'lotacao') {
      this.temp = this.tempAll.filter(obj => {
        return obj.nome_lotacao == data.name
      })
      this.onDataChart(this.temp)
    } else if (filtro == 'dia') {
      this.temp = this.tempAll.filter(obj => {
        return obj.dia == data.name
      })
      this.onDataChart(this.temp)
    }
    else if (filtro == 'canal') {
      this.temp = this.tempAll.filter(obj => {
        return obj.canal == data.name
      })
      this.onDataChart(this.temp)
    }
    else if (filtro == 'itens') {
      this.temp = this.tempAll.filter(obj => {
        return obj.nome_item == data.name
      })
      this.onDataChart(this.temp)
    }
    else if (filtro == 'subitem') {
      this.temp = this.tempAll.filter(obj => {
        return obj.nome_subitem == data.name
      })
      this.onDataChart(this.temp)
    }
    else if (filtro == 'status') {
      this.temp = this.tempAll.filter(obj => {
        return obj.status_ticket == data.name
      })
      this.onDataChart(this.temp)
    }
    else if (filtro == 'status24') {
      this.temp = this.temp24;
      this.onDataChart(this.temp)
    }

  }
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }
  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  onLoadLastTickets(ini?: any, fim?: any, qtd_dias?: any) {
    this.onLoadUsuarios(this.key_lotacao)
    let nomeLotacao$ = this.genProvider.onGetTableKey(this.lotacao, this.key_lotacao)
    if (nomeLotacao$) {
      this.nome_lotacao = nomeLotacao$.sigla
    }
    this.progressBar = 'indeterminate'
    this.myBtnFiltro = true;
    let dias = 1;
    if (this.unMsg) {
      this.unMsg.unsubscribe();
    }
    if (!ini) {
      if (qtd_dias) {
        dias = qtd_dias
        this.titulo = "Últimos " + qtd_dias + " dias"
      } else {
        this.titulo = "Últimas 24 horas"
      }
      let time = new Date();
      let inicio = new Date();
      inicio.setDate(time.getDate() - dias);
      let dia = Date.parse(inicio.toString());
      this.unMsg = this.genProvider.onGetPeriodoFilter('dados/' + this.path + '/ticket', 'data_criacao', 'key_lotacao', this.key_lotacao, dia).subscribe(data => {
        let temp = JSON.parse(JSON.stringify(data));
        if (temp.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo para consulta.' });
          this.progressBar = 'determinate'
          this.myBtnFiltro = false;
        } else {
          this.temp = temp.map(res => {
            if (!res.nome_responsavel) {
              res.nome_responsavel = 'Sem responsável'
            }
            res.dia = new DatePipe("pt-BR").transform(res.data_criacao, "dd-MM");
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
            if (res.qtd_interacao) {
              res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
            }
            if (res.key_usuario) {
              let nome$ = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario)
              if (nome$) {
                res.nome_agente = nome$.nome
              }
            }

            if (res.key_lotacao) {
              let nome$ = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (nome$) {
                res.nome_lotacao = nome$.nome
              }
            }
            if (res.key_item) {
              let nome$ = this.genProvider.onGetTableKey(this.itemCatalogo, res.key_item)
              if (nome$) {
                res.nome_item = nome$.nome
              }
            }
            if (res.key_responsavel) {
              let nome$ = this.genProvider.onGetTableKey(this.pessoa_lotacao, res.key_responsavel)
              if (nome$) {
                res.nome_responsavel = nome$.nome
              }
            }
            if (res.key_subitem) {
              let nome$ = this.genProvider.onGetTableKey(this.subItem, res.key_subitem)
              if (nome$) {
                res.nome_subitem = nome$.nome
              }
            }
            res.canal = res.canal.toUpperCase()
            return res;
          });
          this.tempAll = this.temp
          this.onDataChart(this.temp)
        }
      });
    } else {
      let inicio = new Date(ini).getTime()
      let final = new Date(fim).getTime()
      this.titulo = "Periodo de " + new DatePipe("pt-BR").transform(inicio, "dd/MM") + " à " + new DatePipe("pt-BR").transform(final, "dd/MM")
      this.unMsg = this.genProvider.onGetPeriodoFilter('dados/' + this.path + '/ticket', 'data_criacao', 'key_lotacao', this.key_lotacao, inicio, final).subscribe(data => {
        let temp = JSON.parse(JSON.stringify(data));
        if (temp.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo para consulta.' });
          this.progressBar = 'determinate'
          this.myBtnFiltro = false;
        } else {
          this.temp = temp.map(res => {
            if (!res.nome_responsavel) {
              res.nome_responsavel = 'Sem responsável'
            }
            res.dia = new DatePipe("pt-BR").transform(res.data_criacao, "dd-MM");
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
            if (res.qtd_interacao) {
              res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
            }

            let nome$ = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario)
            if (nome$) {
              res.nome_agente = nome$.nome
            }
            if (res.key_lotacao) {
              nome$ = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (nome$) {
                res.nome_lotacao = nome$.nome
              }
            }
            if (res.key_item) {
              let nome$ = this.genProvider.onGetTableKey(this.itemCatalogo, res.key_item)
              if (nome$) {
                res.nome_item = nome$.nome
              }
            }
            if (res.key_responsavel) {
              let nome$ = this.genProvider.onGetTableKey(this.pessoa_lotacao, res.key_responsavel)
              if (nome$) {
                res.nome_responsavel = nome$.nome
              }
            }
            if (res.key_subitem) {
              let nome$ = this.genProvider.onGetTableKey(this.subItem, res.key_subitem)
              if (nome$) {
                res.nome_subitem = nome$.nome
              }
            }
            res.canal = res.canal.toUpperCase()
            return res;
          });
          this.tempAll = this.temp;
          this.onDataChart(this.temp)
        }
      });
    }
  }
  onDataChart(temp) {
    this.progressBar = 'indeterminate'
    this.myBtnFiltro = true;
    this.listaLotacao = []
    this.listaCanal = [];
    this.listaAgente = [];
    let listaArray = [];
    let c = 0;
    let i = 0;
    let nome$: any;
    let nome: any;
    let key: any;
    listaArray = temp.filter(obj => {
      return obj.status != 'Cancelado'
    })
    let listaTemp = [];
    // lista por canal
    listaArray.sort(function (a, b) {
      if (a.canal > b.canal) {
        return 1;
      }
      if (a.canal < b.canal) {
        return -1;
      }
      return 0;
    });
    let canal = listaArray[0].canal;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      i++
      c++
      if (canal != e.canal) {
        listaTemp.push({
          name: canal.toUpperCase() || '',
          value: c - 1
        })
        canal = e.canal
        c = 1;
      } else if (listaArray.length == i) {
        canal = e.canal
        listaTemp.push({
          name: canal.toUpperCase() || '',
          value: c
        })
      }
    });
    this.listaCanal = listaTemp;
    // fim

    // lista por agente
    listaTemp = [];
    nome$ = '';
    nome = '';
    c = 0;
    i = 0;
    listaArray.sort(function (a, b) {
      if (a.key_usuario > b.key_usuario) {
        return 1;
      }
      if (a.key_usuario < b.key_usuario) {
        return -1;
      }
      return 0;
    });
    key = listaArray[0].key_usuario
    nome = listaArray[0].nome_agente

    listaArray.forEach(e => {
      c++
      i++
      if (key != e.key_usuario) {
        listaTemp.push({
          key: e.key_usuario,
          name: nome,
          value: c - 1
        })
        key = e.key_usuario
        nome = e.nome_agente
        c = 1;
      } else if (listaArray.length == i) {
        key = e.key_usuario
        nome = e.nome_agente
        listaTemp.push({
          key: e.key_usuario,
          name: nome,
          value: c
        })
      }
    });
    this.listaAgente = listaTemp;
    // fim

    // lista por dia
    listaTemp = [];
    nome$ = '';
    nome = '';
    c = 0;
    i = 0;
    listaArray.sort(function (a, b) {
      if (a.dia > b.dia) {
        return 1;
      }
      if (a.dia < b.dia) {
        return -1;
      }
      return 0;
    });
    let dia = listaArray[0].dia;
    listaArray.forEach(e => {
      c++
      i++
      if (dia != e.dia) {
        listaTemp.push({
          name: dia.toUpperCase(),
          value: c - 1
        })
        dia = e.dia
        c = 1;
      } else if (listaArray.length == i) {
        dia = e.dia
        listaTemp.push({
          name: dia.toUpperCase(),
          value: c
        })
      }
    });
    this.listaDia = listaTemp;
    listaTemp = [];
    // fim

    // lista por status ticket
    listaTemp = [];
    nome$ = '';
    nome = '';
    c = 0;
    i = 0;
    listaArray.sort(function (a, b) {
      if (a.status_ticket > b.status_ticket) {
        return 1;
      }
      if (a.status_ticket < b.status_ticket) {
        return -1;
      }
      return 0;
    });
    let status_ticket = listaArray[0].status_ticket;
    listaArray.forEach(e => {
      c++
      i++
      if (status_ticket != e.status_ticket) {
        listaTemp.push({
          name: status_ticket,
          value: c - 1
        })
        status_ticket = e.status_ticket
        c = 1;
      } else if (listaArray.length == i) {
        status_ticket = e.status_ticket
        listaTemp.push({
          name: status_ticket,
          value: c
        })
      }
    });
    this.listaStatus_ticket = listaTemp;
    // fim
    // lista nao visualizados ultimas 24 horas
    // inicio
    let time = new Date();
    let inicio = new Date();
    inicio.setDate(time.getDate() - 1);
    let ult24 = Date.parse(inicio.toString());
   // console.log (temp)
    this.listaArray24 = temp.filter(obj => {
      return obj.status_ticket == 'Novo' &&  obj.time <= ult24
    })
    this.temp24 = this.listaArray24;
    listaTemp = [];
    nome$ = '';
    nome = '';
    c = 0;
    i = 0;
    if (this.listaArray24.length > 0) {
      this.listaArray24.sort(function (a, b) {
        if (a.time > b.time) {
          return 1;
        }
        if (a.time < b.time) {
          return -1;
        }
        return 0;
      });
      status_ticket = this.listaArray24[0].status_ticket;
      this.listaArray24.forEach(e => {
        c++
        i++
        if (status_ticket != e.status_ticket) {
          listaTemp.push({
            name: status_ticket,
            value: c - 1
          })
          status_ticket = e.status_ticket
          c = 1;
        } else if (this.listaArray24.length == i) {
          status_ticket = e.status_ticket
          listaTemp.push({
            name: status_ticket,
            value: c
          })
        }
      });
      this.listaStatus24 = listaTemp;
    } else {
      this.listaStatus24.push({
        name: "Sem Registro",
        value: 0
      })
    }
    // fim


    // lista por responsavel
    listaTemp = [];
    this.listaAgente = [];
    nome$ = '';
    nome = '';
    c = 0;
    i = 0;
    listaArray.sort(function (a, b) {
      if (a.key_responsavel > b.key_responsavel) {
        return 1;
      }
      if (a.key_responsavel < b.key_responsavel) {
        return -1;
      }
      return 0;
    });
    let result = listaArray.filter(obj => {
      return obj.key_responsavel
    })
    if (result.length > 0) {
      key = result[0].key_responsavel
      if (key) {
        nome$ = this.genProvider.onGetTableKey(this.pessoa_lotacao, key)
        if (nome$) {
          nome = nome$.nome
        }
      }
      listaArray.forEach(e => {
        c++
        i++
        if (key != e.key_responsavel) {
          listaTemp.push({
            key: e.key_responsavel,
            name: nome,
            value: c - 1
          })
          key = e.key_responsavel
          if (key) {
            nome$ = this.genProvider.onGetTableKey(this.pessoa_lotacao, key)
            if (nome$) {
              nome = nome$.nome
            }
          }
          c = 1;
        } else if (listaArray.length == i) {
          key = e.key_responsavel
          if (key) {
            nome$ = this.genProvider.onGetTableKey(this.pessoa_lotacao, key)
            if (nome$) {
              nome = nome$.nome
            }
          }

          listaTemp.push({
            key: e.key_usuario,
            name: nome,
            value: c
          })
        }
      });
      this.listaAgente = listaTemp;
      // fim
    }

    // lista por item
    listaTemp = [];
    nome$ = '';
    nome = '';
    c = 0;
    i = 0;
    listaArray.sort(function (a, b) {
      if (a.key_item > b.key_item) {
        return 1;
      }
      if (a.key_item < b.key_item) {
        return -1;
      }
      return 0;
    });
    key = listaArray[0].key_item
    nome = listaArray[0].nome_item
    listaArray.forEach(e => {
      c++
      i++
      if (key != e.key_item) {
        listaTemp.push({
          key: e.key_item,
          name: nome || 'Sem título',
          value: c - 1
        })
        key = e.key_item
        nome = e.nome_item || 'Sem título'
        c = 1;
      } else if (listaArray.length == i) {
        key = e.key_item
        nome = e.nome_item
        listaTemp.push({
          key: e.key_item,
          name: nome || 'Sem título',
          value: c
        })
      }
    });
    this.listaItem = listaTemp;

    // fim
    // lista por subitem

    listaTemp = [];
    nome = '';
    c = 0;
    i = 0;
    listaArray.sort(function (a, b) {
      if (a.key_subitem > b.key_subitem) {
        return 1;
      }
      if (a.key_subitem < b.key_subitem) {
        return -1;
      }
      return 0;
    });
    key = listaArray[0].key_subitem
    nome = listaArray[0].nome_subitem || 'Sem título'

    listaArray.forEach(e => {
      c++
      i++
      if (key != e.key_subitem) {
        listaTemp.push({
          key: e.key_subitem,
          name: e.nome_subitem || 'Sem título',
          value: c - 1
        })
        key = e.key_subitem
        nome = e.nome_subitem || 'Sem título'
        c = 1;
      } else if (listaArray.length == i) {
        key = e.key_subitem
        nome = e.nome_subitem || 'Sem título'
        listaTemp.push({
          key: e.key_subitem,
          name: e.nome_subitem || 'Sem título',
          value: c
        })
      }
    });
    this.listaSubitem = listaTemp;

    // fim
    this.progressBar = 'determinate'
    this.myBtnFiltro = false;
    this.modal_tools = false;

  }
  showFiltro() {
    this.progressBar = 'determinate'
    this.myBtnFiltro = false;
    this.modal_tools = true;
  }
  onLoadCatalogoSubItem(key) {
    this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + this.key_catalogo + '/item/' + key + '/subitem', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
      this.subItemCatalogo = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    );
  }
  onLoadCatalogo() {
    this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').pipe(take(1)).subscribe(data => {
      this.catalogo = JSON.parse(JSON.stringify(data));
      this.catalogo.forEach(e => {
        this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item', "nome").pipe(take(1)).subscribe(data => {
          let itemCatalogo = JSON.parse(JSON.stringify(data));
          itemCatalogo.forEach(i => {
            this.itemCatalogo.push(i)
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item/' + i.key + '/subitem', 'nome').pipe(take(1)).subscribe(data => {
              let subItem = JSON.parse(JSON.stringify(data));
              subItem.forEach(s => {
                this.subItem.push(s)
              })
            });
          });
        },
          err => console.log(err)
        ));
      });
    },
      err => console.log(err)
    );
  }
  onLoadLotacao() {
    this.genProvider.onGetAll('dados/' + this.path + '/lotacao', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
      let lotacao = JSON.parse(JSON.stringify(data));
      //    this.lotacao = lotacao;
      this.lotacao = lotacao.map(res => {
        res.busca = res.nome + " " + res.sigla
        return res;
      });
      this.onLoadPessoa_lotacao(this.key_pessoa);

    },
      err => console.log(err)
    );
  }
  onLoadPessoa_lotacao(key) {
    if (this.parametros.nivel != '99') {
      this.genProvider.onGet('dados/' + this.path + "/pessoa/" + key, 'lotacao').pipe(take(1)).subscribe(data => {
        let pessoa_lotacao = JSON.parse(JSON.stringify(data));
        if (pessoa_lotacao.length > 0) {
          pessoa_lotacao.forEach(res => {
            if ((res.data_expira) && (res.data_expira <= Date.now())) {
              var lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao);
              res.nome = lotacao.nome;
              if (res.data_expira) {
                res.data = new DatePipe("pt-BR").transform(res.data_expira, "dd/MM/yyyy - HH:mm");
              }
              res.nomeData = res.nome + ' - ' + res.data
              this.pessoa_lotacao.push(res);
            } else if (!res.data_expira) {
              var lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao);
              res.nome = lotacao.nome;
              res.data = 'Indeterminado'
              res.nomeData = res.nome + ' - ' + res.data
              res.nomeSigla = res.nome + ' - ' + res.sigla + res.codigo + ' - ' + res.codigo
              this.pessoa_lotacao.push(res)
            }
          });
        }
      },
        err => console.log(err)
      );
    } else {
      this.lotacao.forEach(res => {
        let dados = {
          nome: res.nome,
          data: 'Indeterminado',
          nomeData: res.nome,
          nomeSigla: res.nome + ' - ' + res.sigla,
          key_lotacao: res.key
        }
        this.pessoa_lotacao.push(dados)
      })
    }
  }
  onGetPessoa_lotacao(key) {
    if (!key) {
      this.nivel = '99';
      this.key_lotacao = this.key_lotacao_principal;
      //  this.unMsg.unsubscribe();
      //    this.onLoadLastTickets()
    } else {
      this.key_lotacao = key;
      this.nivel = '0';
      //  this.unMsg.unsubscribe();
      //   this.onLoadLastTickets()
    }
  }
  onMostraTicket(event) {
    this.ticket = event
    let joins = [{ key: "key_lotacao", table: "lotacao" }, { key: "key_cargo", table: "cargo" }]
    let telefone = this.validaCampoProvider.formataTelefoneDDD(event.key_solicitante)
    this.ticket.telefone = telefone;
    this.genProvider.onGetfilter('dados/' + this.path + '/pessoa', 'telefone', telefone, joins).pipe(take(1)).subscribe(res => {
      let pessoa = JSON.parse(JSON.stringify(res));
      if (pessoa.length > 0) {
        this.pessoa = pessoa[0]
        if (pessoa[0].lotacao) {
          this.pessoa.nome_lotacao = pessoa[0].lotacao.nome
        }
        if (pessoa[0].cargo) {
          this.pessoa.nome_cargo = pessoa[0].cargo.nome
        }
      }
    });
    this.genProvider.onGetAllPageDesc('dados/' + this.path + '/chat/' + event.protocolo + '/' + 'chat_body', 'time', 999).pipe(take(1)).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      this.ticket_body = lista.map(res => {
        if (res.status != 'Enviada') {
          res.status = "Recebida"
        }
        return res;
      });
    });
    if (!event.key_responsavel) {
      this.nome_responsavel = " Sem Responsável"
    } else {
      this.genProvider.onGetKey('login', event.key_responsavel).pipe(take(1)).subscribe(res => {
        let login = JSON.parse(JSON.stringify(res));
        this.nome_responsavel = login.nome
      });
    }
    this.mostraTicket = true;
    //  })
  }
  openMidia(res) {
    this.midia = {
      type: res.type,
      file: res.value,
      lat: res.lat || 0,
      lng: res.lng || 0
    }

    this.mostraMidia = true;

  }
  exportExcel() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.temp
    }
    let dados: any = []
    lista.forEach(e => {
      let campo = '{';
      let c = 0;
      this.cols_ticket.forEach(i => {
        c++
        if (c < this.cols_ticket.length) {
          campo += '"' + [i.field] + '":"' + e[i.field] + '",'

        } else {
          campo += '"' + [i.field] + '":"' + e[i.field] + '"}'
        }
      });
      var objeto = JSON.parse(campo);
      dados.push(objeto)
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(dados);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.temp
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(20)
    doc.setFontType('bold');
    doc.text(14, 10, 'Chamados')
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    doc.text(this.titulo, 14, 15);
    doc.autoTable({
      columnStyles: {
        0: { halign: 'left', fillColor: [220, 220, 220] }
      },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('listaTickets.pdf');
  }
}
/*
https://webhook.site/
*/