import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TimeGridSlicer } from '@fullcalendar/timegrid';
import { MessageService } from 'primeng/api';
import { GenProvider } from 'src/providers/gen/gen';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
    parametro: any;
    agenteLetra: any;
    trocaSenha: any;
    senha1: any;
    senha2: any;
    nomeUsuario: any;

    constructor(
        public app: AppMainComponent,
        private genProvider: GenProvider,
        public router: Router,
        private messageService: MessageService) {
        this.parametro = this.genProvider.parametros;
//        this.nomeUsuario = this.parametro.nome.split(' ').slice(0, 1).join(' ');
        this.nomeUsuario = this.parametro.nome
    }
    ngOnInit() {
        this.agenteLetra = this.parametro.nome.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').substring(0, 1);
    }
    onTrocaSenha() {
        this.trocaSenha = true;
    }
    onValidaSenha() {
        if (this.senha1 == this.senha2) {
            this.onSubmit()
        } else {
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Senha incorreta.' });
        }
    }
    onSubmit() {
        let dados = {
            key: this.parametro.key_usuario,
            senha: this.senha1,
            data_hora_alteracao: new DatePipe('pt-BR').transform(
                new Date(),
                'yyyy-MM-dd HH:mm:ss'
            )
        }
        this.genProvider.onSaveIncrementZero("login", dados, "usuario", 6).then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Senha alterada com sucesso.' });
            this.trocaSenha = false;
            this.router.navigate(['login'])
        })
            .catch(e => {
                this.messageService.add({ severity: 'info', summary: 'Erro', detail: 'Não foi possível gravar seus dados.' });
                console.log(e);
            });
    }
}
