/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { DatePipe, registerLocaleData } from '@angular/common';
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage";
import { finalize, take } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-catalogo',
  templateUrl: './Catalogo.component.html',
  styleUrls: ['./Catalogo.component.scss'],
  providers: [ConfirmationService]
})
export class CatalogoComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @ViewChild('dtListaItem') dtListaItem: Table;
  @ViewChild('dtListaItemSub') dtListaItemSub: Table;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  formulario_sub: FormGroup;
  item: any;
  sla: any;
  permissao: any = true;
  uploadedFiles: any[] = [];
  type_anexo: any;
  anexarArquivo: boolean;
  fileSrc: any;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  progress: Observable<number>;
  snapshot: Observable<unknown>;
  downloadURL: any;
  anexarArquivo_sub: boolean;
  tipo: { nome: string; }[];
  tabMenuitens: { id: string; label: string; icon: string; }[];
  page: string;
  unSub: Subscription[] = [];
  mostraItem: boolean;
  listaItem: any;
  formulario_item: FormGroup;
  cols_Item: { header: string; field: string; }[];
  exportColumns_item: any;
  mostraFrmItem: boolean;
  key_catalago: any;
  listaItemSub: any;
  loading:any;
  lotacao: any;
  catalogo: any = [];
  template: any;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public storage: AngularFirestore,
    private afStorage: AngularFireStorage
  ) {
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      key_lotacao: null,
      key_template: null,
      descricao: [null, Validators.required],
      disponivel: [null, Validators.required],
      tipo: [null, Validators.required],
      status: [null, Validators.required]
    });
    this.formulario_item = this.formBuilder.group({
      key: null,
      key_lotacao: null,
      file: '',
      fullPath: '',
      target: '',
      nome: [null, Validators.required],
      pop:'',
      pop_url: '',
      pop_midia: '',
      fullName: '',
      type: '',
      status: [null, Validators.required]
    });


    this.formulario_sub = this.formBuilder.group({
      key: null,
      file: '',
      fullPath: '',
      target: '',
      fullName: '',
      type: '',
      nome: [null, Validators.required],
      pop_url: '',
      pop_midia: '',
      pop:''
    });
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnInit() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.tipo = [
      { nome: 'Catálogo de Serviço' },
      { nome: 'Carta de Serviço ao Cidadão' }
    ]
    this.tabMenuitens = [
      { id: '1', label: 'Dados', icon: 'pi pi-fw pi-globe' },
      { id: '2', label: 'item', icon: 'pi pi-fw pi-align-justify' }
    ];
    this.page = "1";
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Nome', field: 'nome' },
      { header: 'Descriçao', field: 'descricao' },
      { header: 'POP', field: 'pop' },
      { header: 'Tipo', field: 'tipo' },
      { header: 'Disponível', field: 'disponivel' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.cols_Item = [
      { header: 'Código', field: 'key' },
      { header: 'Nome do Item', field: 'nome' },
      { header: 'Procedimento Op. Url', field: 'pop_url' },
      { header: 'Procedimento Op. Mídia', field: 'pop_midia' },
      { header: 'POP', field: 'pop' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns_item = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.onLoad();
    this.onLoadLotacao();
    this.onLoadTemplate();
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }
  onTab(e) {
    this.page = e.activeItem.id
  }
  onItem(data){
    this.mostraItem = true;
    this.key_catalago = data.key;
    this.formulario.patchValue(data)
    this.onLoadItem(data.key)
  }
  onLoad() {
    this.loading = true;
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo', "nome").subscribe(data => {
      this.lista = data;
      this.loading = false;
    },
      err => console.log(err)
    ));
  }
  onLoadItem(key) {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo/'+key+'/item', "nome").subscribe(data => {
      this.listaItem = data;
    },
      err => console.log(err)
    ));
  }
  onLoadItemSub(key) {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo/'+this.key_catalago+'/item/'+key+'/subitem', "nome").subscribe(data => {
      this.listaItemSub = data;
    },
      err => console.log(err)
    ));
  }
  onLoadSub(key) {
    this.unSub.push(this.genProvider.onGet("dados/" + this.path + "/catalogo/" + key , "item").subscribe(data => {
      this.item = data;
    },
      err => console.log(err)
    ));
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo Catálogo/Carta de Serviço';
    this.onMostraFrm();
    this.permissao = false;
    this.formulario.patchValue({
      disponivel : 'todos'
    })

  }
  onNovoItem() {
    this.formulario_item.reset();
    this.titulo = 'Novo Item';
    this.mostraFrmItem = true;
    this.permissao = false;
    this.anexarArquivo = false;
    this.anexarArquivo_sub = false;
    this.item = [];
    this.listaItem = [];

  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event)
    this.titulo = 'Editar Catálogo/Carta de Serviço';
    this.onMostraFrm();
    this.permissao = false;
  }
  onEditItem(event: any) {
    this.formulario_item.reset();
    this.formulario_item.patchValue(event)
    this.titulo = 'Editar Item';
    this.mostraFrmItem = true;
    this.onLoadItemSub(this.formulario_item.value.key)
    this.permissao = false;
    this.anexarArquivo = false;
  }
  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.genProvider.remove('dados/' + this.path + '/catalogo', event.key, 'key_catalogo').then(res=>{
          if (res!=null){
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada, este registro está relaciondo à '+ res +' tabelas!' });
          }else {
            this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
          }
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onDeleteConfirmItem(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/catalogo/'+this.key_catalago+'/item', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      this.onMostraFrm();
      if (this.anexarArquivo) {
        this.upLoadFile(this.formulario)
      } else {
        this.onSubmit();
      }
    }
  }
  onSaveItem() {
    if (this.formulario_item.valid) {
      this.mostraFrmItem = false;
      if (this.anexarArquivo) {
        this.upLoadFile(this.formulario_item)
      } else {
        this.onSubmitItem(this.key_catalago);
      }
    }
  }
  onSubmitItem(key) {
    let dados = this.formulario_item.value
    delete dados["target"];
    this.genProvider.onSaveIncrement("dados/" + this.path + "/catalogo/"+key+"/item", dados, "item").then(t => {
      this.onLoadItemSub(t)
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  onSubmit() {
    this.genProvider.onSaveIncrement("dados/" + this.path + "/catalogo", this.formulario.value, "catalogo").then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "Catálogo/Carta")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  exportPdf_Item() {
    let lista: any;
    if (this.dtListaItem.filteredValue) {
      lista = this.dtListaItem.filteredValue
    } else {
      lista = this.listaItem
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "Catálogo/Carta")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onSaveSub() {
    if (this.formulario_sub.valid) {
      if (this.anexarArquivo_sub) {
        this.upLoadFileSub(this.formulario_sub)
      } else {
        this.onSubmitSub();
      }
    }
  }
  onSubmitSub() {
    let dados = this.formulario_item.value
    let dados_sub = this.formulario_sub.value
    delete dados["target"];
    delete dados_sub["target"];
    if ((this.formulario_item.valid) && (this.formulario_sub.valid) && (this.formulario_item.value.key)) { 
      this.onLoadSub(this.formulario_item.value.key)
      dados = this.formulario_item.value
      dados_sub = this.formulario_sub.value
      delete dados["target"];
      delete dados_sub["target"];
      this.genProvider.onSaveIncrement('dados/' + this.path + '/catalogo/'+this.key_catalago+'/item/'+this.formulario_item.value.key+"/subitem", dados_sub, "subitem").then(t => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
        this.formulario_sub.reset()
      }).catch(e => {
        this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
        console.log(e);
      });
    } else if ((this.formulario_item.valid) && (this.formulario_sub.valid) && (!this.formulario_item.value.key)) {
      this.genProvider.onSaveIncrement("dados/" + this.path + "/catalogo/"+this.key_catalago+"/item", dados, "item").then(t => {
        this.onLoadItemSub(t)
        this.formulario_item.patchValue({
          key: t
        })
        dados = this.formulario_item.value
        dados_sub = this.formulario_sub.value
        delete dados["target"];
        delete dados_sub["target"];
        this.genProvider.onSaveIncrement('dados/' + this.path + '/catalogo/'+this.key_catalago+'/item/'+this.formulario_item.value.key+"/subitem", dados_sub, "subitem").then(t => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
          this.formulario_sub.reset()
        }).catch(e => {
          this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
          console.log(e);
        });
      })
    }
  }
  onEditSub(event) {
    this.formulario_sub.patchValue(event)
    this.anexarArquivo_sub = false;
  }
  onDeleteSub(event) {
    console.log (this.key_catalago,this.formulario_item.value.key, event.key)
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/catalogo/'+this.key_catalago+'/item/'+this.formulario_item.value.key+'/subitem', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });
  }
  onArquivo(tipo) {
    var botao = document.getElementById("FileUpload" + tipo);
    botao.click();
  }
  onFile(e) {
    this.progress = null;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formulario_item.patchValue({
      fullName: file.name,
      pop_midia: file.name,
      type: file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
      type_anexo: this.type_anexo,
      target: e.target.files[0]
    });
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.anexarArquivo = true;
  }
  _handleReaderLoaded(e) {
    var reader = e.target;
    this.fileSrc = reader.result
    this.formulario_item.patchValue({
      file: reader.result
    });
  }
  onFileSub(e) {
    this.progress = null;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formulario_sub.patchValue({
      fullName: file.name,
      pop_midia: file.name,
      type: file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
      type_anexo: this.type_anexo,
      target: e.target.files[0]
    });
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.anexarArquivo_sub = true;
  }
  _handleReaderLoadedSub(e) {
    var reader = e.target;
    this.fileSrc = reader.result
    this.formulario_sub.patchValue({
      file: reader.result
    });
  }
  upLoadFile(formulario: any) {
    let tabela = 'item'
    const filePath = `/${tabela}/file_${new DatePipe('pt-BR').transform(
      new Date(),
      'yyyy-MM-dd-HH:mm:ss'
    )}.` + formulario.value.fullName
    this.ref = this.afStorage.ref(filePath);
    this.task = this.ref.put(this.formulario_item.value.target);
    this.progress = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL().subscribe(res => {
          formulario.patchValue({
            file: res,
            fullPath: filePath,
            target: ''
          })
          this.onSubmitItem(this.key_catalago);
          this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
        })
      })
    );
    this.snapshot.subscribe(
      res => {
        console.log(res);
      }, err => {
        console.log(err);
      }
    );
  }
  upLoadFileSub(formulario: any) {
    let tabela = 'item'
    const filePath = `/${tabela}/file_${new DatePipe('pt-BR').transform(
      new Date(),
      'yyyy-MM-dd-HH:mm:ss'
    )}.` + formulario.value.fullName
    this.ref = this.afStorage.ref(filePath);
    this.task = this.ref.put(formulario.value.target);
    this.progress = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL().subscribe(res => {
          formulario.patchValue({
            file: res,
            fullPath: filePath,
            target: ''
          })
          this.onSubmitSub();
          this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
        })
      })
    );
    this.snapshot.subscribe(
      res => {
        console.log(res);
      }, err => {
        console.log(err);
      }
    );
  }
  onViewFile(file) {
    console.log(file)
    if (file) {
      window.open(file, '_blank');
    }
  }
  onLoadLotacao() {
    this.genProvider.onGetAll('dados/' + this.path + '/lotacao', "nome").pipe(take(1)).subscribe(data => {
      let lotacao = JSON.parse(JSON.stringify(data));
      this.lotacao = lotacao.map(res => {
        res.nomeSigla = res.nome + " " + res.sigla + " " + res.codigo
        return res;
      });
    },
      err => console.log(err)
    );
  }
  onLoadTemplate() {
    this.genProvider.onGetFilterOrder('dados/' + this.path + '/template', "nome","status","ativo").pipe(take(1)).subscribe(data => {
      this.template = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    );
  }
  onLoadCatalogo() {
    this.loading = true
    let contador = 0
    this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').pipe(take(1)).subscribe(data => {
      let catalogo = JSON.parse(JSON.stringify(data));
      catalogo.forEach(c => {
        this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + c.key + '/item', "nome").pipe(take(1)).subscribe(data => {
          let itemCatalogo = JSON.parse(JSON.stringify(data));
          itemCatalogo.forEach(i => {
       //     this.itemCatalogo.push(i)
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + c.key + '/item/' + i.key + '/subitem', 'nome').pipe(take(1)).subscribe(data => {
              let subItem = JSON.parse(JSON.stringify(data));
              let ss = 0;
              subItem.forEach(s => {
                let lotacao = this.genProvider.onGetTableKey(this.lotacao,i.key_lotacao)
                let nome_lotacao = null
                if (lotacao){
                  nome_lotacao = lotacao.nome
                }
                let tempCatalogo = {
                  catalogo: c.nome,
                  lotacao : nome_lotacao,
                  tipo: c.tipo,
                  descricao : c.descricao,
                  item: i.nome,
                  sub_item : s.nome,
                  pop: s.pop
                }      
                this.catalogo.push(tempCatalogo)  

/*                ss ++
                if ((contador >= catalogo.length) && (ss >= subItem.length)){
                  // && (ss >= subItem.length)
                  console.log (ss)
                  console.log (subItem.length)
                  this.loading = false;
                  console.log (this.catalogo)
               //   this.exportPdf();
                }
                contador ++
                */
              })
            });
          });
        },
          err => console.log(err)
        ));
      });
      setTimeout(() => {
        this.loading = false;
        this.exportExcel();
      }, 5000);
    },
      err => console.log(err)
    );
  }
  exportExcel() {
    let lista = this.catalogo;
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
}
