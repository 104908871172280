/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable, Subject } from 'rxjs';
import { TabMenu} from 'primeng/tabmenu';
import { HttpService } from '../shared/http.service';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss'],
  providers: [ConfirmationService]
})
export class BotComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @ViewChild('tabMenu') tabMenu: TabMenu;
  stateOptionsTimer: any = []; 
  employees: Observable<[]>;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  formulario_sub: FormGroup;
  unSub: Subscription ;
  permissao: any = true;
  loading:any;
  page: string;
  unSub$ = new Subject();
  tabMenuitens: { id: string; label: string; icon: string; }[];
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private http: HttpService

  ) {
    console.log (this.http.test);
    this.stateOptionsTimer = [{label: 'Sim', value: true}, {label: 'Não', value: false}];
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      descricao: [null, Validators.required],
      status: [null, Validators.required],
    });

  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  onCombo(){
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Título', field: 'titulo' },
      { header: 'Data de Criação', field: 'data_hora' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.tabMenuitens = [
      { id: '1', label: 'Início', icon: 'pi pi-desktop' },
      { id: '2', label: 'Mensagens de eventos', icon: 'pi pi-comments' },
      { id: '3', label: 'Configurações', icon: 'pi pi-cog' },
      { id: '4', label: 'Fluxo de atendimento', icon: 'pi pi-sitemap'}
    ];
    this.page = "1";
  }
  onTab(e) {
    this.page = e.activeItem.id
  }
  ngOnInit() {
    this.onCombo();
    this.onLoad();
  }
  ngOnDestroy() {
    this.unSub$.next()
    this.unSub$.complete()
    if (this.unSub != undefined) {
      this.unSub.unsubscribe();
    }
  }
  onLoad() {
    this.loading = true;
    this.unSub = this.genProvider.onGetAll('dados/' + this.path + '/pergunta', "titulo").subscribe( data => {
  //    this.unSub.unsubscribe();
      this.lista = JSON.parse(JSON.stringify(data));
      this.loading = false;
    });
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo';
    this.onMostraFrm();
    this.permissao = false;
  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue({
      event
    });
    this.titulo = 'Editar';
    this.onMostraFrm();
    this.permissao = false;
  }

  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + '/pergunta', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
//this.genProvider.onSaveTeste();
    
    if (this.formulario.valid) {
      this.onSubmit();
    }
    
  }
  onSubmit() {
    this.onMostraFrm();
   // this.unSub.unsubscribe()
    this.genProvider.onSaveIncrement("dados/" + this.path + "/pergunta", this.formulario.value, "pergunta").then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
   //   this.onLoad()
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
    
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "tipos de encerramento de atendimento")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  trimAll(value){
    return value.replace(/\s/g, '')
  }
  nextPage(){
    this.page = (parseInt(this.page)+1).toString();
    //this.tabMenu.activeItem.id = this.page
  }
  previewPage(){
    this.page = (parseInt(this.page)-1).toString()
    //this.tabMenu.activeItem.id = this.page

  }

 }
