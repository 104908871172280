import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the FiltroarrayPipe pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
 * Angular Pipes.
 */
@Pipe({
  name: 'filtroArray',
  pure:false
})
export class FiltroarrayPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, args?:any):any {

     console.log(value);

    if (value.length === 0 || args === undefined){
      return value;
    }

    let filter = args.toLocaleLowerCase();

    let ret = value.filter((dados:any) =>{ dados.titulo.toLocaleLowerCase().indexOf(filter) != -1});
    console.log(ret);
    return ret;
  }
}
