/* tslint:disable */
import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { GenProvider } from '../../providers/gen/gen';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';

@Component({
  selector: 'app-relAtendimento',
  templateUrl: './relAtendimento.component.html',
  styleUrls: ['./relAtendimento.component.scss']
})
export class RelAtendimentoComponent implements OnInit {
  inicio: any;
  final: any;
  @ViewChild('dtLista') dtLista: Table;
  loading: any;
  filtro: any;
  lista: any;
  relacao: any;
  atuacao: any;
  membro: any;
  dados: any;
  qtdCad: any;
  path: any;
  datarelacao: any;
  dataatuacao: any;
  listaCoordenadores: any;

  isSexo: any;
  isTelefone: any;
  isDt_Nasc: any;
  isTipo: any;
  isRelacao: any;
  isEndereco: any;
  isZona: any;
  isSecao: any;
  isColegio: any;
  isBairro: any;
  membro_nome: any;
  key_coordenador: any;
  nome_coordenador: any;
  nome_lideranca: any;
  listaLideranca: any;
  key_lideranca: any;
  parametros: any;
  cols: any;
  exportColumns: any;
  usuarios: any;

  constructor(
    private router: Router,
    private genProvider: GenProvider,
    private validaCampoProvider: ValidaCampoProvider
  ) {
    this.parametros = this.genProvider.parametros;
    this.path = this.genProvider.parametros.path;
    this.listaCoordenadores = [];
    this.lista = [];
  }

  ngOnInit() {
    this.cols = [
      { header: 'Data', field: 'data' },
      { header: 'Protocolo', field: 'protocolo' },
      { header: 'Canal', field: 'canal' },
      { header: 'Natureza', field: 'natureza' },
      { header: 'Tempo', field: 'tempo'},
      { header: 'Agente', field: 'agente'},
      { header: 'Status', field: 'status' },
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this,this.onLoadUsuario();
  }
  onLoadUsuario(){
    this.genProvider.onGetAll('login','nome').subscribe(data=>{
     this.usuarios = JSON.parse(JSON.stringify(data));
    })
  }
  onLoad() {
    if ((this.inicio) && (this.final)) {
      let inicio = new Date(this.inicio).getTime()
      let fim = new Date(this.final).getTime()
      let unSub = this.genProvider.onGetPeriodo('dados/' + this.path + '/atendimento', 'time', inicio, fim).subscribe(data => {
        unSub.unsubscribe()
      //  console.log (this.parametros)
        let lista = JSON.parse(JSON.stringify(data));
        this.lista = lista;
     //   console.log (lista)
        if (this.parametros.nivel !='99'){
          this.lista = lista.filter(obj=>{
            return obj.key_usuario == this.parametros.key_usuario 
          })
        }else {
          this.lista = lista;
        }
        /*
        this.lista = lista.map(res => {
          res.data = new DatePipe("pt-BR").transform(res.time, "dd/MM/yyyy - HH:mm");
          res.canal = res.origem.toUpperCase();
          return res;
          });
.toUpperCase()
        */
        this.lista = lista.map(res => {
          if (res.status=='novo'){
            res.status='Registrado'
          }
          if (res.time){
            res.data = new DatePipe("pt-BR").transform(res.time, "dd/MM/yyyy - HH:mm:ss");
          }else {
            res.data =  new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss");
          }
          res.canal = res.origem;
          res.natureza = res.menu;
          if (res.key_usuario){
            res.agente = this.genProvider.onGetTableKey(this.usuarios,res.key_usuario).nome;
          }else {
            res.agente = 'AgentBot'
          }
          return res
          });
      },
        err => console.log(err)
      );
    }
  }

  exportExcel() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, 'Atendimentos')
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      columnStyles: {
        0: { halign: 'left', fillColor: [220, 220, 220] }
      },
//      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
     // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
}
