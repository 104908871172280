/* tslint:disable */
import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { GenProvider } from '../../providers/gen/gen';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { MessageService } from 'primeng/api';
import { ServiceProvider } from 'src/providers/service/service';

@Component({
  selector: 'app-relAtendimentoDiario',
  templateUrl: './relAtendimentoDiario.component.html',
  styleUrls: ['./relAtendimentoDiario.component.scss'],
  providers: [MessageService]

})
export class RelAtendimentoDiarioComponent implements OnInit {
  inicio: any;
  final: any;
  @ViewChild('dtLista') dtLista: Table;
  lista: any;
  parametros: any;
  cols: any;
  exportColumns: any;
  loading: boolean;
  listaFoot: any;
  listaSemana: any[];
  mostraGrafico: boolean;
  dataChart: any = [];
  options: any = [];
  tipoGraphic: any = [];
  tipo_graphic: any;
  listaSemanaGraphic: any = [];
  listaCanal: any = [];
  tipo_graphicCanal: any;
  optionsCanal: any = [];
  dataChartCanal: any = [];
  totalGeral: number;
  optionsDia: any = []
  dataChartDia: any = [];
  tipo_graphicDia: any;
  tipo_graphicSituacao: any;
  tipo_graphicNatureza: any;
  titulo: string;
  listaSituacao: any = [];
  listaNatureza: any = [];
  optionsSituacao: any = [];
  dataChartSituacao: any = [];
  optionsNatureza: any = [];
  dataChartNatureza: any = [];
  path: any;
  listaAll: any;

  constructor(
    private router: Router,
    private genProvider: GenProvider,
    private messageService: MessageService,
    private service: ServiceProvider,
  ) {
    this.parametros = this.genProvider.parametros;
    this.path = this.genProvider.parametros.path;
    this.lista = [];
    this.tipo_graphic = "pie";
    this.tipo_graphic = "pie";
    this.tipo_graphicCanal = "pie";
    this.tipo_graphicDia = "bar";
    this.tipo_graphicSituacao = "bar";
    this.tipo_graphicNatureza = "bar";
  }

  ngOnInit() {
    this.tipoGraphic = [
      { nome: 'bar' },
      { nome: 'horizontalBar' },
      { nome: 'doughnut' },
      { nome: 'line' },
      { nome: 'polarArea' },
      { nome: 'pie' },
      { nome: 'radar' }
    ];
    this.cols = [
      { header: 'Data', field: 'data' },
      { header: 'Dia', field: 'dia' },
      { header: 'Voip', field: 'voip' },
      { header: 'Whatsapp', field: 'whatsapp' },
      { header: 'E-mail', field: 'email' },
      { header: 'Messenger', field: 'messenger' },
      { header: 'Instagram', field: 'instagram' },
      { header: 'Chat', field: 'chat' },
      { header: 'Painel', field: 'painel' },
      { header: 'Total', field: 'total' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  onLoad() {
    if ((this.inicio) && (this.final)) {
      this.dataChart = [];
      this.dataChartCanal = [];
      this.dataChartNatureza = [];
      this.dataChartSituacao = [];
      this.options = [];
      this.optionsCanal = [];
      this.optionsDia = [];
      this.optionsNatureza = [];
      this.optionsSituacao = [];
      this.lista = [];
      this.listaFoot = [];
      this.listaSemana = []
      this.loading = true
      this.messageService.add({ severity: 'info', summary: 'Aguarde', detail: 'Seu relatório esta sendo gerado...' });
      let inicio = new Date(this.inicio).getTime()
      let fim = new Date(this.final).getTime()
      let unSub = this.genProvider.onGetPeriodo('dados/' + this.path + '/atendimento', 'time', inicio, fim).subscribe(data => {
        unSub.unsubscribe()
        let lista = JSON.parse(JSON.stringify(data));
        if (lista.length > 0) {
          this.listaAll = JSON.parse(JSON.stringify(data));
          let semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
          let voip = 0;
          let whatsapp = 0;
          let email = 0;
          let messenger = 0;
          let instagram = 0;
          let chat = 0;
          let painel = 0;
          let total = 0;
          let voipGeral = 0;
          let whatsappGeral = 0;
          let emailGeral = 0;
          let messengerGeral = 0;
          let instagramGeral = 0;
          let chatGeral = 0;
          let painelGeral = 0;
          let totalGeral = 0;
          let segunda = 0;
          let terca = 0;
          let quarta = 0;
          let quinta = 0;
          let sexta = 0;
          let sabado = 0;
          let domingo = 0;
          let c = 0;
          let newDate = new Date(lista[0].time);
          let dia = semana[newDate.getDay()];
          let diaControle = new DatePipe("pt-BR").transform(lista[0].time, "d, MMMM yyyy");
          let reg = lista.length;
          lista.forEach(e => {
            let newDia = new Date(e.time).getDay();
            c++
            total++
            totalGeral++
            if (newDia == 0) {
              domingo++
            } else if (newDia == 1) {
              segunda++
            } else if (newDia == 2) {
              terca++
            } else if (newDia == 3) {
              quarta++
            } else if (newDia == 4) {
              quinta++
            } else if (newDia == 5) {
              sexta++
            } else if (newDia == 6) {
              sabado++
            }
            if (!e.origem){
                e.origem = 'voip'
            }
            if (e.origem == "voip") {
              voip++
              voipGeral++
            } else if (e.origem == "whatsapp") {
              whatsapp++
              whatsappGeral++
            } else if (e.origem == "email") {
              email++
              emailGeral++
            } else if (e.origem == "messenger") {
              messenger++
              messengerGeral++
            } else if (e.origem == "instagram") {
              instagram++
              instagramGeral++
            } else if (e.origem == "chat") {
              chat++
              chatGeral++
            } else if (e.origem == "painel") {
              painel++
              painelGeral++
            }
            if ((diaControle != new DatePipe("pt-BR").transform(e.time, "d, MMMM yyyy")) || (c == lista.length)) {
              let dados = {
                data: diaControle,
                dia: dia,
                voip: voip,
                whatsapp: whatsapp,
                email: email,
                messenger: messenger,
                instagram: instagram,
                chat: chat,
                painel: painel,
                total: total
              }
              this.lista.push(dados)
              diaControle = new DatePipe("pt-BR").transform(e.time, "d, MMMM yyyy");
              newDate = new Date(e.time);
              dia = semana[newDate.getDay()];
              voip = 0;
              whatsapp = 0;
              email = 0;
              messenger = 0;
              instagram = 0;
              chat = 0;
              painel = 0;
              total = 0;
            }
            newDia = new Date(e.time).getDay();
          });
          // ordenar por situacao
          let listaMenu = lista.filter(obj => {
            return obj.menu
          })
          listaMenu.sort(function (a, b) {
            if (a.menu > b.menu) {
              return 1;
            }
            if (a.menu < b.menu) {
              return -1;
            }
            return 0;
          });
          c = 0;
          let situacao = listaMenu[0].menu;
          let totSituacao = 0;
          reg = listaMenu.length

          listaMenu.forEach(e => {
            // distribuicao por classificacao
            c++
            totSituacao++
            if (reg == c) {
              let temp = {
                nome: situacao,
                total: totSituacao
              }
              this.listaSituacao.push(temp)
              totSituacao = 0;
            } else if (situacao != e.menu) {
              let temp = {
                nome: situacao,
                total: totSituacao
              }
              this.listaSituacao.push(temp)
              totSituacao = 0;
              situacao = e.menu;
            }
            // fim
          });

          // ordenar por natureza
          let listaTicket = lista.filter(obj => {
            return obj.tipo_ticket
          })
          console.log (listaTicket)
          listaTicket.sort(function (a, b) {
            if (a.tipo_ticket > b.tipo_ticket) {
              return 1;
            }
            if (a.tipo_ticket < b.tipo_ticket) {
              return -1;
            }
            return 0;
          });
          c = 0;
          reg = listaTicket.length
          let tipo_ticket = ''
          if (listaTicket.length > 0){
          console.log (listaTicket[0])
            tipo_ticket = listaTicket[0].tipo_ticket || '';
          }
          let totTipo_ticket = 0;
          listaTicket.forEach(e => {
            // distribuixcao por natureza
            totTipo_ticket++
            c++
            if (reg == c) {
              let temp = {
                nome: tipo_ticket,
                total: totTipo_ticket
              }
              this.listaNatureza.push(temp)
              console.log (temp)
           //   tipo_ticket = e.tipo_ticket;
           //   totTipo_ticket = 0;
            } else if (tipo_ticket != e.tipo_ticket) {
              let temp = {
                nome: tipo_ticket,
                total: totTipo_ticket
              }
              this.listaNatureza.push(temp)
              totTipo_ticket = 0;
              tipo_ticket = e.tipo_ticket;
            }
            // fim
          });
          this.loading = false
          this.listaSemana = [
            { dia: 'Domingo', total: domingo},
            { dia: 'Segunda-Feira', total: segunda },
            { dia: 'Terça-Feira', total: terca },
            { dia: 'Quarta-Feira', total: quarta },
            { dia: 'Quinta-Feira', total: quinta },
            { dia: 'Sexta-Feira', total: sexta },
            { dia: 'Sábado', total: sabado },
            { dia: 'Total', total: totalGeral },
          ];
          this.listaSemanaGraphic = [
            { dia: 'Domingo', total: domingo },
            { dia: 'Segunda-Feira', total: segunda },
            { dia: 'Terça-Feira', total: terca },
            { dia: 'Quarta-Feira', total: quarta },
            { dia: 'Quinta-Feira', total: quinta },
            { dia: 'Sexta-Feira', total: sexta },
            { dia: 'Sábado', total: sabado }
          ];
          this.listaCanal = [
            { canal: 'Voip', total: voipGeral },
            { canal: 'Whatsapp', total: whatsappGeral },
            { canal: 'e-mail', total: emailGeral },
            { canal: 'Messenger', total: messengerGeral },
            { canal: 'Instagram', total: instagramGeral },
            { canal: 'Chat', total: chatGeral },
            { canal: 'painel', total: painelGeral }
          ];
          let dados = {
            data: "Total por canal",
            voip: voipGeral,
            whatsapp: whatsappGeral,
            email: emailGeral,
            messenger: messengerGeral,
            instagram: instagramGeral,
            chat: chatGeral,
            painel: painelGeral,
            total: totalGeral
          }
          this.totalGeral = totalGeral;
          this.listaFoot.push(dados)
          this.titulo = "Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm");
        }else{
          this.messageService.add({ severity:"error", summary: 'Atenção!', detail: 'Não existe registros para o periodo solicitado.' });
          this.loading = false
        }
      },
        err => console.log(err)
      );
    }
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, 'Atendimento Diário')
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      columnStyles: {
        0: { halign: 'left', fillColor: [220, 220, 220] },
        1: { halign: 'left', fillColor: [169, 169, 169] },
        9: { fillColor: [207, 207, 196] }
      },
      //margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      foot: this.listaFoot
    });
    doc.text("Resumo do periodo por dia da semana", 14, doc.lastAutoTable.finalY + 10);
    let coluna = [
      { title: "Dia da semana", dataKey: "dia" },
      { title: "Total", dataKey: "total" }
    ]
    doc.autoTable({
      styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
      columnStyles: {
        text: { cellWidth: 'auto' },
        0: { halign: 'left', fillColor: [220, 220, 220] },
      },
      tableWidth: 'wrap',
      //   head: [['Dia da semana', 'Total']],
      columns: coluna,
      body: this.listaSemana,
      startY: doc.lastAutoTable.finalY + 15,
      rowPageBreak: 'auto',
      bodyStyles: { valign: 'top' },
    })
    doc.save('lista.pdf');
  }
  exportExcelAll() {
    let lista = this.listaAll
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "listaAll");
    });
  }
  exportExcel() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  openGraphic() {
    this.mostraGrafico = true;
    this.onGraphic();
    this.onGraphicCanal();
    this.onGraphicDia();
    this.onGraphicSituacao();
    this.onGraphicNatureza();
  }
  onGraphic() {
    let labels = this.listaSemanaGraphic.map(label => (label.dia));
    let values = this.listaSemanaGraphic.map(field => ((field.total / this.totalGeral * 100)).toFixed(2));
    let cor = this.listaSemanaGraphic.map(cor => (this.service.onGerarCor()));
    this.options = {
      title: {
        display: true,
        text: 'Demandas recebidas por dia da semana com um total de ' + this.totalGeral + ' atendimentos',
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    };
    this.dataChart = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: cor,
          hoverBackgroundColor: cor,
          label: new DatePipe('pt-BR').transform(new Date(), 'd, MMMM yyyy - HH:mm')
        }]
    };
  }
  onGraphicCanal() {
    let labels = this.listaCanal.map(label => (label.canal));
    let values = this.listaCanal.map(field => ((field.total / this.totalGeral * 100)).toFixed(2));
    let cor = this.listaCanal.map(cor => (this.service.onGerarCor()));
    this.optionsCanal = {
      title: {
        display: true,
        text: 'Demandas recebidas por canal de atendimento com um total de ' + this.totalGeral + ' atendimentos',
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    };
    this.dataChartCanal = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: cor,
          hoverBackgroundColor: cor,
          fillColor: "#79D1CF",
          strokeColor: "#79D1CF",
          label: new DatePipe('pt-BR').transform(new Date(), 'd, MMMM yyyy - HH:mm')
        }]
    };
  }
  onGraphicDia() {
    let labels = this.lista.map(label => (label.data));
    let values = this.lista.map(field => (field.total));
    let cor = this.lista.map(cor => (this.service.onGerarCor()));
    this.optionsDia = {
      title: {
        display: true,
        text: 'Demandas recebidas de atendimento por dia com um total de ' + this.totalGeral + ' atendimentos no periodo',
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    };
    this.dataChartDia = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: cor,
          hoverBackgroundColor: cor,
          fillColor: "#79D1CF",
          strokeColor: "#79D1CF",
          label: new DatePipe('pt-BR').transform(new Date(), 'd, MMMM yyyy - HH:mm')
        }]
    };
  }
  onGraphicSituacao() {
    let labels = this.listaSituacao.map(label => (label.nome));
    let values = this.listaSituacao.map(field => ((field.total / this.totalGeral * 100)).toFixed(2));
    let cor = this.listaSituacao.map(cor => (this.service.onGerarCor()));
    this.optionsSituacao = {
      title: {
        display: true,
        text: 'Distribuição por classificação de atendimento por dia com um total de ' + this.totalGeral + ' atendimentos',
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    };
    this.dataChartSituacao = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: cor,
          hoverBackgroundColor: cor,
          fillColor: "#79D1CF",
          strokeColor: "#79D1CF",
          label: new DatePipe('pt-BR').transform(new Date(), 'd, MMMM yyyy - HH:mm')
        }]
    };
  }
  onGraphicNatureza() {
    let labels = this.listaNatureza.map(label => (label.nome));
    let values = this.listaNatureza.map(field => ((field.total / this.totalGeral * 100)).toFixed(2));
    let cor = this.listaNatureza.map(cor => (this.service.onGerarCor()));
    this.optionsNatureza = {
      title: {
        display: true,
        text: 'Distribuição por classificação de natureza por dia com um total de ' + this.totalGeral + ' atendimentos',
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    };
    this.dataChartNatureza = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: cor,
          hoverBackgroundColor: cor,
          fillColor: "#79D1CF",
          strokeColor: "#79D1CF",
          label: new DatePipe('pt-BR').transform(new Date(), 'd, MMMM yyyy - HH:mm')
        }]
    };
  }
  exportPdf_old() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontType('normal');
        doc.setFont('arial');
        doc.setFontSize(25)
        doc.setFontType('bold');
        doc.text(14, 10, 'Relatório de Ponto')

        //        doc.addImage(empresa.logoBase, 'PNG', 110, 10, 90, 30); // coluna, linha, largura, altura
        //        doc.addImage(qrCode, 'PNG', 5, 10, 15,15); // coluna, linha, largura, altura
        //        doc.setLineWidth(1.5)
        //        doc.line(5,45,200,45); // coluna inicial, linha inicial, coluna final, linha final
        // inicio da ficha cadastral
        //        doc.setLineWidth(0.2);
        //       for (let index = 50; index < 80; index=index + 5) {
        //         doc.rect(5, index, 195, 5); // coluna , linha, largura, altura
        //      }
        doc.setFontSize(7);
        doc.setFontType('bold');
        doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
        doc.setFontStyle('normal');
        doc.autoTable(this.exportColumns, lista);
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 4: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 20 },
          didDrawPage: (data) => {
            console.log(data)
            doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
          },
          pageBreak: 'avoid',
          startY: 1,
          body: lista,
          columns: this.exportColumns,
        })
        doc.autoTable({
          styles: { fillColor: [255, 0, 0] },
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
          body: [
            ['Sweden', 'Japan', 'Canada'],
            ['Norway', 'China', 'USA'],
            ['Denmark', 'China', 'Mexico'],
          ],
        })
        doc.autoTable({
          columnStyles: { europe: { halign: 'center' } }, // European countries centered
          body: [
            { europe: 'Sweden', america: 'Canada', asia: 'China' },
            { europe: 'Norway', america: 'Mexico', asia: 'Japan' },
          ],
          columns: [
            { header: 'Europe', dataKey: 'europe' },
            { header: 'Asia', dataKey: 'asia' },
          ],
        })

        doc.save('lista.pdf');
      })
    })
  }
}
