/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-pessoa_lotacao',
  templateUrl: './pessoa_lotacao.component.html',
  styleUrls: ['./pessoa_lotacao.component.scss'],
  providers: [ConfirmationService]
})
export class Pessoa_lotacaoComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @ViewChild('table') table: Table;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  lotacao: any;
  tipo: any[];
  unSub: Subscription[] = [];
  cargo: any;
  permissao: any = true;
  loading:any;
  page: string;
  tabMenuitens: any = [];
  formulario_lotacao: FormGroup;
  pessoa_lotacao: any = [];
  btnEnviar: any;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validaCampo: ValidaCampoProvider
  ) {
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      tipo: [null, Validators.required],
      telefone: [null, Validators.required],
      status: [null, Validators.required],
      email: '',
      origem: '',
      key_lotacao: '',
      key_cargo: '',
      matricula: '',
      ramal: '',
      facebook:'',
      instagram:''
    });
    this.formulario_lotacao = this.formBuilder.group({
      key: null,
      key_lotacao: [null, Validators.required],
      data_expira: null
    });
    this.btnEnviar = false;
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnInit() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.tipo = [
      { nome: 'Interno' },
      { nome: 'Externo' }
    ]
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Nome', field: 'nome' },
      { header: 'Tipo', field: 'tipo' },
      { header: 'Unidade Operacional', field: 'nome_lotacao' },
      { header: 'Cargo', field: 'nome_cargo' },
      { header: 'Celular', field: 'celular' },
      { header: 'E-mail', field: 'email' },
      { header: 'Origem', field: 'origem' },
      { header: 'Status', field: 'status' }
    ];
    this.tabMenuitens = [
      { id: '1', label: 'Meus Dados', icon: 'pi pi-fw pi-globe' },
      { id: '2', label: 'Minhas Lotações', icon: 'pi pi-fw pi-desktop' }
    ];
    this.page = "1";
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.onLoadlotacao();
    this.onLoadCargo();
    this.onLoad();
  }
  ngOnDestroy() {
   // console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }
  onTab(e) {
    this.page = e.activeItem.id
  }
  onLoad() {
    let joins=[
      { key:"key_cargo", table:"cargo" },
      { key:"key_lotacao", table:"lotacao" }
    ]

    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/pessoa', "nome",joins).subscribe(data => {
     // console.log ("2")
      this.lista = data;
      this.lista = this.lista.map(res => {
        if (res.lotacao){
          if (res.lotacao){
            res.nome_lotacao = res.lotacao.nome;
          }
        }else {
          res.nome_lotacao = '';
        }
//        res.origem = res.lotacao.origem;
        if (res.cargo){
          res.nome_cargo = res.cargo.nome;
        }else {
          res.nome_cargo = '';
        }
        if (res.telefone){
          if (res.telefone.length == 15) {
            res.celular = res.telefone;
          } else {
            res.celular = this.validaCampo.validaTelefonePromo(res.telefone);
          }
        }
        //        res.telefone = this.validaCampo.formataTelefonePadrao(res.telefone);
        return res;
      });
     // console.log(this.lista)
    },
      err => console.log(err)
    ));
  }

  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event);
    this.titulo = 'Minhas lotações';
    this.onMostraFrm();
    this.permissao = true;
    this.onLoadPessoa_lotacao(event.key)
  }


  onLoadlotacao() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/lotacao', 'nome').subscribe(data => {
      let lotacao = JSON.parse(JSON.stringify(data));
      this.lotacao = lotacao;
      this.lotacao = lotacao.map(res => {
        res.nomeSigla = res.nome + " " + res.sigla + " " + res.codigo || ''
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  onLoadCargo() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/cargo', 'nome').subscribe(data => {
      this.cargo = data;
      this.cargo = this.cargo.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  gravarLotacao() {
    let res = this.pessoa_lotacao.filter(obj => {
      return obj.key_lotacao == this.formulario_lotacao.value.key_lotacao
    });
    if ((res.length > 0) && (!this.formulario_lotacao.value.key)) {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Esta lotação já esta cadastrada para esta pessoa, por favor selecione outra.' });
    } else {
      if (this.formulario_lotacao.valid) {
        this.genProvider.onSaveIncrement('dados/' + this.path + "/pessoa/" + this.formulario.value.key + '/lotacao', this.formulario_lotacao.value, "pessoa_lotacao").then(t => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
          this.formulario_lotacao.reset()
          this.btnEnviar = false;
        }).catch(e => {
          this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
          this.btnEnviar = false;
          console.log(e);
        });
      }
    }

  }
  onEditLotacao(event) {
    this.formulario_lotacao.patchValue(event)
  }
  onDeleteLotacao(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + "/pessoa/" + this.formulario.value.key + '/lotacao', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });
  }
  onLoadPessoa_lotacao(key) {
    this.unSub.push(this.genProvider.onGet('dados/' + this.path + "/pessoa/" + key, 'lotacao').subscribe(data => {
      let pessoa_lotacao = JSON.parse(JSON.stringify(data));
      if (pessoa_lotacao.length > 0) {
        this.pessoa_lotacao = pessoa_lotacao.map(res => {
          var lotacao = this.genProvider.onGetTableKey(this.lotacao,res.key_lotacao);
          if (lotacao){
            res.nome = lotacao.nome;
          }
          
          if (res.data_expira){
            res.data =  new DatePipe("pt-BR").transform(res.data_expira, "dd/MM/yyyy - HH:mm");
          }else{
            res.data = 'Indeterminado'
          }
          return res;
        });
      }
    },
      err => console.log(err)
    ));
  }
}
