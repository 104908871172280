/* tslint:disable */
import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { Subscription } from 'rxjs/Rx';
import { CamelcasePipe } from 'src/pipes/camelcase/camelcase';


@Component({
  selector: 'app-importData',
  templateUrl: './importData.component.html',
  styleUrls: ['./importData.component.scss'],
  providers: [ConfirmationService]
})
export class ImportDataComponent implements OnInit {
  [x: string]: any;
  @ViewChild('dtLista') dtLista: Table;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  permissao: any = true;
  unSub: Subscription[] = [];
  tabelas: any;
  descricao: any;
  fields: string;
  colunas: any;
  uploadedFiles: any;
  reader = new FileReader();
  fileHeard: any;
  fileImport: string;
  resultFile: any[];
  nomeArquivo: string;
  listaTemp: any[];
  array: any;
  checkedPrimary: any;
  checkedNovo: any;
  endPoint: any;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validaCampo: ValidaCampoProvider
  ) {
    this.path = this.genProvider.parametros.path;
    this.progress_save = 0;
    this.progress_file = 0;
    this.progress_upload = 0;
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  ngOnInit() {
    this.tabelas = [
      {
        displayName: 'Pessoas',
        tableName: 'pessoa',
        description: 'Tabela de Pessoas',
        fields: [
          { field: 'key' },
          { field: 'key_lotacao' },
          { field: 'key_cargo' },
          { field: 'nome' },
          { field: 'matricula' },
          { field: 'tipo' },
          { field: 'telefone' },
          { field: 'email' },
          { field: 'facebook' },
          { field: 'instagram' },
          { field: 'origem' },
          { field: 'ramal' },
          { field: 'status' }
        ]
      },
      {
        displayName: 'Lotação',
        tableName: 'lotacao',
        description: 'Tabela de Lotações/Unidade Operacional',
        fields: [
          { field: 'key' },
          { field: 'key_subordinada' },
          { field: 'key_tree' },
          { field: 'nome' },
          { field: 'sigla' },
          { field: 'titular' },
          { field: 'telefone' },
          { field: 'email1' },
          { field: 'email2' },
          { field: 'endereco' },
          { field: 'status' }
        ]
      },
      {
        displayName: 'Cargo',
        tableName: 'cargo',
        description: 'Tabela de Cargos',
        fields: [
          { field: 'key' },
          { field: 'nome' },
          { field: 'descricao' },
          { field: 'status' }
        ]
      },
      {
        displayName: 'PUC',
        tableName: 'puc',
        description: 'Tabela de Central de Serviços (PUC)',
        fields: [
          { field: 'key' },
          { field: 'key_lotacao' },
          { field: 'nome' },
          { field: 'descricao' },
          { field: 'responsavel' },
          { field: 'telefone' },
          { field: 'status' }
        ]
      },
      {
        displayName: 'Catalogo',
        tableName: 'catalogo',
        description: 'Novo Catálogo/Carta de Serviço',
        fields: [
          { field: 'key' },
          { field: 'nome' },
          { field: 'pop_url' },
          { field: 'file' },
          { field: 'fullPath' },
          { field: 'pop_midia' },
          { field: 'fullName' },
          { field: 'type' },
          { field: 'tipo' },
          { field: 'status' }
        ]
      },
    ]


    this.cols = [
      { header: 'Títuto', field: 'displayName' },
      { header: 'Nome da Tabela', field: 'tableName' },
      { header: 'Descrição', field: 'description' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }

  onSave() {
    if (this.formulario.valid) {
      this.onSubmit();
    }
  }
  onSubmit() {
    this.onMostraFrm();
    this.genProvider.onSaveIncrement("dados/" + this.path + "/" + this.formulario.value.nome, this.formulario.value, this.formulario.value.nome).then(t => {
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  onImportar(data) {
    this.onMostraFrm();
    this.progress_save = 0;
    this.progress_file = 0;
    this.progress_upload = 0;
    this.fileHeard = null;
    this.fileImport = null;
    this.resultFile = null;
    this.nomeArquivo = null
    this.listaTemp = [];
    this.array = [];
    this.titulo = data.displayName;
    this.endPoint = data.tableName
    this.descricao = data.description;
    this.array = data.fields
    this.fields = '';
    let i = 0;
    let cols = []
    this.array.forEach(e => {
      this.fields = this.fields + e.field.toUpperCase() + " , "
      if (i <= 8) {
        cols.push(e)
      }
      i++
    });
    this.colunas = this.array.map(
      fields => (
        { header: fields.field.toUpperCase(), field: fields.field }
      )
    );
  }
  onUpload(evt) {
    this.progress = 0;
    this.reader.onerror = this.errorHandler.bind(this);
    this.reader.onprogress = this.updateProgress.bind(this);

    var files = evt.target.files;
    var file = evt.dataTransfer ? evt.dataTransfer.files[0] : evt.target.files[0];
    this.nomeArquivo = file.name + "      " + file.size + "kb      " + file.type
    for (var i = 0, f; f = files[i]; i++) {
      this.reader.onload = this.lerArquivo.bind(this);
      this.reader.readAsText(f);
    }
    this.reader.onabort = (e) => {
      alert('Arquivo cancelado');
    };
    this.reader.onloadstart = (e) => {
      this.progress = 0;
      this.fileHeard = '';
      this.fileImport = '';
      this.resultFile = null;
      // this.showMsg('Aguarde...', 'Carregando Arquivo...', 5000);
    };
  }
  errorHandler(evt) {
    switch (evt.target.error.code) {
      case evt.target.error.NOT_FOUND_ERR:
        alert('File Not Found!');
        break;
      case evt.target.error.NOT_READABLE_ERR:
        alert('File is not readable');
        break;
      case evt.target.error.ABORT_ERR:
        break; // noop
      default:
        alert('An error occurred reading this file.');
    };
  }
  updateProgress(evt) {
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      if (percentLoaded <= 100) {
        this.progress_file = percentLoaded
      }
    }
  }
  lerArquivo(event) {
    console.log (event)
    var conteudo = event.target.result;
    var linhas = conteudo.split('\n');
    let x = 0;
    let startIntervalo = setInterval(() => {
      this.progress_file = Math.round((x / linhas.length) * 100);
      if (x == 0) {
        this.fileHeard = linhas[x]
      }
      if (linhas[x].trim() == '') {
        //  console.log('log => ',x , linhas[x] )
      } else {
        this.fileImport += linhas[x] + '\n';
      }

      if ((x + 1) == linhas.length) {
        clearInterval(startIntervalo);
        this.progress_file = 100;
      }
      x++
    }, 10)

  }
  onFile() {
    var botao = document.getElementById("FileUpload");
    this.progress_save = 0;
    this.progress_file = 0;
    this.progress_upload = 0;
    botao.click();
  }
  csvToJSON() {
    this.progress_upload = 1;
    this.messageService.add({severity: 'info', summary: 'Success', detail: 'Processando arquivo, aguarde...'});
    let content = {
      csv: this.fileImport,
      separator: ';',
      header: this.fileHeard
    }
    var lines = content.csv.split(new RegExp('\n(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
    this.resultFile = []
    var result = [];
    var start = 0;
    // var columnCount = lines[0].split(content.separator).length;
    var columnCount = this.array.length;
    var headers = [];

    if (content.header) {
      // headers=lines[0].split(content.separator);
      this.array.forEach(res => {
        headers.push(res.field)
      });
      start = 1;
    }
    setTimeout(() => {
      let i = 1;
      let startIntervalo = setInterval(() => {
        this.progress_uploload = Math.round((i / lines.length) * 100);
        var obj = {};
        try {
          var currentline = lines[i].split(new RegExp(content.separator + '(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
        } catch (error) {
          clearInterval(startIntervalo);
          alert('Arquivo corrompido ou formato errado, por favor verifique o arquivo importado!');
          this.progress_upload = 0;
        }
        if (currentline.length === columnCount) {
          if (content.header) {
            for (var j = 0; j < headers.length; j++) {
              var dados = this.cleanCsvValue(currentline[j]);
         //     obj[headers[j].toLowerCase()] = new CamelcasePipe().transform(dados).trim()
              obj[headers[j].toLowerCase()] = dados.trim()
        }
          } else {
            for (var k = 0; k < currentline.length; k++) {
              obj[k] = this.cleanCsvValue(currentline[k]);
            }
          }
          this.progress_uploload = Math.round((i / lines.length) * 100);
          result.push(obj);
        }
        if ((i + 1) == lines.length) {
          this.progress_upload = 100;
          clearInterval(startIntervalo);
         // this.resultFile = result;
          if (result.length == 0) {
            alert('Arquivo corrompido ou formato errado, por favor verifique o arquivo importado!')
            this.progress_upload = 0;
          } else {
            this.resultFile = result;
          }
        //  console.log(this.listaTemp)
        }
        i++
      }, 10)
    }, 1000);
  };

  cleanCsvValue(value) {
    return value
      .replace(/^\s*|\s*$/g, "") // remove leading & trailing space
      .replace(/^"|"$/g, "") // remove " on the beginning and end
      .replace(/""/g, '"'); // replace "" with "
  };
  onDelete(e) {
    if (window.confirm('Confirma a Exclusão')) {
      const index = this.resultFile.indexOf(e);
      this.resultFile.splice(index, 1);
    }
  }
  onSaveJson() {
    if (this.checkedRemove) {
    } else {
      this.onBuild(this.resultFile)
    }
  }
  onBuild(array){
    this.progress_save = 0;
    let i = 0;
    if (array) {
      let startIntervalo = setInterval(() => {
        let res = array[i];
        if (res) {
          let chave = res.key
          delete res["key"]
          res.status = 'Ativo'
          res.data_hora = new DatePipe('pt-BR').transform(
            Date.now(),
            'd, MMMM yyyy - HH:mm'
          ),
          res.time = Date.now();
          if (this.titulo=="Lotação"){
            if (res.key_subordinada) {
              if (res.key_subordinada =='1'){
                res.key_tree = res.key_subordinada +'.'+chave
              }else{
                res.key_tree = '1.'+res.key_subordinada +'.'+chave
              }
            }
          }
          if (this.checkedNovo) {
            this.genProvider.onSaveIncrement("dados/" + this.path + "/" + this.endPoint, res, this.endPoint).then(t => {
              this.progress_save = Math.round((i / array.length) * 100);
              if (i+1  > array.length) {
                clearInterval(startIntervalo);
                this.messageService.add({severity: 'info', summary: 'Success', detail: 'Processo Completado'});
                this.progress_save = 100;
              }
            }).catch(e => {
              clearInterval(startIntervalo);
              console.log('Atenção, Erro.', e)
            });
          } else {
            this.genProvider.onSaveDocKey("dados/" + this.path + "/" + this.endPoint, chave, res).then(t => {
              this.progress_save = Math.round((i / array.length) * 100);
              if (i+1 > array.length) {
                console.log ("aqui")
                clearInterval(startIntervalo);
                this.messageService.add({severity: 'info', summary: 'Success', detail: 'Processo Completado'});
                this.progress_saved = 100;
              }
            }).catch(e => {
              clearInterval(startIntervalo);
              console.log('Atenção, Erro.', e)
            });
          }
          i++
        }
      }, 1000);
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Enviando...' });
    }
  }
}
