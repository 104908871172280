/* tslint:disable */
import { Component, OnInit, ViewChild, ElementRef, ComponentFactoryResolver } from '@angular/core';
import { FormGroup, FormBuilder, Validators, SelectMultipleControlValueAccessor } from '@angular/forms';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ServiceProvider } from 'src/providers/service/service';
import { GenProvider } from 'src/providers/gen/gen';
import { CamelcasePipe } from 'src/pipes/camelcase/camelcase';
import { PrimeIcons } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Editor } from 'primeng/editor';
import { FileUpload } from 'primeng/fileupload';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import * as moment from 'moment'
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'


declare var google;
@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss'],
    providers: [ConfirmationService]
})
export class TicketComponent implements OnInit {
    @ViewChild('txtConteudo') textoEditor: Editor;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @ViewChild('dtLista') dtLista: Table;
    @ViewChild('audio') audio: ElementRef;
    @ViewChild('tableQuantificacao') tableQuantificacao: Table;

    inicio: any;
    final: any;
    path: any;
    parametros: any;
    nomeUsuario: any;
    foto: string;
    formulario: FormGroup;
    mostraFrm: boolean;
    mostraFrmNovo: boolean;
    mostraFrmInteracao: boolean;
    dados: any;
    lotacao: any;
    contato: any;
    filtro: any;
    dadosAll: any;
    titulo: string;
    chat_msg: any;
    events1: any[];
    events2: any[];
    solicitante: any;
    fonte: any;
    tamanho: any;
    itens: any;
    itens_encerar: any;
    enviarResp: boolean = false;
    rapidaAll: any;
    tipoTicket: any;
    tipoPrioridade: any = [];
    nova: any;
    pendente: any;
    todos: any;
    meus: any;
    atrasado: any;
    espera: any;
    excluidos: any;
    sousolicitante: any;
    invalidDates: Array<Date>
    tabMenuitens: MenuItem[];
    page: any;
    pageChat: any;
    itensInteracao: any;
    msg_all: any;
    conteudo: any;
    msg_filtro: void;
    conteudoInterno: string;
    historico: any = [];
    key_lotacao: any;
    lista: any;
    cols: any = [];
    exportColumns: any = [];
    sousolicitante_lista: any;
    espera_lista: any;
    atrasado_lista: any;
    pendente_lista: any;
    nova_lista: any;
    meus_lista: any;
    nome_responsavel: any;
    login: any;
    key_responsavel: any;
    mostraAlteracaoResponsavel: boolean;
    checked: boolean;
    selectedRows: any = [];
    listaGrupo: any;
    mostraFrmGroup: any;
    ticketView: boolean;
    catalogo: any;
    subcatalogo: any;
    puc: any = [];
    pessoa: any;
    key_pessoa: any;
    uploadedFiles: any[] = [];
    anexarArquivo: boolean;
    fileSrc: any;
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    progress: Observable<number>;
    snapshot: Observable<unknown>;
    downloadURL: any;
    sla: any;
    mostraMidia: boolean;
    midia: any = [];
    itemCatalogo: any;
    subItemCatalogo: any;
    key_central: any;
    nome_central: any;
    dados_pessoa: any = [];
    tempoAtendimento: string;
    timerAtendimento: number;
    inicio_atendimento: any;
    tramitarTicket: boolean;
    formulario_pessoa: FormGroup;
    qtd_tickts: any = [];
    qtd_upload: number;
    idTicket: any;
    agente: any;
    loading: any;
    key_lotacao_principal: any;
    pessoa_lotacao: any = [];
    nivel: any;
    unSub$ = new Subject();
    unSub: Subscription;
    key_minhas_lotacao: any;
    tramitarTicketGroup: boolean;
    key_lotacao_tramita: any;
    progress_save: number;
    tabMenuitensNovo: { id: string; label: string; icon: string; }[];
    solicitante_lista: any;
    mostraMacro: boolean;
    macro: any;
    key_macro: any;
    key_usuario: any;
    encerrarAtendimento: any;
    atendimento: any;
    item: any = [];
    permissao: boolean = false;
    startLoad: boolean;
    notificacaoSonora: boolean = true;
    usuarios: any;
    tipoPrioridadeGeral: { nome: string; }[];
    tipo_finalizacao: any = [];
    midias: any[];
    lat: any;
    lng: any;
    localizacao: any;
    isPermissao: boolean;
    formulario_catalogo_quantificacao: FormGroup;
    itemCatalogoQuantificacao: any;
    subItemCatalogoQuantificacao: any[];
    unSubQuantificacao: Subscription;
    quantificacao: any;
    template: any = [];
    formulario_dinamico: FormGroup;
    pucAll: any;
    constructor(
        private formBuilder: FormBuilder,
        private genProvider: GenProvider,
        private router: Router,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private confirmationService: ConfirmationService,
        private service: ServiceProvider,
        private afStorage: AngularFireStorage,
        private validaCampoProvider: ValidaCampoProvider
    ) {
        let today = new Date();
        let invalidDate = new Date();
        invalidDate.setDate(today.getDate() - 1);
        this.invalidDates = [today, invalidDate];
        this.path = this.genProvider.parametros.path;
        this.parametros = this.genProvider.parametros;
        this.nivel = this.parametros.nivel;
        this.key_pessoa = this.genProvider.parametros.key_pessoa;
        this.nomeUsuario = new CamelcasePipe().transform(this.parametros.nome).trim()
        this.foto =
            'assets/perfil/' +
            this.nomeUsuario
                .trim()
                .toUpperCase()
                .substring(0, 1) +
            '.jpg';
        this.midia = {
            type: '',
            file: '',
            lat: 0,
            lng: 0
        }
        this.formulario = this.formBuilder.group({
            key: '',
            key_puc: [null, Validators.required],
            key_lotacao: '',
            key_catalogo: [null, Validators.required],
            key_item: [null, Validators.required],
            key_subitem: [null, Validators.required],
            key_usuario: [null, Validators.required],
            key_responsavel: '',
            key_solicitante: [null, Validators.required],
            nome_solicitante: '',
            nome_responsavel: '',
            nome: '',
            key_solicitante_lotacao: '',
            ticketPrincipal: null,
            ticket: null,
            protocolo: null,
            canal: [null, Validators.required],
            id_canal: [null, Validators.required],
            status: [null, Validators.required],
            status_ticket: [null, Validators.required],
            tipo_ticket: [null, Validators.required],
            prioridade: [null, Validators.required],
            prazo_resolucao: '',
            prazo_resolucaoM: '',
            titulo_ticket: [null, Validators.required],
            conteudo: [null, Validators.required],
            conteudoTexto: '',
            conteudoInterno: '',
            enviar_resposta_solicitante: [null, Validators.required],
            ultima_interacao: '',
            qtd_interacao: '',
            data_criacao: '',
            data_hora: '',
            time: '',
            inicio: '',
            encerrado: '',
            fim: '',
            tempo: '',
            nome_puc: '',
            nome_catalogo: '',
            nome_subcatalogo: '',
            nome_usuario: '',
            tramitacao: '',
            key_encerrarAtendimento: '',
            integrado: '0',
            busca: '',
            status_integracao: null
        });
        this.formulario_pessoa = this.formBuilder.group({
            key: null,
            nome: '',
            nome_solicitante: [null, Validators.required],
            tipo: "Externo",
            telefone: [null, Validators.required],
            status: "Ativo",
            email: '',
            origem: '',
            key_lotacao: '',
            nome_lotacao: '',
            key_cargo: '',
            nome_cargo: '',
            matricula: '',
            ramal: '',
            facebook: '',
            instagram: ''
        });
        this.formulario_catalogo_quantificacao = this.formBuilder.group({
            key: null,
            key_ticket: [null, Validators.required],
            key_catalogo: [null, Validators.required],
            nome_catalogo: '',
            key_item: [null, Validators.required],
            nome_item: '',
            key_subitem: [null, Validators.required],
            nome_subitem: '',
            quantidade: [null, Validators.required],
            data: '',
            data_hora: ''
        })
    }
    onTab(e) {
        this.page = e.activeItem.id
    }
    onTabChat(e) {
        this.pageChat = e.activeItem.id
        if (this.pageChat == "2") {
            this.onMsgFiltro('publica')
        } else if (this.pageChat == "3") {
            this.onMsgFiltro('interno')
        }
    }
    onClose() {
        this.router.navigate(['']);
    }
    onMostraFrm() {
        this.mostraFrm = !this.mostraFrm;
    }
    onMostraFrmNovo() {
        this.mostraFrm = !this.mostraFrm;
        this.mostraFrmNovo = !this.mostraFrmNovo;
    }
    onMostraFrmInteracao() {
        this.mostraFrm = !this.mostraFrm;
        this.mostraFrmInteracao = !this.mostraFrmInteracao;

    }
    onFechar() {
        this.mostraFrm = false;
        this.mostraFrmNovo = false;
        this.mostraFrmInteracao = false;
        this.mostraFrmGroup = false;
        this.titulo = 'Ticket';
    }
    onCombos() {
        this.tipo_finalizacao = [
            { nome: 'Resolvido' },
            { nome: 'Não Resolvido' },
            { nome: 'Indeferido' },
            { nome: 'Cancelado' }
        ]
        this.tipoTicket = [
            { nome: 'Evento' },
            { nome: 'Incidente' },
            { nome: 'Requisição' }
        ];
        this.qtd_tickts = [
            { nome: '10', qtd: 9 },
            { nome: '50', qtd: 49 },
            { nome: '100', qtd: 99 },
            { nome: '200', qtd: 199 },
            { nome: '300', qtd: 299 },
            { nome: '400', qtd: 399 },
            { nome: '500', qtd: 499 },
            { nome: '1000', qtd: 999 },
            { nome: 'Todos', qtd: 9999 }
        ]
        this.cols = [
            { header: 'Código', field: 'key', width: '15%' },
            { header: 'Status', field: 'status_ticket', width: '10%' },
            { header: 'Criação', field: 'data_criacaoM', width: '12%' },
            { header: 'Últ.Interação', field: 'ultima_interacaoM', width: '15%' },
            { header: 'Qtd', field: 'qtd_interacao', width: '5%' },
            { header: 'Título', field: 'titulo_ticket', width: '19%' },
            { header: 'solicitante', field: 'nome_solicitante', width: '18%' },
            { header: 'Canal', field: 'canal', width: '10%' },
            { header: 'Sub-Item', field: 'subitem', width: '15%' },
            { header: 'Unidade Operacional', field: 'nome_lotacao', width: '11%' },
            { header: 'Responsável', field: 'nome_responsavel', width: '15%' },
            { header: 'Agente', field: 'agente', width: '15%' },
            { header: 'Prioridade', field: 'prioridade', width: '10%' }
        ];
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
        this.itens_encerar = [
            {
                label: 'Tramitar Ticket', icon: 'pi pi-sitemap', command: () => {
                    if (!this.formulario.valid) {
                        this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
                    } else {
                        this.tramitarTicket = true;
                    }
                }
            },
            {
                label: 'Delvolver a Central', icon: 'pi pi-replay', command: () => {
                    this.onDevolver();
                }
            },
            {
                label: 'Deixar Pendente', icon: 'pi pi-exclamation-triangle', command: () => {
                    this.onPendente()
                }
            },
            {
                label: 'Salvar Alterações', icon: 'pi pi-check', command: () => {
                    this.onSaveAlteracao()
                }
            },
            { separator: true },
            {
                label: 'Imprimir', icon: 'pi pi-print', command: () => {
                    this.onImprimir();
                }
            }

        ];
        if (this.parametros.key_catalogo_quantificacao) {
            this.tabMenuitens = [
                { id: '1', label: 'Resposta*', icon: 'pi pi-fw pi-globe' },
                { id: '2', label: 'Observação interna', icon: 'pi pi-fw pi-question' },
                { id: '3', label: 'Histórico', icon: 'pi pi-fw pi-list' },
                { id: '4', label: 'Mídias', icon: 'pi pi-fw pi-paperclip' },
                { id: '5', label: 'Geolocalização', icon: 'pi pi-fw pi-map' },
                { id: '6', label: 'Quantificação de resultados', icon: 'pi pi-fw pi-tags' }
            ];
        } else {
            this.tabMenuitens = [
                { id: '1', label: 'Resposta*', icon: 'pi pi-fw pi-globe' },
                { id: '2', label: 'Observação interna', icon: 'pi pi-fw pi-question' },
                { id: '3', label: 'Histórico', icon: 'pi pi-fw pi-list' },
                { id: '4', label: 'Mídias', icon: 'pi pi-fw pi-paperclip' },
                { id: '5', label: 'Geolocalização', icon: 'pi pi-fw pi-map' },
            ];
        }

        this.tabMenuitensNovo = [
            { id: '1', label: 'Detalhe*', icon: 'pi pi-fw pi-globe' },
            { id: '2', label: 'Anexos', icon: 'pi pi-fw pi-paperclip' }
        ];
        this.tipoPrioridade = [
            { nome: 'Nenhuma' },
            { nome: 'Baixa' },
            { nome: 'Média' },
            { nome: 'Alta' },
            { nome: 'Urgente' }
        ];
        this.tipoPrioridadeGeral = [
            { nome: 'Nenhuma' },
            { nome: 'Baixa' },
            { nome: 'Média' },
            { nome: 'Alta' },
            { nome: 'Urgente' }
        ];
        this.page = "1";
    }
    ngOnInit() {
        this.primengConfig.ripple = true;
        this.service.onStartChat();
        this.qtd_upload = 9;

        this.titulo = "Tickets"
        // setTimeout(() => {
        this.onLoadCatalogo();
        this.onLoadLotacao();
        this.onLoadUsuarios();
        this.onCombos();
        this.onLoadPessoa(this.key_pessoa);
        this.onLoadEncerrarAtendimento()
        this.onLoadPucAll()
        // }, 2000);
    }
    onLoadInit() {
        this.onLoadAgente();
        let startIntervalo = setInterval(() => {
            if (this.lotacao) {
                this.onLoad();
                this.onLoadSousolicitante(true);
                this.onLoadMacro()
                this.onLoadLotacaoPuc(this.key_lotacao);
                clearInterval(startIntervalo);
            }
        }, 3000);
    }
    ngOnDestroy() {
        this.unSub$.next()
        this.unSub$.complete()
        if (this.unSub != undefined) {
            this.unSub.unsubscribe();
        }
        if (this.unSubQuantificacao != undefined) {
            this.unSubQuantificacao.unsubscribe();
        }
    }
    onLoad() {
        this.loading = true;
        let filtro = [
            "Novo",
            "Pendente",
            "Visualizado",
            "Tramitado"
        ]
        if (this.nivel == '99') {
            this.unSub = this.genProvider.onGetFilterOrderAll('dados/' + this.path + '/ticket', "time", "tramitacao", "1", "==", "status", filtro, "in", this.qtd_upload + 1, 'desc').subscribe(data => {
                let lista = JSON.parse(JSON.stringify(data));
                this.lista = lista.map(res => {
                    if (res.status.toLowerCase() == "novo") {
                        this.onNotificacao();
                    }
                    if (res.status) {
                        res.status = res.status.toLowerCase()
                    }
                    if (res.status_ticket) {
                        res.status_ticket = res.status_ticket.toLowerCase()
                    }
                    if (res.key_lotacao) {
                        let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                        if (lotacao) {
                            res.nome_lotacao = lotacao.nome
                        } else {
                            res.nome_lotacao = ''
                        }
                    } else {
                        res.nome_lotacao = '';
                    }
                    let item = this.genProvider.onGetTableKey(this.item, res.key_item);
                    if (item) {
                        res.item = item.nome
                        if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                            try {
                                let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                                    unSub.unsubscribe();
                                    let subitem = JSON.parse(JSON.stringify(data));
                                    if (subitem) {
                                        res.subitem = subitem.nome;
                                    }
                                })
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                    if (res.key_usuario) {
                        let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
                        if (agente) {
                            res.agente = agente.nome
                        } else {
                            res.agente = '';
                        }
                    } else {
                        res.agente = '';
                    }
                    if (res.enviar_resposta_solicitante_email) {
                        res.resposta = "email"
                    } else {
                        res.resposta = "whatsapp"
                    }
                    //    if (res.qtd_interacao) {
                    res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
                    // {

                    res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
                    res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                    return res;
                });
                //   this.onLoadOneAdmin();
                this.dados = this.lista;
                this.loading = false;
            },
                err => console.log(err)
            );
        } else {
            this.startLoad = false;
            //      this.genProvider.onGetFilterThree('dados/' + this.path + '/ticket', "time", "key_lotacao", this.key_lotacao, "==", "tramitacao", "1", "==", "status", "Finalizado", "!=", this.qtd_upload + 1, 'desc').pipe(take(1)).subscribe(data => {
            this.unSub = this.genProvider.onGetFilterThree('dados/' + this.path + '/ticket', "time", "key_lotacao", this.key_lotacao, "==", "tramitacao", "1", "==", "status", "Finalizado", "!=", this.qtd_upload + 1, 'desc').subscribe(data => {
                let lista = JSON.parse(JSON.stringify(data));

                if (lista.length > 0) {
                    this.lista = lista.map(res => {
                        if (res.status.toLowerCase() == "novo") {
                            this.onNotificacao();
                        }
                        if (res.status) {
                            res.status = res.status.toLowerCase()
                        }
                        if (res.status_ticket) {
                            res.status_ticket = res.status_ticket.toLowerCase()
                        }
                        if (res.key_lotacao) {
                            let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                            if (lotacao) {
                                res.nome_lotacao = lotacao.nome
                            } else {
                                res.nome_lotacao = ''
                            }
                        } else {
                            res.nome_lotacao = '';
                        }
                        let item = this.genProvider.onGetTableKey(this.item, res.key_item);
                        if (item) {
                            res.item = item.nome
                            if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                                try {
                                    let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                                        unSub.unsubscribe();
                                        let subitem = JSON.parse(JSON.stringify(data));
                                        if (subitem) {
                                            res.subitem = subitem.nome;
                                        }
                                    })
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                        if (res.key_usuario) {
                            let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
                            if (agente) {
                                res.agente = agente.nome
                            } else {
                                res.agente = '';
                            }
                        } else {
                            res.agente = '';
                        }
                        if (res.enviar_resposta_solicitante_email) {
                            res.resposta = "email"
                        } else {
                            res.resposta = "whatsapp"
                        }
                        //  if (res.qtd_interacao) {
                        res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
                        //  }
                        res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
                        res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                        return res;
                    });
                    this.dados = this.lista;
                    //   this.onLoadOne();
                }
                this.dados = this.lista
                this.loading = false;
            },
                err => console.log(err)
            );
        }
    }
    onUpdateDados(result) {
        if (result.length > 0) {
            let res = this.lista.filter(obj => {
                return obj.key == result[0].key
            })
            if (res.length > 0) {
                const index = this.lista.indexOf(res[0]);
                this.lista.splice(index, 1);
            }
            if (result[0].status != "finalizado") {
                let lista = result.map(res => {
                    //  if (res.qtd_interacao) {
                    res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
                    //  }
                    res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
                    res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                    return res;
                });
                this.lista.push(lista[0])
            }
        }

        this.lista.sort(function (a, b) {
            if (a.time < b.time) {
                return 1;
            }
            if (a.time > b.time) {
                return -1;
            }
            return 0;
        });

        this.dados = this.lista;
        this.meus_lista = this.lista.filter(obj => {
            return obj.key_responsavel == this.parametros.key_usuario
        })
        this.nova_lista = this.lista.filter(obj => {
            return obj.status == "novo";
        });
        this.pendente_lista = this.lista.filter(obj => {
            return obj.status == "pendente";
        });
        // nao existe estes dois, deve ser implementado posteriormente
        this.atrasado_lista = this.lista.filter(obj => {
            return obj.status == "atrasado";
        });
        this.espera_lista = this.lista.filter(obj => {
            return obj.status == "espera";
        });
        this.solicitante_lista = this.lista.filter(obj => {
            return obj.key_usuario == this.parametros.key_usuario;
        });
        this.todos = this.lista.length
        this.meus = this.meus_lista.length;
        this.nova = this.nova_lista.length;
        this.pendente = this.pendente_lista.length;
        this.atrasado = this.atrasado_lista.length;
        this.espera = this.espera_lista.length;
        this.sousolicitante = this.solicitante_lista.length

    }
    onLoadSousolicitante(valid?: boolean) {
        /*
        let joins = [{ key: "key_lotacao", table: "lotacao" }]
        let unSub = this.genProvider.onGetFilterOrderTo('dados/' + this.path + '/ticket', "time", "key_usuario", this.parametros.key_usuario, "==", "status", "Finalizado", "!=", 999, true, joins).subscribe(data => {
          unSub.unsubscribe()
          let lista = JSON.parse(JSON.stringify(data));
          this.sousolicitante = lista.length
          if (!valid) {
            this.lista = lista.map(res => {
              if (res.lotacao) {
                res.nome_lotacao = res.lotacao.nome;
              } else {
                res.nome_lotacao = '';
              }
              if (res.qtd_interacao) {
                res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
              }
              res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
              res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
              return res;
            });
          }
        },
          err => console.log(err)
        );
        */
    }
    onLoadLotacao() {
        this.genProvider.onGetAll('dados/' + this.path + '/lotacao', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
            let lotacao = JSON.parse(JSON.stringify(data));
            //    this.lotacao = lotacao;
            this.lotacao = lotacao.map(res => {
                res.nomeSigla = res.nome + " " + res.sigla + " " + res.codigo
                return res;
            });
            this.onLoadPessoa_lotacao(this.key_pessoa);
        },
            err => console.log(err)
        );
    }
    onGetLotacao() {
        let lotacao = this.lotacao.filter(obj => {
            return obj.key == this.formulario.value.key_lotacao;
        });
        this.formulario.patchValue({
            nome_lotacao: lotacao[0].nome
        })
    }
    onLoadUsuarios() {
        let unSub = this.genProvider.onGetAll('login', 'nome').subscribe(data => {
            unSub.unsubscribe();
            this.usuarios = JSON.parse(JSON.stringify(data));
        })
    }
    onLoadUsuario() {
        this.genProvider.onGetAllFilterPageDesc('login', 'nome', "key_lotacao", this.parametros.key_lotacao, 9999).pipe(take(1)).subscribe(data => {
            this.login = JSON.parse(JSON.stringify(data));

        },
            err => console.log(err)
        );
    }
    onAlterarResponsavel() {
        this.onLoadUsuario();
        this.mostraAlteracaoResponsavel = true;
    }
    onGetSolicitante() {
        let solicitante = this.solicitante.filter(obj => {
            return obj.key == this.formulario.value.key_solicitante;
        });
        this.formulario.patchValue({
            nome_solicitante: solicitante[0].nome,
            id_canal: solicitante[0].telefone,
            canal: "Painel"
        })
    }
    onLoadRapida() {
        this.genProvider.onGetAll('dados/' + this.path + '/rapida', "titulo").pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.rapidaAll = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );

    }
    onNovo() {
        this.formulario.reset();
        this.uploadedFiles = [];
        this.titulo = 'Novo Ticket';
        this.formulario.patchValue({
            enviar_resposta_solicitante: true,
            key_usuario: this.parametros.key_usuario,
            key_responsavel: this.parametros.key_usuario,
            nome_responsavel: this.pessoa.nome,
            nome_usuario: this.pessoa.nome,
            key_solicitante: this.pessoa.key,
            nome_solicitante: this.pessoa.nome,
            key_solicitante_lotacao: this.pessoa.key_lotacao,
            nome_solicitante_lotacao: this.pessoa.nome_lotacao,
            key_cargo: this.pessoa.key_cargo,
            nome_cargo: this.pessoa.nome_cargo,
            canal: "Painel",
            id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.pessoa.telefone),
            status: "Novo",
            status_ticket: "Novo",
            data_criacao: Date.now(),
            data_hora: Date.now(),
            time: Date.now()
        })
        if (this.puc.length == 1) {
            this.formulario.patchValue({
                key_puc: this.puc[0].key_puc,
            })
        }
        this.onMostraFrmNovo();
    }
    onDeleteConfirm(event) {
        if (window.confirm('Confirma a Exclusão de ' + event.nome + ' - ' + event.telefone)) {
            this.genProvider.remove('dados/' + this.path + '/ticket', event.key);
        }
    }
    onTextEditor(event) {
        this.formulario.patchValue({
            conteudoTexto: event.textValue
        })
    }
    onSaveNovo() {
        let central_tmp = this.genProvider.onSearchArray(this.puc, 'key_puc', this.formulario.value.key_puc);
        let central = ''
        if (central_tmp) {
            central = central_tmp.nome
        }
        let catalogo = '';
        let catalogo_tmp = this.genProvider.onSearchArray(this.catalogo, 'key_catalogo', this.formulario.value.key_catalogo);
        if (catalogo_tmp) {
            catalogo = catalogo_tmp.nome
        }
        let item = '';
        let item_tmp = this.genProvider.onSearchArray(this.itemCatalogo, 'key', this.formulario.value.key_item);
        if (item_tmp) {
            item = item_tmp.nome
        }
        let subItem = '';
        let subItem_tmp = this.genProvider.onSearchArray(this.subItemCatalogo, 'key', this.formulario.value.key_subitem);
        if (subItem_tmp) {
            subItem = subItem_tmp.nome
        }

        this.confirmationService.confirm({
            message: 'Confirma a Criação do Ticket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.onFechar();
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                this.genProvider.onIncrementKeyDateTime().then((keyProtocolo: any) => {
                    let data = {
                        fromMe: true,
                        status_ticket: "Novo",
                        protocolo: keyProtocolo,
                        canal: 'Painel',
                        compartilhado: false,
                        copia_usuario: false,
                        distribuicao: false,
                        encaminhado: false,
                        encerrado: false,
                        distribuido: true,
                        status: 'Novo',
                        nome_usuario: this.nomeUsuario,
                        key_solicitante: this.parametros.key_usuario,
                        nome: this.formulario.value.nome_solicitante,
                        conteudoTexto: this.textoEditor.el.nativeElement.innerText,
                        nome_lotacao: null,
                        time: Date.now(),
                        key_responsavel: null,
                        nome_responsavel: null,
                        qtd_interacao: "",
                        key_lotacao: this.key_lotacao
                    }
                    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", data, "ticket", 6).then(keyTicket => {

                        //       this.genProvider.onIncrementKeyZeroDate("dados/" + this.parametros.path, 'ticket', 6).then((keyTicket: any) => {
                        this.formulario.patchValue({
                            fromMe: true,
                            key: keyTicket,
                            ticket: keyTicket,
                            status_ticket: "Novo",
                            protocolo: keyProtocolo,
                            canal: 'Painel',
                            compartilhado: false,
                            copia_usuario: false,
                            distribuicao: false,
                            encaminhado: false,
                            encerrado: false,
                            distribuido: true,
                            status: 'Novo',
                            nome_usuario: this.nomeUsuario,
                            key_solicitante: this.parametros.key_usuario,
                            nome: this.formulario.value.nome_solicitante,
                            conteudoTexto: this.textoEditor.el.nativeElement.innerText,
                            nome_lotacao: null,
                            time: Date.now(),
                            key_responsavel: null,
                            nome_responsavel: null,
                            qtd_interacao: "",
                            key_lotacao: this.key_lotacao,
                            integrado: '0'
                        })
                        this.genProvider.onSaveObjeto("dados", this.parametros.path, "ticket", this.formulario.value).then(t => {
                            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Ticket #' + t + ' Criado!' });
                            let msg = '*Abertura de Chamado (Ticket)*' + this.service.ctrl
                            msg += 'Ticket: ' + this.formulario.value.ticket + this.service.ctrl
                            msg += 'Data: ' + new DatePipe('pt-BR').transform(this.formulario.value.time, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Status: Registrado ' + this.service.ctrl
                            msg += 'Unidade Operacional Solicitante: ' + central + this.service.ctrl
                            msg += 'Catalógo: ' + catalogo + this.service.ctrl
                            msg += 'Item: ' + item + this.service.ctrl
                            msg += 'SubItem: ' + subItem + this.service.ctrl
                            msg += 'Aberta por : ' + this.formulario.value.nome_usuario + this.service.ctrl
                            msg += 'Detalhe: ' + this.formulario.value.conteudo + this.service.ctrl + this.service.ctrl
                            let msg_chat = msg;
                            msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                            if (this.formulario.value.email) {
                                let assunto = "Abertura de Chamado Ticket " + this.formulario.value.key;
                                let email = {
                                    contato: this.formulario.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }
                            let dados = {
                                status: 'Enviado',
                                key_usuario: this.key_usuario,
                                key_lotacao: this.formulario.value.key_lotacao,
                                nome_lotacao: this.formulario.value.nome_lotacao,
                                nome_usuario: this.nomeUsuario,
                                type: 'chat',
                                ticket: this.formulario.value.ticket,
                                value: this.formulario.value.conteudo,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            let data_movto = {
                                ticket: this.formulario.value.ticket,
                                protocolo: this.formulario.value.protocolo,
                                status: 'Novo',
                                key_usuario: this.key_usuario,
                                key_lotacao: this.formulario.value.key_lotacao,
                                nome_lotacao: this.formulario.value.nome_lotacao,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao_tramitacao: null,
                                key_usuario_destino: null,
                                key_puc: null,
                                time: Date.now(),
                                canal: this.formulario.value.canal,
                                conteudo: this.formulario.value.conteudoTexto
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados);
                            let atendimento = {
                                status: "Novo",
                                protocolo: this.formulario.value.protocolo,
                                ticket: this.formulario.value.ticket,
                                key_lotacao: this.formulario.value.key_solicitante_lotacao,
                                key_puc: this.formulario.value.key_puc,
                                key_usuario: this.parametros.key_usuario,
                                key_solicitante: this.formulario.value.key_solicitante,
                                key_catalogo: this.formulario.value.key_catalogo,
                                key_item: this.formulario.value.key_item,
                                key_subitem: this.formulario.value.key_subitem,
                                origem: "painel",
                                data_criacao: this.formulario.value.data_criacao,
                                data_hora: Date.now(),
                                time: Date.now(),
                                nome_solicitante: this.formulario.value.nome_solicitante,
                                tempo: ''
                            };
                            this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento);
                            let dados_chat = {
                                status: "Novo",
                                image: "assets/icon/ticket.png",
                                protocolo: this.formulario.value.protocolo,
                                ticket: this.formulario.value.ticket,
                                key_lotacao: this.formulario.value.key_solicitante_lotacao,
                                key_puc: this.formulario.value.key_puc,
                                nome_puc: this.formulario.value.nome_puc,
                                key_usuario: null,
                                acesso: "0",
                                novo: '1',
                                origem: "painel",
                                data_criacao: this.formulario.value.data_criacao,
                                data_hora: Date.now(),
                                nome_solicitante: this.formulario.value.nome_solicitante,
                                nome: this.formulario.value.nome_solicitante,
                                id_canal: this.formulario.value.id_canal,
                                telefone: this.validaCampoProvider.transPadraoWhatsapp(this.pessoa.telefone),
                                menu: "ticket",
                                type: "chat",
                                value: msg_chat,
                                time: Date.now(),
                                messageNumber: "99999",
                                notifica_usuario: true,
                                prioridade: this.formulario.value.prioridade || 'Baixa'
                            };
                            this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/chat", dados_chat.protocolo, dados_chat);
                            let dados_body = {
                                protocolo: this.formulario.value.protocolo,
                                telefone: this.formulario.value.id_canal,
                                key_usuario: this.key_usuario,
                                key_lotacao: this.formulario.value.key_lotacao,
                                nome_lotacao: this.formulario.value.nome_lotacao,
                                nome_usuario: this.nomeUsuario,
                                type: "chat",
                                value: msg_chat,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                status_ticket: '0'
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + dados_body.protocolo, "chat_body", dados_body);
                            this.upLoadFile(this.uploadedFiles, this.formulario.value.protocolo)
                        });
                    });
                });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        })
    };
    upLoadFile(files, protocolo) {
        let tabela = 'ticket'
        if (files.length > 0) {
            this.uploadedFiles.forEach(file => {
                const filePath = `/${tabela}/file_${new DatePipe('pt-BR').transform(
                    new Date(),
                    'yyyy-MM-dd-HH:mm:ss'
                )}.` + file.name;
                this.ref = this.afStorage.ref(filePath);
                this.task = this.ref.put(file);
                this.progress = this.task.percentageChanges();
                this.snapshot = this.task.snapshotChanges().pipe(
                    finalize(() => {
                        this.ref.getDownloadURL().subscribe(res => {
                            let dados_body = {
                                protocolo: protocolo,
                                messageNumber: '00000',
                                key_usuario: this.key_usuario,
                                key_lotacao: this.formulario.value.key_lotacao,
                                nome_lotacao: this.formulario.value.nome_lotacao,
                                nome_usuario: this.nomeUsuario,
                                telefone: this.formulario.value.id_canal,
                                type: file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
                                value: res,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                status_ticket: '0'
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + dados_body.protocolo, "chat_body", dados_body);
                            let dados = {
                                status: 'Enviada',
                                key_usuario: this.key_usuario,
                                key_lotacao: this.formulario.value.key_lotacao,
                                nome_lotacao: this.formulario.value.nome_lotacao,
                                nome_usuario: this.nomeUsuario,
                                type: file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
                                ticket: this.formulario.value.key,
                                time: Date.now(),
                                icon: PrimeIcons.CHECK,
                                color: '#607D8B',
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica',
                                nameFile: file.name,
                                fullPath: filePath,
                                value: res
                            }

                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados).then(res => {
                                this.messageService.add({ severity: 'info', summary: 'File Uploaded: ', detail: file.name });
                            }).catch(e => {
                                this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
                                console.log(e);
                            });
                        })
                    })
                );
                this.snapshot.subscribe(
                    res => {
                        console.log(res);
                    }, err => {
                        console.log(err);
                    }
                );
            });
        }
    }
    onAtribuirTicket() {
        if (!this.formulario.value.key_responsavel) {
            this.confirmationService.confirm({
                message: 'Confirma o envio?',
                header: 'Atenção!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                acceptIcon: 'pi pi-check',
                rejectLabel: 'Não',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    let data = {
                        key: this.formulario.value.key,
                        nome_responsavel: this.parametros.nome,
                        key_responsavel: this.parametros.key_usuario
                    }
                    this.nome_responsavel = this.parametros.nome;
                    this.genProvider.onSaveObjeto('dados', this.path, "ticket", data)
                    this.nome_responsavel = this.parametros.nome;
                    //   this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(t => {
                    this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Ticket #' + this.formulario.value.key + ' Gravado!' });
                    //   }).catch(e => {
                    //     this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
                    //     console.log(e);
                    //   });
                },
                reject: () => {
                    this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
                }
            });
        } else {
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Este ticket ja esta com responsável registrado #' + this.nome_responsavel });
        }
    }
    onSaveResponsavel() {
        if (this.key_responsavel) {
            this.confirmationService.confirm({
                message: 'Confirma o envio?',
                header: 'Atenção!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                acceptIcon: 'pi pi-check',
                rejectLabel: 'Não',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    let res = this.login.filter(obj => {
                        return obj.key == this.key_responsavel
                    })
                    console.log(res)
                    if (res.length > 0) {
                        let data = {
                            key: this.formulario.value.key,
                            nome_responsavel: res[0].nome,
                            key_responsavel: this.key_responsavel
                        }
                        this.nome_responsavel = res[0].nome;
                        this.genProvider.onSaveObjeto('dados', this.path, "ticket", data)
                    }

                    //    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(t => {
                    this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Ticket #' + this.formulario.value.key + ' Gravado!' });
                    this.mostraAlteracaoResponsavel = false;
                    //   }).catch(e => {
                    //     this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
                    //     console.log(e);
                    //  });
                },
                reject: () => {
                    this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
                    this.mostraAlteracaoResponsavel = false;
                }
            });
        } else {
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Este ticket ja esta com responsável registrado #' + this.nome_responsavel });
        }
    }
    onInteracao(event: any) {
        //  if (this.nivel == '99') {
        this.template = [];
        this.historico = [];
        this.midias = [];
        this.isPermissao = false;
        this.permissao = false;
        if (this.formulario_dinamico) {
            this.formulario_dinamico.reset();
        }
        if (this.key_lotacao != event.key_lotacao) {
            this.permissao = true;
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Este ticket está disponível apenas para leitura, para fazer interações você precisa esta logado na lotação.' });
        } else {
            this.permissao = false;
        }
        if ((event.status.toLowerCase() == "devolvido") || (event.status.toLowerCase() == "cancelado")) {
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Este ticket não pode ser aberto com o status de ' + event.status.toUpperCase() });
        } else if (event.status.toLowerCase() == "finalizado") {
            this.permissao = true;
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Ticket finalizado está disponível apenas para leitura' });
        }
        let joins = [{ key: "key_lotacao", table: "lotacao" }, { key: "key_cargo", table: "cargo" }]
        let telefone = '000000000'
        if (event.key_solicitante) {
            telefone = this.validaCampoProvider.formataTelefoneDDD(event.key_solicitante)
        }
        if (this.parametros.key_catalogo_quantificacao) {
            this.formulario_catalogo_quantificacao.patchValue({
                key_ticket: event.key,
                key_catalogo: this.parametros.key_catalogo_quantificacao
            })
            this.itemCatalogoQuantificacao = [];
            this.subItemCatalogoQuantificacao = [];
            this.onLoadCatalogoItemQuantificacao(this.parametros.key_catalogo_quantificacao);
            this.onLoadQuantificacao(event.key);
        }
        this.genProvider.onGetfilter('dados/' + this.path + '/pessoa', 'telefone', telefone, joins).pipe(take(1)).subscribe(res => {
            let pessoa = JSON.parse(JSON.stringify(res));
            if (pessoa.length > 0) {
                this.formulario_pessoa.patchValue(pessoa[0])
                if (pessoa[0].lotacao) {
                    this.formulario_pessoa.patchValue({
                        nome_lotacao: pessoa[0].lotacao.nome
                    })
                }
                if (pessoa[0].cargo) {
                    this.formulario_pessoa.patchValue({
                        nome_cargo: pessoa[0].cargo.nome
                    })
                }
            }
        });
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/ticket/' + event.key + '/' + 'ticket_body', 'time').subscribe(data => {
            let historico = JSON.parse(JSON.stringify(data));
            unSub.unsubscribe();
            historico = historico.map(res => {
                if (res.status != 'Enviada') {
                    res.status = "Recebida"
                }
                if (res.key_usuario) {
                    let usuario = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
                    if (usuario) {
                        res.nome_usuario = usuario.nome;
                    }
                } else {
                    res.nome_usuario = ''
                }
                if (res.key_lotacao) {
                    let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                    if (lotacao) {
                        res.nome_lotacao = lotacao.nome
                        res.tipo_lotacao = lotacao.tipo_lotacao;
                    }
                }
                return res;
            });

            let texto = '';
            let temp = historico.filter(res => {
                return (res.type == 'chat' || res.type == 'interactive')
            })
            temp.forEach(e => {
                e.value = e.value.replace(/\n/g, '<br>');
                this.historico.push(e)
            });

            historico.forEach(e => {
                if (e.type == 'chat' || e.type == 'interactive') {

                    texto = texto + e.value + this.service.ctrl;
                } else {
                    this.midias.push(e)
                }
            });
            texto = texto + this.service.ctrl + "***************** FIM DO HISTÓTIO *****************" + this.service.ctrl;
            //    let valor = res.value.replace(/\*/g, '');
            texto = texto.replace(/\n/g, '<br>');

            this.formulario.patchValue({
                conteudo: event.conteudo
            })

        });
        if (event.status_ticket == "novo") {
            this.inicio_atendimento = Date.now();
        } else {
            if (!event.inicio) {
                this.inicio_atendimento = Date.now();
            } else {
                this.inicio_atendimento = event.inicio
            }
        }
        event.time = Date.now();
        event.inicio = this.inicio_atendimento
        this.formulario = this.formBuilder.group(event);
        // carregar tabelas chaves secundarias
        //  this.onLoadCatalogo(event.key_catalogo);
        this.onLoadCatalogoItem(event.key_catalogo);
        this.onLoadCatalogoSubItem(event.key_item);

        // formulario dinamico
        let unSubForm = this.genProvider.onGetFilterOrder("dados/" + this.parametros.path + "/chat_dinamico", "time", "protocolo", this.formulario.value.protocolo).subscribe(data => {
            unSubForm.unsubscribe();
            let dados = JSON.parse(JSON.stringify(data));
            if (dados.length > 0) {
                this.formulario_dinamico = this.formBuilder.group(dados[0]);
                unSub = this.genProvider.onGetAll('dados/' + this.parametros.path + '/template/' + this.formulario_dinamico.value.key_template + '/template_body', 'numero').subscribe(data => {
                    unSub.unsubscribe();
                    let temp = JSON.parse(JSON.stringify(data))
                    let template = temp.filter(res => {
                        return res.status != 'cancelado'
                    });
                    try {
                        this.template = template.map(col => ({ title: col.field.toUpperCase(), field: col.field, numero: col.numero, type: col.type, rows: col.rows, noDisplay: col.noDisplay }));
                        console.log(this.template)
                    } catch (error) {
                        console.log(error)
                    }
                });
            }
        });
        // fim
        this.enviarResp = event.enviar_resposta_solicitante;
        if (!event.key_responsavel) {
            this.nome_responsavel = " Sem Responsável"
        } else {
            if (!this.permissao) {
                this.genProvider.onGetKey('login', event.key_responsavel).pipe(take(1)).subscribe(res => {
                    let login = JSON.parse(JSON.stringify(res));
                    this.nome_responsavel = login.nome
                });
            }
        }
        if ((event.nome_responsavel) && (event.key_responsavel == this.parametros.key_usuario)) {

            /*this.formulario.patchValue({
              conteudo: ''
            })
            */
            this.onInicioTempoAtendimento();
            if (!this.permissao) {
                let dados = {
                    key: event.key,
                    status: 'Visualizado',
                    status_ticket: 'Visualizado',
                    time: Date.now(),
                    integrado: '0',
                    key_usuario: this.key_usuario,
                    key_lotacao: this.key_lotacao
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'ticket', dados);
            }
            this.onMostraFrmInteracao();
        } else if ((event.key_responsavel) && (event.key_responsavel != this.parametros.key_usuario)) {
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Este ticket já esta sendo atendimento por #' + this.nome_responsavel });
            this.formulario.reset();
        } else {
            this.onInicioTempoAtendimento();
            this.formulario.patchValue({
                conteudo: ''
            })
            if (!this.permissao) {
                let dados = {
                    key: event.key,
                    status: 'Visualizado',
                    status_ticket: 'Visualizado',
                    inicio: this.inicio_atendimento,
                    time: Date.now(),
                    integrado: '0',
                    key_usuario: this.key_usuario,
                    key_lotacao: this.key_lotacao
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'ticket', dados);
            }
            this.onMostraFrmInteracao();
        }

    }
    onMsgFiltro(filtro?: any) {
        this.msg_filtro = this.msg_all.filter(obj => {
            return obj.tipo == filtro;
        });
    }
    onReload() {
        /*
        if (this.lista.length > 0) {
          if (this.lista[0].status == "Finalizado") {
            this.onLoad();
          }
        } else {
          this.onLoad();
        }
        */
        // this.lista = this.dados;
        this.unSub.unsubscribe();
        this.lista = [];
        this.onLoad()
    }
    onSaveObservacao() {
        this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Observação foi atualizada.' });
        }).catch(e => {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
            console.log(e);
        });
    }
    selectRow(checkValue) {
        if (checkValue) {
            //   this.selectedRows = this.cars.filter(value => value.year < 2000);
        } else {
            this.selectedRows = [];
        }
    }
    onTramitarGroup(event: any) {
        if (this.dtLista._selection.length == 1) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!', detail: 'Para tramitar tickets agrupados você deve selecionar ao menos dois tickets.' });
        } else {
            this.listaGrupo = this.dtLista._selection;
            this.titulo = 'Tramitar em grupo';
            console.log(this.listaGrupo);
            this.tramitarTicketGroup = true;
            // this.onMostraFrmGroup();
        }
    }
    onNovoGroup(event: any) {
        if (!this.dtLista._selection) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!', detail: 'Para gerar um novo ticket agrupado você deve selecionar ao menos um ticket.' });
        } else {
            this.listaGrupo = this.dtLista._selection;
            this.formulario.reset();
            this.titulo = 'Novo ticket agrupado';
            this.formulario.patchValue({
                conteudo: '',
                status_itil: 'Requisição',
                nivel_atendimento: '3'
            })
            this.onMostraFrmGroup();
        }
    }
    onMostraFrmGroup() {
        this.mostraFrm = !this.mostraFrm;
        this.mostraFrmGroup = !this.mostraFrmGroup;
    }
    onView(data) {
        this.ticketView = true;
        this.formulario.patchValue(data)
    }
    onLoadPuc(key) {
        this.genProvider.onGetFilterOrder('dados/' + this.path + '/lotacao_puc', "nome", "key_lotacao", key).pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.puc = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onLoadSolicitante(key) {
        this.genProvider.onGetFilterOrder('dados/' + this.path + '/pessoa', "nome", "tipo", key).pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.solicitante = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onLoadPessoa(key) {
        this.genProvider.onGetKey('dados/' + this.path + '/pessoa', key).pipe(take(1)).subscribe(data => {
            this.pessoa = JSON.parse(JSON.stringify(data));
            this.key_lotacao = this.pessoa.key_lotacao
            this.key_lotacao_principal = this.pessoa.key_lotacao
            this.onLoadInit()
        })
    }
    onUpload(event) {
        if (event) {
            for (let file of event.currentFiles) {
                this.uploadedFiles.push(file);
            }
        }
        //   this.messageService.add({ severity: 'info', summary: 'Arquivo adicionado', detail: '' });
    }
    openMidia(res) {
        this.midia = {
            type: res.type,
            file: res.value,
            lat: res.lat || 0,
            lng: res.lng || 0
        }
        this.mostraMidia = true;
    }
    /** novas funcionalidades */
    onLoadLotacaoPuc(key) {
        let joins = [
            { key: "key_puc", table: "puc" }
        ]
        this.genProvider.onGetAll('dados/' + this.path + '/lotacao/' + key + '/puc', "key_puc", joins).pipe(take(1)).subscribe(data => {
            let puc = JSON.parse(JSON.stringify(data));
            if (puc.length > 0) {
                this.puc = puc.map(res => {
                    res.nome = res.puc.nome + ' - ' + res.puc.descricao
                    return res;
                });
            } else {
                alert("Atenção, não existe central de atendimento para o usuário!")
            }
        })
    }
    onLoadPucAll() {
        this.genProvider.onGetAll('dados/' + this.path + '/puc', 'nome').pipe(take(1)).subscribe(data => {
            this.pucAll = JSON.parse(JSON.stringify(data));
        })
    }
    onLoadCatalogo() {
        this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.catalogo = JSON.parse(JSON.stringify(data));
            this.onLoadAllItem();
        });
    }
    onGetCatalogoPuc(key_central) {
        this.catalogo = []
        let joins = [{ key: "key_catalogo", table: "catalogo" }]
        this.genProvider.onGetAll('dados/' + this.path + '/puc/' + key_central + '/catalogo', 'key_catalogo', joins).pipe(takeUntil(this.unSub$)).subscribe(data => {
            let catalogo = JSON.parse(JSON.stringify(data));
            catalogo.forEach(s => {
                if (s.catalogo) {
                    let item = {
                        key: s.key,
                        key_catalogo: s.key_catalogo,
                        nome: s.catalogo.nome,
                        descricao: s.catalogo.descricao,
                        tipo: s.catalogo.tipo,
                        disponivel: s.catalogo.disponivel,
                        status: s.catalogo.status
                    }
                    this.catalogo.push(item)
                }
            });
            console.log(this.catalogo)
        });
    }
    onLoadAllItem() {
        this.loading = true
        this.catalogo.forEach(e => {
            let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item', "nome").subscribe(data => {
                unSub.unsubscribe();
                let item = JSON.parse(JSON.stringify(data));
                item.forEach(element => {
                    this.item.push(element)
                });
            });
        })
        this.loading = false;
    }
    onLoadCatalogoItem(key) {
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + key + '/item', "nome").subscribe(data => {
            this.itemCatalogo = JSON.parse(JSON.stringify(data));
            unSub.unsubscribe();
        },
            err => console.log(err)
        );
    }
    onLoadCatalogoItemQuantificacao(key) {
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + key + '/item', "nome").subscribe(data => {
            this.itemCatalogoQuantificacao = JSON.parse(JSON.stringify(data));
            unSub.unsubscribe()
        },
            err => console.log(err)
        );
    }
    onLoadCatalogoSubItem(key) {
        this.onLoadSla(key)
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + this.formulario.value.key_catalogo + '/item/' + key + '/subitem', "nome").subscribe(data => {
            this.subItemCatalogo = JSON.parse(JSON.stringify(data));
            unSub.unsubscribe();
        },
            err => console.log(err)
        );
    }
    onLoadCatalogoSubItemQuantificacao(key) {
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + this.formulario_catalogo_quantificacao.value.key_catalogo + '/item/' + key + '/subitem', "nome").subscribe(data => {
            this.subItemCatalogoQuantificacao = JSON.parse(JSON.stringify(data));
            unSub.unsubscribe();
        },
            err => console.log(err)
        );
    }
    onLoadSla(key) {
        this.genProvider.onGetFilterOrder('dados/' + this.path + '/sla', "nome", "key_item", key).pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.sla = JSON.parse(JSON.stringify(data));
            if (this.sla.length > 0) {
                var time = new Date(this.formulario.value.data_criacao);
                var soma = (this.sla[0].tmr * 60 * 60 * 1000)
                var date = new Date(time.getTime() + soma);
                let tempo = Date.parse(date.toString());
                this.formulario.patchValue({
                    prazo_resolucao: tempo,
                    prazo_resolucaoM: new DatePipe("pt-BR").transform(tempo, "d/MM/yyyy - HH:mm")
                })
            }
        },
            err => console.log(err)
        );
    }
    onEncerrarAtendimento() {
        if (!this.formulario.valid) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
        } else {
            let form = this.formulario.value;
            form.status_ticket = null;
            this.formulario.patchValue(
                form
            )
            this.formulario = this.formBuilder.group(form);
            this.encerrarAtendimento = true;
        }
    }
    onEncerrar() {
        this.confirmationService.confirm({
            message: 'Confirma a finalização do ticket?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Ticket Finalizado.' });
                this.formulario.patchValue({
                    time: Date.now(),
                    qtd_interacao: parseInt(this.formulario.value.qtd_interacao) + 1,
                    status: "Finalizado",
                    ultima_interacao: Date.now(),
                    fim: Date.now(),
                    tempo: this.tempoAtendimento,
                    integrado: '0',
                    key_usuario: this.parametros.key_usuario,
                    key_lotacao: this.key_lotacao,
                })
                this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(ticket => {
                    if (this.formulario.value.enviar_resposta_solicitante) {
                        let msg = "Título: " + this.formulario.value.titulo_ticket + this.service.ctrl
                        msg += 'Ticket: #' + this.formulario.value.key + this.service.ctrl
                        msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Status: ' + this.formulario.value.status + this.service.ctrl
                        msg += 'Status do ticket: ' + this.formulario.value.status_ticket + this.service.ctrl
                        msg += 'Data do encerramento: ' + new DatePipe('pt-BR').transform(this.formulario.value.fim, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Aberta por : ' + this.formulario.value.nome_solicitante + this.service.ctrl
                        msg += 'Detalhe: ' + this.formulario.value.conteudo + this.service.ctrl + this.service.ctrl
                        msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                        if (this.formulario.value.email) {
                            let assunto = "Chamado do Ticket " + this.formulario.value.key;
                            let email = {
                                contato: this.formulario.value.email,
                                assunto: assunto,
                                mensagem: msg
                            }
                            this.service.sendMail(email)
                        }
                    }
                    let dados_ticket_body = {
                        status: 'Enviada',
                        key_usuario: this.parametros.key_usuario,
                        nome_usuario: this.nomeUsuario,
                        key_lotacao: this.formulario.value.key_lotacao,
                        nome_lotacao: this.formulario.value.nome_lotacao,
                        type: 'chat',
                        ticket: ticket,
                        value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        tipo: 'publica'
                    }
                    this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados_ticket_body);
                    let dados_body = {
                        protocolo: this.formulario.value.protocolo,
                        messageNumber: 1,
                        key_usuario: this.key_usuario,
                        key_lotacao: this.formulario.value.key_lotacao,
                        nome_lotacao: this.formulario.value.nome_lotacao,
                        nome_usuario: this.nomeUsuario,
                        telefone: this.formulario.value.id_canal,
                        type: "chat",
                        value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        status_ticket: '0'
                    };
                    let dados = {
                        key: this.formulario.value.protocolo,
                        status: 'Finalizado',
                        finalizada: 'sim',
                        time: Date.now(),
                        fim: Date.now(),
                        tempo: this.tempoAtendimento,
                        key_usuario: null
                    }
                    let data_movto = {
                        ticket: ticket,
                        protocolo: this.formulario.value.protocolo,
                        status: 'Finalizado',
                        key_usuario: this.parametros.key_usuario,
                        key_lotacao: this.key_lotacao,
                        key_lotacao_tramitacao: null,
                        key_usuario_destino: null,
                        key_puc: null,
                        time: Date.now(),
                        canal: this.formulario.value.canal,
                        conteudo: this.formulario.value.conteudoTexto
                    }
                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                    this.genProvider.onGetfilter('dados/' + this.path + '/chat', 'protocolo', this.formulario.value.protocolo).subscribe(res => {
                        let data = JSON.parse(JSON.stringify(res));
                        if (data.length > 0) {
                            dados.key = this.formulario.value.protocolo;
                            this.genProvider.onSaveObjeto('dados', this.path, "chat", dados)
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.protocolo, "chat_body", dados_body);
                        } else {
                            let dados = this.formulario.value;
                            delete dados["key"]
                            dados.status = 'Finalizado';
                            this.genProvider.onSaveDocKey('dados/' + this.parametros.path + '/chat', dados.protocolo, dados).then(() => {
                                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + dados.protocolo, "chat_body", dados_body);
                            });
                        }
                    });
                    this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario.value.protocolo).subscribe(res => {
                        let data = JSON.parse(JSON.stringify(res));
                        if (data.length > 0) {
                            let atendimento = {
                                key: data[0].key,
                                status: "Finalizado",
                                tipo_ticket: this.formulario.value.tipo_ticket,
                                key_puc: this.formulario.value.key_puc,
                                key_solicitante: this.formulario.value.key_solicitante,
                                key_catalogo: this.formulario.value.key_catalogo,
                                key_item: this.formulario.value.key_item,
                                key_subitem: this.formulario.value.key_subitem,
                                key_usuario: this.parametros.key_usuario,
                                key_lotacao: this.key_lotacao,
                                time: Date.now(),
                                nome_solicitante: this.formulario.value.nome_solicitante,
                                tempo: this.formulario.value.tempo,
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                        }
                    })
                    this.mostraFrmInteracao = false;
                    this.onExcluirTicket(this.formulario.value.ticket)
                });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onExcluirTicket(key) {
        let res = this.lista.filter(obj => {
            return obj.ticket == key
        })
        if (res.length > 0) {
            const index = this.lista.indexOf(res[0]);
            this.lista.splice(index, 1);
        }
    }
    onTramitar() {
        this.uploadedFiles = [];
        if (!this.formulario.valid) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
        } else {
            this.confirmationService.confirm({
                message: 'Confirma a tramitação do ticket?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                acceptIcon: 'pi pi-check',
                rejectLabel: 'Não',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Ticket Finalizado.' });
                    this.tramitarTicket = false;
                    this.mostraFrmInteracao = false;
                    this.formulario.patchValue({
                        status: "Novo",
                        status_ticket: "Novo",
                        qtd_interacao: parseInt(this.formulario.value.qtd_interacao) + 1,
                        //   tramitacao: parseInt(this.formulario.value.tramitacao) + 1,
                        ultima_interacao: Date.now(),
                        time: Date.now(),
                        key_responsavel: '',
                        nome_responsavel: '',
                        integrado: '0'
                    })
                    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(ticket => {

                        let msg = "Título: " + this.formulario.value.titulo_ticket + this.service.ctrl
                        msg += 'Ticket: ' + this.formulario.value.key + this.service.ctrl
                        msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Status: Tramitado ' + this.service.ctrl
                        msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario.value.key_lotacao).nome + this.service.ctrl
                        msg += 'Aberta por : ' + this.formulario.value.nome_solicitante + this.service.ctrl
                        msg += 'Detalhe: ' + this.formulario.value.conteudo + this.service.ctrl + this.service.ctrl
                        msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                        if (this.formulario.value.enviar_resposta_solicitante) {
                            if (this.formulario.value.email) {
                                let assunto = "Chamado do Ticket " + this.formulario.value.key;
                                let email = {
                                    contato: this.formulario.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }
                        }
                        let lot = this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario.value.key_lotacao)
                        if (lot) {
                            if (lot.email) {
                                let assunto = "Novo Ticket Nº " + this.formulario.value.key;
                                let email = {
                                    contato: lot.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }
                        }
                        if (this.formulario.value.conteudoTexto) {
                            let dados_ticket_body = {
                                status: 'Enviada',
                                key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.formulario.value.key_lotacao,
                                nome_lotacao: this.formulario.value.nome_lotacao,
                                type: 'chat',
                                ticket: ticket,
                                value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados_ticket_body);
                        }
                        let data_movto = {
                            ticket: ticket,
                            protocolo: this.formulario.value.protocolo,
                            status: 'Tramitado',
                            key_usuario: this.parametros.key_usuario,
                            key_lotacao: this.key_lotacao,
                            key_lotacao_tramitacao: this.formulario.value.key_lotacao,
                            key_usuario_destino: null,
                            key_puc: null,
                            time: Date.now(),
                            canal: this.formulario.value.canal,
                            conteudo: this.formulario.value.conteudoTexto
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                        /*
                            this.chat_msg.forEach(e => {
                              setTimeout(() => {
                                let dados = {
                                  key_usuario: this.parametros.key_usuario,
                                  nome_usuario: this.nomeUsuario,
                                  key_lotacao: this.formulario.value.key_lotacao,
                                  nome_lotacao: this.formulario.value.nome_lotacao,
                                  ticket: ticket,
                                  ...e
                                }
                                console.log (dados)
                                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados);
                              }, 1000);
                            });
                */
                        let dados_body = {
                            protocolo: this.formulario.value.protocolo,
                            messageNumber: 1,
                            key_usuario: this.key_usuario,
                            key_lotacao: this.formulario.value.key_lotacao,
                            nome_lotacao: this.formulario.value.nome_lotacao,
                            nome_usuario: this.nomeUsuario,
                            telefone: this.formulario.value.id_canal,
                            type: "chat",
                            value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            status_ticket: '0'
                        };
                        let dados = {
                            key: this.formulario.value.protocolo,
                            status: 'Tramitado',
                            time: Date.now(),
                            key_usuario: null,
                        }
                        this.onExcluirTicket(this.formulario.value.ticket)
                        this.genProvider.onSaveObjeto('dados', this.path, "chat", dados)
                        if (this.formulario.value.conteudoTexto) {
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.protocolo, "chat_body", dados_body);
                        }
                        this.upLoadFile(this.uploadedFiles, this.formulario.value.protocolo)
                    });
                },
                reject: () => {
                    this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
                }
            });
        }
    }
    onDevolver() {
        this.uploadedFiles = [];
        if (!this.formulario.valid) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
        } else {
            this.confirmationService.confirm({
                message: 'Confirma a devolução para a central de atendimento?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                acceptIcon: 'pi pi-check',
                rejectLabel: 'Não',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Ticket Devolvido.' });
                    this.formulario.patchValue({
                        status: "Devolvido",
                        status_ticket: "Devolvido",
                        qtd_interacao: parseInt(this.formulario.value.qtd_interacao) + 1,
                        tramitacao: '0',
                        key_usuario: this.parametros.key_usuario,
                        ultima_interacao: Date.now(),
                        time: Date.now(),
                        integrado: '0'
                    })
                    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(ticket => {
                        let dados_ticket_body = {
                            status: 'Enviada',
                            key_usuario: this.key_usuario,
                            key_lotacao: this.formulario.value.key_lotacao,
                            nome_lotacao: this.formulario.value.nome_lotacao,
                            nome_usuario: this.nomeUsuario,
                            type: 'chat',
                            ticket: ticket,
                            value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            tipo: 'publica'
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados_ticket_body);
                        let data_movto = {
                            ticket: this.formulario.value.key,
                            protocolo: this.formulario.value.protocolo,
                            status: 'Devolvido',
                            key_usuario: this.parametros.key_usuario,
                            key_lotacao: this.key_lotacao,
                            key_lotacao_tramitacao: this.formulario.value.key_lotacao,
                            key_usuario_destino: null,
                            key_puc: null,
                            time: Date.now(),
                            canal: this.formulario.value.canal,
                            conteudo: this.formulario.value.conteudoTexto
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                        let origem = this.formulario.value.canal;
                        if (this.formulario.value.canal == 'voip' || this.formulario.value.canal == 'chatbot') {
                            origem = 'whatsapp'
                        }
                        let dados = {
                            key: this.formulario.value.protocolo,
                            status: 'Devolvido',
                            time: Date.now(),
                            novo: '1',
                            key_usuario: null,
                            value: this.formulario.value.conteudoTexto,
                            menu: 'Devolução de ticket',
                            origem: 'voip',
                            image: 'assets/icon/ticket.png',
                            nome_usuario: this.nomeUsuario,
                            type: 'chat',
                            ticket: this.formulario.value.ticket,
                        }
                        this.genProvider.onGetfilter('dados/' + this.path + '/chat', 'protocolo', this.formulario.value.protocolo).subscribe(res => {
                            let data = JSON.parse(JSON.stringify(res));
                            if (data.length > 0) {
                                dados.key = this.formulario.value.protocolo;
                                this.genProvider.onSaveObjeto('dados', this.path, "chat", dados)
                                let dados_body = {
                                    protocolo: this.formulario.value.protocolo,
                                    type: "chat",
                                    key_usuario: this.key_usuario,
                                    key_lotacao: this.formulario.value.key_lotacao,
                                    nome_lotacao: this.formulario.value.nome_lotacao,
                                    nome_usuario: this.nomeUsuario,
                                    value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: Date.now(),
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                    status_ticket: '0'
                                };
                                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.protocolo, "chat_body", dados_body);
                            } else {
                                let dados = this.formulario.value;
                                delete dados["key"]
                                dados.status = 'Devolvido';
                                dados.time = Date.now();
                                dados.novo = '1';
                                dados.key_usuario = null;
                                dados.value = this.formulario.value.conteudoTexto;
                                dados.menu = 'Devolução de ticket';
                                dados.origem = 'voip';
                                dados.image = 'assets/icon/ticket.png';
                                dados.nome_usuario = this.nomeUsuario;
                                dados.type = 'chat';
                                dados.ticket = this.formulario.value.ticket;
                                this.genProvider.onSaveDocKey('dados/' + this.parametros.path + '/chat', dados.protocolo, dados).then(() => {
                                    let dados_body = {
                                        protocolo: this.formulario.value.protocolo,
                                        type: "chat",
                                        key_usuario: this.key_usuario,
                                        key_lotacao: this.formulario.value.key_lotacao,
                                        nome_lotacao: this.formulario.value.nome_lotacao,
                                        nome_usuario: this.nomeUsuario,
                                        value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                                        time: Date.now(),
                                        data_criacao: Date.now(),
                                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                        status_ticket: '0'
                                    };
                                    this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.protocolo, "chat_body", dados_body);
                                });
                            }
                        });;
                        this.upLoadFile(this.uploadedFiles, this.formulario.value.protocolo)
                        this.onExcluirTicket(this.formulario.value.ticket)
                        this.tramitarTicket = false;
                        this.mostraFrmInteracao = false;
                    });
                },
                reject: () => {
                    this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
                }
            });
        }

    }
    onPendente() {
        this.confirmationService.confirm({
            message: 'Confirma deixar ticket pendente?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Ticket Pendente.' });
                this.formulario.patchValue({
                    status: "Pendente",
                    status_ticket: "Pendente",
                    qtd_interacao: parseInt(this.formulario.value.qtd_interacao) + 1,
                    ultima_interacao: Date.now(),
                    time: Date.now(),
                    integrado: '0'
                })
                this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario.value, "ticket", 6).then(ticket => {

                    if (this.formulario.value.enviar_resposta_solicitante) {
                        let msg = "Título: " + this.formulario.value.titulo_ticket + this.service.ctrl
                        msg += 'Ticket: #' + this.formulario.value.key + this.service.ctrl
                        msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Status: Em tratativa' + this.service.ctrl
                        msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario.value.key_lotacao).nome + this.service.ctrl
                        msg += 'Aberta por : ' + this.formulario.value.nome + this.service.ctrl
                        msg += 'Detalhe: ' + this.formulario.value.conteudo + this.service.ctrl + this.service.ctrl
                        msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                        if (this.formulario.value.email) {
                            let assunto = "Chamado do Ticket " + this.formulario.value.key;
                            let email = {
                                contato: this.formulario.value.email,
                                assunto: assunto,
                                mensagem: msg
                            }
                            this.service.sendMail(email)
                        }
                    }
                    let dados_ticket_body = {
                        status: 'Enviada',
                        key_usuario: this.key_usuario,
                        key_lotacao: this.formulario.value.key_lotacao,
                        nome_lotacao: this.formulario.value.nome_lotacao,
                        nome_usuario: this.nomeUsuario,
                        type: 'chat',
                        ticket: ticket,
                        value: "Lotação: " + this.formulario.value.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + this.formulario.value.conteudoTexto,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        tipo: 'publica',
                        status_ticket: '0'
                    }
                    this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario.value.key, "ticket_body", dados_ticket_body);
                    this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.protocolo, "chat_body", dados_ticket_body);
                    this.mostraFrmInteracao = false;
                });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });


    }
    onSaveAlteracao() {
        this.confirmationService.confirm({
            message: 'Salvar as alterações?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                console.log(this.formulario.value)
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Salvar ticket.' });
                this.formulario.patchValue({
                    ticket: this.formulario.value.key
                })
                //  this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket', this.formulario.value);
                if (this.formulario_dinamico) {
                    if (this.formulario_dinamico.value.key) {
                        //   this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat_dinamico', this.formulario_dinamico.value);
                    }
                }
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });


    }
    horasAtendimento() {
        var dtInicio = new DatePipe("pt-BR").transform(this.inicio_atendimento, "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 16:40:00";
        var dtAtual = new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 11:20:00";
        var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
        //      console.log(ms)
        var d = moment.duration(ms);
        var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
        //   totalHoraDisplay.innerHTML = s;
        this.tempoAtendimento = s;
    }
    onInicioTempoAtendimento() {
        this.timerAtendimento = setInterval(() => {
            this.horasAtendimento();
        }, 1000);
    }
    onFinalTempoAtendimento() {
        clearInterval(this.timerAtendimento)
        this.tempoAtendimento = null;
    }
    onLoadTickets() {
        this.unSub$.next()
        this.unSub$.complete()
        if (this.idTicket) {
            this.genProvider.onGetKey('dados/' + this.path + '/ticket', this.idTicket).pipe(take(1)).subscribe(data => {
                let res = JSON.parse(JSON.stringify(data));
                if (!res.time) {
                    this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este ticket.' });
                } else {
                    if (this.nivel == '99') {
                        let lista = [res]
                        this.lista = lista.map(res => {
                            if (res.key_lotacao) {
                                res.nome_lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao).nome
                            } else {
                                res.nome_lotacao = '';
                            }
                            if (res.key_usuario) {
                                res.agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario).nome
                            } else {
                                res.agente = '';
                            }
                            if (res.enviar_resposta_solicitante_email) {
                                res.resposta = "e-mail"
                            } else {
                                res.resposta = "Whatsapp"
                            }
                            //    if (res.qtd_interacao) {
                            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
                            //    }
                            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
                            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                            return res;
                        });
                        let listaAll = [];
                        this.onUpdateDados(listaAll)
                    } else {
                        if (res.key_lotacao != this.key_lotacao) {
                            this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Este ticket não pertence a esta lotação.' })
                        } else {
                            let lista = [res]
                            this.lista = lista.map(res => {
                                if (res.key_lotacao) {
                                    res.nome_lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao).nome
                                } else {
                                    res.nome_lotacao = '';
                                }
                                if (res.key_usuario) {
                                    res.agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario).nome
                                } else {
                                    res.agente = '';
                                }
                                if (res.enviar_resposta_solicitante_email) {
                                    res.resposta = "e-mail"
                                } else {
                                    res.resposta = "Whatsapp"
                                }
                                //     if (res.qtd_interacao) {
                                res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
                                //     }
                                res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
                                res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                                return res;
                            });
                            let listaAll = [];
                            this.onUpdateDados(listaAll)
                        }

                    }
                }
            });

        }
    }
    onAtualizaChat() {
        this.genProvider.onGetAll('dados/' + this.path + '/chat', "time").pipe(take(1)).subscribe(data => {
            let chat = JSON.parse(JSON.stringify(data));
            //  console.log (chat)
            let c = 0
            chat.forEach(e => {
                console.log(e)
                /*
                   if (!e.image) {
                     e.image = 'assets/icon/ticket.png'
                   }
                   if (!e.origem) {
                     e.origem = 'voip'
                   }
                   e.telefone = e.id_canal
                   */
                if (e.ticket) {
                    e.key_usuario = null
                    e.status = "Tramitado"
                    console.log(e)
                }
                this.genProvider.onSaveObjeto('dados', this.path, "chat", e)
                console.log(e)
                this.service.sleep(1000)
            });
        })
    }
    onAtualizaTicket() {
        let i = 0;
        let startIntervalo = setInterval(() => {
            let res = this.lista[i];
            if (!res.key_encerrarAtendimento) {
                res.key_encerrarAtendimento = null
                res.integrado = 0;
                console.log(res)
                this.genProvider.onSaveObjeto('dados', this.path, "ticket", res)
            } else {
                console.log("chave existente " + res.key_encerrarAtendimento)
            }
            /*
            res.data_hora = res.time;
            res.time = Date.now()
            res.status = 'Finalizado';
            res.status_ticket = 'Cancelado'
            this.genProvider.onSaveObjeto('dados', this.path, "ticket", res)
            */

            i++
            if ((i + 1) > this.lista.length) {
                clearInterval(startIntervalo);
            }
        }, 500);

    }
    onLoadAgente() {
        this.genProvider.onGetAll('login', 'nome').pipe(take(1)).subscribe(data => {
            this.agente = JSON.parse(JSON.stringify(data));
            //  console.log (this.agente)
            let res = this.agente.filter(obj => {
                return obj.key == this.parametros.key_usuario
            });
            if (res[0].prioridade) {
                this.tipoPrioridade = [
                    { nome: 'Nenhuma' },
                    { nome: 'Baixa' },
                    { nome: 'Média' },
                    { nome: 'Alta' },
                    { nome: 'Urgente' }
                ];
            } else {
                this.tipoPrioridade = [
                    { nome: 'Baixa' }
                ];
            }
            // console.log (res)
        })

    }

    onLoadMacro() {
        this.genProvider.onGetAll('dados/' + this.path + "/macro", 'titulo').pipe(take(1)).subscribe(data => {
            this.macro = JSON.parse(JSON.stringify(data));
        })
    }
    onCancelaTicket(data) {
        this.confirmationService.confirm({
            message: 'Confirma cancelamento do ticket #' + data.ticket,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Ticket Cancelado.' });
                let dados_ticket = {
                    key: data.key,
                    status: 'Cancelado',
                    status_ticket: 'Cancelado',
                    tramitacao: "0",
                    key_usuario_cancelamento: this.parametros.key_usuario,
                    time: Date.now(),
                    data_envio: new DatePipe('pt-BR').transform(
                        Date.now(),
                        'd, MMMM yyyy - HH:mm'
                    ),
                    integrado: '0'
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'ticket', dados_ticket);
                let dados_chat = {
                    key: data.protocolo,
                    status: 'Cancelado',
                    novo: '0',
                    key_usuario: null,
                    key_usuario_cancelamento: this.parametros.key_usuario,
                    time: Date.now(),
                    data_envio: new DatePipe('pt-BR').transform(
                        Date.now(),
                        'd, MMMM yyyy - HH:mm'
                    )
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados_chat);
                this.genProvider.onSaveObjeto('dados', this.path, 'atendimento', dados_chat);
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });


    }
    onExcluirTicketCancelado() {
        this.ngOnDestroy();
        this.genProvider.onGetfilter('dados/' + this.path + '/ticket', 'status', 'Cancelado').pipe(take(1)).subscribe(data => {
            let lista = JSON.parse(JSON.stringify(data));
            console.log(lista.length)
            let i = 0;
            let startIntervalo = setInterval(() => {
                let res = lista[i];
                //   this.genProvider.remove('dados/' + this.path + '/ticket', res.key);
                //   this.genProvider.remove('dados/' + this.path + '/chat', res.protocolo);
                // data da criacao 1631405803505 - ultimo ticket 5150
                //  res.status = 'Cancelado';
                //  res.status_ticket = 'Cancelado'
                res.tramitacao = '0'
                res.integrado = 0
                //   let data = {
                //     key: res.protocolo,
                //     status: 'Cancelado'
                //  }
                this.genProvider.onSaveObjeto('dados', this.path, "ticket", res)
                //  this.genProvider.onSaveObjeto('dados', this.path, "chat", data)
                console.log(res)
                console.log(i)
                i++

                if ((i + 1) > lista.length) {
                    clearInterval(startIntervalo);
                    alert("finalizou " + res.ticket)
                }
            }, 1000);
        })
    }
    onLoadPessoa_lotacao(key) {
        if (this.parametros.nivel != '99') {
            this.genProvider.onGet('dados/' + this.path + "/pessoa/" + key, 'lotacao').pipe(take(1)).subscribe(data => {
                let pessoa_lotacao = JSON.parse(JSON.stringify(data));
                if (pessoa_lotacao.length > 0) {
                    pessoa_lotacao.forEach(res => {
                        if ((res.data_expira) && (res.data_expira <= Date.now())) {
                            var lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao);
                            res.nome = lotacao.nome;
                            if (res.data_expira) {
                                res.data = new DatePipe("pt-BR").transform(res.data_expira, "dd/MM/yyyy - HH:mm");
                            }
                            res.nomeData = res.nome + ' - ' + res.data
                            this.pessoa_lotacao.push(res);
                        } else if (!res.data_expira) {
                            var lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao);
                            res.nome = lotacao.nome;
                            res.data = 'Indeterminado'
                            res.nomeData = res.nome + ' - ' + res.data
                            res.nomeSigla = res.nome + ' - ' + res.sigla + ' - ' + res.codigo
                            this.pessoa_lotacao.push(res)
                        }
                    });
                }
            },
                err => console.log(err)
            );
        } else {
            this.lotacao.forEach(res => {
                let dados = {
                    nome: res.nome,
                    data: 'Indeterminado',
                    nomeData: res.nome,
                    nomeSigla: res.nome + ' - ' + res.sigla,
                    key_lotacao: res.key
                }
                this.pessoa_lotacao.push(dados)
            })
        }
    }
    onGetPessoa_lotacao(key) {
        if (!key) {
            this.nivel = '99';
            this.key_lotacao = this.key_lotacao_principal;
            if (this.unSub != undefined) {
                this.unSub.unsubscribe();
            }
            this.lista = [];
            this.onLoad()
        } else {
            this.key_lotacao = key;
            this.nivel = '0';
            if (this.unSub != undefined) {
                this.unSub.unsubscribe();
            }
            this.lista = [];
            this.onLoad()
        }
    }
    onPaginacao() {
        if (this.unSub != undefined) {
            this.unSub.unsubscribe();
        }
        this.lista = [];
        this.onLoad()
    }
    onTramitarLote(lista) {
        let i = 0;
        let startIntervalo = setInterval(() => {
            let formulario = lista[i];
            formulario.status = "Novo";
            formulario.status_ticket = "Novo";
            formulario.qtd_interacao = parseInt(formulario.qtd_interacao) + 1;
            formulario.ultima_interacao = Date.now();
            formulario.time = Date.now();
            formulario.key_responsavel = '';
            formulario.nome_responsavel = '';
            formulario.conteudoTexto = this.formulario.value.conteudoTexto;
            formulario.conteudo = this.conteudo;
            formulario.key_lotacao = this.key_lotacao_tramita;
            formulario.integrado = 0;
            this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", formulario, "ticket", 6).then(ticket => {
                //     if (formulario.enviar_resposta_solicitante) {
                let msg = "Título: " + formulario.titulo_ticket + this.service.ctrl
                msg += 'Ticket: ' + formulario.key + this.service.ctrl
                msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(formulario.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                msg += 'Status: Tramitado ' + this.service.ctrl
                msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', formulario.key_lotacao).nome + this.service.ctrl
                msg += 'Aberta por : ' + formulario.nome_solicitante + this.service.ctrl
                msg += 'Detalhe: ' + formulario.conteudo + this.service.ctrl + this.service.ctrl
                msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                if (this.formulario.value.email) {
                    let assunto = "Chamado do Ticket " + this.formulario.value.key;
                    let email = {
                        contato: this.formulario.value.email,
                        assunto: assunto,
                        mensagem: msg
                    }
                    this.service.sendMail(email)
                }
                if (formulario.conteudoTexto) {
                    let dados_ticket_body = {
                        status: 'Enviada',
                        key_usuario: this.parametros.key_usuario,
                        nome_usuario: this.nomeUsuario,
                        key_lotacao: formulario.key_lotacao,
                        nome_lotacao: formulario.nome_lotacao,
                        type: 'chat',
                        ticket: ticket,
                        value: "Lotação: " + formulario.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + formulario.conteudoTexto,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        tipo: 'publica'
                    }
                    this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + formulario.key, "ticket_body", dados_ticket_body);
                }
                let data_movto = {
                    ticket: ticket,
                    protocolo: formulario.protocolo,
                    status: 'Tramitado',
                    key_usuario: this.parametros.key_usuario,
                    key_lotacao: this.key_lotacao,
                    key_lotacao_tramitacao: formulario.key_lotacao,
                    key_usuario_destino: null,
                    key_puc: null,
                    time: Date.now(),
                    canal: formulario.canal,
                    conteudo: formulario.conteudoTexto
                }
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                let dados_body = {
                    protocolo: formulario.protocolo,
                    messageNumber: 1,
                    telefone: formulario.id_canal,
                    type: "chat",
                    value: "Lotação: " + formulario.nome_lotacao + this.service.ctrl + "Usuario: " + this.nomeUsuario + this.service.ctrl + this.service.ctrl + formulario.conteudoTexto,
                    time: Date.now(),
                    data_criacao: Date.now(),
                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                    status_ticket: '0'
                };
                let dados = {
                    key: formulario.protocolo,
                    status: 'Tramitado',
                    time: Date.now(),
                    key_usuario: null,
                }
                this.onExcluirTicket(formulario.ticket)
                this.genProvider.onSaveObjeto('dados', this.path, "chat", dados)
                if (formulario.conteudoTexto) {
                    this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + formulario.protocolo, "chat_body", dados_body);
                }
            });

            i++
            this.progress_save = Math.round((i / lista.length) * 100);
            if (i >= lista.length) {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: '#' + i + ' tickets tramitados com sucesso.' });
                clearInterval(startIntervalo);
                this.progress_save = 100;
                this.tramitarTicketGroup = false;
            }
        }, 1000);
    }
    onMacro() {
        this.mostraMacro = true
    }
    onAddMacro(e) {
        let macro = this.macro.filter(obj => {
            return obj.key == e;
        });
        this.formulario.patchValue({
            conteudo: this.formulario.value.conteudo + this.service.ctrl + macro[0].mensagem
        })
        this.mostraMacro = false;
    }
    trimAll(value) {
        if (value) {
            return value.replace(/\s/g, '')
        } else {
            return ''
        }
    }
    onLoadEncerrarAtendimento() {
        this.genProvider.onGetAll('dados/' + this.path + '/encerraratendimento', 'nome').pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.atendimento = data;
            this.atendimento = this.atendimento.map(res => {
                res.nomeDescricao = res.nome + " - " + res.descricao
                return res;
            });
        },
            err => console.log(err)
        );
    }
    onLoadFinalizados() {
        let inicio = new Date(this.inicio).getTime()
        let fim = new Date(this.final).getTime()
        this.unSub.unsubscribe();
        if ((inicio > 0) && (fim > 0)) {
            this.messageService.add({ severity: 'info', summary: 'Aguarde', detail: 'Seu relatório esta sendo gerado...' });
            if (this.nivel == '99') {
                this.genProvider.onGetPeriodoFilter('dados/' + this.path + '/ticket', 'time', 'status', 'Finalizado', inicio, fim).pipe(take(1)).subscribe(data => {
                    let temp = JSON.parse(JSON.stringify(data));
                    let res = temp.filter(obj => {
                        return obj.tramitacao == '1'
                    })
                    if (res.length == 0) {
                        this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo.' });
                    } else {
                        this.lista = res.map(res => {
                            if (res.status) {
                                res.status = res.status.toLowerCase()
                            }
                            if (res.status_ticket) {
                                res.status_ticket = res.status_ticket.toLowerCase()
                            }
                            if (res.key_lotacao) {
                                let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                                if (lotacao) {
                                    res.nome_lotacao = lotacao.nome
                                } else {
                                    res.nome_lotacao = ''
                                }
                            } else {
                                res.nome_lotacao = '';
                            }
                            let item = this.genProvider.onGetTableKey(this.item, res.key_item);
                            if (item) {
                                res.item = item.nome
                                if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                                    try {
                                        let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                                            unSub.unsubscribe();
                                            let subitem = JSON.parse(JSON.stringify(data));
                                            if (subitem) {
                                                res.subitem = subitem.nome;
                                            }
                                        })
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                            }
                            if (res.key_usuario) {
                                let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
                                if (agente) {
                                    res.agente = agente.nome
                                } else {
                                    res.agente = '';
                                }
                            } else {
                                res.agente = '';
                            }
                            if (res.enviar_resposta_solicitante_email) {
                                res.resposta = "e-mail"
                            } else {
                                res.resposta = "Whatsapp"
                            }
                            //  if (res.qtd_interacao) {
                            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
                            //  }
                            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
                            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                            return res;
                        });
                        let listaAll = [];
                        this.onUpdateDados(listaAll)

                    }
                });
            } else {
                this.genProvider.onGetPeriodoFilter('dados/' + this.path + '/ticket', 'time', 'key_lotacao', this.key_lotacao, inicio, fim).pipe(take(1)).subscribe(data => {
                    let temp = JSON.parse(JSON.stringify(data));
                    let res = temp.filter(obj => {
                        return obj.status == 'Finalizado' && obj.tramitacao == '1'
                    })
                    if (res.length == 0) {
                        this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo.' });
                    } else {
                        let lista = res
                        this.lista = lista.map(res => {
                            if (res.status) {
                                res.status = res.status.toLowerCase()
                            }
                            if (res.status_ticket) {
                                res.status_ticket = res.status_ticket.toLowerCase()
                            }
                            if (res.key_lotacao) {
                                let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                                if (lotacao) {
                                    res.nome_lotacao = lotacao.nome
                                } else {
                                    res.nome_lotacao = ''
                                }
                            } else {
                                res.nome_lotacao = '';
                            }
                            let item = this.genProvider.onGetTableKey(this.item, res.key_item);
                            if (item) {
                                res.item = item.nome
                                if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                                    try {
                                        let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                                            unSub.unsubscribe();
                                            let subitem = JSON.parse(JSON.stringify(data));
                                            if (subitem) {
                                                res.subitem = subitem.nome;
                                            }
                                        })
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                            }
                            if (res.key_usuario) {
                                let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
                                if (agente) {
                                    res.agente = agente.nome
                                } else {
                                    res.agente = '';
                                }
                            } else {
                                res.agente = '';
                            }
                            if (res.enviar_resposta_solicitante_email) {
                                res.resposta = "e-mail"
                            } else {
                                res.resposta = "Whatsapp"
                            }
                            //  if (res.qtd_interacao) {
                            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
                            //  }
                            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
                            res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                            return res;
                        });
                        let listaAll = [];
                        this.onUpdateDados(listaAll)

                    }
                });
            }
        }
    }
    onLoadSouSolicitante() {
        let inicio = new Date(this.inicio).getTime()
        let fim = new Date(this.final).getTime()
        this.unSub.unsubscribe();
        if ((inicio > 0) && (fim > 0)) {
            this.messageService.add({ severity: 'info', summary: 'Aguarde', detail: 'Sua solicitação esta sendo gerada...' });
            this.genProvider.onGetPeriodoFilter('dados/' + this.path + '/ticket', 'data_criacao', 'key_solicitante', this.parametros.key_usuario, inicio, fim).pipe(take(1)).subscribe(data => {
                let res = JSON.parse(JSON.stringify(data));
                /*
                let res = temp.filter(obj => {
                  return obj.key_ == 'Enviado' })
                */
                if (res.length > 0) {
                    //          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo.' });
                    //        } else {

                    let lista = res
                    this.lista = lista.map(res => {
                        if (res.status) {
                            res.status = res.status.toLowerCase()
                        }
                        if (res.status_ticket) {
                            res.status_ticket = res.status_ticket.toLowerCase()
                        }
                        if (res.key_lotacao) {
                            let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                            if (lotacao) {
                                res.nome_lotacao = lotacao.nome
                            } else {
                                res.nome_lotacao = ''
                            }
                        } else {
                            res.nome_lotacao = '';
                        }
                        let item = this.genProvider.onGetTableKey(this.item, res.key_item);
                        if (item) {
                            res.item = item.nome
                            if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                                try {
                                    let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                                        unSub.unsubscribe();
                                        let subitem = JSON.parse(JSON.stringify(data));
                                        if (subitem) {
                                            res.subitem = subitem.nome;
                                        }
                                    })
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                        if (res.key_usuario) {
                            let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
                            if (agente) {
                                res.agente = agente.nome
                            } else {
                                res.agente = '';
                            }
                        } else {
                            res.agente = '';
                        }
                        if (res.enviar_resposta_solicitante_email) {
                            res.resposta = "e-mail"
                        } else {
                            res.resposta = "Whatsapp"
                        }
                        //  if (res.qtd_interacao) {
                        res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
                        // }
                        res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
                        res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                        return res;
                    });
                    let listaAll = [];
                    this.onUpdateDados(listaAll)

                }
            });
        }
    }
    exportPdf() {
        let lista: any;
        if (this.dtLista.filteredValue) {
            lista = this.dtLista.filteredValue
        } else {
            lista = this.dados
        }
        const doc = new jsPDF('l', 'mm', 'a4');
        doc.setFontType('normal');
        doc.setFont('arial');
        doc.setFontSize(25)
        doc.setFontType('bold');
        doc.text(14, 10, "Tickets")
        doc.setFontSize(7);
        doc.setFontStyle('normal');
        //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
        doc.autoTable({
            //      columnStyles: {
            //       0: { halign: 'left', fillColor: [220, 220, 220] }
            //     },
            //      margin: { top: 20 },
            showFoot: 'lastPage',
            startY: 20,
            didDrawPage: (data) => {
                doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
            },
            columns: this.exportColumns,
            body: lista,
            // foot: this.listaFoot
        });
        doc.save('lista.pdf');
    }
    onNotificaOnOff() {
        this.notificacaoSonora = !this.notificacaoSonora;
        this.onNotificacao();
    }
    onNotificacao() {
        if (this.notificacaoSonora) {
            this.audio.nativeElement.play();
        }
    }
    onUpdateStatus() {
        let c = 0;
        let st = null;
        console.log(this.lista)
        let startIntervalo = setInterval(() => {
            st = 'Visualizado'
            if (st) {
                let dados = {
                    key: this.lista[c].key,
                    status_ticket: st,
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'ticket', dados);
                console.log(dados)
            }
            c++
            console.log(c)
            st = null;
            if (c >= this.lista.length)
                clearInterval(startIntervalo);
        }, 100);

    }
    onImprimir() {
        /*
          let temp = this.dados.filter(obj => {
            return obj.key = this.formulario.value.key
          })
          */
        let detalhe: any = [];
        let lista = this.formulario.value;
        let detalhamento = '';
        let unSub = this.genProvider.onGetAll('dados/' + this.path + '/ticket/' + lista.key + '/' + 'ticket_body', 'time').pipe(take(1)).subscribe(data => {
            unSub.unsubscribe();
            let historico = JSON.parse(JSON.stringify(data));
            let tmp = historico.map(res => {
                if (res.status != 'Enviada') {
                    res.status = "Recebida"
                }
                if (res.key_usuario) {
                    let usuario = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
                    if (usuario) {
                        res.nome_usuario = usuario.nome;
                    }
                } else {
                    res.nome_usuario = ''
                }
                if (res.key_lotacao) {
                    let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                    if (lotacao) {
                        res.nome_lotacao = lotacao.nome
                        res.tipo_lotacao = lotacao.tipo_lotacao || 'PADRÃO';
                    }
                }
                // let value = res.value.replace(/\n/g, '<p>');
                // value = value.replace(/\n/g, '</p>');
                // value = value.replace(/\n/g, '<br>');

                if (res.type == "location") {
                    let coor = res.value.split(/\s*;\s*/);
                    res.lat = parseFloat(coor[0]);
                    res.lng = parseFloat(coor[1]);
                    res.value = res.lat + ';' + res.lng
                }
                if (res.type == 'chat') {
                    detalhamento += "Data: " + res.data_hora + this.service.ctrl;
                    detalhamento += "Lotação: " + res.nome_lotacao + this.service.ctrl;
                    detalhamento += "Usuário: " + res.nome_usuario + this.service.ctrl;
                    detalhamento += "Relato: " + res.value + this.service.ctrl;
                } else {
                    detalhamento += "Data: " + res.data_hora + this.service.ctrl;
                    detalhamento += "Mídia Link: " + res.value + this.service.ctrl;
                }

                detalhe.push({
                    value: detalhamento
                })
                detalhamento = '';
                return res;
            });

            if (lista.complemento == null) {
                lista.complemento = ''
            }
            var doc = new jsPDF('p', 'mm', 'a4');
            doc.setFontType('normal');
            doc.setFont('arial');

            doc.setFontSize(25)
            doc.setFontType('bold');
            doc.text(20, 30, 'TICKET')

            doc.addImage(this.parametros.midia, 'PNG', 110, 10, 90, 30); // coluna, linha, largura, altura
            doc.setLineWidth(1.5)
            doc.line(5, 45, 200, 45); // coluna inicial, linha inicial, coluna final, linha final
            doc.setLineWidth(0.2);
            for (let index = 50; index < 85; index = index + 5) {
                doc.rect(5, index, 195, 5); // coluna , linha, largura, altura
            }
            doc.setFontSize(7);
            doc.setFontType('bold');
            doc.text(20, 49, "INFORMAÇÕES ADICIONAIS");
            doc.setFontStyle('normal');

            doc.setFontSize(6);
            doc.text(7, 53, "TICKET Nº");
            doc.setFontSize(10);
            doc.text(22, 54, lista.key);

            doc.setFontSize(6);
            doc.text(7, 58, "CRIADO EM");
            doc.setFontSize(10);
            doc.text(22, 59, lista.data_hora);

            doc.line(170, 55, 170, 60) // vertical
            doc.setFontSize(6);
            doc.text(171, 58, "STATUS");
            doc.setFontSize(10);
            doc.text(182, 59, lista.status_ticket);

            doc.setFontSize(6);
            doc.text(7, 63, "LOTAÇÃO");
            doc.setFontSize(10);
            doc.text(22, 64, lista.nome_lotacao);

            doc.line(135, 60, 135, 65) // vertical
            doc.setFontSize(6);
            doc.text(137, 63, "AGENTE");
            doc.setFontSize(10);
            doc.text(148, 64, lista.agente);
            // linhas verticais
            //  doc.line(70, 65, 70, 70) // vertical
            // doc.line(90, 65, 90, 70) // vertical
            //  doc.line(135, 65, 135, 70) // vertical
            doc.setFontSize(6);
            doc.text(7, 68, "ITEM");
            doc.setFontSize(10);
            doc.text(17, 69, lista.item);

            doc.setFontSize(6);
            doc.text(7, 73, "SUB-ITEM");
            doc.setFontSize(10);
            doc.text(19, 74, lista.subitem);

            doc.setFontSize(6);
            doc.text(7, 78, "NOME SOLICITANTE");
            doc.setFontSize(10);
            doc.text(30, 79, lista.nome_solicitante);

            doc.setFontSize(6);
            doc.text(7, 83, "ASSUNTO");
            doc.setFontSize(10);
            doc.text(22, 84, lista.titulo_ticket);

            doc.setFontSize(7);
            doc.setFontStyle('normal');
            // endereço
            let ln = 90
            if (lista.localizacao) {
                doc.setFontSize(7);
                doc.setFontType('bold');
                doc.text(20, 89, "ENDEREÇO");
                doc.setFontStyle('normal');
                doc.setLineWidth(0.2);
                for (let index = 90; index < 105; index = index + 5) {
                    doc.rect(5, index, 195, 5); // coluna , linha, largura, altura
                }

                // LINHA 1

                doc.setFontSize(6);
                doc.text(7, 93, "LOCAL");
                doc.setFontSize(10);
                doc.text(17, 94, lista.localizacao);

                doc.line(150, 90, 150, 95) // vertical
                doc.setFontSize(6);
                doc.text(151, 93, "NÚMERO");
                doc.setFontSize(10);
                doc.text(161, 94, lista.numero);

                // LINHA 2
                doc.setFontSize(6);
                doc.text(7, 98, "COMPLEMENTO");
                doc.setFontSize(10);
                doc.text(25, 99, lista.complemento);

                doc.line(95, 95, 95, 100) // vertical
                doc.setFontSize(6);
                doc.text(96, 98, "BAIRRO");
                doc.setFontSize(10);
                doc.text(106, 99, lista.bairro);
                // linha 3
                doc.setFontSize(6);
                doc.text(7, 103, "CIDADE");
                doc.setFontSize(10);
                doc.text(17, 104, lista.cidade);

                doc.line(70, 100, 70, 105) // vertical
                doc.setFontSize(6);
                doc.text(71, 103, "ESTADO");
                doc.setFontSize(10);
                doc.text(81, 104, lista.estado);

                doc.line(145, 100, 145, 105) // vertical
                doc.setFontSize(6);
                doc.text(146, 103, "LAT/LNG");
                doc.setFontSize(6);
                doc.text(157, 104, lista.lat.toString() + ';' + lista.lng.toString());

                /*
                        doc.line(170, 90, 170, 95) // vertical
                        doc.setFontSize(6);
                        doc.text(171, 93, "CEP");
                        doc.setFontSize(10);
                        doc.text(182, 94, lista.cep);

                 */
                ln = 108;
            }

            // fim


            doc.autoTable({
                margin: { left: 5, right: 10 },
                body: detalhe,
                showFoot: 'lastPage',
                startY: ln,
                didDrawPage: (data) => {
                    doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
                },
                columns: [{ title: 'DETALHE / HISTÓRICO', dataKey: 'value' }],
            });

            // preparar para imprimir

            let pdf = doc.output('arraybuffer');
            var utf8 = new Uint8Array(pdf);
            var file = new Blob([pdf], { type: 'application/pdf' });

            var fileURL = URL.createObjectURL(file);
            var win = window.open();
            win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
        });
    }
    // funcionalizades p/ geolozalizacao

    onConsultaCEP(cep) {
        let valor = cep.replace(/[^0-9]/g, '');
        if (valor.length != 8) {
            this.formulario.patchValue({
                cep: ''
            });
            this.messageService.add({ severity: 'info', summary: 'Erro!!', detail: 'Ops! CEP ' + valor + ', inválido' });
        } else {
            this.validaCampoProvider.validaCEP(cep).subscribe((data: any) => {
                if (data.cep) {
                    data.rua = data.logradouro
                    this.populaDadosForm(data);
                    //          this.errorCep = false;
                } else {
                    this.formulario.patchValue({
                        cep: ''
                    });
                    this.messageService.add({ severity: 'info', summary: 'Erro!!', detail: 'Ops! CEP ' + valor + ', inválido' });

                }
            }, error => alert(error));
        }
    }
    populaDadosForm(dados) {
        //this.formulario.setValue({});
        this.formulario.patchValue({
            rua: dados.rua,
            cep: dados.cep,
            complemento: dados.complemento,
            bairro: dados.bairro,
            cidade: dados.localidade,
            estado: dados.uf,
            localizacao:
                dados.rua +
                ' , ' +
                dados.bairro +
                ' , ' +
                dados.cidade +
                ', ' +
                dados.estado,
            local: dados.rua +
                ' , ' +
                dados.bairro +
                ' , ' +
                dados.cidade +
                ', ' +
                dados.estado,
        });
        this.onAddressMap();
    }
    onAddressMap() {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(
            { address: this.formulario.value.local },
            (results, status) => {
                if (results) {
                    if (results[0]) {
                        this.localizacao = results[0].formatted_address;
                        this.lat = results[0].geometry.location.lat();
                        this.lng = results[0].geometry.location.lng();
                        console.log(results[0])

                        if (results[0].address_components.length == 4) {
                            this.formulario.patchValue({
                                localizacao: results[0].formatted_address,
                                rua: results[0].address_components[1].long_name || ' ',
                                numero: results[0].address_components[0].long_name || ' ',
                                bairro: results[0].address_components[2].long_name || ' ',
                                cidade: results[0].address_components[3].long_name || ' ',
                                estado: '',
                                cep: '',
                                lat: this.lat,
                                lng: this.lng

                            });
                        } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
                            this.formulario.patchValue({
                                localizacao: results[0].formatted_address,
                                rua: results[0].address_components[1].long_name || ' ',
                                numero: results[0].address_components[0].long_name || ' ',
                                bairro: results[0].address_components[2].long_name || ' ',
                                cidade: results[0].address_components[3].long_name || ' ',
                                estado: results[0].address_components[4].long_name || ' ',
                                cep: results[0].address_components[5].long_name || ' ',
                                lat: this.lat,
                                lng: this.lng
                            });
                        } else if (results[0].address_components.length >= 7) {
                            this.formulario.patchValue({
                                localizacao: results[0].formatted_address,
                                rua: results[0].address_components[1].long_name || ' ',
                                numero: results[0].address_components[0].long_name || ' ',
                                bairro: results[0].address_components[2].long_name || ' ',
                                cidade: results[0].address_components[3].long_name || ' ',
                                estado: results[0].address_components[4].long_name || ' ',
                                cep: results[0].address_components[6].long_name || ' ',
                                lat: this.lat,
                                lng: this.lng
                            });
                        } else if (results[0].address_components.length < 4) {
                            this.formulario.patchValue({
                                localizacao: '',
                                rua: '',
                                numero: '',
                                bairro: '',
                                cidade: '',
                                estado: '',
                                cep: '',
                                lat: this.lat,
                                lng: this.lng
                            });
                        }
                    }
                }
            }
        );
    }
    getLocal() {
        this.formulario.patchValue({
            localizacao:
                this.formulario.value.rua +
                ' , ' +
                this.formulario.value.numero +
                ', ' +
                this.formulario.value.bairro +
                ' , ' +
                this.formulario.value.cidade +
                ', ' +
                this.formulario.value.estado
        });
        //  console.log(this.formulario.value.localizacao);
        this.onAddressMap();
    }
    onMap() {
        if ((this.formulario.value.lat) && (this.formulario.value.lng)) {
            this.onClickMapChat(parseFloat(this.formulario.value.lat), parseFloat(this.formulario.value.lng))
        }
    }
    onClickMap(e) {
        this.lat = e.coords.lat;
        this.lng = e.coords.lng;
        this.formulario.patchValue({
            latitude: e.coords.lat,
            longitude: e.coords.lng
        });
        let geocoder = new google.maps.Geocoder();
        let latLng = new google.maps.LatLng(this.lat, this.lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (results[0].address_components.length == 4) {
                this.formulario.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: '',
                    cep: '',
                    lat: this.lat,
                    lng: this.lng

                });
            } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
                this.formulario.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: '',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length >= 7) {
                this.formulario.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: results[0].address_components[6].long_name || ' ',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length < 4) {
                this.formulario.patchValue({
                    localizacao: '',
                    rua: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    cep: '',
                    lat: '',
                    lng: ''
                });
            }
        });
    }
    onClickMapChat(lat, lng) {
        this.lat = lat;
        this.lng = lng;
        let geocoder = new google.maps.Geocoder();
        let latLng = new google.maps.LatLng(this.lat, this.lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (results[0].address_components.length == 4) {
                this.formulario.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: '',
                    cep: '',
                    lat: this.lat,
                    lng: this.lng

                });
            } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
                this.formulario.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: results[0].address_components[5].long_name || ' ',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length >= 7) {
                this.formulario.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: results[0].address_components[6].long_name || ' ',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length < 4) {
                this.formulario.patchValue({
                    localizacao: '',
                    rua: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    cep: ''
                });
            }
        });
    }
    onSaveQuantificacao() {
        let res = this.quantificacao.filter(obj => {
            return obj.key_subitem == this.formulario_catalogo_quantificacao.value.key_subitem
        });
        if ((res.length > 0) && (!this.formulario_catalogo_quantificacao.value.key)) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Este item já esta cadastrada, por favor selecione outro.' });
        } else {
            this.formulario_catalogo_quantificacao.patchValue({
                nome_catalogo: this.genProvider.onGetTableKey(this.catalogo, this.formulario_catalogo_quantificacao.value.key_catalogo).nome,
                nome_item: this.genProvider.onGetTableKey(this.itemCatalogoQuantificacao, this.formulario_catalogo_quantificacao.value.key_item).nome,
                nome_subitem: this.genProvider.onGetTableKey(this.subItemCatalogoQuantificacao, this.formulario_catalogo_quantificacao.value.key_subitem).nome,
                data: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm")
            })
            this.genProvider.onSaveObjeto('dados', this.path, 'quantificacao', this.formulario_catalogo_quantificacao.value).then(t => {
                this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
                this.formulario_catalogo_quantificacao.patchValue({
                    key: null,
                    key_item: null,
                    key_subitem: null,
                    quantidade: null
                });
                //   this.onLoad()
            }).catch(e => {
                this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
                console.log(e);
            });
        }
    }
    onExcluirQuantificacao(event) {
        this.confirmationService.confirm({
            message: 'Confirma a Exclusão de ' + event.nome_subitem,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
                this.genProvider.remove('dados/' + this.path + '/quantificacao', event.key);
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
            }
        });
    }
    onEditarQuantificacao(event) {
        this.formulario_catalogo_quantificacao.patchValue(event);
    }
    onLoadQuantificacao(key) {
        if (this.unSubQuantificacao != undefined) {
            this.unSubQuantificacao.unsubscribe();
        }
        this.unSubQuantificacao = this.genProvider.onGetFilterOrder('dados/' + this.path + '/quantificacao', 'data', 'key_ticket', key).subscribe(data => {
            this.quantificacao = JSON.parse(JSON.stringify(data));
        });
    }
}
/*
key_encerrarAtendimento
STATUS: Novo, Finalizado, Cancelado, Distribuido, Registrando, Editando, Visualizado
STATUS_TICKET:  Tramitando, Finalizado, Pedendete, Cancelado, Visualizado, Transferido
*/
