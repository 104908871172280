/* tslint:disable */
import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { GenProvider } from '../../providers/gen/gen';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-relCatalogo',
  templateUrl: './relCatalogo.component.html',
  styleUrls: ['./relCatalogo.component.scss'],
  providers: [MessageService]
})
export class RelCatalogoComponent implements OnInit {
  inicio: any;
  final: any;
  @ViewChild('dtLista') dtLista: Table;

  filtro: any;
  lista: any;
  relacao: any;
  atuacao: any;
  membro: any;
  dados: any;
  qtdCad: any;
  path: any;
  datarelacao: any;
  dataatuacao: any;
  listaCoordenadores: any;

  isSexo: any;
  isTelefone: any;
  isDt_Nasc: any;
  isTipo: any;
  isRelacao: any;
  isEndereco: any;
  isZona: any;
  isSecao: any;
  isColegio: any;
  isBairro: any;
  membro_nome: any;
  key_coordenador: any;
  nome_coordenador: any;
  nome_lideranca: any;
  listaLideranca: any;
  key_lideranca: any;
  parametros: any;
  cols: any;
  exportColumns: any;
  usuarios: any;
  pessoas: any;
  lotacoes: any;
  puc: any;
  catalogo: any;
  item: any = [];
  loading: any;
  lotacao: any;
  unSub: any;
  itemCatalogo: any = [];
  subItem: any = [];

  constructor(
    private router: Router,
    private genProvider: GenProvider,
    private validaCampoProvider: ValidaCampoProvider,
    private messageService: MessageService
  ) {
    this.parametros = this.genProvider.parametros;
    this.path = this.genProvider.parametros.path;
    this.listaCoordenadores = [];
    this.lista = [];
  }

  ngOnInit() {
    this.cols = [
      { header: 'Catálogo/Carta', field: 'catalogo', width: '10%' },
      { header: 'Descrição', field: 'descricao', width: '10%' },
      { header: 'U.C', field: 'lotacao', width: '10%' },
      { header: 'Item', field: 'item', width: '10%' },
      { header: 'POP', field: 'popItem', width: '20%' },
      { header: 'Sub-Item', field: 'subItem', width: '10%' },
      { header: 'POP', field: 'popSubItem', width: '30%' },
    ];
     this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
   // this.exportColumns = [{ title: 'Ticket#', dataKey: 'ticket' }, { title: 'Data', dataKey: 'data_criacao' }, { title: 'Canal', dataKey: 'canal' }];
    this.onLoadLotacao();
  }

  onLoadLotacao() {
      this.loading = true;
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/lotacao', 'nome').subscribe(data => {
      unSub.unsubscribe()
      this.lotacao = JSON.parse(JSON.stringify(data));
      this.onLoadCatalogo();
    })
  }
  onLoadCatalogo() {
    this.genProvider.onGetAll('dados/' + this.path + '/catalogo', 'nome').pipe(take(1)).subscribe(data => {
      this.catalogo = JSON.parse(JSON.stringify(data));
      this.catalogo.forEach(e => {
        let catalogo = e.nome;
        let descricao = e.descricao;
        let lotacao: any;
        if (e.key_lotacao) {
          lotacao = this.genProvider.onGetTableKey(this.lotacao, e.key_lotacao).nome
        }
        this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item', "nome").pipe(take(1)).subscribe(data => {
          let itemCatalogo = JSON.parse(JSON.stringify(data));
          itemCatalogo.forEach(i => {
     //       this.itemCatalogo.push(i)
            let item = i.nome;
            let popItem = i.pop;
            if (i.key_lotacao) {
              lotacao = this.genProvider.onGetTableKey(this.lotacao, i.key_lotacao).nome
            }
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + e.key + '/item/' + i.key + '/subitem', 'nome').pipe(take(1)).subscribe(data => {
              let subItem = JSON.parse(JSON.stringify(data));
              subItem.forEach(s => {
                let subitem ={
                  catalogo: catalogo,
                  descricao : descricao,
                  lotacao : lotacao,
                  item: item,
                  popItem : popItem,
                  subItem : s.nome,
                  popSubItem : s.pop
                }
                this.lista.push(subitem)
              })
            });
          });
        },
          err => console.log(err)
        );
      });
     // console.log (this.lista)
      this.loading = false;
    },
      err => console.log(err)
    );
  }
  exportExcel() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, 'Catálogos')
    doc.setFontSize(6);
    doc.setFontStyle('normal');
//    doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      columnStyles: {
        0: { halign: 'left', fillColor: [220, 220, 220] }
      },
//      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
     // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
}
