/* tslint:disable */
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { FirebaseApp } from '@angular/fire';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { shareReplay, map, tap, delay, auditTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FireSQL } from 'firesql';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { asLiteral } from '@angular/compiler/src/render3/view/util';

@Injectable()
export class GenProvider {
  public parametros: any;
  public permissao: any;
  public frmActive: any;
  public unSub: Subscription[] = [];
  public lotacao: any;
  options: { headers: HttpHeaders; };
  constructor(
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    private fb: FirebaseApp,
    public http: HttpClient
  ) {
    //  const fireSQL = new FireSQL(this.afs);
    let headers = new HttpHeaders({
      "Content-Type": "application/json; charset=UTF-8",
      "Transfer-Encoding": "chunked",
      "Connection": "keep-alive",
      "Vary": "Accept-Encoding",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
      "Content-Encoding": "gzip"
    });
    this.options = {
      headers: headers
    }
    this.frmActive = '';
  }
  ngOnDestroy() {
    this.auth.signOut();
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
  }

  // https:/httpbin.org/post -> testar envio de dados
  onParametros(data) {
    this.onLoadPermissao(data.key_login)
    let parametros = {
      key: null,
      path: data.path,
      nivel: data.nivel,
      nome: data.nome,
      grupo: data.grupo,
      key_grupo: data.key_grupo,
      key_login: data.key_login,
      key_lotacao: data.key_lotacao,
      key_usuario: data.key,
      key_pessoa: data.key_pessoa,
      imagem: data.imagem,
      login: data.login
    }

    this.parametros = JSON.parse(JSON.stringify(parametros));
    let unSub = this.onGetAll('dados/' + data.path + '/parametros', 'token').subscribe(res => {
      // unSub.unsubscribe()
      let dados = JSON.parse(JSON.stringify(res));
      let parametros: any;
      if (dados.length > 0) {
        parametros = {
          key: dados[0].key,
          nomeBot: dados[0].nomeBot,
          intervalo_inicio: dados[0].intervalo_inicio,
          intervalo_fim: dados[0].intervalo_fim,
          apos: dados[0].apos,
          pausar: dados[0].pausar,
          saudacao: dados[0].saudacao,
          hora_inicio: dados[0].hora_inicio,
          hora_fim: dados[0].hora_fim,
          semana: dados[0].semana,
          sabado: dados[0].sabado,
          domingo: dados[0].domingo,
          servidor: dados[0].servidor,
          instancia: dados[0].instancia,
          token: dados[0].token,
          telefone: dados[0].telefone,
          request: dados[0].request,
          menu_automatico: dados[0].menu_automatico,
          menu_automatico_tempo: dados[0].menu_automatico_tempo,
          plataforma: dados[0].plataforma,
          path: data.path,
          nivel: data.nivel,
          nome: data.nome,
          grupo: data.grupo,
          key_grupo: data.key_grupo,
          key_login: data.key_login,
          key_usuario: data.key,
          key_pessoa: data.key_pessoa,
          key_lotacao: data.key_lotacao,
          imagem: data.imagem,
          login: data.login,
          webhookUrl: dados[0].webhookUrl,
          webhookDenuncia: dados[0].webhookDenuncia,
          webhookMensagem: dados[0].webhookMensagem,
          webhookOuvidoria: dados[0].webhookOuvidoria,
          whatsMensagem: dados[0].whatsMensagem,
          whatsDenuncia: dados[0].whatsDenuncia,
          whatsOuvidoria: dados[0].whatsOuvidoria,
          tokenFace: dados[0].tokenFace,
          userIdFace: dados[0].userIdFace,
          intervalo_live: dados[0].intervalo_live,
          apos_live: dados[0].apos_live,
          pausar_live: dados[0].pausar_live,
          saudacao_live: dados[0].saudacao_live,
          key_intervalo: dados[0].key_intervalo,
          mostraUsuario: dados[0].mostraUsuario,
          usuarioAnonimo: dados[0].usuarioAnonimo,
          licencas: dados[0].licencas,
          filaAgente: dados[0].filaAgente,
          intervalo_jornada: dados[0].intervalo_jornada,
          qtdFila: dados[0].qtdFila,
          notifica_usuario: dados[0].notifica_usuario,
          fullPath: dados[0].fullPath,
          midia: dados[0].midia,
          intervalo_msg_recebida: dados[0].intervalo_msg_recebida,
          webhookSetor: dados[0].webhookSetor,
          key_puc: dados[0].key_puc,
          nome_puc: dados[0].nome_puc,
          mostra_numero_menu: dados[0].mostra_numero_menu,
          conta_email: dados[0].conta_email,
          email_resposta: dados[0].email_resposta,
          intervalo_email: dados[0].intervalo_email,
          key_email: dados[0].key_email,
          url_send: dados[0].url_send,
          token_user: dados[0].token_user,
          key_catalogo_quantificacao: dados[0].key_catalogo_quantificacao
        }
        this.parametros = JSON.parse(JSON.stringify(parametros));
      } else {
        parametros = {
          key: null,
          path: data.path,
          nivel: data.nivel,
          nome: data.nome,
          grupo: data.grupo,
          key_grupo: data.key_grupo,
          key_login: data.key_login,
          key_usuario: data.key,
          key_pessoa: data.key_pessoa,
          imagem: data.imagem,
          login: data.login,
          key_lotacao: data.key_lotacao
        }
        this.parametros = JSON.parse(JSON.stringify(parametros));
      }

    },
      err => console.log(err)
    );
    //  this.onLoadTable();
  };
  onLoadPermissao(key) {
    if (key) {
      let unSub = this.onGetFilterOrder('login_permissao', 'nome', 'key_login', key)
        .subscribe(data => {
          //   unSub.unsubscribe()
          this.permissao = JSON.parse(JSON.stringify(data))
        });
    }
  }
  filtroPermissao(formulario) {
    return new Promise(resolve => {
      try {
        let tmp = this.permissao.filter(obj => {
          return obj.nome == formulario;
        });
        if (tmp.length > 0) {
          resolve(tmp[0]);
        } else {
          if (this.parametros.nivel = '99') {
            let permissao = {
              nome: formulario,
              per_bloqueio: false,
              per_inserir: true,
              per_alterar: true,
              per_excluir: true,
              per_imprimir: true,
              per_visualizar: true
            }
            resolve(permissao);
          } else {
            let permissao = {
              nome: formulario,
              per_bloqueio: true,
              per_inserir: false,
              per_alterar: false,
              per_excluir: false,
              per_imprimir: false,
              per_visualizar: false
            }
            resolve(permissao);
          }
        }
      } catch (error) {
        if (this.parametros.nivel = '99') {
          let permissao = {
            nome: formulario,
            per_bloqueio: false,
            per_inserir: true,
            per_alterar: true,
            per_excluir: true,
            per_imprimir: true,
            per_visualizar: true
          }
          resolve(permissao);
        } else {
          let permissao = {
            nome: formulario,
            per_bloqueio: true,
            per_inserir: false,
            per_alterar: false,
            per_excluir: false,
            per_imprimir: false,
            per_visualizar: false
          }
          resolve(permissao);
        }
      }

    })
  }
  // crud firebase
  onGetSearchKey(table, key) {
    let PATH = 'dados/' + this.parametros.path + '/' + table
    if (key) {
      var docRef = this.afs.firestore.collection(PATH).doc(key);
      let res: any = [];
      return docRef.get().then((doc) => {
        if (doc.exists) {
          res = {
            key: doc.id,
            ...doc.data() as {}
          };
          if (res.length == 1) {
            return res[0]
          } else {
            return res
          }
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        // console.log("Error getting document:", error);
      });

    } else {
      return null
    }

  }
  onGet(PATH: string, key: string) {
    return this.afs
      .collection(PATH + '/' + key)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({
          key: c.payload.doc.id, ...c.payload.doc.data() as {}
        })
        );
      });
  }
  onGetKey(PATH: string, key: string, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.doc(PATH + '/' + key).snapshotChanges().pipe(
      auditTime(1000),
      //tap(console.log),
      map(c => {
        let data = {
          key: c.payload.id,
          ...c.payload.data() as {}
        };
        if (array) {
          array.forEach(e => {
            if (data[e.key]) {
              this.onGetSearchKey(e.table, data[e.key]).then(result => {
                data[e.table] = result
              })
            }
          })
          return data
        } else {
          return data
        }
      }), delay(tempo));
  }
  onGetAllUrl(url, array?: any) {
    return this.http.get(url, this.options).map(
      dados => {
        return dados.toString();
      },
      error => {
        //  console.log('erro no retorno ', error);
        return 'URL Invalida! ' + error;
      }
    );
  }
  onGetAll2(PATH: string, order: string, array?: any) {
    return this.afs.collection(PATH, ref => ref.orderBy(order)).snapshotChanges().pipe(
      map(actions => actions.map(c => c.payload.doc.data()))
    );
  }

  onGetAll(PATH: string, order: string, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.orderBy(order)).snapshotChanges(['added', 'removed','modified'])
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          } 
        }), delay(tempo),
           shareReplay(),
      )
  }
  onGetAllreference(PATH: string, order: string, array?: any) {
    return this.afs
      .collection(PATH, ref => ref.orderBy(order)).snapshotChanges()
      .map(changes => {
        return changes.map(c => (
          {
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          }
        ));
      });
  }
  onGetfilter(PATH: string, filtro: string, valor: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs
      .collection(PATH, ref => ref.where(filtro, '==', valor)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  getFilter(PATH: string, filtro: string, valor: any) {
    return this.afs.collection(PATH, ref => ref.where(filtro, '==', valor)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          return changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
        }),
      )
  }
  onGetFilterOrderToTo(PATH: string, order: string, filtro1: string, valor1: string, operador1: any, filtro2: string, valor2: any, operador2: any, page?: any, desc?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if (desc && page) {
      let tempo = 0; if (array) { tempo = 1000 };
      return this.afs.collection(PATH, ref => ref.where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).limit(page).orderBy(order, "desc")).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (page) {
      return this.afs
        .collection(PATH, ref =>
          ref.where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).limit(page).orderBy(order)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else {
      return this.afs
        .collection(PATH, ref =>
          ref.where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).orderBy(order)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    }
  }
  onGetFilterOrderTo(PATH: string, order: string, filtro1: string, valor1: string, operador1: any, filtro2: string, valor2: any, operador2: any, page?: any, desc?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if (desc && page) {
      let tempo = 0; if (array) { tempo = 1000 };
      return this.afs.collection(PATH, ref => ref.orderBy(filtro2).where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).limit(page).orderBy(order, "desc")).snapshotChanges()
        .pipe(          
        auditTime(1000),
        //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (page) {
      return this.afs
        .collection(PATH, ref =>
          ref.orderBy(filtro2).where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).limit(page).orderBy(order)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else {
      return this.afs
        .collection(PATH, ref =>
          ref.orderBy(filtro2).where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).orderBy(order)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    }
  }
  onGetFilterOrderAll(PATH: string, order: string, filtro1: string, valor1: string, operador1: any, filtro2: string, valor2: any, operador2: any, page: any, fluxo: any) {
    if (page == 0) {
      page = 10000
    }
    return this.afs.collection(PATH, ref => ref.where(filtro2, operador2, valor2).where(filtro1, operador1, valor1).limit(page).orderBy(order, fluxo)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          return changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
        }),
      )
  }
  onGetFilterThree(PATH: string, order: string, filtro1: string, valor1: string, operador1: any, filtro2: string, valor2: string, operador2: any, filtro3: string, valor3: string, operador3: any, page: any, fluxo: any) {
    if (page == 0) {
      page = 10000
    }
    return this.afs.collection(PATH, ref => ref.orderBy(filtro3).where(filtro1, operador1, valor1).where(filtro2, operador2, valor2).where(filtro3, operador3, valor3).limit(page).orderBy(order, fluxo)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          return data
        }),
      )
  }
  onGetFilterOrder(PATH: string, order: string, filtro: string, valor: any, operador?: any, page?: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if ((operador == '!=') && (page > 0)) {
      return this.afs
        .collection(PATH, ref =>
          ref.orderBy(filtro).where(filtro, operador, valor).limit(page).orderBy(order)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (operador == '!=') {
      return this.afs
        .collection(PATH, ref =>
          ref.orderBy(filtro).where(filtro, operador, valor).orderBy(order)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (operador == '<=') {
      return this.afs
        .collection(PATH, ref => ref.orderBy(order, 'asc').orderBy(filtro).endAt(valor)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )

    } else if (operador == '>=') {
      return this.afs
        .collection(PATH, ref => ref.orderBy(filtro).startAt(valor)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (operador == '==') {
      return this.afs
        .collection(PATH, ref => ref.where(filtro, '==', valor).orderBy(order, 'asc')).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if ((!operador) && (page)) {
      return this.afs
        .collection(PATH, ref => ref.where(filtro, '==', valor).limit(page).orderBy(order, 'asc')).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (!operador) {
      return this.afs
        .collection(PATH, ref => ref.where(filtro, '==', valor).limit(page).orderBy(order, 'asc')).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    }
  }
  onGetFilterOrderPage(PATH: string, order: string, filtro: string, valor: any, operador?: any, page?: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.where(filtro, operador, valor).limit(page).orderBy(order)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetLike(PATH: string, order: string, filtro: string, valor: any, fluxo: any, page?: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    //  return this.afs.collection(PATH, ref => ref.orderBy(filtro).startAt(valor)).snapshotChanges()
    return this.afs.collection(PATH, ref => ref.where(filtro, ">=", valor).limit(page)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onSqlQuery(PATH, query, array?: any) {
    return new Promise(async (resolve) => {
      const fireSQL = new FireSQL(firebase.firestore().doc(PATH));
      const query$ = fireSQL.query(query);
      const result = await query$.then(async data => {
        let lista = data.map(res => {
          if (array) {
            array.forEach(e => {
              if (res[e.key]) {
                this.onGetSearchKey(e.table, res[e.key]).then(result => {
                  res[e.table] = result;
                });
              }
            });
            return res;
          } else {
            return data;
          }
        });
        return lista
      });
      resolve(result)
    });
  }
  onGetFilterOrderDesc(PATH: string, order: string, filtro: string, valor: any, operador?: any, page?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if ((operador == '==') && (page > 0)) {
      return this.afs.collection(PATH, ref => ref.orderBy(filtro).where(filtro, operador, valor).limit(page).orderBy(order, 'desc')).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if ((operador == '!=') && (page > 0)) {
      return this.afs
        .collection(PATH, ref => ref.orderBy(filtro).where(filtro, operador, valor).limit(page).orderBy(order, 'desc')).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (operador == '!=') {
      return this.afs
        .collection(PATH, ref => ref.orderBy(filtro).where(filtro, operador, valor).orderBy(order, 'desc')).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else if (operador == '<=') {
      return this.afs.collection(PATH, ref =>
        ref.orderBy(order, 'desc').orderBy(filtro).endAt(valor)).snapshotChanges()
        .pipe(
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )

    } else
      if (operador == '>=') {
        return this.afs
          .collection(PATH, ref => ref.orderBy(filtro).startAt(valor)).snapshotChanges()
          .pipe(
            map((changes: any) => {
              let data = changes.map((c: any) =>
              ({
                key: c.payload.doc.id,
                ...c.payload.doc.data() as {}
              })
              )
              if (array) {
                return data.map(res => {
                  array.forEach(e => {
                    if (res[e.key]) {
                      this.onGetSearchKey(e.table, res[e.key]).then(result => {
                        res[e.table] = result
                      })
                    }
                  });
                  return res
                })
              } else {
                return data
              }
            }),
            delay(tempo)
          )
      } else
        if (operador == '==') {
          return this.afs
            .collection(PATH, ref => ref.where(filtro, '==', valor).orderBy(order, 'desc')).snapshotChanges()
            .pipe(
              map((changes: any) => {
                let data = changes.map((c: any) =>
                ({
                  key: c.payload.doc.id,
                  ...c.payload.doc.data() as {}
                })
                )
                if (array) {
                  return data.map(res => {
                    array.forEach(e => {
                      if (res[e.key]) {
                        this.onGetSearchKey(e.table, res[e.key]).then(result => {
                          res[e.table] = result
                        })
                      }
                    });
                    return res
                  })
                } else {
                  return data
                }
              }),
              delay(tempo)
            )
        } else {
          return this.afs.collection(PATH, ref => ref.where(filtro, '==', valor).orderBy(order, 'desc')).snapshotChanges()
            .pipe(
              map((changes: any) => {
                let data = changes.map((c: any) =>
                ({
                  key: c.payload.doc.id,
                  ...c.payload.doc.data() as {}
                })
                )
                if (array) {
                  return data.map(res => {
                    array.forEach(e => {
                      if (res[e.key]) {
                        this.onGetSearchKey(e.table, res[e.key]).then(result => {
                          res[e.table] = result
                        })
                      }
                    });
                    return res
                  })
                } else {
                  return data
                }
              }),
              delay(tempo)
            )
        }
  }
  onGetFilterOrderPageDesc(PATH: string, order: string, filtro: string, valor: any, operador?: any, page?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.where(filtro, operador, valor).limit(page)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetArray(PATH: string, order: string, campo: string, operador: any, valor: any, sentido: any, page?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if (page) {
      return this.afs.collection(PATH, ref => ref.where(campo, operador, valor).limit(page).orderBy(order, sentido)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else {
      return this.afs
        .collection(PATH, ref => ref.where(campo, operador, valor).orderBy(order, sentido)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    }

  }
  onGetFilterArray(PATH: string, order: string, campoFiltro: string, valorFiltro: any, operadorFiltro: any, campo: string, operador: any, valor: any, sentido: any, page?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if (page) {
      return this.afs.collection(PATH, ref => ref.orderBy(campo).where(campoFiltro, operadorFiltro, valorFiltro).where(campo, operador, valor).limit(page).orderBy(order, sentido)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else {
      return this.afs
        .collection(PATH, ref => ref.where(campo, operador, valor).orderBy(order, sentido)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  if (res[e.key]) {
                    this.onGetSearchKey(e.table, res[e.key]).then(result => {
                      res[e.table] = result
                    })
                  }
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    }

  }
  onGetAllFilterPageDesc(PATH: string, order: string, filtro: string, valor: any, page: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.orderBy(order, 'desc').where(filtro, '==', valor).limit(page)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetAllFilterPage(PATH: string, filtro: string, valor: any, page: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.where(filtro, '==', valor).limit(page)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )

  }
  onGetAllPageDesc(PATH: string, order: string, page: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.orderBy(order, 'desc').limit(page)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetAllPage(PATH: string, order: string, page: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH, ref => ref.orderBy(order).limit(page)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetAllObjeto(PATH: string, key: string, objeto: string, order: string, page?: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH).doc(key).collection(objeto, ref => ref.orderBy(order).limit(page || 999)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetAllObjetoDesc(PATH: string, key: string, objeto: string, order: string, page?: number, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    return this.afs.collection(PATH).doc(key).collection(objeto, ref => ref.orderBy(order, 'desc').limit(page || 999)).snapshotChanges()
      .pipe(
        auditTime(1000),
        //tap(console.log),
        map((changes: any) => {
          let data = changes.map((c: any) =>
          ({
            key: c.payload.doc.id,
            ...c.payload.doc.data() as {}
          })
          )
          if (array) {
            return data.map(res => {
              array.forEach(e => {
                if (res[e.key]) {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                }
              });
              return res
            })
          } else {
            return data
          }
        }),
        delay(tempo)
      )
  }
  onGetPeriodo(PATH: string, filtro, inicio: any, final?: any, array?: any) {
    let tempo = 0; if (array) { tempo = 1000 };
    if (final) {
      return this.afs.collection(PATH, ref => ref.orderBy(filtro).startAt(inicio).endAt(final)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    } else {
      return this.afs.collection(PATH, ref => ref.orderBy(filtro).startAt(inicio)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            if (array) {
              return data.map(res => {
                array.forEach(e => {
                  this.onGetSearchKey(e.table, res[e.key]).then(result => {
                    res[e.table] = result
                  })
                });
                return res
              })
            } else {
              return data
            }
          }),
          delay(tempo)
        )
    }

  }
  onGetPeriodoFilter(PATH: string, order: any, filtro: any, valor: any, inicio: any, final?: any, array?: any) {
    if (final) {
      return this.afs.collection(PATH, ref => ref.orderBy(order).startAt(inicio).endAt(final).where(filtro, '==', valor)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            return data

          }),
        )
    } else {
      return this.afs.collection(PATH, ref => ref.orderBy(order).startAt(inicio).where(filtro, '==', valor)).snapshotChanges()
        .pipe(
          auditTime(1000),
          //tap(console.log),
          map((changes: any) => {
            let data = changes.map((c: any) =>
            ({
              key: c.payload.doc.id,
              ...c.payload.doc.data() as {}
            })
            )
            return data
          }),
        )
    }
  }
  onGetTableKey(table, key) {
    let res = table.filter(obj => {
      return obj.key == key
    });
    return res[0]
  }
  onGetTableCampo(table, campo, key) {
    let res = table.filter(obj => {
      return obj[campo] == key
    });
    return res[0]
  }
  onSave(PATH, parametro: any) {
    //   let data = parametro;
    let data = JSON.parse(JSON.stringify(parametro));
    delete data['key'];
    return new Promise((resolve, reject) => {
      if (parametro.key) {
        this.afs
          .collection(PATH)
          .doc(parametro.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH)
          .add(data)
          .then(res => {
            resolve(res.id); // chamando ADD gera um key automatico
          });
      }
    });
  }
  onSaveObjeto(PATH: string, key: string, objeto: string, parametro, time?: any) {
    let data = JSON.parse(JSON.stringify(parametro));
    delete data['key'];
    return new Promise((resolve, reject) => {
      if (parametro.key) {
        this.afs
          .collection(PATH)
          .doc(key)
          .collection(objeto)
          .doc(parametro.key)
          .update(data)
          .then(res => {
            resolve(parametro.key); // chamando ADD gera um key automatico
          }).catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH)
          .doc(key)
          .collection(objeto)
          .add(data)
          .then(res => {
            resolve(res.id); // chamando ADD gera um key automatico
          });
      }
    });
  }
  onSaveDoc(PATH, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(data.uid)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  onSaveDocListaTransmissao(PATH, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(data.key_campanha)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  onSaveDocPesquisa(PATH, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(data.key_pesquisa)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  onSaveDocEnquete(PATH, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(data.key_enquete)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  onSaveDocPergunta(PATH, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(data.key_pergunta)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  onSaveDocQuestionario(PATH, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(data.key_questionario)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  removeCollection(PATH, collection: any) {
    return new Promise((resolve, reject) => {
      this.afs.collection(PATH).doc(collection)
        .delete()
        .then(() => {
          resolve(null);
          //   console.log('Document successfully deleted!');
        })
        .catch(function (error) {
          reject(error);
          console.error('Error removing document: ', error);
        });
    });
  }
  searchTable(array, key) {
    return new Promise(resolve => {
      let c = 0;
      let i = 0;
      array.forEach(e => {
        this.onGetAllFilterPage(e.path, e.key, key, 1).subscribe(data => {
          i++
          let lista = JSON.parse(JSON.stringify(data));
          if (lista.length > 0) {
            c++
          }
          if (i >= array.length) {
            resolve(c);
          }
        })
      });
    });
  }
  remove(PATH, key: any, key_foreign?: any) {
    return new Promise((resolve, reject) => {
      if (key_foreign) {
        let array = [
          { key: key_foreign, path: "dados/" + this.parametros.path + "/lotacao" },
          { key: key_foreign, path: "dados/" + this.parametros.path + "/pessoa" },
          { key: key_foreign, path: "dados/" + this.parametros.path + "/ticket" },
          { key: key_foreign, path: "dados/" + this.parametros.path + "/puc" },
          { key: key_foreign, path: "dados/" + this.parametros.path + "/chat" },
          { key: key_foreign, path: "dados/" + this.parametros.path + "/ticket" },
          { key: key_foreign, path: "login" },
          { key: key_foreign, path: "grupo" },
          { key: key_foreign, path: "dados/" + this.parametros.path + "/catalogo" }
        ]
        this.searchTable(array, key).then(res=>{
          if (res > 0) {
            resolve(res);
          } else {
            this.afs
            .collection(PATH)
            .doc(key)
            .delete()
            .then(() => {
              resolve(null);
            })
            .catch(function (error) {
              reject(error);
              console.error('Error removing document: ', error);
            });
          }
        })
      } else {
        this.afs
          .collection(PATH)
          .doc(key)
          .delete()
          .then(() => {
            resolve(null);
          })
          .catch(function (error) {
            reject(error);
            console.error('Error removing document: ', error);
          });
      }
    });
  }
  removeObjeto(PATH: string, key: any, objeto: string, id: string) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(PATH)
        .doc(key)
        .collection(objeto)
        .doc(id)
        .delete()
        .then(() => {
          resolve(null);
          // console.log('Document successfully deleted!');
        })
        .catch(function (error) {
          reject(error);
          console.error('Error removing document: ', error);
        });
    });
  }
  removeFile(PATH, key: string, fullPath: string) {
    //  console.log('remover ' + key);
    return this.afs
      .collection(PATH)
      .doc(key)
      .delete()
      .then(() => {
        this.removeOnFile(fullPath);
      });
  }
  removeOnFile(fullPath: string) {
    //  console.log('remover foto ' + fullPath);
    if (fullPath != null || fullPath) {
      let storageRef = this.fb.storage().ref();
      storageRef.child(fullPath).delete();
    }
  }
  statusUsuario() {
    return this.auth.authState.subscribe(user => {
      //      return (this.usuario = user);
    });
  }
  onSaveDocKey(PATH, key: string, data: any) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        this.afs
          .collection(PATH + '/')
          .doc(key)
          .set(data)
          .then(() => resolve(null));
      }
    });
  }
  onAtualizaContador(PATH, key) {
    const sfDocRef = this.afs.firestore.collection(PATH).doc(key);
    return this.afs.firestore.runTransaction(transaction =>
      transaction.get(sfDocRef)
        .then(sfDoc => {
          let newcontador = 0;
          if (sfDoc.data().contador) {
            newcontador = sfDoc.data().contador + 1;
          } else {
            newcontador = 1;
          }
          return transaction.update(sfDocRef, { contador: newcontador });
        }))
  }
  onUpdateMsgEnviada(PATH, key) {
    const sfDocRef = this.afs.firestore.collection(PATH).doc(key);
    return this.afs.firestore.runTransaction(transaction =>
      transaction.get(sfDocRef)
        .then(sfDoc => {
          let newcontador = 0;
          if (sfDoc.data().msgEnviada) {
            newcontador = sfDoc.data().msgEnviada + 1;
          } else {
            newcontador = 1;
          }
          return transaction.update(sfDocRef, { msgEnviada: newcontador });
        }))
  }
  onUpdateVoto(PATH, key) {
    const sfDocRef = this.afs.firestore.collection(PATH).doc(key);
    return this.afs.firestore.runTransaction(transaction =>
      transaction.get(sfDocRef)
        .then(sfDoc => {
          let newcontador = 0;
          if (sfDoc.data().votos) {
            newcontador = sfDoc.data().votos + 1;
          } else {
            newcontador = 1;
          }
          return transaction.update(sfDocRef, { votos: newcontador });
        }))
  }
  onUpdateMsgEntregue(PATH, key) {
    const sfDocRef = this.afs.firestore.collection(PATH).doc(key);
    return this.afs.firestore.runTransaction(transaction =>
      transaction.get(sfDocRef)
        .then(sfDoc => {
          let newcontador = 0;
          if (sfDoc.data().msgEntregue) {
            newcontador = sfDoc.data().msgEntregue + 1;
          } else {
            newcontador = 1;
          }
          return transaction.update(sfDocRef, { msgEntregue: newcontador });
        }))
  }
  onUpdateMsgFalha(PATH, key) {
    const sfDocRef = this.afs.firestore.collection(PATH).doc(key);
    return this.afs.firestore.runTransaction(transaction =>
      transaction.get(sfDocRef)
        .then(sfDoc => {
          let newcontador = 0;
          if (sfDoc.data().msgFalha) {
            newcontador = sfDoc.data().msgFalha + 1;
          } else {
            newcontador = 1;
          }
          return transaction.update(sfDocRef, { msgFalha: newcontador });
        }))
  }
  onIncrementKey(PATH, TABLE) {
    return new Promise(async (resolve, reject) => {
      const sfDocRef = this.afs.firestore.collection(PATH + '/incrementKey').doc(TABLE);
      try {
        const result = await this.afs.firestore.runTransaction(t => {
          return t.get(sfDocRef).then((doc: any) => {
            if (doc.data()) {
              let newKey = doc.data().countKey;
              newKey++;
              let dados = {
                countKey: newKey
              };
              t.update(sfDocRef, dados);
              resolve(dados.countKey.toString());
            }
            else {
              let dados_1 = {
                countKey: 1
              };
              t.set(sfDocRef, dados_1);
              resolve(dados_1.countKey.toString());
            }
          });
        });
        //  console.log('Transaction sucesso', result);
      }
      catch (err) {
        //  console.log('Transaction falha:', err);
      }
    });
  };
  onIncrementKeyDate(PATH, table: string) {
    return new Promise(async (resolve, reject) => {
      const sfDocRef = this.afs.firestore.collection(PATH + '/incrementKey').doc(table);
      try {
        const result = await this.afs.firestore.runTransaction(t => {
          return t.get(sfDocRef).then((doc: any) => {
            if (doc.data()) {
              let newKey = doc.data().countKey;
              newKey++;
              let dados = {
                countKey: newKey
              };
              t.update(sfDocRef, dados);
              let key = new DatePipe('pt-BR').transform(Date.now(), 'yyyyMMdd') + '.' + this.parametros.key_usuario + '.' + dados.countKey.toString()
              resolve(key);
            }
            else {
              let dados_1 = {
                countKey: 1
              };
              t.set(sfDocRef, dados_1);
              resolve(dados_1.countKey.toString());
            }
          });
        });
        //  console.log('Transaction sucesso', result);
      }
      catch (err) {
        //  console.log('Transaction falha:', err);
      }
    });
  };
  onIncrementKeyZeroDate(PATH, table: string, length: number) {
    return new Promise(async (resolve, reject) => {
      const sfDocRef = this.afs.firestore.collection(PATH + '/incrementKey').doc(table);
      try {
        const result = await this.afs.firestore.runTransaction(t => {
          return t.get(sfDocRef).then((doc: any) => {
            if (doc.data()) {
              let newKey = doc.data().countKey;
              newKey++;
              if (newKey == 1000000) {
                newKey = 1
              }
              let dados = {
                countKey: newKey
              };
              t.update(sfDocRef, dados);
              this.pad(dados.countKey.toString(), length).then(key => {
                let keyValue = new DatePipe('pt-BR').transform(Date.now(), 'yyyyMMdd') + '.' + this.parametros.key_usuario + '.' + key
                resolve(keyValue);
              })
            }
            else {
              let dados_1 = {
                countKey: 1
              };
              t.set(sfDocRef, dados_1);
              resolve(dados_1.countKey.toString());
            }
          });
        });
        //  console.log('Transaction sucesso', result);
      }
      catch (err) {
        //  console.log('Transaction falha:', err);
      }
    });
  };
  onIncrementKeyZero(PATH, table: string, length: number) {
    return new Promise(async (resolve, reject) => {
      const sfDocRef = this.afs.firestore.collection(PATH + '/incrementKey').doc(table);
      try {
        const result = await this.afs.firestore.runTransaction(t => {
          return t.get(sfDocRef).then((doc: any) => {
            if (doc.data()) {
              let newKey = doc.data().countKey;
              newKey++;
              if (newKey == 1000000) {
                newKey = 1
              }
              let dados = {
                countKey: newKey
              };
              t.update(sfDocRef, dados);
              this.pad(dados.countKey.toString(), length).then(key => {
                resolve(key);
              })
            }
            else {
              let dados_1 = {
                countKey: 1
              };
              t.set(sfDocRef, dados_1);
              this.pad(dados_1.countKey.toString(), length).then(key => {
                resolve(key);
              })
            }
          });
        });
        //  console.log('Transaction sucesso', result);
      }
      catch (err) {
        //  console.log('Transaction falha:', err);
      }
    });
  };

  onSaveIncrement(PATH, data: any, table: string) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        delete data["key"];
        this.onIncrementKey('dados/' + this.parametros.path, table).then((keyValue: any) => {
          this.afs.collection(PATH + '/').doc(keyValue).set(data).then(() => resolve(keyValue));
        });
      }
    });
  }
  onSaveIncrementDate(PATH, data: any, table: string) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        delete data["key"];
        this.onIncrementKey('dados/' + this.parametros.path, table).then((keyValue: any) => {
          this.afs.collection(PATH + '/').doc(keyValue).set(data).then(() => resolve(keyValue));
        });
      }
    });
  }
  onSaveIncrementZero(PATH, data: any, table: string, length: number) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        delete data["key"];
        this.onIncrementKeyZero('dados/' + this.parametros.path, table, length).then((keyValue: any) => {
          this.afs.collection(PATH + '/').doc(keyValue).set(data).then(() => resolve(keyValue));
        });
      }
    });
  }
  onSaveIncrementZeroRoot(PATH, data: any, table: string, length: number) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        delete data["key"];
        this.onIncrementKeyZero('', table, length).then((keyValue: any) => {
          this.afs.collection(PATH + '/').doc(keyValue).set(data).then(() => resolve(keyValue));
        });
      }
    });
  }
  onSaveIncrementZeroDate(PATH, data: any, table: string, length) {
    return new Promise((resolve, reject) => {
      if (data.key) {
        this.afs
          .collection(PATH + '/')
          .doc(data.key)
          .update(data)
          .then(() => resolve(null))
          .catch(e => reject(e));
      } else {
        delete data["key"];
        this.onIncrementKeyZeroDate('dados/' + this.parametros.path, table, length).then((key: any) => {
          this.afs.collection(PATH + '/').doc(key).set(data).then(() => resolve(key));
        });
      }
    });
  }
  onIncrementKeyDateTime() {
    return new Promise(async (resolve) => {
      let key = new DatePipe('pt-BR').transform(Date.now(), 'yyyyMMdd') + '.' + this.parametros.key_usuario + '.' + new DatePipe('pt-BR').transform(Date.now(), 'HHmmssSSS')
      resolve(key);
    });
  };

  pad(str, length) {
    return new Promise(async (resolve, reject) => {
      var adicionar = length - str.length;
      for (var i = 0; i < adicionar; i++) str = '0' + str;
      resolve(str.slice(0, length));
    });
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  onSearchArray(table: any, key_obj: any, key_Search: any) {
    let res = table.filter(obj => {
      return obj[key_obj] == key_Search
    });
    if (res.length > 0) {
      return res[0]
    } else {
      return null
    }
  }
}

/* paginacao com firebase
var first = db.collection("cities")
        .orderBy("population")
        .limit(25);

return first.get().then((documentSnapshots) => {
  // Get the last visible document
  var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
  console.log("last", lastVisible);

  // Construct a new query starting at this document,
  // get the next 25 cities.
  var next = db.collection("cities")
          .orderBy("population")
          .startAfter(lastVisible)
          .limit(25);
});

            it("should handle multiple orderBy", () => {
                // [START start_multiple_orderby]
                // Will return all Springfields
                db.collection("cities")
                   .orderBy("name")
                   .orderBy("state")
                   .startAt("Springfield");

                // Will return "Springfield, Missouri" and "Springfield, Wisconsin"
                db.collection("cities")
                   .orderBy("name")
                   .orderBy("state")
                   .startAt("Springfield", "Missouri");
                // [END start_multiple_orderby]
            });

            it("shoud paginate", () => {
              // [START paginate]
              var first = db.collection("cities")
                      .orderBy("population")
                      .limit(25);

              return first.get().then((documentSnapshots) => {
                // Get the last visible document
                var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
                console.log("last", lastVisible);

                // Construct a new query starting at this document,
                // get the next 25 cities.
                var next = db.collection("cities")
                        .orderBy("population")
                        .startAfter(lastVisible)
                        .limit(25);
              });
              // [END paginate]
            });
        });


*/
