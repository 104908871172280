/* tslint:disable */
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { emojis } from '../_emojis';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ISubscription } from 'rxjs/Subscription';
import * as moment from 'moment'
import { GenProvider } from 'src/providers/gen/gen';
import { ServiceProvider } from 'src/providers/service/service';
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { CamelcasePipe } from 'src/pipes/camelcase/camelcase';
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage";
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import { Editor } from 'primeng/editor';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Table } from 'primeng/table';
import { Subject, Subscription } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { FileUpload } from 'primeng/fileupload';

declare var google;
@Component({
    selector: 'app-call',
    templateUrl: './call.component.html',
    styleUrls: ['./call.component.scss'],
    providers: [ConfirmationService, MessageService, AngularFirestore]
})
export class CallComponent implements OnInit, OnDestroy {
    @ViewChild('scroll') objDiv: ElementRef;
    @ViewChild('scrollAssistente') objDivAssistente: ElementRef;
    @ViewChild('scrollroom') objDivroom: ElementRef;
    @ViewChild('idformSend') idformSend: ElementRef;
    @ViewChild('idformSendRoom') idformSendRoom: ElementRef;
    @ViewChild('audio') audio: ElementRef;
    @ViewChild('txtConteudo') textoEditor: Editor;
    @ViewChild('dtCatalogo') dtCatalogo: Table;
    @ViewChild('dtListaCatalogoItem') dtListaCatalogoItem: Table;
    @ViewChild('dtTickets') dtTickets: Table;
    @ViewChild('dtPessoa') dtPessoa: Table;
    @ViewChild('fileUpload') fileUpload: FileUpload;

    isLogoStart = "../../../assets/images/logon.png";
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    progress: Observable<number>;
    qtdPage: any;
    usuario: any;
    lottieConfig: any;
    elementType: 'url' | 'canvas' | 'img' = 'url';
    value: string = 'www.portalexata.com.br';
    formulario: FormGroup;
    lista: any;
    dados: any = [];
    filtro: string;
    formSend: any;
    mostraFrm: boolean = false;
    titulo: any;
    mostraMsg = false;
    textMsg = '';
    tituloMsg = '';
    dados_msg: any;
    uid: any;
    friend: any;
    assinarResp: any = 1;
    chat_msg: any;
    perfilUsuario: any;
    foto: string;
    path: any;
    parametros: any;
    frmActive: any;
    acesso: {};
    natureza: any;
    status: any;
    dialog: any;
    getChatUpdate: any;
    ChatUpdate: any;
    start: number = 0;
    id_canal: any;
    image: any;
    emojis: any;
    emoji: any;
    people: any;
    nature: any;
    objects: any;
    places: any;
    symbols: any;
    lat: any;
    lng: any;
    localizacao: string;
    frm_Resposta: FormGroup;
    filtroRapida: any;
    rapida: any;
    myEmoji = false;
    frmDetalhe: any;
    frmNatureza: any;
    frmLocalizacao: any;
    ChatDenuncia: any;
    frmMensagem = "Atendimento";
    mostraUsuario: boolean = true;
    tabela: any;
    tabela_body: any;
    data_hora: any;
    colorDiv: any;
    myChat: any;
    imageSrc: any;
    fileSrc: any;
    formulario_file: FormGroup;
    downloadURL: any;
    formulario_ticket: FormGroup;
    type_anexo: any;
    starLoad: ISubscription;
    chatDD: any;
    isChatDD: boolean;
    key_natureza: any;
    detalhe: any;
    isStart: any;
    rapidaAll: any;
    formulario_ponto_intervalo: FormGroup;
    formulario_ponto: FormGroup;
    itens: MenuItem[];
    activeItem: MenuItem;
    activeItemChat: MenuItem;
    page: any;
    formSendRoom: any;
    chat_msg_room: any[];
    ponto: any;
    key_usuario: any;
    timer: any;
    intervalos: any;
    pontoIntervalo: any;
    timerIntervalo: any;
    totalTempo: any;
    timerHora: any;
    chatLiveOn: any;
    form_teste: FormGroup;
    tempo_ocioso: any;
    pontoAllIntervalo: any;
    chat: any;
    idUser: any;
    myValueSub: any;
    formularioAtendimento: any;
    timerAtendimento: any;
    tempoAtendimento: any;
    historico: any;
    vtr: any;
    origem: any;
    email: any;
    formulario_pessoa: FormGroup;
    cliente: any;
    id: any;
    unidade: any;
    chatLive: any;
    status_voz: any;
    macro: any;
    tipo_msg: any;
    atendeChat: boolean = false;
    resposta: boolean = false;
    intervalo: boolean = false;
    pontoAgente: boolean = false;
    atendeVoz: boolean = false;
    microfone: boolean = false;
    anexarArquivo: boolean = false;
    modal: boolean = true;
    textoMessage: string;
    displayMessage: boolean = false;
    agenteStatus: any;
    agenteLetra: any;
    snapshot: any;
    ticket: boolean = false;
    itens_encerar: any[] = [];
    itensInteracao: any[] = [];
    criar: any[] = [];
    tipoTicket: any[] = [];
    tipoPrioridade: any[] = [];
    solicitante: any;
    lotacao: any[] = []; //SelectItemGroup[];
    timeDisplay: string;
    conteudo: any;
    lat_lng: { center: { lat: any; lng: any; }; zoom: number; };
    tabTicket: any[] = [];
    fila: any[] = [];
    encerra_atendimento: boolean;
    msgResp: any = 0;
    msgPendente: any = 0;
    msgAtendimento: any = 0;
    msgDistribuido: any = 0;
    msgDevolvido: any = 0;
    protocoloAtendimento: any;
    mostraProtocolo: boolean;
    iddenuncia: any;
    mostraMidia: boolean;
    midia: { type: any; file: any; lat: any; lng: any; };
    chatFila: any;
    ticketCiop!: String;
    msgCol: any;
    isLogados: any;
    isLogado: any;
    pontoProtocolo: any;
    isStartLoad: any;
    qtdFila: number;
    timeCheck: any;
    msgPendenteData: any;
    nomeUsuario: any;
    tipoOrigem: { nome: string; }[];
    inicio: any;
    final: any;
    denuncias: any;
    protocolo: string;
    mostraMgsAvaliada: boolean;
    chat_msg_consulta: any;
    ticketFinalizar: boolean = false;
    origemTicket: { nome: string; }[];
    categoria: any;
    subCategoria: any;
    puc: any;
    endPoint: string;
    endPoint_body: string;
    telefone: any;
    inicio_atendimento: number;
    subcatalogo: any;
    catalogo: any = [];
    sla: any;
    tabMenuitens: { id: string; label: string; icon: string; }[];
    file: number;
    tramitarTicket: boolean;
    tramitarTicketSub: boolean;
    transferirPuc: boolean;
    transferirAgente: boolean;
    agente: any;
    lotacaoAll: any;
    key_lotacao: any;
    key_central: any;
    key_pessoa: any;
    voip: boolean;
    call_number: any;
    myVoip: any;
    itemCatalogo: any;
    subItemCatalogo: any;
    ticketVoip: any;
    itens_encerar_voip: any = [];
    numeroTicket: any;
    subTicket: boolean;
    itens_subticket: ({ label: string; icon: string; command: () => void; separator?: undefined; } | { separator: boolean; label?: undefined; icon?: undefined; command?: undefined; })[];
    tramitarSubTicket: boolean;
    nome_central: any;
    tramitarTicketVoip: boolean;
    nome_lotacao: any;
    msgTramitado: any;
    histor: { data_criacao: number; data_hora: string; key: string; key_lotacao: string; key_solicitante: string; key_usuario: string; nome_lotacao: string; nome_solicitante: string; nome_usuario: string; protocolo: string; status: string; ticket: string; time: number; tipo: string; type: string; value: string; }[];
    mostraCatalogo: boolean;
    cols: { header: string; field: string; }[];
    mostraListaCatalogo: boolean;
    mostraCatalogoItem: boolean;
    cols_Item: { header: string; field: string; }[];
    key_catalago: any;
    listaItemSub: any;
    mostraCatalogoItemSubItem: boolean;
    tabMenuitensCatalogo: { id: string; label: string; icon: string; }[];
    dados_item_catalogo: any;
    buscaCatalogo: any = [];
    protoclo: any;
    myBtnVoip: any;
    formulario_cadastro_pessoa: FormGroup;
    cadPessoa: boolean;
    tipo_pessoa: { nome: string; }[];
    cargo: any;
    idTicket: any;
    dtInicio: any;
    dtFinal: any;
    tickets: any;
    cols_ticket: any = [];
    mostraTicket: boolean;
    nome_responsavel: string;
    formulario_consulta_ticket: FormGroup;
    ticket_body: any = [];
    formulario_pessoa_consulta: FormGroup;
    usuarios: any;
    canal: any;
    lockSender: boolean;
    formulario_subTicket: FormGroup;
    mostraConsulta: any;
    loading: any;
    cities: any;
    selectedCity: any;
    mostraConsultaTicket: any;
    unSub$ = new Subject();
    unSubMsg$: Subscription;
    unSubLoad$: Subscription;
    unSubFila$: Subscription;
    tabMenuitensVoip: { id: string; label: string; icon: string; }[];
    mostraMacro: boolean;
    buscaPessoa: any;
    pesquisaPessoa: any;
    permissao: any;
    encerrarAtendimento: any;
    atendimento: any;
    encerrarAtendimentoVoip: boolean;
    colsPessoa: { header: string; field: string; }[];
    busca: any;
    listaPessoa: any;
    notificacaoSonora: boolean = true;
    historico_ticket: any = [];
    tipo_finalizacao: any = [];
    uploadedFiles: any[] = [];
    usuarioAnonimo: any;
    qtd_usuario_online: any = [];
    formulario_geolocalizacao: FormGroup;
    chat_assistente: any = [];
    isAssistente: any = false;
    cont_assistente: number = 0;
    validacao: any;
    acao: any;
    titulo_pergunta: any;
    msg_template: any = [];
    list: boolean;
    list_rows: any = [];
    location: boolean;
    assistenteAtendimento: any;
    geoLocalizacao: any;
    plataforma: any;
    type: any;
    midias_chat: any = [];
    formulario_dinamico: FormGroup;
    template: any = [];
    key_template: any;
    tabItensChat: { id: string; label: string; icon: string; }[];
    pageChat: any;
    chatPendente: any[];
    chatDistribuido: any[];
    friendLetra: any;

    constructor(
        private formBuilder: FormBuilder,
        private genProvider: GenProvider,
        private router: Router,
        private service: ServiceProvider,
        private validaCampoProvider: ValidaCampoProvider,
        private messageService: MessageService,
        public storage: AngularFirestore,
        private afStorage: AngularFireStorage,
        private confirmationService: ConfirmationService,
        public http: HttpClient

    ) {
        this.formSend = '';
        this.call_number = '';
        this.myVoip = false;
        this.midia = {
            type: '',
            file: '',
            lat: 0,
            lng: 0
        }

        this.emojis = emojis;
        this.parametros = this.genProvider.parametros;
        this.path = this.parametros.path;
        this.usuario = this.parametros.nome;
        this.nomeUsuario = this.parametros.nome.split(' ').slice(0, 1).join(' ');
        this.key_usuario = this.parametros.key_usuario;
        this.key_pessoa = this.parametros.key_pessoa;
        this.qtdFila = this.parametros.qtdFila;
        this.msgCol = 1;
        this.agenteLetra = this.parametros.nome.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').substring(0, 1);
        this.myChat = false;
        this.usuarioAnonimo = true
        this.formulario = this.formBuilder.group({
            key: '',
            protocolo: '',
            ticket: '',
            nome: '',
            fromMe: false,
            id_canal: '',
            value: '',
            data_hora: '',
            type: '',
            time: '',
            usuario: '',
            key_usuario: '',
            key_agente:'',
            key_solicitante:'',
            menu: '',
            status: '',
            acesso: '',
            bloqueado: '',
            coordenadas: '',
            data_nascimento: '',
            invalida: '',
            localizacao: '',
            nivel: '',
            tipo: '',
            key_pergunta: '',
            key_master: '',
            key_child: '',
            uid: '',
            opcao: '',
            origem: '',
            enviar_resposta_solicitante_email: '',
            email: null,
            lat: '',
            lng: '',
            cep: '',
            rua: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            local: '',
            busca: '',
            telefone: ''

        });
        this.formulario_ticket = this.formBuilder.group({
            key: '',
            ticket: '',
            key_puc: [null, Validators.required],
            key_lotacao: '',
            key_catalogo: [null, Validators.required],
            key_item: [null, Validators.required],
            key_subitem: [null, Validators.required],
            key_usuario: [null, Validators.required],
            key_responsavel: '',
            key_solicitante: [null, Validators.required],
            nome_solicitante: '',
            key_agente:'',
            key_solicitante_lotacao: '',
            ticketPrincipal: null,
            protocolo: null,
            canal: [null, Validators.required],
            id_canal: [null, Validators.required],
            status: [null, Validators.required],
            status_ticket: [null, Validators.required],
            tipo_ticket: [null, Validators.required],
            email: null,
            prioridade: [null, Validators.required],
            prazo_resolucao: '',
            prazo_resolucaoM: '',
            titulo_ticket: [null, Validators.required],
            conteudo: [null, Validators.required],
            conteudoTexto: '',
            conteudoInterno: '',
            enviar_resposta_solicitante: [null, Validators.required],
            ultima_interacao: '',
            qtd_interacao: '',
            data_criacao: '',
            data_hora: '',
            time: '',
            encerrado: '',
            fim: '',
            tempo: '',
            tramitacao: '0',
            enviar_resposta_solicitante_email: '',
            key_encerrarAtendimento: '',
            integrado: '0',
            lat: '',
            lng: '',
            cep: '',
            rua: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            local: '',
            localizacao: '',
            anexarConversa: false,
            busca: '',
            status_integracao: null,
            telefone: ''

        });
        this.formulario_subTicket = this.formBuilder.group({
            key: '',
            ticket: '',
            key_puc: [null, Validators.required],
            key_lotacao: '',
            key_catalogo: [null, Validators.required],
            key_item: [null, Validators.required],
            key_subitem: [null, Validators.required],
            key_usuario: [null, Validators.required],
            key_responsavel: '',
            key_solicitante: [null, Validators.required],
            nome_solicitante: '',
            key_solicitante_lotacao: '',
            key_agente:'',
            ticketPrincipal: null,
            protocolo: null,
            canal: [null, Validators.required],
            id_canal: [null, Validators.required],
            status: [null, Validators.required],
            status_ticket: [null, Validators.required],
            tipo_ticket: [null, Validators.required],
            email: null,
            prioridade: [null, Validators.required],
            prazo_resolucao: '',
            prazo_resolucaoM: '',
            titulo_ticket: [null, Validators.required],
            conteudo: [null, Validators.required],
            conteudoTexto: '',
            conteudoInterno: '',
            enviar_resposta_solicitante: [null, Validators.required],
            ultima_interacao: '',
            qtd_interacao: '',
            data_criacao: '',
            data_hora: '',
            time: '',
            encerrado: '',
            fim: '',
            tempo: '',
            tramitacao: '0',
            enviar_resposta_solicitante_email: '',
            key_encerrarAtendimento: '',
            integrado: '0',
            busca: '',
            status_integracao: null

        });
        this.frm_Resposta = this.formBuilder.group({
            key: '',
            titulo: [null, Validators.required],
            mensagem: [null, Validators.required],
            data_hora: ''

        })
        this.formulario_file = this.formBuilder.group({
            fullPath: "",
            fullName: '',
            type: "",
            file: '',
            type_anexo: '',
            target: ''
        });
        this.formulario_pessoa = this.formBuilder.group({
            key: null,
            nome: [null, Validators.required],
            tipo: "Externo",
            telefone: '',
            enviar_resposta_solicitante_email: true,
            status: "Ativo",
            email: '',
            origem: '',
            key_lotacao: '',
            nome_lotacao: '',
            key_cargo: '',
            nome_cargo: '',
            matricula: '',
            ramal: '',
            facebook: '',
            instagram: ''
        });
        this.formulario_pessoa_consulta = this.formBuilder.group({
            key: null,
            nome: [null, Validators.required],
            tipo: "Externo",
            telefone: [null, Validators.required],
            status: "Ativo",
            email: '',
            origem: '',
            key_lotacao: '',
            nome_lotacao: '',
            key_cargo: '',
            nome_cargo: '',
            matricula: '',
            ramal: '',
            facebook: '',
            instagram: ''
        });
        this.formulario_ponto = this.formBuilder.group({
            key: '',
            entrada: '',
            saida: '',
            entrada_intervalo: '',
            saida_intervalo: '',
            total: '',
            dia: '',
            time: '',
            key_usuario: '',
            nome: "",
            status: "",
            atendimento_status: '',
            canal: '',
            atendimento_time: '',
            isEntrada: true,
            isSaidaIntervalo: false,
            isEntradaIntervalo: false,
            isSaida: false,
            id: ''
        });
        this.formulario_ponto_intervalo = this.formBuilder.group({
            key: '',
            key_ponto: '',
            key_intervalo: [null, Validators.required],
            key_usuario: '',
            intervalo: [null, Validators.required],
            entrada: '',
            saida: '',
            total: '',
            dia: '',
            time: '',
            isEntrada: true,
            isSaida: false,
            status: '',
            nome: ''
        });
        this.formulario_cadastro_pessoa = this.formBuilder.group({
            key: null,
            nome: [null, Validators.required],
            tipo: [null, Validators.required],
            telefone: [null, Validators.required],
            status: [null, Validators.required],
            email: '',
            origem: '',
            key_lotacao: '',
            key_cargo: '',
            matricula: '',
            ramal: '',
            facebook: '',
            instagram: ''
        });
        this.formulario_consulta_ticket = this.formBuilder.group({
            key: '',
            key_puc: [null, Validators.required],
            key_lotacao: '',
            key_catalogo: [null, Validators.required],
            key_item: [null, Validators.required],
            key_subitem: [null, Validators.required],
            key_usuario: [null, Validators.required],
            key_responsavel: '',
            key_solicitante: [null, Validators.required],
            nome_solicitante: '',
            key_solicitante_lotacao: '',
            ticketPrincipal: null,
            ticket: null,
            protocolo: null,
            canal: [null, Validators.required],
            id_canal: [null, Validators.required],
            status: [null, Validators.required],
            status_ticket: [null, Validators.required],
            tipo_ticket: [null, Validators.required],
            prioridade: "",
            prazo_resolucao: '',
            prazo_resolucaoM: '',
            titulo_ticket: [null, Validators.required],
            conteudo: [null, Validators.required],
            conteudoTexto: '',
            conteudoInterno: '',
            enviar_resposta_solicitante: [null, Validators.required],
            ultima_interacao: '',
            qtd_interacao: '',
            data_criacao: '',
            data_hora: '',
            time: '',
            inicio: '',
            encerrado: '',
            fim: '',
            tempo: '',
            nome_puc: '',
            nome_catalogo: '',
            nome_subcatalogo: '',
            nome_responsavel: '',
            nome_usuario: '',
            tramitacao: ''

        });
        this.formulario_dinamico = this.formBuilder.group({

        });

    }
    onCombos() {
        this.itens_encerar = [
            {
                label: 'Tramitar Ticket', icon: 'pi pi-sitemap', command: () => {
                    this.formulario_ticket.patchValue({
                        key_puc: this.key_central,
                        key_lotacao: this.key_lotacao,
                        key_agente: this.key_usuario,
                        key_solicitante: this.formulario.value.key_solicitente || null,
                        id_canal: this.formulario.value.id_canal,
                        status_ticket: 'Novo'
                    })
                    this.tramitarTicket = true;
                    /*
                    if (!this.formulario_ticket.valid) {
                        // console.log(this.formulario_ticket.value)
                        this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
                    } else {
                        this.formulario_ticket.patchValue({
                            key_lotacao: null
                        })
                        this.tramitarTicket = true;
                    }
                    */
                }
            },
            {
                label: 'Transferir p/ PUC', icon: 'pi pi-desktop', command: () => {
                    this.transferirPuc = true;
                }
            },
            //   {
            //     label: 'Transferir p/ agente', icon: 'pi pi-user', command: () => {
            //       this.transferirAgente = true;
            //     }
            //   },
            {
                label: 'Criar Sub-Ticket', icon: 'pi pi-copy', command: () => {
                    this.onSubTicket()
                }
            },
            { separator: true },
            {
                label: 'Engano', icon: 'fas fa-comment-slash', command: () => {
                    this.enganoTrote('Engano')
                }
            },
            { separator: true },
            /*
            {
              label: 'Imprimir', icon: 'pi pi-print', command: () => {
                //   this.onFechar();
              }
            }
            */
        ];
        this.itens_subticket = [
            {
                label: 'Tramitar Ticket', icon: 'pi pi-sitemap', command: () => {
                    this.tramitarSubTicket = true;
                }
            }
        ];
        this.itens_encerar_voip = [
            {
                label: 'Tramitar Ticket', icon: 'pi pi-sitemap', command: () => {
                    if (!this.formulario_ticket.valid) {
                        this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
                    } else {
                        this.formulario_ticket.patchValue({
                            key_lotacao: null
                        })
                        this.tramitarTicketVoip = true;
                    }
                }
            },
            { separator: true },
            {
                label: 'Trote', icon: 'fas fa-phone-slash', command: () => {
                    this.encerraStatus('Trote')
                }
            },
            {
                label: 'Engano', icon: 'fas fa-phone-slash', command: () => {
                    this.encerraStatus('Engano')
                }
            },
            {
                label: 'Ligação Encerrada', icon: 'fas fa-phone-slash', command: () => {
                    this.encerraStatus('Ligação Encerrada')
                }
            },
            { separator: true },

            {
                label: 'Cancelar', icon: 'pi pi-replay', command: () => {
                    this.encerraStatus('Cancelar')
                }
            }

        ];
        this.tabItensChat = [
            { id: '1', label: 'Em fila', icon: 'pi pi-fw pi-list' },
            { id: '2', label: 'Pendentes', icon: 'pi pi-fw pi-question' },
        ];
        this.tabMenuitens = [
            { id: '1', label: 'Relato*', icon: 'pi pi-fw pi-pencil' },
            { id: '2', label: 'Observação interna', icon: 'pi pi-fw pi-question' },
            { id: '3', label: 'Histórico', icon: 'pi pi-fw pi-list' },
            { id: '4', label: 'Mídias', icon: 'pi pi-fw pi-paperclip' },
        ];
        this.tabMenuitensVoip = [
            { id: '1', label: 'Relato*', icon: 'pi pi-fw pi-pencil' },
            { id: '2', label: 'Observação interna', icon: 'pi pi-fw pi-question' },

        ];
        if (this.geoLocalizacao) {
            this.tabMenuitens.push(
                { id: '5', label: 'Geolocalização', icon: 'pi pi-fw pi-map' }
            )
            this.tabMenuitensVoip.push(
                { id: '3', label: 'Geolocalização', icon: 'pi pi-fw pi-map' }
            );
        }
        this.tabTicket = [
            { id: '1', label: 'Histórico', icon: 'pi pi-fw pi-comments' }
        ];
        this.tipoTicket = [
            { nome: 'Evento' },
            { nome: 'Incidente' },
            { nome: 'Requisição' }
        ];
        this.tipoPrioridade = [
            { nome: 'Nenhuma' },
            { nome: 'Baixa' },
            { nome: 'Média' },
            { nome: 'Alta' },
            { nome: 'Urgente' }
        ];

        this.itens = [
            { label: '', icon: 'fas fa-user', id: '1' },
            { label: '', icon: 'fas fa-comments', id: '2' },
            { label: '', icon: 'pi pi-clock', id: '3' },
            { label: '', icon: 'fas fa-list', id: '4' },
        ];
        this.page = 1
        this.tipo_msg = [
            { nome: 'Atendimento' },
            { nome: 'Geral' },
            { nome: 'Novo' },
            { nome: 'Respondidas' },
            { nome: 'Pendente' },
            { nome: 'Na Fila' },
            { nome: 'Devolvido' }
        ];
        this.tipo_pessoa = [
            { nome: 'Interno' },
            { nome: 'Externo' }
        ]
        this.tipo_finalizacao = [
            { nome: 'Resolvido' },
            { nome: 'Não Resolvido' },
            { nome: 'Indeferido' },
            { nome: 'Cancelado' }
        ]
        this.people = this.emojis[0]["emojis"]
        this.nature = this.emojis[1]["emojis"]
        this.objects = this.emojis[2]["emojis"]
        this.places = this.emojis[3]["emojis"]
        this.symbols = this.emojis[4]["emojis"]
        this.cols_ticket = [
            { header: '#Ticket', field: 'codigo', width: '20%' },
            { header: 'Ticket Principal', field: 'ticketPrincipal', width: '15%' },
            { header: 'Agente', field: 'agente', width: '10%' },
            { header: 'Criação', field: 'data_criacaoM', width: '10%' },
            { header: 'Status', field: 'status_ticket', width: '10%' },
            { header: 'Título', field: 'titulo_ticket', width: '19%' },
            //  { header: 'Id Solicitane', field: 'id_canal', width: '10%' },
            { header: 'Nome', field: 'nome_solicitante', width: '10%' },
            { header: 'Canal', field: 'canal', width: '10%' },
            { header: 'Últ.Interação/Qtd', field: 'ultima_interacaoM', width: '15%' },
            { header: 'Conteúdo', field: 'conteudoTexto', width: '20%' },
            { header: 'Unidade Operacional', field: 'nome_lotacao', width: '10%' },
            //  { header: 'Responsável', field: 'nome_responsavel', width: '15%' },
        ];
        this.colsPessoa = [
            { header: 'Código', field: 'key' },
            { header: 'Nome', field: 'nome' },
            { header: 'Tipo', field: 'tipo' },
            { header: 'Unidade Operacional', field: 'nome_lotacao' },
            { header: 'Cargo', field: 'nome_cargo' },
            { header: 'Celular', field: 'celular' },
            { header: 'E-mail', field: 'email' },
            { header: 'Origem', field: 'origem' },
            { header: 'Status', field: 'status' }
        ];

    }
    ngOnInit() {
        //   document.getElementById("body").style.backgroundImage = 'url('+this.parametros.midia+')';
        this.onLoadLotacaoPuc(this.key_pessoa);
        this.pageChat = '1';
        this.msgResp = 0;
        this.msgPendente = 0;
        this.msgAtendimento = 0;
        this.msgDistribuido = 0;
        this.msgDevolvido = 0;
        this.myChat = false;
        this.file = 0;
        this.chatFila = [];
        this.chatPendente = [];
        this.chatDistribuido = [];
        this.onLoadQtdUsuarios();
        this.onLoadMacro();
        this.onLoadUsuario();
        this.onLoadCargo()
        this.onLoadEncerrarAtendimento()
        this.perfilUsuario = new CamelcasePipe().transform(this.parametros.nome).trim()
        this.onLoadPontoIntervalo();
        this.onLoadIntervalos();
        this.onLoadRapida();
        this.onLoadLotacao();
        this.onLoadPuc();
        this.onLoadAgente();
        this.onLoad();
        this.service.onStartChat();
        this.onCombos();
        this.onLoadPonto().then(res => {
            if ((this.ponto.length > 0) && (this.ponto[0].atendimento_status == "Em Atendimento") && (this.ponto[0].canal == 'voip')) {
                this.onVoip();
            } else if ((this.ponto.length > 0) && (this.ponto[0].atendimento_status == "Intervalo")) {
                this.onPontoIntervalo();
            }
        })
    }
    ngOnDestroy() {
        console.log("destroying child atendiento...")
        this.unSub$.next()
        this.unSub$.complete()
        if (this.unSubMsg$) {
            this.unSubMsg$.unsubscribe();
        }
        if (this.unSubLoad$) {
            this.unSubLoad$.unsubscribe();
        }
        if (this.unSubFila$) {
            this.unSubFila$.unsubscribe();
        }
        clearInterval(this.timeCheck)
    }
    onLoadQtdUsuarios() {
        this.genProvider.onGetFilterOrderDesc('dados/' + this.path + '/ponto', 'entrada', 'status', 'Aberto').pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.qtd_usuario_online = JSON.parse(JSON.stringify(data));
        });
    }
    onFechar() {
        this.onMostraFrm();
    }
    onInteracao(protocolo) {
        return new Promise(resolve => {
            this.genProvider.onGetFilterOrder('dados/' + this.path + '/chat_body', 'time', 'protocolo', protocolo).pipe(take(1)).subscribe(data => {
                let interacao = JSON.parse(JSON.stringify(data));
                if (interacao.length > 0) {
                    let qtd = interacao.filter(obj => {
                        return obj.status == "Enviada";
                    });
                    resolve(qtd.length)
                } else {
                    resolve(0)
                }
            });
        })
    }
    onFreeAtendente() {
        let dados_ponto = {
            key: this.ponto[0].key,
            atendimento_time: Date.now(),
            protocolo: '',
            canal: '',
            atendimento_status: "Livre",
            id: 1

        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
        this.chat_msg = [];
        this.myBtnVoip = false;
        // this.formulario.reset();
        //  this.formulario_ticket.reset();
    }
    onClose() {
        if (this.agenteStatus == "Livre" || this.agenteStatus == "Ponto Off" || this.agenteStatus == "Ponto") {
            this.router.navigate(['']);
        } else {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, para sair do atendimento você precisa primeiro finalizar seu atendimento.' });
        }
        // this.onMostraAtendimento();
    }
    onMostraFrm() {
        this.mostraFrm = !this.mostraFrm;
    }
    onReabreIntervalo(key) {
        this.genProvider.onGetfilter('dados/' + this.path + '/pontoIntervalo', "key_usuario", key).pipe(take(1)).subscribe(data => {
            let dados = JSON.parse(JSON.stringify(data));
            if (dados.length > 0) {
                let res = dados.filter(obj => {
                    return obj.status == "Aberto"
                })
                if (res.length > 0) {
                    this.formulario_ponto_intervalo.patchValue(res)
                    this.intervalo = true;
                }
            }
        })
    }
    onLoadLotacao() {
        this.genProvider.onGetAll('dados/' + this.path + '/lotacao', "key_subordinada").pipe(takeUntil(this.unSub$)).subscribe(data => {
            let lotacao = JSON.parse(JSON.stringify(data));
            this.lotacao = lotacao;
            this.lotacao = lotacao.map(res => {
                res.nomeSigla = res.nome + " " + res.sigla + " " + res.codigo
                return res;
            });

        },
            err => console.log(err)
        );
    }
    onGetLotacao() {
        let lotacao = this.lotacao.filter(obj => {
            return obj.key == this.formulario_ticket.value.key_lotacao;
        });
        if (lotacao.length > 0) {
            this.formulario_ticket.patchValue({
                nome_lotacao: lotacao[0].nome
            })
        }
    }
    onLoad() {
        let filtro = [
            "Pendente",
            "Nova Mensagem",
            "Em Atendimento",
            "Distribuido",
            "Devolvido"
        ]
        this.unSubLoad$ = this.genProvider.onGetFilterOrderAll('dados/' + this.path + '/chat', "time", "key_usuario", this.parametros.key_usuario, "==", "status", filtro, "in", 999, 'desc').subscribe(data => {
            let chat = JSON.parse(JSON.stringify(data));
            this.chat = chat.map(res => {
                if (!res.ticket) {
                    res.ticket = ''
                }
                return res;
            });
            this.onUpdateDados(this.chat)
        },
            err => console.log(err)
        );
    }
    onLoadChatFila() {
        if (!this.qtdFila) {
            this.qtdFila = 999
        }
        this.unSubFila$ = this.genProvider.onGetFilterOrderTo('dados/' + this.path + '/chat', "time", "key_puc", this.key_central, "==", "novo", '0', "!=", this.qtdFila, false).subscribe(data => {
            this.fila = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onLoadChat(key) {
        this.chat_msg = [];
        this.unSubMsg$ = this.genProvider.onGetAll('dados/' + this.path + '/chat/' + key + '/chat_body', 'time').subscribe(data => {
            let chat_msg = JSON.parse(JSON.stringify(data));
            this.scrollToBottom();
            let i = 0
            this.chat_msg = chat_msg.map(res => {
                i++
                if (res.value) {
                    let valor = res.value.replace(/\*/g, '');
                    valor = valor.replace(/\n/g, '<br>');
                    res.value = valor;
                }
                if (res.type == "location") {
                    let coor = res.value.split(/\s*;\s*/);
                    res.lat = parseFloat(coor[0]);
                    res.lng = parseFloat(coor[1]);
                }
                return res;
            });
        },
            err => {
                console.log(err);
            }
        );
    }
    onUpdateDados(chat) {
        this.dados = chat.filter(obj => {
            return obj.status != "Pendente"
        });
        let msgPendente = chat.filter(obj => {
            return obj.status == "Pendente";
        });
        let msgDistribuido = chat.filter(obj => {
            return obj.status == "Distribuido";
        });
        /*
         let msgResp = chat.filter(obj => {
           return obj.status == "Nova Mensagem";
         });


         let msgDevolvido = chat.filter(obj => {
           return obj.status == "Devolvido";
         });
         let msgTramitado = chat.filter(obj => {
           return obj.status == "Tramitado";
         });
         */
        this.msgPendente = msgPendente.length;
        this.msgPendenteData = msgPendente;
        this.msgAtendimento = this.dados.length;
        this.chatPendente = msgPendente;
        this.chatDistribuido = msgDistribuido;

    }
    ngAfterViewChecked() {
        // this.scrollToBottom();
    }
    scrollToBottom(): void {
        try {
            this.objDiv.nativeElement.scrollTop = (this.objDiv.nativeElement.scrollHeight);
            //    console.log(this.objDiv.nativeElement.scrollHeight)
        } catch (err) { }
    }
    onTab(e) {
        this.page = e.activeItem.id
    }
    onTabChat(e) {
        this.pageChat = e.activeItem.id
    }
    onLoadRapida() {
        this.genProvider.onGetAll('dados/' + this.path + '/rapida', "titulo").pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.rapidaAll = JSON.parse(JSON.stringify(data));
            this.rapida = this.rapidaAll.map(res => {
                res.busca = res.mensagem
                res.busca += res.titulo
                return res;
            });
        },
            err => console.log(err)
        );

    }
    onLoadPontoStart() {
        this.genProvider.onGetFilterOrderDesc('dados/' + this.path + '/ponto', "time", "key_usuario", this.parametros.key_usuario).pipe(
            tap(v => {
                //console.log(v)
            }),
            take(1)
        ).subscribe(data => {
            let ponto = JSON.parse(JSON.stringify(data));
            if (ponto.length > 0) {
                this.genProvider.onSaveIncrementDate('dados/' + this.parametros.path + "/ponto", this.ponto[0], "ponto")
            }
        },
            err => console.log(err)
        );
    }
    // funcoes de ponto
    onLoadPonto() {
        return new Promise(resolve => {
            this.genProvider.onGetFilterOrderDesc('dados/' + this.path + '/ponto', "time", "status", "Aberto").pipe(takeUntil(this.unSub$)).subscribe(data => {
                let ponto = JSON.parse(JSON.stringify(data));
                this.isLogado = false
                this.ponto = ponto.filter(obj => {
                    return obj.key_usuario == this.parametros.key_usuario
                });
                if (this.ponto.length > 0) {
                    this.pontoProtocolo = this.ponto[0].protocolo;
                    this.agenteStatus = this.ponto[0].atendimento_status
                    this.formulario_ponto.patchValue({
                        key: this.ponto[0].key,
                        entrada: this.ponto[0].entrada,
                        saida: this.ponto[0].saida,
                        entrada_intervalo: this.ponto[0].entrada_intervalo,
                        saida_intervalo: this.ponto[0].saida_intervalo,
                        total: this.ponto[0].total,
                        dia: this.ponto[0].dia,
                        time: this.ponto[0].time,
                        key_usuario: this.ponto[0].key_usuario,
                        nome: this.ponto[0].nome,
                        status: this.ponto[0].status,
                        atendimento_status: this.ponto[0].atendimento_status,
                        canal: this.ponto[0].canal,
                        atendimento_time: this.ponto[0].atendimento_time,
                        isEntrada: this.ponto[0].isEntrada,
                        isSaidaIntervalo: this.ponto[0].isSaidaIntervalo,
                        isEntradaIntervalo: this.ponto[0].isEntradaIntervalo,
                        isSaida: this.ponto[0].isSaida
                    })
                    this.onLoadAllPontoIntervalo(this.ponto[0].key)
                    if (this.ponto[0].atendimento_status == "Ponto") {
                        this.isStart = false;
                    } else {
                        this.isStart = true;
                    }
                } else {
                    this.agenteStatus = "Ponto Off"
                    this.myChat = false;
                    this.isStart = false;
                }
                resolve(true);
            },
                err => console.log(err)
            )
            //);

        });

    }
    onLoadPontoIntervalo() {
        this.genProvider.onGetFilterOrderDesc('dados/' + this.path + '/pontoIntervalo', "time", "key_usuario", this.parametros.key_usuario).pipe(takeUntil(this.unSub$)).subscribe(data => {
            let pontoIntervalo = JSON.parse(JSON.stringify(data));
            this.pontoIntervalo = pontoIntervalo.filter(obj => {
                return obj.status == "Aberto";
            });
        },
            err => console.log(err)
        );
    }
    onLoadAllPontoIntervalo(key) {
        this.genProvider.onGetFilterOrderDesc('dados/' + this.path + '/pontoIntervalo', "time", "key_ponto", key).pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.pontoAllIntervalo = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onLoadIntervalos() {
        this.genProvider.onGetAll('dados/' + this.path + '/intervalos', 'nome').pipe(takeUntil(this.unSub$)).subscribe(
            data => {
                this.intervalos = JSON.parse(JSON.stringify(data));
            },
            err => console.log(err)
        );
    }
    // fim
    onSaveChat() {
        if ((this.formSend) && (this.formSend != '')) {
            let ultima_msg = this.chat_msg;

            ultima_msg.sort(function (a, b) {
                if (a.time < b.time) {
                    return 1;
                }
                if (a.time > b.time) {
                    return -1;
                }
                return 0;
            });
            let time = new Date();
            let date = new Date(time.getTime() - 1435 * 60 * 1000);
            let tempo = Date.parse(date.toString());
            let envia_msg = null;
            if (ultima_msg[0].time < tempo) {
                envia_msg = false
            } else {
                envia_msg = true
            }

            if ((envia_msg) && this.formulario.value.origem == "whatsapp") {
                if (this.formulario.value.id_canal) {
                    this.onSaveContatoZap()
                }
                let dados = {
                    status: 'Enviada',
                    key_lotacao: this.key_lotacao,
                    key_usuario: this.key_usuario,
                    nome_usuario: this.nomeUsuario,
                    nome_lotacao: this.nome_lotacao,
                    protocolo: this.formulario.value.protocolo,
                    nome: this.perfilUsuario,
                    id_canal: this.formulario.value.id_canal,
                    type: "chat",
                    tipo: 'publica',
                    value: this.formSend,
                    time: Date.now(),
                    data_criacao: Date.now(),
                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                    status_ticket: '1'
                }
                this.formSend = undefined;
                let url = 'dados/' + this.path + '/chat/' + this.formulario.value.key + '/chat_body'
                this.service.onEnviaChatServidor(dados, url)
                this.onEnviarChat(dados);
            } else if ((!envia_msg) && this.formulario.value.origem == "whatsapp") {
                alert("Não é possivel enviar uma msg para esta conversa, o tempo de resposta a última conversa por parte de solicitante já utrapassou 24 hrs.")
            } else {
                if (this.formulario.value.id_canal) {
                    this.onSaveContatoZap()
                }
                let dados = {
                    status: 'Enviada',
                    key_lotacao: this.key_lotacao,
                    key_usuario: this.key_usuario,
                    nome_usuario: this.nomeUsuario,
                    nome_lotacao: this.nome_lotacao,
                    protocolo: this.formulario.value.protocolo,
                    nome: this.perfilUsuario,
                    id_canal: this.formulario.value.id_canal,
                    type: "chat",
                    tipo: 'publica',
                    value: this.formSend,
                    time: Date.now(),
                    data_criacao: Date.now(),
                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                    status_ticket: '1'
                }
                this.formSend = undefined;
                let url = 'dados/' + this.path + '/chat/' + this.formulario.value.key + '/chat_body'
                this.service.onEnviaChatServidor(dados, url)
                this.onEnviarChat(dados);
            }
        }
    }
    onEnviarChat(dados) {
        let agente = null;
        if (this.parametros.mostraUsuario) {
            agente = this.parametros.nome.split(' ').slice(0, 1).join(' ');
        } else {
            agente = this.parametros.nomeBot
        }
        let msg = '*' + agente + '*' + this.service.ctrl + dados.value
        if (this.assinarResp == 2) {
            msg = this.service.ctrl + dados.value
        }
        let origem = this.formulario.value.origem
        let formulario = {
            origem: origem,
            contato: dados.id_canal,
            mensagem: msg
        };
        this.service.enviarMsg(formulario);
    }
    onSaveFile() {
        this.anexarArquivo = false;
        let dados = {
            status: 'Enviada',
            key_lotacao: this.key_lotacao,
            key_usuario: this.key_usuario,
            nome_usuario: this.nomeUsuario,
            nome_lotacao: this.nome_lotacao,
            origem: this.formulario.value.origem,
            protocolo: this.formulario.value.protocolo,
            nome: this.perfilUsuario,
            id_canal: this.formulario.value.id_canal,
            type: this.formulario_file.value.type,
            type_anexo: this.formulario_file.value.type_anexo,
            value: this.formulario_file.value.file,
            time: Date.now(),
            data_criacao: Date.now(),
            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
            status_ticket: '1'
        }
        this.type_anexo = '';
        this.genProvider.onSaveObjeto('dados', this.path + '/chat/' + this.formulario.value.key, 'chat_body', dados);
        let upDateTime = {
            key: this.formulario.value.protocolo,
            status: 'Em Atendimento',
            time: Date.now()
        }
        this.genProvider.onSaveObjeto('dados', this.path, "chat", upDateTime)
        this.enviarFile(dados);

    }
    enviarFile(dados) {
        let formularioNew = {
            origem: dados.origem,
            contato: dados.id_canal,
            midia: dados.value,
            type_midia: dados.type,
            type_anexo: dados.type_anexo
        };
        this.formulario_file.reset();
        this.service.enviarMsg(formularioNew);
    }
    onkey(e) {
        if (e.keyCode === 13 && e.ctrlKey) {
            this.onSaveChat();
        }

        // if (e.keyCode === 13 && !e.ctrlKey) {
        //   this.onSave();
        // }
    }
    onEnviar() {
        this.onSaveChat();
    }
    onEnviarEmail() {
        if ((this.formSend) && (this.formSend != '')) {
            let email = {
                origem: 'email',
                contato: this.formulario.value.id_canal,
                mensagem: this.formSend
            }
            this.service.enviarMsg(email)
            let dados = {
                status: 'Enviada',
                protocolo: this.formulario.value.protocolo,
                key_lotacao: this.key_lotacao,
                key_usuario: this.key_usuario,
                nome_usuario: this.nomeUsuario,
                nome_lotacao: this.nome_lotacao,
                nome: this.perfilUsuario,
                id_canal: this.formulario.value.id_canal,
                type: "chat",
                value: this.formSend,
                time: Date.now(),
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                status_ticket: '1'
            }
            this.genProvider.onSaveObjeto('dados', this.path + '/chat/' + this.formulario.value.key, 'chat_body', dados);
            let upDateTime = {
                key: this.formulario.value.protocolo,
                status: 'Em Atendimento',
                time: Date.now()
            }
            this.genProvider.onSaveObjeto('dados', this.path, "chat", upDateTime)
            this.formSend = undefined;
        }
    }
    onSaveResposta() {
        this.frm_Resposta.value.data_hora = new DatePipe('pt-BR').transform(
            new Date(),
            'd, MMMM yyyy - HH:mm'
        );
        if (this.frm_Resposta.valid) {
            this.genProvider.onSaveIncrementZero('dados/' + this.path + '/rapida', this.frm_Resposta.value, "rapida", 4).then(t => {
                this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
                this.frm_Resposta.reset();
            }).catch(e => {
                this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
                // console.log(e);
            });
        }
    }
    onDeleteResposta(event) {
        if (window.confirm('Confirma a Exclusão de ' + event.titulo + ' - ' + event.mensagem)) {
            this.genProvider.remove('dados/' + this.path + '/rapida', event.key);
        }
    }
    pegaMensagem(dados) {
        this.formSend = dados.mensagem
        this.atendeChat = false;
    }
    onEmoji() {
        this.myEmoji = !this.myEmoji;
    }
    goEmoji(event) {
        if (this.formSend == undefined) {
            this.formSend = event
        } else {
            this.formSend += event
        }
    }
    onArquivo(btn) {
        this.type_anexo = btn.toLowerCase()
        var botao = document.getElementById("FileUpload" + btn);
        botao.click();
    }
    onConfirm() {
        this.messageService.clear('c');
    }
    onReject() {
        this.messageService.clear('c');
    }
    clear() {
        this.messageService.clear();
    }
    onListaPendente() {
        this.lista = this.chat
        this.lista = this.lista.filter(obj => {
            return obj.status == "Nova Mensagem";
        })
    }
    notificacao() {
        this.audio.nativeElement.play();
    }
    onFile(e) {
        this.progress = null;
        this.formulario_file.reset();
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        this.formulario_file.patchValue({
            fullName: file.name,
            type: file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
            type_anexo: this.type_anexo,
            target: e.target.files[0]
        });
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
        this.anexarArquivo = true;

    }
    _handleReaderLoaded(e) {
        var reader = e.target;
        this.fileSrc = reader.result
        this.formulario_file.patchValue({
            file: reader.result
        });
    }
    upLoadFile() {
        const filePath = `/${'chat'}/file_${new DatePipe('pt-BR').transform(
            new Date(),
            'yyyy-MM-dd-HH:mm:ss'
        )}.` + this.formulario_file.value.fullName
        this.ref = this.afStorage.ref(filePath);
        this.task = this.ref.put(this.formulario_file.value.target);
        this.progress = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
            finalize(() => {
                this.downloadURL = this.ref.getDownloadURL().subscribe(res => {
                    this.formulario_file.patchValue({
                        file: res,
                        fullPath: filePath,
                        target: ''
                    })
                    this.onSaveFile();
                })
            })
        );
        this.snapshot.subscribe(
            res => {
                // console.log(res);
            }, err => {
                console.log(err);
            }
        );

    }
    refreshTime(): void {
        //  let timeDisplay = document.getElementById("time");
        var dateString = new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm:ss");
        //  timeDisplay.innerHTML = dateString;
        this.timeDisplay = dateString
    }
    horasTrabalhadas(): void {
        let totalHoraDisplay = document.getElementById("totalHora");
        if (this.formulario_ponto.value.entrada) {
            var dtInicio = new DatePipe("pt-BR").transform(this.formulario_ponto.value.entrada, "dd/MM/yyyy - HH:mm:ss"); "20/06/2017 16:40:00";
            var dtAtual = new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 11:20:00";
            var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
            var d = moment.duration(ms);
            var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
            totalHoraDisplay.innerHTML = s;
            this.totalTempo = s;
        }
    }
    timerOff() {
        clearInterval(this.timer)
    }
    onPonto() {
        if (this.ponto.length > 0) {
            if (this.ponto[0].atendimento_status == 'Livre') {
                this.openPonto();
                this.formulario_ponto.patchValue({
                    key: this.ponto[0].key,
                    entrada: this.ponto[0].entrada,
                    saida: this.ponto[0].saida,
                    entrada_intervalo: this.ponto[0].entrada_intervalo,
                    saida_intervalo: this.ponto[0].saida_intervalo,
                    total: this.ponto[0].total,
                    dia: this.ponto[0].dia,
                    time: this.ponto[0].time,
                    key_usuario: this.ponto[0].key_usuario,
                    nome: this.ponto[0].nome,
                    status: this.ponto[0].status,
                    atendimento_status: this.ponto[0].atendimento_status,
                    atendimento_time: this.ponto[0].atendimento_time,
                    isEntrada: this.ponto[0].isEntrada,
                    isSaidaIntervalo: this.ponto[0].isSaidaIntervalo,
                    isEntradaIntervalo: this.ponto[0].isEntradaIntervalo,
                    isSaida: this.ponto[0].isSaida,
                    id: 2
                });
                this.timer = setInterval(() => {
                    this.refreshTime();
                    this.horasTrabalhadas();
                }, 1000);
            } else {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Antes de prosseguir com esta operação vc precisa encerrar seu atendimento' });
            }
        } else {
            if (this.qtd_usuario_online.length >= this.parametros.licencas) {
                alert("Atenção, limite de usuários online excede as licença, por favor encerre o ponto de outro usuário para prosseguir. ")
            } else {
                this.openPonto();
                this.formulario_ponto.patchValue({
                    entrada: Date.now(),
                    saida: '',
                    entrada_intervalo: '',
                    saida_intervalo: '',
                    total: '',
                    dia: new DatePipe("pt-BR").transform(
                        Date.now(),
                        "d, MMMM yyyy - HH:mm"
                    ),
                    time: Date.now(),
                    key_usuario: this.key_usuario,
                    nome: this.usuario,
                    status: "Aberto",
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                    atendimento_time: '',
                    isEntrada: true,
                    isSaidaIntervalo: false,
                    isEntradaIntervalo: false,
                    isSaida: false,
                    id: 3
                });

                this.timer = setInterval(() => {
                    this.refreshTime();
                    this.horasTrabalhadas();
                }, 1000);
            }
            //   this.timer = setInterval(this.refreshTime, 1000);
        }
    }
    onEntrada() {
        this.confirmationService.confirm({
            message: 'Confirma o registro?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                if (this.parametros.intervalo_jornada) {
                    this.formulario_ponto.patchValue({
                        entrada: Date.now(),
                        status: "Aberto",
                        protocolo: '',
                        atendimento_status: "Livre",
                        canal: '',
                        atendimento_time: Date.now(),
                        isEntrada: false,
                        isSaidaIntervalo: true,
                        id: 4
                    });
                    this.onSavePonto(this.formulario_ponto.value);
                } else {
                    this.formulario_ponto.patchValue({
                        entrada_intervalo: Date.now(),
                        status: "Aberto",
                        protocolo: '',
                        atendimento_status: "Livre",
                        atendimento_time: Date.now(),
                        isEntradaIntervalo: false,
                        isEntrada: false,
                        isSaidaIntervalo: false,
                        isSaida: true,
                        id: 5
                    });
                    this.onSavePonto(this.formulario_ponto.value);
                }
                this.pontoAgente = false;
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });

    }
    onSaidaIntervalo() {
        this.confirmationService.confirm({
            message: 'Confirma o registro?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                let dados_ponto = {
                    key: this.ponto[0].key,
                    saida_intervalo: Date.now(),
                    status: "Aberto",
                    atendimento_status: "Ponto",
                    atendimento_time: Date.now(),
                    isSaidaIntervalo: false,
                    isEntradaIntervalo: true
                };
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                this.pontoAgente = false;
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onEntradaIntervalo() {
        this.confirmationService.confirm({
            message: 'Confirma o registro?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                let dados_ponto = {
                    key: this.ponto[0].key,
                    entrada_intervalo: Date.now(),
                    status: "Aberto",
                    protocolo: '',
                    atendimento_status: "Livre",
                    atendimento_time: Date.now(),
                    isEntradaIntervalo: false,
                    isSaida: true,
                    id: 6
                };
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                this.pontoAgente = false;
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onSaida() {
        this.confirmationService.confirm({
            message: 'Confirma o registro?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                let dados_ponto = {
                    key: this.ponto[0].key,
                    saida: Date.now(),
                    status: "Encerrado",
                    atendimento_status: "Encerrado",
                    canal: '',
                    atendimento_time: Date.now(),
                    isSaida: true,
                    total: this.totalTempo
                };
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                this.isStart = !this.isStart;
                this.pontoAgente = false;
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onSavePonto(dados, start?: String) {
        this.genProvider.onSaveIncrementDate('dados/' + this.parametros.path + "/ponto", dados, "ponto").then(t => {
            this.formulario_ponto.patchValue({
                key: t
            });
            if (start == "sair") {
                this.isStart = !this.isStart;
                this.formulario_ponto.reset()
            }
            this.timerOff()
        });
    }
    horasIntervalo(): void {
        //  let totalHoraDisplay = document.getElementById("totalHora");
        if (this.formulario_ponto_intervalo.value.entrada) {
            var dtInicio = new DatePipe("pt-BR").transform(this.formulario_ponto_intervalo.value.entrada, "dd/MM/yyyy - HH:mm:ss"); "20/06/2017 16:40:00";
            var dtAtual = new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 11:20:00";
            var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
            var d = moment.duration(ms);
            var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
            //   totalHoraDisplay.innerHTML = s;
            this.totalTempo = s;
        }
    }
    timerOffIntervalo() {
        clearInterval(this.timerIntervalo)
        clearInterval(this.timerHora)
    }
    horasAtendimento() {
        var dtInicio = new DatePipe("pt-BR").transform(this.inicio_atendimento, "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 16:40:00";
        var dtAtual = new DatePipe("pt-BR").transform(Date.now(), "dd/MM/yyyy - HH:mm:ss"); //"20/06/2017 11:20:00";
        var ms = moment(dtAtual, "DD/MM/YYYY HH:mm:ss").diff(moment(dtInicio, "DD/MM/YYYY HH:mm:ss"));
        var d = moment.duration(ms);
        var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
        //   totalHoraDisplay.innerHTML = s;
        this.tempoAtendimento = s;
    }
    onFinalTempoAtendimento() {
        clearInterval(this.timerAtendimento)
        this.tempoAtendimento = null;
    }
    onPontoIntervalo() {
        //  if (this.ponto[0].atendimento_status!='Em Atendimento'){
        if (this.ponto[0].atendimento_status != 'Em Atendimento') {
            this.openIntervalo();
            this.timerOffIntervalo();
            /*
            let dados_ponto = {
              key: this.ponto[0].key,
              protocolo : '',
              atendimento_time: Date.now(),
              atendimento_status: "Intervalo",
            }
            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
            */
            if (this.pontoIntervalo.length > 0) {
                this.formulario_ponto_intervalo.patchValue({
                    key: this.pontoIntervalo[0].key,
                    key_ponto: this.ponto[0].key,
                    key_intervalo: this.pontoIntervalo[0].key_intervalo,
                    intervalo: this.pontoIntervalo[0].intervalo,
                    entrada: this.pontoIntervalo[0].entrada,
                    saida: this.pontoIntervalo[0].saida,
                    total: this.pontoIntervalo[0].total,
                    dia: this.pontoIntervalo[0].dia,
                    time: this.pontoIntervalo[0].time,
                    key_usuario: this.pontoIntervalo[0].key_usuario,
                    nome: this.pontoIntervalo[0].nome,
                    status: this.pontoIntervalo[0].status,
                    isEntrada: this.pontoIntervalo[0].isEntrada,
                    isSaida: this.pontoIntervalo[0].isSaida
                });
                this.timerHora = setInterval(() => {
                    this.refreshTime();
                }, 1000);
                this.timerIntervalo = setInterval(() => {
                    this.horasIntervalo();
                }, 1000);
            } else {
                this.formulario_ponto_intervalo.patchValue({
                    key_ponto: this.ponto[0].key,
                    entrada: '',
                    saida: '',
                    total: '',
                    key_usuario: this.key_usuario,
                    nome: this.usuario,
                    status: "Aberto",
                    isEntrada: true,
                    isSaida: false
                });
                this.timerHora = setInterval(() => {
                    this.refreshTime();
                }, 1000);
            }
        } else {
            this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Antes de prosseguir com esta operação vc precisa encerrar seu atendimento' });
        }

    }
    onEntradaPontoIntervalo() {
        this.confirmationService.confirm({
            message: 'Confirma o registro?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                this.timerIntervalo = setInterval(() => {
                    this.horasIntervalo();
                }, 1000);

                this.formulario_ponto_intervalo.patchValue({
                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                    nome_usuario: this.parametros.nome,
                    entrada: Date.now(),
                    status: "Aberto",
                    time: Date.now(),
                    dia: new DatePipe("pt-BR").transform(
                        Date.now(),
                        "d, MMMM yyyy - HH:mm"
                    ),
                    isEntrada: false,
                    isSaida: true
                });
                this.genProvider.onSaveIncrementDate('dados/' + this.parametros.path + "/pontoIntervalo", this.formulario_ponto_intervalo.value, "pontoIntervalo").then(t => {
                    this.formulario_ponto_intervalo.patchValue({
                        key: t
                    });
                    this.isStart = !this.isStart;
                    let dados_ponto = {
                        key: this.ponto[0].key,
                        status: "Aberto",
                        atendimento_status: "Intervalo",
                    };
                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                    this.pontoAgente = false;
                });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });

    }
    onSaidaPontoIntervalo() {
        console.log(this.pontoIntervalo)
        this.confirmationService.confirm({
            message: 'Confirma o registro?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Registro confirmado' });
                this.formulario_ponto_intervalo.patchValue({
                    saida: Date.now(),
                    status: "Encerrado",
                    total: this.totalTempo,
                    isSaida: true
                });
                let data = {
                    key: this.pontoIntervalo[0].key,
                    saida: Date.now(),
                    status: "Encerrado",
                    total: this.totalTempo,
                    isSaida: true
                }
                this.onSavePontoIntervalo(data, "sair");
                this.timerOffIntervalo();
                this.isStart = !this.isStart;
                let dados_ponto = {
                    key: this.ponto[0].key,
                    status: "Aberto",
                    protocolo: '',
                    atendimento_status: "Livre",
                    id: 7
                };
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                this.intervalo = false;
                this.formulario_ponto_intervalo.reset()
                this.pontoIntervalo
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });

    }
    onSavePontoIntervalo(dados, acao?: String) {
        this.genProvider.onSaveIncrementDate('dados/' + this.parametros.path + "/pontoIntervalo", dados, "pontoIntervalo").then(t => {
            this.formulario_ponto_intervalo.patchValue({
                key: t
            });
            //        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
            if (acao == "sair") {
                //     this.timerOffIntervalo();
                this.atendeChat = false;
                this.formulario_ponto_intervalo.reset()
            }
        });

    }
    onGetIntervalo() {
        let res = this.intervalos.filter(obj => {
            return obj.key == this.formulario_ponto_intervalo.value.key_intervalo
        });
        this.formulario_ponto_intervalo.patchValue({
            intervalo: res[0].nome
        })
    }
    onRejeitar(e) {
        this.atendeChat = false;
        let dados_ponto = {
            key: this.ponto[0].key,
            protocolo: '',
            atendimento_time: Date.now(),
            atendimento_status: "Livre",
            id: 8
        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(t => {
            this.onFinalTempoAtendimento();
            let dados_chat = {
                key: this.chatLive.key,
                status: 'Novo',
                tempo_espera: 0,
                tempo_ocioso: 0
            }
            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chatLive', dados_chat);
        })
    }
    onLiberaChatLive(key) {
        let dados_chat = {
            key: key,
            status: 'Novo',
            tempo_espera: 0,
            tempo_ocioso: 0
        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chatLive', dados_chat);
    }
    onOpenPonto(e) {
        this.formulario_ponto_intervalo.reset();
        this.atendeChat = false;
        // atualizar tabela chatLive
        let dados_ponto = {
            key: this.ponto[0].key,
            atendimento_time: Date.now(),
            atendimento_status: 'Ponto',
            canal: ''
        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(t => {
            let dados_chat = {
                key: e.key,
                status: 'Novo',
                tempo_espera: 0,
                tempo_ocioso: 0
            }
            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chatLive', dados_chat);
            this.pontoAgente = true;
        })

    }
    onOpenIntervalo(e) {
        this.atendeChat = false;
        this.totalTempo = "";
        let dados_ponto = {
            key: this.ponto[0].key,
            atendimento_time: Date.now(),
            atendimento_status: 'Intervalo',
        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(t => {
            let dados_chat = {
                key: e.key,
                status: 'Novo',
                tempo_espera: 0,
                tempo_ocioso: 0
            }
            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chatLive', dados_chat);
            if (this.pontoIntervalo.length > 0) {
                this.formulario_ponto_intervalo.patchValue({
                    key: this.pontoIntervalo[0].key,
                    key_ponto: this.ponto[0].key,
                    key_intervalo: this.pontoIntervalo[0].key_intervalo,
                    intervalo: this.pontoIntervalo[0].intervalo,
                    entrada: this.pontoIntervalo[0].entrada,
                    saida: this.pontoIntervalo[0].saida,
                    total: this.pontoIntervalo[0].total,
                    dia: this.pontoIntervalo[0].dia,
                    time: this.pontoIntervalo[0].time,
                    key_usuario: this.pontoIntervalo[0].key_usuario,
                    nome: this.pontoIntervalo[0].nome,
                    status: this.pontoIntervalo[0].status,
                    isEntrada: this.pontoIntervalo[0].isEntrada,
                    isSaida: this.pontoIntervalo[0].isSaida
                });
            } else {
                this.formulario_ponto_intervalo.patchValue({
                    key_ponto: this.ponto[0].key,
                    entrada: '',
                    saida: '',
                    total: '',
                    key_usuario: this.key_usuario,
                    nome: this.usuario,
                    status: "Aberto",
                    isEntrada: true,
                    isSaida: false
                });
            }
            this.intervalo = true;
        })
    }
    onTextEditor(event) {
        this.formulario_ticket.patchValue({
            conteudoTexto: event.textValue
        })
    }
    onEncerrarAtendimento() {
        this.formulario_ticket.patchValue({
            key_puc: this.key_central,
            key_lotacao: this.key_lotacao,
            key_usuario: this.key_usuario,
            key_solicitante: this.formulario.value.id_canal,
            id_canal: this.formulario.value.id_canal,
            busca: this.formulario_ticket.value.busca + ' ' + this.formulario_ticket.value.conteudoTexto
        })
        if (!this.formulario_ticket.valid) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
        } else {
            this.formulario_ticket.patchValue({
                status_ticket: 'Novo'
            })
            this.encerrarAtendimento = true;
        }
    }
    onEncerrarAtendimentoVoip() {
        if (!this.formulario_ticket.valid) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
        } else {
            this.formulario_ticket.patchValue({
                status_ticket: null,
                busca: this.formulario_ticket.value.conteudoTexto
            })
            this.encerrarAtendimentoVoip = true;
        }
    }
    onEncerrar(subTicket?: boolean) {
        this.confirmationService.confirm({
            message: 'Confirma o encerramento do atendimento?',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                //    this.onFechar();
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Atendimento encerrado.' });
                this.ticketFinalizar = false;
                let dados_ponto = {
                    key: this.ponto[0].key,
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                }
                if (!subTicket) {
                    this.ticketFinalizar = false;
                    this.encerrarAtendimento = false;
                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
                        let dados = {
                            key: this.formulario.value.key,
                            status: 'Finalizado',
                            finalizada: 'sim',
                            time: Date.now(),
                            fim: Date.now(),
                            tempo: this.tempoAtendimento,
                            key_lotacao: this.key_lotacao,
                            key_usuario: this.key_usuario,
                        }
                        this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados).then(res => {
                            this.formulario_ticket.patchValue({
                                qtd_interacao: 1,
                                status: "Finalizado",
                                time: Date.now(),
                                ultima_interacao: Date.now(),
                                fim: Date.now(),
                                tempo: this.tempoAtendimento,
                                protocolo: this.formulario.value.key,
                                ticketPrincipal: null,
                                key_lotacao: this.key_lotacao,
                                key_usuario: this.key_usuario,
                                busca: this.formulario_ticket.value.busca + ' ' + this.formulario_ticket.value.conteudoTexto,
                                email: this.formulario.value.email,
                                telefone: this.formulario.value.telefone
                            })
                            this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(res => {
                                this.onSaveFormDinamicoChat(this.formulario_dinamico.value, this.formulario_ticket.value);
                                let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                                msg += 'Ticket: #' + this.formulario_ticket.value.key + this.service.ctrl
                                msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                                msg += 'Status: ' + this.formulario_ticket.value.status + this.service.ctrl
                                msg += 'Status do ticket: ' + this.formulario_ticket.value.status_ticket + this.service.ctrl
                                msg += 'Data do encerramento: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.fim, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                                msg += 'Aberta por : ' + this.formulario.value.nome + this.service.ctrl
                                msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                                msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                                if (this.formulario_ticket.value.email) {
                                    let assunto = "Chamado do Ticket " + this.formulario_ticket.value.key;
                                    let email = {
                                        contato: this.formulario_ticket.value.email,
                                        assunto: assunto,
                                        mensagem: msg
                                    }
                                    this.service.sendMail(email)
                                }

                                let dados_ticket_body = {
                                    status: 'Enviada',
                                    key_lotacao: this.key_lotacao,
                                    key_usuario: this.key_usuario,
                                    nome_usuario: this.nomeUsuario,
                                    nome_lotacao: this.nome_lotacao,
                                    type: 'chat',
                                    ticket: this.formulario_ticket.value.key,
                                    value: this.formulario_ticket.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: Date.now(),
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                    tipo: 'publica'
                                }
                                //   this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_ticket.value.key, "ticket_body", dados_ticket_body);

                                let dados_body = {
                                    status: 'Enviada',
                                    key_lotacao: this.key_lotacao,
                                    key_usuario: this.key_usuario,
                                    nome_usuario: this.nomeUsuario,
                                    nome_lotacao: this.nome_lotacao,
                                    protocolo: this.formulario.value.protocolo,
                                    nome: this.perfilUsuario,
                                    id_canal: this.formulario.value.id_canal,
                                    type: "chat",
                                    tipo: 'publica',
                                    value: this.formulario_ticket.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: Date.now(),
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm")
                                }
                                let data_movto = {
                                    ticket: this.formulario_ticket.value.key,
                                    protocolo: this.formulario.value.key,
                                    status: 'Finalizado',
                                    key_lotacao_tramitacao: null,
                                    key_usuario_destino: null,
                                    key_puc: null,
                                    time: Date.now(),
                                    canal: this.formulario_ticket.value.origem,
                                    conteudo: this.formulario_ticket.value.conteudoTexto,
                                    key_lotacao: this.key_lotacao,
                                    key_usuario: this.key_usuario,
                                }
                                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.key, "chat_body", dados_body).then(res => {
                                    this.onAtualizarHistoricoTicket(this.formulario.value.key, this.formulario_ticket.value.key);
                                })
                                this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario_ticket.value.protocolo).pipe(take(1)).subscribe(res => {
                                    let data = JSON.parse(JSON.stringify(res));
                                    if (data.length > 0) {
                                        let atendimento = {
                                            key: data[0].key,
                                            status: "Finalizado",
                                            origem: this.formulario_ticket.value.origem,
                                            menu: this.formulario_ticket.value.tipo_ticket,
                                            tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                            key_puc: this.formulario_ticket.value.key_puc,
                                            key_lotacao: this.key_lotacao,
                                            key_usuario: this.key_usuario,
                                            key_solicitante: this.formulario_ticket.value.key_solicitante,
                                            key_catalogo: this.formulario_ticket.value.key_catalogo,
                                            key_item: this.formulario_ticket.value.key_item,
                                            key_subitem: this.formulario_ticket.value.key_subitem,
                                            time: Date.now(),
                                            nome: this.formulario.value.nome_solicitante,
                                            tempo: this.tempoAtendimento
                                        }
                                        this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                                    }
                                    this.onFinalizaChatUser(this.formulario_ticket.value.id_canal);
                                    this.formulario_pessoa.reset();
                                    this.onFinalTempoAtendimento();
                                })
                            });
                        });
                    });
                    this.chat_msg = [];
                    this.myChat = false;
                    this.onMostraAtendimento();
                } else {
                    this.subTicket = false;
                    let formulario_old = this.formulario_ticket;
                    let formulario = this.formulario_ticket;
                    formulario.patchValue({
                        key: null,
                        protocolo: this.formulario.value.key,
                        time: Date.now(),
                        ticketPrincipal: this.formulario.value.ticket,
                        qtd_interacao: 1,
                        status: "Finalizado",
                        status_ticket: "Finalizado",
                        ultima_interacao: Date.now(),
                        fim: Date.now(),
                        tempo: this.tempoAtendimento,
                        key_lotacao: this.key_lotacao,
                        key_usuario: this.key_usuario,
                        email: this.formulario.value.email,
                        telefone: this.formulario.value.telefone
                    })

                    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", formulario.value, "ticket", 6).then(ticket => {
                        this.formulario_ticket.patchValue({
                            key: ticket,
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        })
                        this.onSaveFormDinamicoChat(this.formulario_dinamico.value, this.formulario_ticket.value);
                        let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                        msg += 'Ticket: #' + this.formulario_ticket.value.key + this.service.ctrl
                        msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Status: ' + this.formulario_ticket.value.status + this.service.ctrl
                        msg += 'Status do ticket: ' + this.formulario_ticket.value.status_ticket + this.service.ctrl
                        msg += 'Data do encerramento: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.fim, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Aberta por : ' + this.formulario.value.nome + this.service.ctrl
                        msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                        msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                        if (this.formulario_ticket.value.email) {
                            let assunto = "Chamado do Ticket " + this.formulario_ticket.value.key;
                            let email = {
                                contato: this.formulario_ticket.value.email,
                                assunto: assunto,
                                mensagem: msg
                            }
                            this.service.sendMail(email)
                        }

                        let dados_ticket_body = {
                            status: 'Enviada',
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            nome_usuario: this.nomeUsuario,
                            key_lotacao: this.key_lotacao,
                            nome_lotacao: this.nome_lotacao,
                            type: 'chat',
                            ticket: ticket,
                            value: this.formulario_ticket.value.conteudoTexto,
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            tipo: 'publica'
                        }
                        let data_movto = {
                            ticket: this.formulario_ticket.value.key,
                            protocolo: this.formulario.value.key,
                            status: 'Finalizado',
                            key_lotacao: this.key_lotacao,
                            key_usuario: this.key_usuario,
                            key_lotacao_tramitacao: null,
                            key_usuario_destino: null,
                            key_puc: null,
                            time: Date.now(),
                            canal: this.formulario_ticket.value.origem,
                            conteudo: this.formulario_ticket.value.conteudoTexto
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                        //   this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_ticket.value.key, "ticket_body", dados_ticket_body);
                        let dados_body = {
                            status: 'Enviada',
                            key_lotacao: this.key_lotacao,
                            key_usuario: this.key_usuario,
                            nome_usuario: this.nomeUsuario,
                            nome_lotacao: this.nome_lotacao,
                            protocolo: this.formulario.value.protocolo,
                            nome: this.perfilUsuario,
                            id_canal: this.formulario.value.id_canal,
                            type: "chat",
                            tipo: 'publica',
                            value: this.formulario_ticket.value.conteudoTexto,
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm")
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.key, "chat_body", dados_body).then(res => {
                            this.onAtualizarHistoricoTicket(this.formulario.value.key, this.formulario_ticket.value.key)
                        })
                        this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario_ticket.value.protocolo).pipe(take(1)).subscribe(res => {
                            let data = JSON.parse(JSON.stringify(res));
                            let atendimento = {
                                key: data[0].key,
                                status: "Finalizado",
                                origem: this.formulario_ticket.value.origem,
                                menu: this.formulario_ticket.value.tipo_ticket,
                                tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                key_lotacao: this.key_lotacao,
                                key_usuario: this.key_usuario,
                                key_puc: this.formulario_ticket.value.key_puc,
                                key_solicitante: this.formulario_ticket.value.key_solicitante,
                                key_catalogo: this.formulario_ticket.value.key_catalogo,
                                key_item: this.formulario_ticket.value.key_item,
                                key_subitem: this.formulario_ticket.value.key_subitem,
                                time: Date.now(),
                                nome: this.formulario.value.nome_solicitante,
                                tempo: this.tempoAtendimento
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                        })
                    });
                    this.formulario_ticket = formulario_old;
                    this.subTicket = false;
                    this.ticketFinalizar = true;
                    this.onLoads();
                }
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onExcluirChat(key) {
        let res = this.chat.filter(obj => {
            return obj.key == key
        })
        if (res.length > 0) {
            const index = this.chat.indexOf(res[0]);
            this.chat.splice(index, 1);
            this.msgAtendimento = this.msgAtendimento - 1
        }
    }
    onAtualizarHistoricoTicket(protocolo, ticket) {
        this.chat_msg = [];
        let unMsg = this.genProvider.onGetAll('dados/' + this.path + '/chat/' + protocolo + '/chat_body', 'time').subscribe(data => {
            unMsg.unsubscribe();
            let lista = JSON.parse(JSON.stringify(data));
            /*
            let lista = msg.filter(res => {
              return !res.status_ticket
            })
            */
            lista.forEach(dados => {
                let dados_body = {
                    key: dados.key,
                    status_ticket: '0'
                };
                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + protocolo, "chat_body", dados_body);
                delete dados['key'];
                delete dados['status_ticket'];
                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + ticket, "ticket_body", dados);
            });
            this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Histórico do ticket atualizado.' });
        });

    }
    onAtualizarHistoricoMidiaTicket(protocolo, ticket) {
        this.chat_msg = [];
        /*
        let lista = this.midias_chat.filter(res => {
          return !res.status_ticket
        })
        */
        this.midias_chat.forEach(dados => {
            let dados_body = {
                key: dados.key,
                status_ticket: '0'
            };
            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + protocolo, "chat_body", dados_body);
            delete dados['key'];
            delete dados['status_ticket'];
            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + ticket, "ticket_body", dados);
        });
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Histórico de mídias do ticket atualizado.' });
    }
    onTramitar(subTicket?: boolean) {
        this.confirmationService.confirm({
            message: 'Confirma a tramitação do Ticket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Tramitação confirmada' });
                if (!subTicket) {
                    // ticket principal
                    this.ticketFinalizar = false;
                    let dados_ponto = {
                        key: this.ponto[0].key,
                        protocolo: '',
                        atendimento_status: "Livre",
                        canal: '',
                    }
                    if (this.formulario.value.ticket) {
                        this.formulario_ticket.patchValue({
                            key: this.formulario.value.ticket,
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        })
                    } else {
                        this.formulario_ticket.patchValue({
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        })
                    }

                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
                        this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(ticket => {
                            this.formulario_ticket.patchValue({
                                key: ticket
                            })
                            this.onSaveFormDinamicoChat(this.formulario_dinamico.value, this.formulario_ticket.value);
                            // salva os adados na tabela chat
                            let dados = {
                                key: this.formulario.value.protocolo,
                                status: 'Tramitado',
                                time: Date.now(),
                                //   key_usuario: '',
                                tempo: this.tempoAtendimento,
                                ticket: ticket
                            }
                            this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados).then(res => {
                                let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                                msg += 'Ticket: #' + ticket + this.service.ctrl
                                msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                                msg += 'Status: Tramitado ' + this.service.ctrl
                                msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_ticket.value.key_lotacao).nome + this.service.ctrl
                                msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                                msg += 'Esta é uma mensagem automática, não responda.'
                                if (this.formulario_ticket.value.email) {
                                    let assunto = "Chamado do Ticket " + ticket;
                                    let email = {
                                        contato: this.formulario_ticket.value.email,
                                        assunto: assunto,
                                        mensagem: msg
                                    }
                                    this.service.sendMail(email)
                                }
                                if (this.formulario_ticket.value.canal == 'whatsapp') {
                                    let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                                    msg += 'Ticket: #' + ticket + this.service.ctrl
                                    msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                                    msg += 'Status: Tramitado ' + this.service.ctrl + this.service.ctrl
                                    msg += '*Atendimento finalizado!*' + this.service.ctrl + this.service.ctrl + 'Esta é uma mensagem automática, não responda.'
                                    let id = this.formulario_ticket.value.id_canal
                                    let formulario = {
                                        origem: 'whatsapp',
                                        contato: id,
                                        mensagem: msg
                                    };
                                    this.service.enviarMsg(formulario);
                                    setTimeout(() => {
                                        this.onFinalizaChatUser(id);
                                    }, 2000);
                                }
                                let lot = this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_ticket.value.key_lotacao)
                                if (lot) {
                                    if (lot.email) {
                                        let assunto = "Notificação de chamado ticket Nº " + ticket;
                                        let email = {
                                            contato: lot.email,
                                            assunto: assunto,
                                            mensagem: msg
                                        }
                                        this.service.sendMail(email)
                                    }
                                }
                                let data_movto = {
                                    ticket: ticket,
                                    protocolo: this.formulario.value.key,
                                    status: 'Tramitado',
                                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                    key_lotacao: this.key_lotacao,
                                    key_lotacao_tramitacao: this.formulario_ticket.value.key_lotacao,
                                    key_usuario_destino: null,
                                    key_puc: null,
                                    time: Date.now(),
                                    canal: this.formulario_ticket.value.origem,
                                    conteudo: this.formulario_ticket.value.conteudoTexto
                                }
                                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                                let dados_body = {
                                    protocolo: this.formulario.value.key,
                                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                    nome_usuario: this.nomeUsuario,
                                    key_lotacao: this.key_lotacao,
                                    nome_lotacao: this.nome_lotacao,
                                    type: 'chat',
                                    status: 'Enviada',
                                    tipo: 'publica',
                                    ticket: ticket,
                                    telefone: this.formulario.value.id_canal,
                                    value: this.formulario_ticket.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: Date.now(),
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                    status_ticket: '1'

                                };
                                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.key, "chat_body", dados_body).then(res => {
                                    if (this.formulario_ticket.value.anexarConversa) {
                                        this.onAtualizarHistoricoTicket(this.formulario.value.protocolo, ticket);
                                    } else {
                                        this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + ticket, "ticket_body", dados_body);
                                        this.onAtualizarHistoricoMidiaTicket(this.formulario.value.protocolo, ticket);
                                    }
                                    if (this.parametros.webhookUrl) {
                                        this.onWebHook(this.formulario_ticket.value)
                                    }
                                    if (!this.parametros.noIntegracao) {
                                        this.onIntegracaoTicket(this.formulario_ticket.value)
                                    }
                                })
                            });
                        })
                        // fim
                    });
                    this.chat_msg = [];
                    this.myChat = false;
                    this.tramitarTicket = false;
                    this.onFinalTempoAtendimento();
                    this.onMostraAtendimento();

                } else {
                    console.log("sub-ticket")
                    // sub-ticket
                    let formulario_old = this.formulario_ticket;
                    let formulario = this.formulario_ticket;
                    formulario.patchValue({
                        qtd_interacao: 1,
                        status: "Novo",
                        status_ticket: "Novo",
                        time: Date.now(),
                        ultima_interacao: Date.now(),
                        key: null,
                        protocolo: this.formulario.value.key,
                        ticketPrincipal: this.formulario.value.ticket,
                        tramitacao: '1',
                        integrado: '0',
                        status_integracao: null,
                        busca: this.formulario_ticket.value.busca + ' ' + this.formulario_ticket.value.conteudoTexto,
                        email: this.formulario.value.email,
                        telefone: this.formulario.value.telefone
                    })
                    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", formulario.value, "ticket", 6).then(ticket => {
                        this.formulario_ticket.patchValue({
                            key: ticket,
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        })
                        this.onSaveFormDinamicoChat(this.formulario_dinamico.value, this.formulario_ticket.value);
                        let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                        msg += 'Ticket: #' + ticket + this.service.ctrl
                        msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Status: Tramitado ' + this.service.ctrl
                        msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_ticket.value.key_lotacao).nome + this.service.ctrl
                        msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                        msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                        if (this.formulario_ticket.value.email) {
                            let assunto = "Chamado do Ticket " + ticket;
                            let email = {
                                contato: this.formulario_ticket.value.email,
                                assunto: assunto,
                                mensagem: msg
                            }
                            this.service.sendMail(email)
                        }
                        let lot = this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_ticket.value.key_lotacao)
                        if (lot) {
                            if (lot.email) {
                                let assunto = "Notificação de chamado ticket Nº " + ticket;
                                let email = {
                                    contato: lot.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }
                        }
                        let data_movto = {
                            ticket: ticket,
                            protocolo: this.formulario.value.protocolo,
                            status: 'Tramitado',
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            key_lotacao: this.key_lotacao,
                            key_lotacao_tramitacao: this.formulario_ticket.value.key_lotacao,
                            key_usuario_destino: null,
                            key_puc: null,
                            time: Date.now(),
                            canal: this.formulario_ticket.value.origem,
                            conteudo: this.formulario_ticket.value.conteudoTexto
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                        let dados_body = {
                            protocolo: this.formulario.value.protocolo,
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            nome_usuario: this.nomeUsuario,
                            key_lotacao: this.key_lotacao,
                            nome_lotacao: this.nome_lotacao,
                            type: 'chat',
                            status: 'Enviada',
                            tipo: 'publica',
                            ticket: this.formulario_ticket.value.key,
                            telefone: this.formulario.value.id_canal,
                            value: this.formulario_ticket.value.conteudoTexto,
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            status_ticket: '1'
                        };
                        this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.key, "chat_body", dados_body).then(res => {
                            if (this.formulario_ticket.value.anexarConversa) {
                                this.onAtualizarHistoricoTicket(this.formulario.value.protocolo, ticket);
                            } else {
                                this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + ticket, "ticket_body", dados_body);
                                this.onAtualizarHistoricoMidiaTicket(this.formulario.value.protocolo, ticket);
                            }
                            if (this.parametros.webhookUrl) {
                                this.onWebHook(this.formulario_ticket.value)
                            }
                            if (!this.parametros.noIntegracao) {
                                this.onIntegracaoTicket(this.formulario_ticket.value)
                            }
                        })
                        alert("Ticket Número: " + ticket)
                    });
                    this.formulario_ticket = formulario_old;
                    this.tramitarSubTicket = false;
                    this.subTicket = false;
                    this.ticketFinalizar = true;
                    // this.upLoadFile(this.uploadedFiles, this.formulario.value.protocolo)
                    this.upLoadFile();
                }
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onTransferirPuc() {
        this.confirmationService.confirm({
            message: 'Confirma a transferência do Ticket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.transferirPuc = false;
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Transferência confirmada' });
                this.ticketFinalizar = false;
                this.onExcluirChat(this.formulario.value.key);
                let dados_ponto = {
                    key: this.ponto[0].key,
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                }
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
                    let dados = {
                        key: this.formulario.value.key,
                        status: 'Novo',
                        key_puc: this.formulario_ticket.value.key_puc,
                        nome_puc: this.formulario_ticket.value.nome_puc,
                        time: Date.now(),
                    }
                    this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados).then(res => {
                        this.formulario_ticket.patchValue({
                            qtd_interacao: parseInt(this.formulario_ticket.value.qtd_interacao) + 1,
                            status: "Novo",
                            status_ticket: "Novo",
                            time: Date.now(),
                            ultima_interacao: Date.now(),
                            integrado: '0'
                            // fim: Date.now(),
                            // tempo: this.tempoAtendimento
                        })
                        this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(res => {
                            let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                            msg += 'Ticket: #' + this.formulario_ticket.value.key + this.service.ctrl
                            msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Status: Tramitado ' + this.service.ctrl
                            msg += 'Unidade Operacional: ' + this.formulario_ticket.value.nome_lotacao + this.service.ctrl
                            msg += 'Serviço: ' + this.formulario_ticket.value.nome_catalogo + this.service.ctrl
                            msg += 'Item: ' + this.formulario_ticket.value.nome_subcatalogo + this.service.ctrl
                            msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                            msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                            if (this.formulario.value.email) {
                                let assunto = "Chamado n." + this.formulario.value.key;
                                let email = {
                                    contato: this.formulario.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }

                            let dados_ticket_body = {
                                status: 'Enviada',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: '',
                                nome_lotacao: '',
                                type: 'chat',
                                ticket: this.formulario_ticket.value.key,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            let data_movto = {
                                ticket: this.formulario_ticket.value.key,
                                protocolo: this.formulario.value.key,
                                status: 'Trasferido puc',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                key_lotacao: this.key_lotacao,
                                key_lotacao_tramitacao: null,
                                key_usuario_destino: null,
                                key_puc: this.formulario_ticket.value.key_puc,
                                time: Date.now(),
                                canal: this.formulario_ticket.value.origem,
                                conteudo: this.formulario_ticket.value.conteudoTexto
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                            //  this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_ticket.value.key, "ticket_body", dados_ticket_body);
                            let dados_body = {
                                protocolo: this.formulario.value.key,
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.key_lotacao,
                                nome_lotacao: this.nome_lotacao,
                                type: 'chat',
                                status: 'Enviada',
                                tipo: 'publica',
                                ticket: this.formulario_ticket.value.key,
                                telefone: this.formulario.value.id_canal,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                status_ticket: '1'
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.key, "chat_body", dados_body).then(res => {
                                this.onAtualizarHistoricoTicket(this.formulario.value.key, this.formulario_ticket.value.key);
                            })
                        });
                    });
                });
                this.chat_msg = [];
                this.myChat = false;
                this.onFinalTempoAtendimento();
                this.onMostraAtendimento();
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onTransferirAgente() {
        let NovoAtendente: null
        this.genProvider.onGetKey('login', this.formulario_ticket.value.key_usuario).pipe(take(1)).subscribe(data => {
            let pessoa = JSON.parse(JSON.stringify(data));
            //    console.log(pessoa)
            NovoAtendente = pessoa.nome.split(' ').slice(0, 1).join(' ');
        });

        this.confirmationService.confirm({
            message: 'Confirma a transferência do Ticket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.transferirAgente = false;
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Transferência confirmada' });
                this.ticketFinalizar = false;
                let dados_ponto = {
                    key: this.ponto[0].key,
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                }
                this.onExcluirChat(this.formulario.value.key);
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
                    let dados = {
                        status: 'Distribuido',
                        key: this.formulario.value.key,
                        time: Date.now(),
                        key_usuario: this.formulario_ticket.value.key_usuario,
                        nome_usuario: NovoAtendente
                    }
                    this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados).then(res => {
                        this.formulario_ticket.patchValue({
                            qtd_interacao: parseInt(this.formulario_ticket.value.qtd_interacao) + 1,
                            status_ticket: "Novo",
                            time: Date.now(),
                            ultima_interacao: Date.now(),
                            integrado: '0'
                        })
                        this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(res => {

                            let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                            msg += 'Ticket: #' + this.formulario_ticket.value.key + this.service.ctrl
                            msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Seu atendimento foi transferido para o atendente *' + NovoAtendente + "*" + this.service.ctrl + this.service.ctrl
                            msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                            if (this.formulario.value.email) {
                                let assunto = "Chamado n. " + this.formulario.value.key;
                                let email = {
                                    contato: this.formulario.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }


                            let dados_ticket_body = {
                                status: 'Enviada',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: '',
                                nome_lotacao: '',
                                type: 'chat',
                                ticket: this.formulario_ticket.value.key,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            let data_movto = {
                                ticket: this.formulario_ticket.value.key,
                                protocolo: this.formulario.value.key,
                                status: 'Trasferido agente',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                key_lotacao: this.key_lotacao,
                                key_lotacao_tramitacao: null,
                                key_usuario_destino: this.formulario_ticket.value.key_usuario,
                                key_puc: null,
                                time: Date.now(),
                                canal: this.formulario_ticket.value.origem,
                                conteudo: this.formulario_ticket.value.conteudoTexto
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                            //            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_ticket.value.key, "ticket_body", dados_ticket_body);
                            let dados_body = {
                                protocolo: this.formulario.value.key,
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.key_lotacao,
                                nome_lotacao: this.nome_lotacao,
                                type: 'chat',
                                status: 'Enviada',
                                tipo: 'publica',
                                ticket: this.formulario_ticket.value.key,
                                telefone: this.formulario.value.id_canal,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                status_ticket: '1'
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario.value.key, "chat_body", dados_body).then(res => {
                                this.onAtualizarHistoricoTicket(this.formulario.value.key, this.formulario_ticket.value.key);
                            })
                        });
                    });
                });
                this.chat_msg = [];
                this.myChat = false;
                this.onFinalTempoAtendimento();
                this.onMostraAtendimento();
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onMostraAtendimento() {
        this.onGetFiltroChat("Atendimento");
    }
    onSaveObservacao() {
        this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Observação foi atualizada.' });
        }).catch(e => {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
            //  console.log(e);
        });
    }
    onNome() {
        if (this.formulario_cadastro_pessoa.value.nome) {
            this.formulario_cadastro_pessoa.patchValue({
                nome: this.formulario_cadastro_pessoa.value.nome.toUpperCase()
            });
        }
    }
    onEmail(valor) {
        let res = this.validaCampoProvider.validaEmail(this.formulario_pessoa.value.email);
        if (res) {
            this.formulario_pessoa.patchValue({
                email: res
            })
        } else {
            this.formulario_pessoa.patchValue({
                email: ''
            })

            this.messageService.add({ severity: 'info', summary: 'Erro!', detail: 'Ops! email ' + valor + ', inválido' });
        }
    }
    openResposta() {
        this.resposta = !this.resposta
    }
    openIntervalo() {
        this.intervalo = !this.intervalo
    }
    openPonto() {
        this.pontoAgente = !this.pontoAgente
    }
    onInicioTempoAtendimento() {
        this.timerAtendimento = setInterval(() => {
            this.horasAtendimento();
        }, 1000);
    }
    openMidia(res) {
        this.midia = {
            type: res.type,
            file: res.value,
            lat: res.lat || 0,
            lng: res.lng || 0
        }

        this.mostraMidia = true;

    }
    openAtendeChat() {
        this.myChat = false;
        this.atendeChat = true;
    }
    getFila() {
        alert("teste")
    }
    // funcoes do chat
    onGetFiltroChat(res) {
        this.frmMensagem = res
        //  console.log(this.unSubChatFila)
        // if (this.unSubChatFila.closed) {
        //   this.onLoadChatFila();
        // } else {
        //  this.dados = this.onFiltroStatusChat();
        // }
    }

    onChat(event: any) {
        if (this.unSubMsg$) {
            this.unSubMsg$.unsubscribe();
        }
        //  console.log (event)
        if ((event.origem == "voip") || (event.origem == "email")) {
            this.lockSender = true;
        }
        this.genProvider.onGetKey('dados/' + this.path + '/chat', event.protocolo).pipe(take(1)).subscribe(res => {
            let data = JSON.parse(JSON.stringify(res));
            if ((data.key_usuario != this.parametros.key_usuario) && (data.key_usuario) && data.menu != 'Devolvido') {
                alert("Chamado em atendimento.")
            } else {
                if ((event.status == 'Finalizado') || (this.agenteStatus == 'Ponto Off') || (this.agenteStatus == 'Ponto Off')) {
                    this.myChat = false;
                    //  this.onLoadChat(event.protocolo);
                } else {
                    this.onFinalTempoAtendimento();
                    if ((!event.nome) && (event.nome_solicitante)) {
                        event.nome = event.nome_solicitante
                    }
                    this.chat_msg = [];
                    this.tempoAtendimento = ''
                    this.protocolo = event.protocolo;
                    this.ticket = event.ticket;
                    this.myChat = true;
                    let canal = event.origem
                    if (event.origem == 'voip') {
                        canal = 'whatsapp';
                    }
                    let dados_ponto = {
                        key: this.ponto[0].key,
                        protocolo: event.protocolo,
                        canal: canal,
                        atendimento_time: Date.now(),
                        atendimento_status: 'Em Atendimento'
                    }
                    let dados = {
                        key: event.protocolo,
                        status: 'Em Atendimento',
                        novo: '0',
                        nome_usuario: this.perfilUsuario,
                        inicio: this.inicio_atendimento,
                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                        time: Date.now(),
                        data_envio: new DatePipe('pt-BR').transform(
                            Date.now(),
                            'd, MMMM yyyy - HH:mm'
                        )
                    }
                    this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados);
                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto, Date.now());
                    this.formSend = '';
                    let telefone = this.validaCampoProvider.formataTelefoneDDD(event.telefone)
                    this.genProvider.onGetfilter('dados/' + this.parametros.path + '/pessoa', 'telefone', telefone).pipe(take(1)).subscribe(res => {
                        let pessoa = JSON.parse(JSON.stringify(res));
                        if (pessoa.length > 0) {
                            this.formulario_pessoa.patchValue(pessoa[0])
                            this.formulario.patchValue({
                                nome: pessoa[0].nome,
                                telefone: pessoa[0].telefone,
                                email: pessoa[0].email
                            })
                            console.log(this.formulario.value)
                        } else {
                            this.formulario_pessoa.patchValue({
                                nome: event.nome,
                                email: event.email,
                                telefone: this.validaCampoProvider.formataTelefoneDDD(event.telefone),
                                status: "Ativo",
                                tipo: "Externo"
                            })
                        }
                    })
                    if (this.isStart) {

                        setTimeout(() => {
                            this.genProvider.onGetKey('dados/' + this.path + '/chat', event.protocolo).pipe(take(1)).subscribe(res => {
                                let data = JSON.parse(JSON.stringify(res));
//                                if ((data.key_agente != this.parametros.key_usuario) && (data.key_agente)) {
                                    if ((data.key_usuario != this.parametros.key_usuario) && (data.key_usuario)) {
                                    this.onRemoveAtendimento(data);
                                    alert("Chamado já em atendimento por outro agente.")
                                }
                            })
                        }, 2000);

                        this.onLoadChat(event.protocolo);
                        this.formulario.reset();
                        this.friend = event.nome;
                        this.friendLetra = event.nome.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').substring(0, 1);
                        this.canal = event.origem;
                        this.data_hora = event.data_hora;
                        this.id_canal = event.id_canal;
                        if (event.telefone) {
                            this.telefone = this.validaCampoProvider.formataTelefoneDDD(event.telefone)
                        } else {
                            this.telefone = ''
                        }
                        this.image = event.image;
                        this.origem = event.origem;
                        if (event.status == "Novo") {
                            this.inicio_atendimento = Date.now();
                        } else {
                            if (!event.inicio) {
                                this.inicio_atendimento = Date.now();
                            } else {
                                this.inicio_atendimento = event.inicio
                            }
                        }

                        event.time = Date.now();
                        event.inicio = this.inicio_atendimento
                        this.formulario.patchValue(event);
                        this.onInicioTempoAtendimento();
                        this.onGetFiltroChat("Atendimento");
                        this.titulo = 'Chat';
                    }
                }
            }
        })
    }
    onRemoveAtendimento(data) {
        this.chat_msg = [];
        data.receive = "Chamado já em atendimento por outro agente..."
        //  this.genProvider.onSaveObjeto('dados', this.parametros.path, 'logAtendimento', data);
        this.protocolo = null;
        this.ticket = null;
        this.myChat = false;
        this.onFreeAtendente();
        let res = this.dados.filter(obj => {
            return obj.protocolo == data.protocolo;
        });
        if (res.length > 0) {
            const index = this.dados.indexOf(res[0]);
            this.dados.splice(index, 1);
        }
    }
    onSaveContatoZap() {
        let contato = {
            key: this.formulario.value.id_canal,
            id_canal: this.formulario.value.id_canal,
            chat: true,
            acesso: 2,
            contato_tipo: 'interno',
            online: 'on',
            bloqueado: this.formulario.value.bloqueado,
            coordenadas: this.formulario.value.coordenadas,
            data_criacao: Date.now(),
            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
            data_nascimento: this.formulario.value.data_nascimento,
            nivel: '1',
            nome: this.formulario.value.nome,
            status: "Ativo",
            telefone: this.formulario.value.id_canal,
            time: Date.now(),
            tipo: "Chat",
            protocolo: this.formulario.value.protocolo,
            uid: '',
            opcao: '',
            menu: this.formulario.value.menu,
            chatLive: true

        }
        let comando = {
            comando: "atualizaContato",
            dia: new DatePipe("pt-BR").transform(
                Date.now(),
                "d, MMMM yyyy - HH:mm"
            ),
            time: Date.now(),
            key_usuario: this.key_usuario,
            nome: this.usuario,
            protocolo: this.formulario.value.protocolo,
            contato: contato,
            id_canal: this.formulario.value.id_canal
        }
        this.genProvider.onSaveDocKey('dados/' + this.parametros.path + '/srvComando', this.parametros.telefone, comando).then(t => { }).catch(e => {
            //console.log('Atenção, erro ao salvar dados.')
        });
        this.service.atualizaContato(contato);
    }
    onPendente() {
        this.myChat = false;
        this.chat_msg = [];
        let dados_msg = {
            key: this.formulario.value.protocolo,
            status: 'Pendente',
            finalizada: 'nao',
            time: Date.now()
        }
        this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados_msg).then(res => {
            this.onFinalTempoAtendimento();
            this.onFreeAtendente();
            this.tempoAtendimento = null;
            this.onMostraAtendimento();
        })
    }
    onTicketFinalizar() {
        this.formulario_ticket.reset();
        this.template = [];
        let texto = '';
        let busca = '';
        this.historico_ticket = this.chat_msg
        this.midias_chat = [];
        this.chat_msg.forEach(e => {
            if (e.type == 'chat' || e.type == 'interactive') {
                texto = texto + e.value + this.service.ctrl;
            } else {
                this.midias_chat.push(e)
            }
        });
        busca = texto.replace(/\n/g, ' ');
        busca = busca.replace(/<br>/g, ' ');
        texto = texto + "***************** FIM DO CHAT *****************" + this.service.ctrl;

        this.formulario_ticket.patchValue({
            status: 'Novo',
            status_ticket: "Novo",
            enviar_resposta_solicitante: false,
            protocolo: this.formulario.value.protocolo,
            canal: this.formulario.value.origem,
            id_canal: this.formulario.value.id_canal,
            data_criacao: Date.now(),
            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
            nome_solicitante: this.formulario.value.nome,
            compartilhado: false,
            copia_usuario: false,
            distribuicao: false,
            encaminhado: false,
            encerrado: false,
            distribuido: true,
            nome_usuario: null,
            key_agente: this.parametros.key_agente,
            key_solicitante: this.formulario.value.key_solicitante,
            key_puc: this.key_central,
            time: Date.now(),
            key_responsavel: null,
            nome_responsavel: null,
            conteudo: texto.replace(/\n/g, '<br>'),
            conteudoTexto: texto,
            qtd_interacao: 0,
            ultima_interacao: '',
            tramitacao: '1',
            busca: busca
        })
        if (this.plataforma == 'anonima') {
            this.formulario_ticket.patchValue({
                key_solicitante: '000000000000',
                nome_solicitante: 'Usuário Anônimo',
                id_canal: '000000000000'
            })
        }

        let unSub = this.genProvider.onGetFilterOrder("dados/" + this.parametros.path + "/chat_dinamico", "time", "protocolo", this.formulario.value.protocolo).subscribe(data => {
            unSub.unsubscribe();
            let dados = JSON.parse(JSON.stringify(data));
            if (dados.length > 0) {
                this.formulario_dinamico = this.formBuilder.group(dados[0]);
                unSub = this.genProvider.onGetAll('dados/' + this.parametros.path + '/template/' + this.formulario_dinamico.value.key_template + '/template_body', 'numero').subscribe(data => {
                    unSub.unsubscribe();
                    let temp = JSON.parse(JSON.stringify(data))
                    let template = temp.filter(res => {
                        return res.status != 'cancelado'
                    });
                    this.template = template.map(col => ({ title: col.field.toUpperCase(), field: col.field, numero: col.numero, type: col.type, rows: col.rows, noDisplay: col.noDisplay }));
                    /*
                    let rel = this.template.filter(obj => {
                      return obj.field == 'relato'
                    })
                    if (rel.length > 0) {
                      let texto = this.formulario_dinamico.value.relato
                      this.formulario_ticket.patchValue({
                        status: 'Novo',
                        status_ticket: "Novo",
                        enviar_resposta_solicitante: false,
                        protocolo: this.formulario.value.protocolo,
                        canal: this.formulario.value.origem,
                        id_canal: this.formulario.value.id_canal,
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        nome_solicitante: this.formulario.value.nome,
                        compartilhado: false,
                        copia_usuario: false,
                        distribuicao: false,
                        encaminhado: false,
                        encerrado: false,
                        distribuido: true,
                        nome_usuario: this.nomeUsuario,
                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                        key_solicitante: this.formulario.value.key_usuario,
                        key_puc: this.key_central,
                        time: Date.now(),
                        key_responsavel: null,
                        nome_responsavel: null,
                        conteudo: texto.replace(/\n/g, '<br>'),
                        conteudoTexto: texto,
                        qtd_interacao: 0,
                        ultima_interacao: '',
                        tramitacao: '1',
                        busca: busca
                      })
                      if (this.plataforma == 'anonima') {
                        this.formulario_ticket.patchValue({
                          key_solicitante: '000000000000',
                          nome_solicitante: 'Usuário Anônimo',
                          id_canal: '000000000000'
                        })
                      }
                    }
                    */
                });
            }
        });
        this.ticketFinalizar = true;

    }

    onLoadCatalogo(key_central) {
        this.catalogo = []
        let joins = [{ key: "key_catalogo", table: "catalogo" }]
        this.genProvider.onGetAll('dados/' + this.path + '/puc/' + key_central + '/catalogo', 'key_catalogo', joins).pipe(takeUntil(this.unSub$)).subscribe(data => {
            let catalogo = JSON.parse(JSON.stringify(data));
            catalogo.forEach(s => {
                if (s.catalogo) {
                    let item = {
                        key: s.key,
                        key_catalogo: s.key_catalogo,
                        nome: s.catalogo.nome,
                        descricao: s.catalogo.descricao,
                        tipo: s.catalogo.tipo,
                        disponivel: s.catalogo.disponivel,
                        status: s.catalogo.status
                    }
                    this.catalogo.push(item)
                }
            });
        });
    }
    onLoadCatalogoItem(key) {
        if (key) {
            this.genProvider.onGetKey('dados/' + this.path + '/catalogo', key).pipe(take(1)).subscribe(data => {
                let temp = JSON.parse(JSON.stringify(data));
                if (temp.key_template) {
                    this.onDisplayFormDinamico(temp.key_template);
                    this.key_template = temp.key_template;
                }
            },
                err => console.log(err)
            );
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + key + '/item', "nome").pipe(take(1)).subscribe(data => {
                this.itemCatalogo = JSON.parse(JSON.stringify(data));
            },
                err => console.log(err)
            );
        } else {
            this.template = [];
        }
    }
    onLoadCatalogoItemChat(key) {
        if (key) {
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + key + '/item', "nome").pipe(take(1)).subscribe(data => {
                this.itemCatalogo = JSON.parse(JSON.stringify(data));
            },
                err => console.log(err)
            );
        }
    }
    onLoadCatalogoSubItem(key) {
        this.onLoadSla(key)
        if (this.formulario_ticket.value.key_catalogo) {
            this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + this.formulario_ticket.value.key_catalogo + '/item/' + key + '/subitem', "nome").pipe(take(1)).subscribe(data => {
                this.subItemCatalogo = JSON.parse(JSON.stringify(data));
            },
                err => console.log(err)
            );
        }

    }
    onLoadSla(key) {
        this.genProvider.onGetFilterOrder('dados/' + this.path + '/sla', "nome", "key_item", key).pipe(take(1)).subscribe(data => {
            this.sla = JSON.parse(JSON.stringify(data));
            if (this.sla.length > 0) {
                var time = new Date(this.formulario_ticket.value.data_criacao);
                var soma = (this.sla[0].tmr * 60 * 60 * 1000)
                var date = new Date(time.getTime() + soma);
                let tempo = Date.parse(date.toString());
                this.formulario_ticket.patchValue({
                    prazo_resolucao: tempo,
                    prazo_resolucaoM: new DatePipe("pt-BR").transform(tempo, "d/MM/yyyy - HH:mm")
                })
            }
        },
            err => console.log(err)
        );
    }
    onLoadPuc() {
        this.genProvider.onGetAll('dados/' + this.path + '/puc', "nome").pipe(take(1)).subscribe(data => {
            this.puc = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onLoadAgente() {
        this.genProvider.onGetAll('login', "nome").pipe(take(1)).subscribe(data => {
            this.agente = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onGetAgente() {
        let res = this.agente.filter(obj => {
            return obj.key == this.formulario_ticket.value.key_agente;
        });
        if (res.length > 0) {
            this.formulario_ticket.patchValue({
                nome_usuario: res[0].nome
            })
        }
    }
    onLoadLotacaoPuc(key) {
        this.genProvider.onGetKey('dados/' + this.path + '/pessoa', key).pipe(take(1)).subscribe(data => {
            let pessoa = JSON.parse(JSON.stringify(data));
            this.genProvider.onGetFilterOrder('dados/' + this.path + '/puc', "nome", "key_lotacao", pessoa.key_lotacao).pipe(take(1)).subscribe(data => {
                let res = JSON.parse(JSON.stringify(data));
                if (res.length > 0) {
                    this.key_central = res[0].key;
                    this.assistenteAtendimento = res[0].assistenteAtendimento;
                    this.geoLocalizacao = res[0].geoLocalizacao;
                    this.nome_central = res[0].nome;
                    this.nome_lotacao = res[0].nome;
                    this.key_lotacao = res[0].key;
                    this.plataforma = res[0].plataforma;
                    if (this.plataforma == 'anonima') {
                        this.usuarioAnonimo = true
                    } else {
                        this.usuarioAnonimo = false
                    }
                    this.onLoadCatalogo(this.key_central);
                    this.onLoadChatFila();
                    this.onCombos();
                } else {
                    alert("Atenção, não existe central de atendimento para este agente!")
                }
            })
        });

    }
    openVoip() {
        this.voip = true;
        this.call_number = '';
    }
    openTicketVoip() {
        if (this.ponto.length > 0) {
            if (this.ponto[0].atendimento_status == "Livre") {
                this.formulario_pessoa.reset();
                this.myBtnVoip = true;
                this.onVoip();
            } else {
                if ((this.ponto[0].canal == 'voip') && (this.ponto[0].atendimento_status == "Em Atendimento")) {
                    this.onVoip();
                } else {
                    alert("Agente em atendimento, por favor finalize o atendimento atual para atender a chamada de voz.");
                }
            }
        } else {
            alert("Agente sem ponto aberto para atendimento!");
        }

    }
    onClickNumber(value) {
        this.call_number += value
    }
    onClearNumber() {
        this.call_number = ''
    }
    onEncerrarVoip() {
        this.confirmationService.confirm({
            message: 'Confirma o encerramento  do Ticket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Encerramento confirmado' });
                this.ticketFinalizar = false;
                let dados_ponto = {
                    key: this.ponto[0].key,
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                }
                this.myBtnVoip = false;
                this.encerrarAtendimentoVoip = false;
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
                    this.genProvider.onIncrementKeyDateTime().then((keyValue: any) => {
                        this.formulario_ticket.patchValue({
                            protocolo: keyValue,
                            qtd_interacao: 1,
                            status: "Finalizado",
                            key_lotacao: this.key_lotacao,
                            key_agente: this.key_usuario,
                            time: Date.now(),
                            ultima_interacao: Date.now(),
                            id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                            key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                            nome_solicitante: this.formulario_pessoa.value.nome,
                            tempo: this.tempoAtendimento,
                            tramitacao: '0',
                            enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email,
                            integrado: '0',
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        })
                        if (this.plataforma == 'anonima') {
                            this.formulario_ticket.patchValue({
                                key_solicitante: '000000000000',
                                nome_solicitante: 'Usuário Anônimo',
                                id_canal: '000000000000'
                            })
                        }
                        let data = this.formulario_ticket.value;
                        delete data["key"]

                        this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/ticket", data.ticket, data).then(() => {
                            this.formulario_ticket.patchValue({
                                key: this.formulario_ticket.value.ticket
                            })
                            this.onSaveFormDinamicoVoip(this.formulario_dinamico.value, this.formulario_ticket.value);
                            //            this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket").then(() => {

                            let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                            msg += 'Ticket: #' + this.formulario_ticket.value.key + this.service.ctrl
                            msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Data do Encerramento: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Status: Encerrado' + this.service.ctrl
                            msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                            msg += '<b>Esta é uma mensagem automática, não responda.</b>'

                            if (this.formulario.value.email) {
                                let assunto = "Chamado do Ticket " + this.formulario_ticket.value.key;
                                let email = {
                                    contato: this.formulario.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }

                            let dados_ticket_body = {
                                status: 'Enviada',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.formulario_ticket.value.key_lotacao,
                                nome_lotacao: this.formulario_ticket.value.nome_lotacao,
                                type: 'chat',
                                ticket: this.formulario_ticket.value.key,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            //   this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_ticket.value.key, "ticket_body", dados_ticket_body);
                            let dados = {
                                protocolo: this.formulario_ticket.value.protocolo,
                                status: 'Finalizado',
                                key_puc: this.formulario_ticket.value.key_puc,
                                key_catalogo: this.formulario_ticket.value.key_catalogo,
                                key_item: this.formulario_ticket.value.key_item,
                                key_subitem: this.formulario_ticket.value.key_subitem,
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.formulario_ticket.value.key_lotacao,
                                type: 'chat',
                                ticket: this.formulario_ticket.value.key,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: this.formulario_ticket.value.data_criacao,
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                                key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                                nome_solicitante: this.formulario_pessoa.value.nome,
                                nome: this.formulario_pessoa.value.nome,
                                tempo: this.formulario_ticket.value.tempo,
                                email: this.formulario_pessoa.value.email || null,
                                enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email
                            }
                            if (this.plataforma == 'anonima') {
                                dados = {
                                    protocolo: this.formulario_ticket.value.protocolo,
                                    status: 'Finalizado',
                                    key_puc: this.formulario_ticket.value.key_puc,
                                    key_catalogo: this.formulario_ticket.value.key_catalogo,
                                    key_item: this.formulario_ticket.value.key_item,
                                    key_subitem: this.formulario_ticket.value.key_subitem,
                                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                    nome_usuario: this.nomeUsuario,
                                    key_lotacao: this.formulario_ticket.value.key_lotacao,
                                    type: 'chat',
                                    ticket: this.formulario_ticket.value.key,
                                    value: this.formulario_ticket.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: this.formulario_ticket.value.data_criacao,
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                    nome: this.formulario_pessoa.value.nome,
                                    tempo: this.formulario_ticket.value.tempo,
                                    email: this.formulario_pessoa.value.email || null,
                                    enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email,
                                    key_solicitante: '000000000000',
                                    nome_solicitante: 'Usuário Anônimo',
                                    id_canal: '000000000000'
                                }
                            }

                            this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/chat", dados.protocolo, dados);
                            let data_movto = {
                                ticket: this.formulario_ticket.value.key,
                                protocolo: this.formulario.value.key,
                                status: 'Finalizado',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                key_lotacao: this.key_lotacao,
                                key_lotacao_tramitacao: null,
                                key_usuario_destino: null,
                                key_puc: null,
                                time: Date.now(),
                                canal: this.formulario_ticket.value.origem,
                                conteudo: this.formulario_ticket.value.conteudoTexto
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                            let dados_body = {
                                protocolo: this.formulario.value.key,
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.key_lotacao,
                                nome_lotacao: this.nome_lotacao,
                                type: 'chat',
                                status: 'Enviada',
                                tipo: 'publica',
                                ticket: this.formulario_ticket.value.key,
                                telefone: this.formulario.value.id_canal,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                status_ticket: '1'
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + dados.protocolo, "chat_body", dados_body).then(res => {
                                let ticket = this.formulario_ticket.value.key;
                                let protocolo = dados.protocolo
                                this.onAtualizarHistoricoTicket(protocolo, ticket);
                            })
                            this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario_ticket.value.protocolo).pipe(take(1)).subscribe(res => {
                                let data = JSON.parse(JSON.stringify(res));
                                if (data.length > 0) {
                                    let atendimento = {
                                        key: data[0].key,
                                        status: "Finalizado",
                                        menu: this.formulario_ticket.value.tipo_ticket,
                                        origem: 'voip',
                                        protocolo: this.formulario_ticket.value.protocolo,
                                        tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                        key_lotacao: this.formulario_ticket.value.key_solicitante_lotacao,
                                        key_puc: this.formulario_ticket.value.key_puc,
                                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                        key_solicitante: this.formulario_ticket.value.key_solicitante,
                                        key_catalogo: this.formulario_ticket.value.key_catalogo,
                                        key_item: this.formulario_ticket.value.key_item,
                                        key_subitem: this.formulario_ticket.value.key_subitem,
                                        time: Date.now(),
                                        nome: this.formulario_ticket.value.nome_solicitante,
                                        tempo: this.formulario_ticket.value.tempo,
                                    }
                                    if (this.plataforma == 'anonima') {
                                        atendimento = {
                                            key: data[0].key,
                                            status: "Finalizado",
                                            menu: this.formulario_ticket.value.tipo_ticket,
                                            origem: 'voip',
                                            protocolo: this.formulario_ticket.value.protocolo,
                                            tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                            key_lotacao: this.formulario_ticket.value.key_solicitante_lotacao,
                                            key_puc: this.formulario_ticket.value.key_puc,
                                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                            key_catalogo: this.formulario_ticket.value.key_catalogo,
                                            key_item: this.formulario_ticket.value.key_item,
                                            key_subitem: this.formulario_ticket.value.key_subitem,
                                            time: Date.now(),
                                            tempo: this.formulario_ticket.value.tempo,
                                            key_solicitante: '000000000000',
                                            nome: 'Usuário Anônimo'
                                        }
                                    }
                                    this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                                } else {
                                    let atendimento = {
                                        status: "Finalizado",
                                        menu: this.formulario_ticket.value.tipo_ticket,
                                        origem: 'voip',
                                        protocolo: this.formulario_ticket.value.protocolo,
                                        tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                        key_lotacao: this.formulario_ticket.value.key_solicitante_lotacao,
                                        key_puc: this.formulario_ticket.value.key_puc,
                                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                        key_solicitante: this.formulario_ticket.value.key_solicitante,
                                        key_catalogo: this.formulario_ticket.value.key_catalogo,
                                        key_item: this.formulario_ticket.value.key_item,
                                        key_subitem: this.formulario_ticket.value.key_subitem,
                                        ticket: this.formulario_ticket.value.ticket,
                                        time: Date.now(),
                                        nome: this.formulario_ticket.value.nome_solicitante,
                                        tempo: this.formulario_ticket.value.tempo,
                                    }
                                    if (this.plataforma == 'anonima') {
                                        atendimento = {
                                            status: "Finalizado",
                                            menu: this.formulario_ticket.value.tipo_ticket,
                                            origem: 'voip',
                                            protocolo: this.formulario_ticket.value.protocolo,
                                            tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                            key_lotacao: this.formulario_ticket.value.key_solicitante_lotacao,
                                            key_puc: this.formulario_ticket.value.key_puc,
                                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                            key_catalogo: this.formulario_ticket.value.key_catalogo,
                                            key_item: this.formulario_ticket.value.key_item,
                                            key_subitem: this.formulario_ticket.value.key_subitem,
                                            ticket: this.formulario_ticket.value.ticket,
                                            time: Date.now(),
                                            tempo: this.formulario_ticket.value.tempo,
                                            key_solicitante: '000000000000',
                                            nome: 'Usuário Anônimo'
                                        }
                                    }
                                    // this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/atendimento", atendimento, "atendimento");
                                    this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento);
                                }
                                //  this.formulario_ticket.reset();
                                //  this.formulario.reset();
                                this.onFinalTempoAtendimento();
                                this.onMostraAtendimento();
                                this.onLoads();
                            })
                        });
                    });
                    this.ticketVoip = false;
                    this.myChat = false;
                    this.onLoads();
                });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onTramitarTicketVoip() {
        this.confirmationService.confirm({
            message: 'Confirma a tramitação do Ticket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Tramitação confirmada' });
                this.ticketFinalizar = false;
                let dados_ponto = {
                    key: this.ponto[0].key,
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                }
                this.myBtnVoip = false;
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto).then(res => {
                    this.genProvider.onIncrementKeyDateTime().then((keyValue: any) => {
                        this.formulario_ticket.patchValue({
                            protocolo: keyValue,
                            qtd_interacao: 1,
                            status: "Novo",
                            status_ticket: "Novo",
                            time: Date.now(),
                            ultima_interacao: Date.now(),
                            ticketPrincipal: this.formulario.value.ticket,
                            conteudoTexto: this.formulario_ticket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                            tramitacao: '1',
                            enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email,
                            key_responsavel: '',
                            nome_responsavel: '',
                            integrado: '0',
                        })
                        if (this.plataforma == 'anonima') {
                            this.formulario_ticket.patchValue({
                                key_solicitante: '000000000000',
                                nome_solicitante: 'Usuário Anônimo',
                                id_canal: '000000000000',
                                nome: 'Usuário Anônimo',
                            })
                        } else {
                            this.formulario_ticket.patchValue({
                                id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                                key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                                nome_solicitante: this.formulario_pessoa.value.nome,
                                nome: this.formulario_pessoa.value.nome
                            })
                        }
                        let data = this.formulario_ticket.value;
                        delete data["key"]
                        this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/ticket", data.ticket, data).then(() => {
                            this.formulario_ticket.patchValue({
                                key: this.formulario_ticket.value.ticket
                            })
                            if (this.formulario_dinamico.value) {
                                this.onSaveFormDinamicoVoip(this.formulario_dinamico.value, this.formulario_ticket.value);
                            }
                            let msg = "Título: " + this.formulario_ticket.value.titulo_ticket + this.service.ctrl
                            msg += 'Ticket: #' + this.formulario_ticket.value.key + this.service.ctrl
                            msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_ticket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Status: Tramitado ' + this.service.ctrl
                            msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_ticket.value.key_lotacao).nome + this.service.ctrl
                            msg += 'Detalhe: ' + this.formulario_ticket.value.conteudo + this.service.ctrl + this.service.ctrl
                            msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                            if (this.formulario_ticket.value.email) {
                                let assunto = "Chamado do Ticket " + this.formulario_ticket.value.key;
                                let email = {
                                    contato: this.formulario_ticket.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }
                            let lot = this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_ticket.value.key_lotacao)
                            if (lot) {
                                if (lot.email) {
                                    let assunto = "Novo Ticket Nº " + this.formulario_ticket.value.key;
                                    let email = {
                                        contato: lot.email,
                                        assunto: assunto,
                                        mensagem: msg
                                    }
                                    this.service.sendMail(email)
                                }
                            }

                            let dados_ticket_body = {
                                status: 'Enviada',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.formulario_ticket.value.key_lotacao,
                                nome_lotacao: this.formulario_ticket.value.nome_lotacao,
                                type: 'chat',
                                ticket: this.formulario_ticket.value.key,
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            let dados: any = [];
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_ticket.value.key, "ticket_body", dados_ticket_body);
                            if (this.plataforma != 'anonima') {
                                dados = {
                                    protocolo: this.formulario_ticket.value.protocolo,
                                    status: 'Tramitado',
                                    origem: 'voip',
                                    novo: '0',
                                    menu: 'Ticket voip tramitado',
                                    image: 'assets/icon/ticket.png',
                                    key_puc: this.formulario_ticket.value.key_puc,
                                    key_catalogo: this.formulario_ticket.value.key_catalogo,
                                    key_item: this.formulario_ticket.value.key_item,
                                    key_subitem: this.formulario_ticket.value.key_subitem,
                                    key_usuario: '',
                                    nome_usuario: this.nomeUsuario,
                                    key_lotacao: this.formulario_ticket.value.key_lotacao,
                                    type: 'chat',
                                    ticket: this.formulario_ticket.value.ticket,
                                    value: this.formulario_ticket.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: this.formulario_ticket.value.data_criacao,
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                    id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                                    key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.key),
                                    nome_solicitante: this.formulario_pessoa.value.nome,
                                    nome: this.formulario_pessoa.value.nome,
                                    tempo: this.formulario_ticket.value.tempo,
                                    email: this.formulario_pessoa.value.email || null,
                                    enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email,
                                    telefone: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone)
                                }
                            } else {
                                dados = {
                                    protocolo: this.formulario_ticket.value.protocolo,
                                    status: 'Tramitado',
                                    origem: 'voip',
                                    novo: '0',
                                    menu: 'Ticket voip tramitado',
                                    image: 'assets/icon/ticket.png',
                                    key_puc: this.formulario_ticket.value.key_puc,
                                    key_catalogo: this.formulario_ticket.value.key_catalogo,
                                    key_item: this.formulario_ticket.value.key_item,
                                    key_subitem: this.formulario_ticket.value.key_subitem,
                                    key_usuario: '',
                                    nome_usuario: this.nomeUsuario,
                                    key_lotacao: this.formulario_ticket.value.key_lotacao,
                                    type: 'chat',
                                    ticket: this.formulario_ticket.value.ticket,
                                    value: this.formulario_ticket.value.conteudoTexto,
                                    time: Date.now(),
                                    data_criacao: this.formulario_ticket.value.data_criacao,
                                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                    id_canal: '000000000000',
                                    key_solicitante: '000000000000',
                                    nome_solicitante: 'Usuário Anônimo',
                                    nome: 'Usuário Anônimo',
                                    tempo: this.formulario_ticket.value.tempo,
                                    email: this.formulario_pessoa.value.email || null,
                                    enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email,
                                    telefone: '000000000000'
                                }

                            }
                            this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/chat", dados.protocolo, dados);
                            let data_movto = {
                                ticket: this.formulario_ticket.value.key,
                                protocolo: this.formulario.value.key,
                                status: 'Tramitado',
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                key_lotacao: this.key_lotacao,
                                key_lotacao_tramitacao: this.formulario_ticket.value.key_lotacao,
                                key_usuario_destino: null,
                                key_puc: null,
                                time: Date.now(),
                                canal: this.formulario_ticket.value.origem,
                                conteudo: this.formulario_ticket.value.conteudoTexto
                            }

                            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                            let dados_body = {
                                protocolo: dados.protocolo,
                                messageNumber: 1,
                                key_lotacao: this.key_lotacao,
                                key_usuario: this.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                nome_lotacao: this.nome_lotacao,
                                telefone: this.formulario_ticket.value.id_canal,
                                type: "chat",
                                value: this.formulario_ticket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + dados.protocolo, "chat_body", dados_body).then(res => {
                                this.onAtualizarHistoricoTicket(dados.protocolo, this.formulario_ticket.value.key);
                            })
                            this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario_ticket.value.protocolo).pipe(take(1)).subscribe(res => {
                                let data = JSON.parse(JSON.stringify(res));
                                if (data.length > 0) {
                                    let atendimento = {
                                        key: data[0].key,
                                        status: "Tramitado",
                                        menu: this.formulario_ticket.value.tipo_ticket,
                                        protocolo: this.formulario_ticket.value.protocolo,
                                        origem: this.formulario_ticket.value.canal,
                                        ticket: this.formulario_ticket.value.key,
                                        tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                        key_lotacao: this.formulario_ticket.value.key_solicitante_lotacao,
                                        key_puc: this.formulario_ticket.value.key_puc,
                                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                        key_solicitante: this.formulario_ticket.value.key_solicitante,
                                        key_catalogo: this.formulario_ticket.value.key_catalogo,
                                        key_item: this.formulario_ticket.value.key_item,
                                        key_subitem: this.formulario_ticket.value.key_subitem,
                                        time: Date.now(),
                                        nome: this.formulario_ticket.value.nome_solicitante,
                                        tempo: this.tempoAtendimento,
                                    }
                                    this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                                } else {
                                    let atendimento = {
                                        status: "Tramitado",
                                        menu: this.formulario_ticket.value.tipo_ticket,
                                        protocolo: this.formulario_ticket.value.protocolo,
                                        origem: this.formulario_ticket.value.canal,
                                        ticket: this.formulario_ticket.value.key,
                                        tipo_ticket: this.formulario_ticket.value.tipo_ticket,
                                        key_lotacao: this.formulario_ticket.value.key_solicitante_lotacao,
                                        key_puc: this.formulario_ticket.value.key_puc,
                                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                        key_solicitante: this.formulario_ticket.value.key_solicitante,
                                        key_catalogo: this.formulario_ticket.value.key_catalogo,
                                        key_item: this.formulario_ticket.value.key_item,
                                        key_subitem: this.formulario_ticket.value.key_subitem,
                                        time: Date.now(),
                                        nome: this.formulario_ticket.value.nome_solicitante,
                                        tempo: this.tempoAtendimento,
                                    }
                                    //  this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/atendimento", atendimento, "atendimento");
                                    this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento);
                                    this.onFinalTempoAtendimento();
                                    this.formulario_ticket.reset();
                                    this.formulario.reset();
                                    this.onLoad();
                                    this.onLoadChatFila();
                                }
                            })
                            //            this.tramitarTicketVoip = false;
                            //            this.ticketVoip = false;
                            //            this.myChat = false;
                            //            this.onMostraAtendimento();
                        });
                    });
                    this.tramitarTicketVoip = false;
                    this.ticketVoip = false;
                    this.myChat = false;
                    this.onMostraAtendimento();
                })

            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onVoip() {
        this.myBtnVoip = true;
        this.formulario_ticket.reset();
        let dados_ponto = {
            key: this.ponto[0].key,
            atendimento_time: Date.now(),
            atendimento_status: "Em Atendimento",
            canal: "voip",
        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
        this.offLoads()
        this.genProvider.onGetfilter('dados/' + this.path + '/ticket', 'status', 'Registrando').pipe(take(1)).subscribe(data => {
            let res = JSON.parse(JSON.stringify(data));
            if (res.length > 0) {
                let ticket = res.filter(obj => {
                    return obj.key_usuario == this.parametros.key_usuario
                })
                if (ticket.length > 0) {
                    this.formulario_ticket.patchValue(ticket[0])
                    this.numeroTicket = ticket[0].key;
                    this.formulario_ticket.patchValue({
                        ticket: this.numeroTicket,
                    })
                    if (this.plataforma == 'anonima') {
                        this.formulario_ticket.patchValue({
                            key_solicitante: '000000000000',
                            nome_solicitante: 'Usuário Anônimo',
                            id_canal: '000000000000'
                        })
                    }
                    this.detalhe = '';
                    this.numeroTicket = '';
                    this.inicio_atendimento = ticket[0].data_criacao;
                    this.onInicioTempoAtendimento();
                    this.origem = "voip"
                    this.ticketVoip = true;
                    this.myBtnVoip = false;
                } else {
                    this.formulario_ticket.patchValue({
                        status_ticket: "Novo",
                        status: 'Registrando',
                        canal: 'voip',
                        enviar_resposta_solicitante: true,
                        key_puc: this.key_central,
                        compartilhado: false,
                        copia_usuario: false,
                        distribuicao: false,
                        encaminhado: false,
                        encerrado: false,
                        distribuido: true,
                        data_criacao: Date.now(),
                        time: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        nome_usuario: this.nomeUsuario,
                        key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                        key_solicitante: null,
                        nome_solicitante: null,
                        conteudoTexto: null,
                        key_responsavel: null,
                        nome_responsavel: null,
                        qtd_interacao: "",
                        integrado: '0'
                    })
                    if (this.plataforma == 'anonima') {
                        this.formulario_ticket.patchValue({
                            key_solicitante: '000000000000',
                            nome_solicitante: 'Usuário Anônimo',
                            id_canal: '000000000000'
                        })
                    }
                    this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(keyTicket => {
                        // this.genProvider.onIncrementKeyZeroDate('dados/' + this.parametros.path, "ticket", 6).then((keyTicket: string) => {
                        this.numeroTicket = keyTicket;
                        //  this.genProvider.onIncrementKeyTime('dados/' + this.parametros.path, "protocolo").then((keyValue: string) => {
                        this.formulario_ticket.patchValue({
                            ticket: this.numeroTicket,
                            //   protocolo: keyValue
                        })
                        this.detalhe = '';
                        this.numeroTicket = '';
                        this.inicio_atendimento = Date.now();
                        this.onInicioTempoAtendimento();
                        this.origem = "voip"
                        this.ticketVoip = true;
                        this.myBtnVoip = false;
                    })
                }
            } else {
                this.formulario_ticket.patchValue({
                    status_ticket: "Novo",
                    status: 'Registrando',
                    canal: 'voip',
                    enviar_resposta_solicitante: true,
                    key_puc: this.key_central,
                    compartilhado: false,
                    copia_usuario: false,
                    distribuicao: false,
                    encaminhado: false,
                    encerrado: false,
                    distribuido: true,
                    data_criacao: Date.now(),
                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                    nome_usuario: this.nomeUsuario,
                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                    key_solicitante: null,
                    nome_solicitante: null,
                    conteudoTexto: null,
                    time: Date.now(),
                    key_responsavel: null,
                    nome_responsavel: null,
                    qtd_interacao: "",
                    integrado: '0'
                })
                if (this.plataforma == 'anonima') {
                    this.formulario_ticket.patchValue({
                        key_solicitante: '000000000000',
                        nome_solicitante: 'Usuário Anônimo',
                        id_canal: '000000000000'
                    })
                }
                this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_ticket.value, "ticket", 6).then(keyTicket => {
                    // this.genProvider.onIncrementKeyZeroDate('dados/' + this.parametros.path, "ticket", 6).then((keyTicket: string) => {
                    this.numeroTicket = keyTicket;
                    //  this.genProvider.onIncrementKeyTime('dados/' + this.parametros.path, "protocolo").then((keyValue: string) => {
                    this.formulario_ticket.patchValue({
                        ticket: this.numeroTicket,
                        //   protocolo: keyValue
                    })
                    this.detalhe = '';
                    this.numeroTicket = '';
                    this.inicio_atendimento = Date.now();
                    this.onInicioTempoAtendimento();
                    this.origem = "voip"
                    this.ticketVoip = true;
                    this.myBtnVoip = false;
                })
            }
        });
    }
    onDevolveTicket(data) {
        this.confirmationService.confirm({
            message: 'Confirma a evoluçaõ do ticket p/ central' + status,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Concluido.' });

                let dados_ticket = {
                    key: data.ticket,
                    status_ticket: 'Devolvido',
                    status: 'Devolvido',
                    menu: "Devolvido",
                    novo: '1',
                    integrado: '0',
                    time: Date.now(),
                    key_usuario: this.key_usuario,
                    key_lotacao: this.key_lotacao
                }
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket', dados_ticket);
                let dados_chat = {
                    key: data.protocolo,
                    status: 'Devolvido',
                    status_ticket: 'Devolvido',
                    menu: "Devolvido",
                    novo: '1',
                    time: Date.now(),
                    key_usuario: this.key_usuario,
                    key_lotacao: this.key_lotacao
                }
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat', dados_chat);
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    encerraStatus(status) {
        this.confirmationService.confirm({
            message: 'Confirma ' + status,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Concluido.' });
                this.ticketVoip = false;
                //   if (this.formulario_ticket.value.status_ticket == 'Editando') {
                this.genProvider.remove('dados/' + this.parametros.path + '/ticket', this.formulario_ticket.value.ticket);
                //  }
                this.genProvider.onIncrementKeyDateTime().then(key => {
                    let atendimento = {
                        status: "Finalizado",
                        key_usuario: this.key_usuario,
                        key_lotacao: this.key_lotacao,
                        key_puc: this.key_central,
                        protocolo: key.toString(),
                        ticket: this.formulario_ticket.value.ticket,
                        origem: "voip",
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        time: Date.now(),
                        menu: status,
                        value: status,
                        tempo: this.tempoAtendimento
                    };
                    //  this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/atendimento", atendimento, "atendimento");
                    this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento)
                })
                if (this.formulario_ticket.value.id_canal) {
                    this.onFinalizaChatUser(this.formulario_ticket.value.id_canal);
                }
                let dados_ponto = {
                    key: this.ponto[0].key,
                    protocolo: '',
                    atendimento_status: "Livre",
                    canal: '',
                }
                this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                this.onFreeAtendente();
                this.onFinalTempoAtendimento();
                this.onLoads()
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    enganoTrote(status) {
        this.confirmationService.confirm({
            message: 'Confirma ' + status,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Concluido.' });
                this.ticketFinalizar = false;
                let dados_chat = {
                    status: 'Finalizado',
                    menu: status,
                    key: this.formulario.value.protocolo,
                    key_usuario: this.key_usuario,
                    key_lotacao: this.key_lotacao,
                    time: Date.now(),
                    value: status,
                    tempo: this.tempoAtendimento
                }
                this.genProvider.onSaveObjeto("dados", this.parametros.path, "chat", dados_chat);
                if (this.formulario.value.ticket) {
                    let dados_ticket = {
                        status: 'Finalizado',
                        status_ticket: 'Cancelado',
                        key_usuario: this.key_usuario,
                        key_lotacao: this.key_lotacao,
                        key_puc: this.key_central,
                        key: this.formulario.value.ticket,
                        menu: status,
                        time: Date.now(),
                        value: status,
                        tempo: this.tempoAtendimento,
                        integrado: '0'
                    }
                    this.genProvider.onSaveObjeto("dados", this.parametros.path, "ticket", dados_ticket);
                }

                this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario_ticket.value.protocolo).pipe(take(1)).subscribe(res => {
                    let data = JSON.parse(JSON.stringify(res));
                    if (data.length > 0) {
                        let atendimento = {
                            key: data[0].key,
                            status: status,
                            time: Date.now(),
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            menu: status,
                            tempo: this.tempoAtendimento,
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                    } else {
                        let atendimento = {
                            status: "Finalizado",
                            tipo: this.formulario.value.tipo,
                            protocolo: this.formulario.value.protocolo,
                            ticket: null,
                            menu: status,
                            key_usuario: this.key_usuario,
                            key_lotacao: this.key_lotacao,
                            key_puc: this.key_central,
                            //   key_solicitante: this.formulario.value.telefone,
                            nome_solicitante: this.formulario.value.nome,
                            origem: this.formulario.value.origem,
                            id_canal: this.formulario.value.id_canal,
                            //    telefone: this.formulario.value.telefone,
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            time: Date.now(),
                            tempo: this.tempoAtendimento,
                        };
                        //this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/atendimento", atendimento, "atendimento");
                        this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento);

                    }
                    let dados_ponto = {
                        key: this.ponto[0].key,
                        protocolo: '',
                        atendimento_status: "Livre",
                        canal: '',
                    }
                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                })
                this.myChat = false;
                this.onFinalizaChatUser(this.formulario_ticket.value.id_canal);
                this.onFreeAtendente();
                this.onGetFiltroChat('Na Fila');
                this.onFinalTempoAtendimento();
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    onSaveContato() {
        this.formulario.patchValue({
            email: this.formulario_pessoa.value.email,
            telefone: this.formulario_pessoa.value.telefone
        })
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat', this.formulario.value);
        if (!this.formulario_pessoa.value.key) {
            this.formulario_pessoa.patchValue({
                id: this.id,
                friend: this.friend,
                imagem: this.image,
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                time: Date.now()
            })
        } else {
            this.formulario_pessoa.patchValue({
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                time: Date.now()
            })
        }
        this.genProvider.onSaveIncrement('dados/' + this.parametros.path + "/pessoa", this.formulario_pessoa.value, "pessoa").then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
            if (t) {
                this.formulario_pessoa.patchValue({
                    key: t
                })
            }
        })
            .catch(e => {
                this.messageService.add({ severity: 'danger', summary: 'Erro', detail: 'Dados não Gravados.' });
                //  console.log(e);
            });

    }
    onSaveContatoVoip() {
        this.formulario_ticket.patchValue({
            key: this.formulario_ticket.value.ticket,
            email: this.formulario_pessoa.value.email,
            telefone: this.formulario_pessoa.value.telefone
        })
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket', this.formulario_ticket.value);
        if (!this.formulario_pessoa.value.key) {
            this.formulario_pessoa.patchValue({
                nome: this.formulario_pessoa.value.nome,
                email: this.formulario_pessoa.value.email,
                telefone: this.formulario_pessoa.value.telefone,
                id: this.id,
                friend: this.friend,
                imagem: this.image,
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                time: Date.now()
            })
        } else {
            this.formulario_pessoa.patchValue({
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                time: Date.now(),
                nome: this.formulario_pessoa.value.nome,
                email: this.formulario_pessoa.value.email,
                telefone: this.formulario_pessoa.value.telefone
            })
        }
        this.genProvider.onSaveIncrement('dados/' + this.parametros.path + "/pessoa", this.formulario_pessoa.value, "pessoa").then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
            if (t) {
                this.formulario_pessoa.patchValue({
                    key: t
                })
            }
        })
            .catch(e => {
                this.messageService.add({ severity: 'danger', summary: 'Erro', detail: 'Dados não Gravados.' });
                //  console.log(e);
            });

    }
    onGetTelefone(event) {
        this.genProvider.onGetfilter('dados/' + this.parametros.path + '/pessoa', 'telefone', event.telefone).pipe(take(1)).subscribe(res => {
            let pessoa = JSON.parse(JSON.stringify(res));
            if (pessoa.length > 0) {
                this.formulario_pessoa.patchValue(pessoa[0])
                this.formulario_ticket.patchValue({
                    key: this.formulario_ticket.value.ticket,
                    key_solicitante: this.formulario_pessoa.value.telefone,
                    nome_solicitante: this.formulario_pessoa.value.nome,
                    id_canal: this.formulario_pessoa.value.telefone,
                    enviar_resposta_solicitante_email: false,
                    email: this.formulario_pessoa.value.email,
                    telefone: this.formulario_pessoa.value.telefone
                })

            } else {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Telefone não cadastrado no sistema.' });
                this.formulario_pessoa.patchValue({
                    nome: this.formulario_pessoa.value.nome,
                    email: this.formulario_pessoa.value.email,
                    telefone: this.formulario_pessoa.value.telefone,
                    status: "Ativo",
                    tipo: "Externo",
                    origem: this.origem
                })
                this.formulario_ticket.patchValue({
                    key: this.formulario_ticket.value.ticket,
                    key_solicitante: this.formulario_pessoa.value.telefone,
                    nome_solicitante: this.formulario_pessoa.value.nome,
                    id_canal: this.formulario_pessoa.value.telefone,
                    enviar_resposta_solicitante_email: false,
                    email: this.formulario_pessoa.value.email,
                    telefone: this.formulario_pessoa.value.telefone
                })
            }
            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket', this.formulario_ticket.value);
        })
    }
    onGetEmail(event) {
        this.genProvider.onGetfilter('dados/' + this.parametros.path + '/pessoa', 'email', event.email).pipe(take(1)).subscribe(res => {
            let pessoa = JSON.parse(JSON.stringify(res));
            if (pessoa.length > 0) {
                this.formulario_pessoa.patchValue(pessoa[0])
                this.formulario_ticket.patchValue({
                    key: this.formulario_ticket.value.ticket,
                    key_solicitante: this.formulario_pessoa.value.telefone,
                    nome_solicitante: this.formulario_pessoa.value.nome,
                    id_canal: this.formulario_pessoa.value.email,
                    email: this.formulario_pessoa.value.email,
                    telefone: this.formulario_pessoa.value.telefone
                })
            } else {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'E-mail não cadastrado no sistema.' });
                this.formulario_pessoa.patchValue({
                    nome: this.formulario_pessoa.value.nome,
                    email: this.formulario_pessoa.value.email,
                    telefone: this.formulario_pessoa.value.telefone,
                    status: "Ativo",
                    tipo: "Externo",
                    origem: this.origem
                })
                this.formulario_ticket.patchValue({
                    key: this.formulario_ticket.value.ticket,
                    key_solicitante: this.formulario_pessoa.value.telefone,
                    nome_solicitante: this.formulario_pessoa.value.nome,
                    //   id_canal: this.formulario_pessoa.value.email,
                    email: this.formulario_pessoa.value.email,
                    telefone: this.formulario_pessoa.value.telefone
                })
            }
            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket', this.formulario_ticket.value);
        })
    }
    onChangePessoa(pessoa) {
        this.formulario_pessoa.patchValue(pessoa)
        if (pessoa.email) {
            this.formulario_ticket.patchValue({
                key_solicitante: this.formulario_pessoa.value.telefone,
                nome_solicitante: this.formulario_pessoa.value.nome,
                id_canal: this.formulario_pessoa.value.email,
                email: this.formulario_pessoa.value.email,
                telefone: this.formulario_pessoa.value.telefone
            })
        } else {
            this.formulario_ticket.patchValue({
                key_solicitante: this.formulario_pessoa.value.telefone,
                nome_solicitante: this.formulario_pessoa.value.nome,
                id_canal: this.formulario_pessoa.value.telefone,
                email: this.formulario_pessoa.value.email,
                telefone: this.formulario_pessoa.value.telefone
            })
        }
    }
    onFinalizaChatUser(key) {
        if (key) {
            let data = {
                comando: "finalizaAtendimento",
                telefone: key,
            }
            console.log(data)
            this.genProvider.onSaveDocKey('dados/' + this.parametros.path + '/srvComando', this.parametros.telefone, data).then(t => {
                console.log("Usuario finalizado")
            }).catch(e => {
                console.log('Atenção, erro ao salvar dados.')
            });
        }
    }
    onEnviaChatServidor2(body, url) {
        let data = {
            comando: "saveChat",
            body: body,
            url: url
        }
        this.genProvider.onSaveDocKey('dados/' + this.parametros.path + '/srvComando', this.parametros.telefone, data).then(t => {
            //   console.log("chat enviado")
        }).catch(e => { console.log('Atenção, erro ao enviar chat para o serividor.') });
    }
    onWebHook(val) {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept": "application/json",
            "method": "post"
        });
        let options = {
            headers: headers
        }
        let msg = {
            instaceId: this.parametros.insta

        }
        let a = {
            teste: 'teste'
        }
        //  console.log(val)
        this.http.post(this.parametros.webhookUrl, val, options).pipe(take(1)).subscribe(res => {
            let data = JSON.parse(JSON.stringify(res));
        });
    }
    openCatalogo() {
        this.cols = [
            { header: 'Nome', field: 'nome' },
            { header: 'Descriçao', field: 'descricao' },
            { header: 'Tipo', field: 'tipo' },
            { header: 'Disponível', field: 'disponivel' },
            { header: 'Status', field: 'status' }
        ];
        // this.onLoadCatalogo(this.key_central);
        this.mostraCatalogo = true;
        this.mostraListaCatalogo = true;
        this.mostraCatalogoItem = false;
    }
    onViewCatalogoItem(data) {
        this.cols_Item = [
            { header: 'Código', field: 'key' },
            { header: 'Nome do Item', field: 'nome' },
            { header: 'POP Url', field: 'pop_url' },
            { header: 'POP Mídia', field: 'pop_midia' },
            { header: 'Status', field: 'status' }
        ];
        this.mostraListaCatalogo = false;
        this.mostraCatalogoItem = true;
        // this.key_catalago = data.key_catalago;
        this.onLoadCatalogoItem(data.key_catalogo)
    }
    onViewCatalogoSubItem(data) {
        this.tabMenuitensCatalogo = [
            { id: '1', label: 'Item', icon: 'pi pi-fw pi-globe' },
            { id: '2', label: 'SubItem', icon: 'pi pi-fw pi-align-justify' }
        ];
        // console.log(data)
        this.dados_item_catalogo = data
        this.mostraCatalogoItem = false;
        this.mostraCatalogoItemSubItem = true;
        this.onLoadItemSub(data.key)

    }
    onLoadItemSub(key) {
        // console.log(this.key_catalago)
        this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + this.key_catalago + '/item/' + key + '/subitem', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.listaItemSub = data;
            //  console.log(this.listaItemSub)
        },
            err => console.log(err)
        );
    }
    onTabCatalogo(e) {
        this.page = e.activeItem.id
    }
    onViewFile(file) {
        //  console.log(file)
        if (file) {
            window.open(file, '_blank');
        }
    }
    onAtualizaTitulo(e) {
        let conteudo = ''
        if (e.selectedOption.pop) {
            if (this.formulario_ticket.value.conteudoInterno) {
                conteudo = this.formulario_ticket.value.conteudoInterno + this.service.ctrl + e.selectedOption.pop + this.service.ctrl
                this.formulario_ticket.patchValue({
                    conteudoInterno: conteudo
                })

            } else {
                conteudo = e.selectedOption.pop + this.service.ctrl
                this.formulario_ticket.patchValue({
                    conteudoInterno: conteudo
                })
            }
        }
        if (e.selectedOption.nome) {
            this.formulario_ticket.patchValue({
                titulo_ticket: e.selectedOption.nome
            })
        }


    }
    onAtualizaTituloSub(e) {
        let conteudo = ''
        if (e.selectedOption.pop) {
            if (this.formulario_subTicket.value.conteudoInterno) {
                conteudo = this.formulario_subTicket.value.conteudoInterno + this.service.ctrl + e.selectedOption.pop + this.service.ctrl
                this.formulario_subTicket.patchValue({
                    conteudoInterno: conteudo
                })

            } else {
                conteudo = e.selectedOption.pop + this.service.ctrl
                this.formulario_subTicket.patchValue({
                    conteudoInterno: conteudo
                })
            }
        }
        if (e.selectedOption.nome) {
            this.formulario_subTicket.patchValue({
                titulo_ticket: e.selectedOption.nome
            })
        }
    }
    onAtualizaPop(e) {
        if (e.selectedOption.pop) {
            let conteudo = ''
            if (this.formulario_ticket.value.conteudoInterno) {
                conteudo = this.formulario_ticket.value.conteudoInterno + this.service.ctrl + e.selectedOption.pop
            } else {
                conteudo = e.selectedOption.pop
            }
            this.formulario_ticket.patchValue({
                conteudoInterno: conteudo
            })
        }

    }
    onAtualizaPopSub(e) {
        if (e.selectedOption.pop) {
            let conteudo = ''
            if (this.formulario_subTicket.value.conteudoInterno) {
                conteudo = this.formulario_subTicket.value.conteudoInterno + this.service.ctrl + e.selectedOption.pop
            } else {
                conteudo = e.selectedOption.pop
            }
            this.formulario_subTicket.patchValue({
                conteudoInterno: conteudo
            })
        }
    }
    onNovaPessoa() {
        this.formulario_cadastro_pessoa.reset();
        this.cadPessoa = true;
        this.formulario_cadastro_pessoa.patchValue({
            tipo: "Interno",
            origem: "Painel",
            status: "Ativo"
        });
        this.cadPessoa = true

    }
    onSaveCadastroPessoa() {
        if (this.formulario_cadastro_pessoa.valid) {
            //   console.log(this.formulario_cadastro_pessoa.value)
            this.genProvider.onSaveIncrement("dados/" + this.path + "/pessoa", this.formulario_cadastro_pessoa.value, "pessoa").then(t => {
                this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Cadastro realizado com sucesso.' });
                this.formulario_cadastro_pessoa.reset();
                this.cadPessoa = false;
            }).catch(e => {
                this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
                // console.log(e);
            });
        }
    }
    onLoadCargo() {
        this.genProvider.onGetAll('dados/' + this.path + '/cargo', 'nome').pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.cargo = data;
            this.cargo = this.cargo.map(res => {
                res.nomeDescricao = res.nome + " - " + res.descricao
                return res;
            });
        },
            err => console.log(err)
        );
    }
    onLoadEncerrarAtendimento() {
        this.genProvider.onGetAll('dados/' + this.path + '/encerraratendimento', 'nome').pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.atendimento = data;
            this.atendimento = this.atendimento.map(res => {
                res.nomeDescricao = res.nome + " - " + res.descricao
                return res;
            });
        },
            err => console.log(err)
        );
    }
    onLoadTickets() {
        if ((!this.idTicket) && (this.dtInicio) && (this.dtFinal)) {
            this.tickets = [];
            let inicio = new Date(this.dtInicio).getTime()
            let fim = new Date(this.dtFinal).getTime()
            this.genProvider.onGetPeriodo('dados/' + this.path + '/ticket', 'data_criacao', inicio, fim).pipe(take(1)).subscribe(data => {
                let lista = JSON.parse(JSON.stringify(data));
                //   console.log(lista)
                if (lista.length == 0) {
                    this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este periodo para consulta.' });
                } else {
                    this.tickets = lista.map(res => {
                        if (res.key_lotacao) {
                            let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                            if (lotacao) {
                                res.nome_lotacao = lotacao.nome
                            }
                        } else {
                            res.nome_lotacao = '';
                        }
                        if (res.key_usuario) {
                            let agente = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
                            if (agente) {
                                res.agente = agente.nome;
                            }
                        } else {
                            res.agente = ''
                        }
                        if (res.qtd_interacao) {
                            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
                        }
                        res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
                        res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                        //            if ((res.status_ticket == 'Novo') && (res.key_usuario == this.parametros.key_usuario) && (res.canal == 'voip')) {
                        if (res.status_ticket == 'Novo') {
                            res.edita = true
                        } else {
                            res.edita = false
                        }
                        if (res.enviar_resposta_solicitante_email) {
                            res.resposta = "e-mail"
                        } else {
                            res.resposta = "Whatsapp"
                        }
                        res.ticket = res.key
                        res.codigo = res.key
                        return res;
                    });
                }
            });
        } else if (this.idTicket) {
            this.tickets = [];
            this.genProvider.onGetKey('dados/' + this.path + '/ticket', this.idTicket).pipe(take(1)).subscribe(data => {
                let res = JSON.parse(JSON.stringify(data));
                let lista = [res]
                if (!res.status) {
                    this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe este ticket.' });
                } else {
                    this.tickets = lista.map(res => {
                        if (res.key_lotacao) {
                            res.nome_lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao).nome
                        } else {
                            res.nome_lotacao = '';
                        }
                        if (res.key_usuario) {
                            let agente = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
                            if (agente) {
                                res.agente = agente.nome;
                            }
                        } else {
                            res.agente = ''
                        }
                        if (res.enviar_resposta_solicitante_email) {
                            res.resposta = "e-mail"
                        } else {
                            res.resposta = "Whatsapp"
                        }
                        if (res.qtd_interacao) {
                            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM - HH:mm") + " / " + res.qtd_interacao || '';
                        }
                        res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
                        res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
                        if ((res.status == 'Novo') && (res.key_usuario == this.parametros.key_usuario) && (res.canal == 'voip')) {
                            res.edita = true
                        } else {
                            res.edita = false
                        }
                        res.ticket = this.idTicket
                        res.key = this.idTicket
                        res.codigo = this.idTicket
                        return res;
                    });
                }
            });
        }
    }
    onMostraTicket(event) {
        let joins = [{ key: "key_lotacao", table: "lotacao" }, { key: "key_cargo", table: "cargo" }]
        let telefone = this.validaCampoProvider.formataTelefoneDDD(event.key_solicitante)
        this.genProvider.onGetfilter('dados/' + this.path + '/pessoa', 'telefone', telefone, joins).pipe(take(1)).subscribe(res => {
            let pessoa = JSON.parse(JSON.stringify(res));
            if (pessoa.length > 0) {
                this.formulario_pessoa_consulta.patchValue(pessoa[0])
                if (pessoa[0].lotacao) {
                    this.formulario_pessoa_consulta.patchValue({
                        nome_lotacao: pessoa[0].lotacao.nome
                    })
                }
                if (pessoa[0].cargo) {
                    this.formulario_pessoa_consulta.patchValue({
                        nome_cargo: pessoa[0].cargo.nome
                    })
                }
            }
        });
        this.genProvider.onGetAllPageDesc('dados/' + this.path + '/ticket/' + event.ticket + '/' + 'ticket_body', 'time', 999).pipe(take(1)).subscribe(data => {
            let lista = JSON.parse(JSON.stringify(data));
            console.log(lista)
            if (lista.length > 0) {
                this.ticket_body = lista.map(res => {
                    if (res.status != 'Enviada') {
                        res.status = "Recebida"
                    }
                    if (res.key_usuario) {
                        let usuario = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
                        if (usuario) {
                            res.nome_usuario = usuario.nome;
                        }
                    } else {
                        res.nome_usuario = ''
                    }
                    if (res.key_lotacao) {
                        let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                        if (lotacao) {
                            res.nome_lotacao = lotacao.nome
                            res.tipo_lotacao = lotacao.tipo_lotacao;
                        }
                    }
                    return res;
                });
            } else {
                this.unSubMsg$ = this.genProvider.onGetAll('dados/' + this.path + '/chat/' + event.protocolo + '/chat_body', 'time').subscribe(data => {
                    let lista = JSON.parse(JSON.stringify(data));
                    if (lista.length > 0) {
                        this.ticket_body = lista.map(res => {
                            if (res.status != 'Enviada') {
                                res.status = "Recebida"
                            }
                            if (res.key_usuario) {
                                let usuario = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario);
                                if (usuario) {
                                    res.nome_usuario = usuario.nome;
                                }
                            } else {
                                res.nome_usuario = ''
                            }
                            if (res.key_lotacao) {
                                let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
                                if (lotacao) {
                                    res.nome_lotacao = lotacao.nome
                                    res.tipo_lotacao = lotacao.tipo_lotacao;
                                }
                            }
                            return res;
                        });
                    }
                });
            }
        });
        //   this.formulario_consulta_ticket = this.formBuilder.group(event);
        this.formulario_consulta_ticket.patchValue(event)
        // this.onLoadCatalogo(event.key_puc);
        this.onLoadCatalogoItem(event.key_catalogo);
        this.onLoadCatalogoSubItemConsulta(event.key_item, event.key_catalogo);
        if (!event.key_responsavel) {
            this.nome_responsavel = " Sem Responsável"
        } else {
            this.genProvider.onGetKey('login', event.key_responsavel).pipe(take(1)).subscribe(res => {
                let login = JSON.parse(JSON.stringify(res));
                this.nome_responsavel = login.nome
            });
        }
        this.mostraTicket = true;
    }
    onLoadCatalogoSubItemConsulta(key, catalogo) {
        this.onLoadSla(key)
        this.genProvider.onGetAll('dados/' + this.path + '/catalogo/' + catalogo + '/item/' + key + '/subitem', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
            this.subItemCatalogo = JSON.parse(JSON.stringify(data));
        },
            err => console.log(err)
        );
    }
    onEditTicket(data) {
        let dados = {
            key: data.key,
            ticket: data.key,
            tramitacao: '0',
            key_lotacao: null,
            status_ticket: 'Editando'
        }
        this.formulario_ticket.patchValue(data)
        this.formulario_ticket.patchValue({
            ticket: data.key,
            status_ticket: 'Editando',
            ticketPrincipal: data.ticketPrincipal
        })
        let telefone = this.validaCampoProvider.formataTelefoneDDD(data.key_solicitante)
        this.genProvider.onGetfilter('dados/' + this.parametros.path + '/pessoa', 'telefone', telefone).pipe(take(1)).subscribe(res => {
            let pessoa = JSON.parse(JSON.stringify(res));
            if (pessoa.length > 0) {
                this.formulario_pessoa.patchValue(pessoa[0])
            }
        });
        this.genProvider.onSaveObjeto('dados', this.path, 'ticket', dados);
        this.ticketVoip = true;
        let dados_ponto = {
            key: this.ponto[0].key,
            atendimento_time: Date.now(),
            atendimento_status: "Em Atendimento",
            canal: "voip",
        }
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
        this.detalhe = '';
        this.numeroTicket = '';
        this.inicio_atendimento = data.time;
        this.onInicioTempoAtendimento();
        this.origem = "voip"

    }
    onExcluirTicket() {
        this.genProvider.onGetfilter('dados/' + this.path + '/ticket', 'status', 'Registrando').pipe(take(1)).subscribe(data => {
            let lista = JSON.parse(JSON.stringify(data));
            //  console.log(lista)
            lista.forEach(e => {
                setTimeout(() => {
                    this.genProvider.remove('dados/' + this.path + '/ticket', e.key);
                }, 1000);
            });

        })
    }
    onLoadUsuario() {
        this.genProvider.onGetAll('login', 'nome').pipe(take(1)).subscribe(data => {
            this.usuarios = JSON.parse(JSON.stringify(data));
        })
    }
    onSearch() {
        if (this.filtro == undefined || this.filtro.trim() == '' || this.filtro == null
        ) {
            this.onLoadChatFila();
        } else {
            // this.dados = this.chatFila;
            let res = this.dados.filter(obj => {
                if (
                    obj.busca.toLocaleLowerCase().indexOf(this.filtro.toLowerCase()) >= 0
                ) {
                    return true;
                }
                return false;
            });
            this.dados = res
        }
    }
    onSubTicket() {
        this.formulario_subTicket.patchValue(this.formulario_ticket.value);
        this.genProvider.onIncrementKeyZeroDate('dados/' + this.parametros.path, "ticket", 6).then((keyTicket: string) => {
            this.numeroTicket = keyTicket;
            this.formulario_subTicket.patchValue({
                ticket: this.numeroTicket,
                key_catalago: null,
                key_item: null,
                key_subitem: null,
                titulo_ticket: ''
            })
        })
        this.subTicket = true;
    }
    onTramitarSubTicket() {
        this.tramitarSubTicket = false;
        this.confirmationService.confirm({
            message: 'Confirma a tramitação do SubTicket',
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Tramitação confirmada' });
                this.subTicket = false;
                this.genProvider.onIncrementKeyDateTime().then((keyValue: any) => {
                    this.formulario_subTicket.patchValue({
                        protocolo: keyValue,
                        qtd_interacao: 1,
                        status: "Novo",
                        status_ticket: "Novo",
                        key_responsavel: '',
                        nome_responsavel: '',
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        time: Date.now(),
                        ultima_interacao: Date.now(),
                        ticketPrincipal: this.formulario_ticket.value.key,
                        id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                        key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                        nome_solicitante: this.formulario_pessoa.value.nome,
                        conteudoTexto: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                        tramitacao: '1',
                        enviar_resposta_solicitante_email: this.formulario_pessoa.value.enviar_resposta_solicitante_email,
                        email: this.formulario.value.email,
                        telefone: this.formulario.value.telefone

                    })
                    let data = this.formulario_subTicket.value;
                    delete data["key"]
                    this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/ticket", data.ticket, data).then(() => {
                        this.formulario_subTicket.patchValue({
                            key: this.formulario_subTicket.value.ticket
                        })

                        let msg = "Título: " + this.formulario_subTicket.value.titulo_ticket + this.service.ctrl
                        msg += 'Ticket: #' + this.formulario_subTicket.value.key + this.service.ctrl
                        msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_subTicket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                        msg += 'Status: Tramitado ' + this.service.ctrl
                        msg += 'Lotação: ' + this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_subTicket.value.key_lotacao).nome + this.service.ctrl
                        msg += 'Detalhe: ' + this.formulario_subTicket.value.conteudo + this.service.ctrl + this.service.ctrl
                        msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                        if (this.formulario_subTicket.value.email) {
                            let assunto = "Chamado do Ticket " + this.formulario_subTicket.value.key;
                            let email = {
                                contato: this.formulario_subTicket.value.email,
                                assunto: assunto,
                                mensagem: msg
                            }
                            this.service.sendMail(email)
                        }

                        let lot = this.genProvider.onSearchArray(this.lotacao, 'key', this.formulario_subTicket.value.key_lotacao)
                        if (lot) {
                            if (lot.email) {
                                let assunto = "Novo Ticket Nº " + this.formulario_subTicket.value.key;
                                let email = {
                                    contato: lot.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }
                        }
                        let dados_ticket_body = {
                            status: 'Enviada',
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            nome_usuario: this.nomeUsuario,
                            key_lotacao: this.formulario_subTicket.value.key_lotacao,
                            nome_lotacao: this.formulario_subTicket.value.nome_lotacao,
                            type: 'chat',
                            ticket: this.formulario_subTicket.value.key,
                            value: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            tipo: 'publica'
                        }
                        //  this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_subTicket.value.key, "ticket_body", dados_ticket_body);
                        let dados = {
                            protocolo: this.formulario_subTicket.value.protocolo,
                            status: 'Tramitado',
                            origem: this.formulario_subTicket.value.canal,
                            novo: '0',
                            menu: 'Ticket voip tramitado',
                            image: 'assets/icon/ticket.png',
                            key_puc: this.formulario_subTicket.value.key_puc,
                            key_catalogo: this.formulario_subTicket.value.key_catalogo,
                            key_item: this.formulario_subTicket.value.key_item,
                            key_subitem: this.formulario_subTicket.value.key_subitem,
                            key_usuario: '',
                            nome_usuario: this.nomeUsuario,
                            key_lotacao: this.formulario_subTicket.value.key_lotacao,
                            type: 'chat',
                            ticket: this.formulario_subTicket.value.ticket,
                            value: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                            key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.key),
                            nome_solicitante: this.formulario_pessoa.value.nome,
                            nome: this.formulario_pessoa.value.nome,
                            tempo: null,
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        }
                        this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/chat", dados.protocolo, dados);
                        let data_movto = {
                            ticket: this.formulario_subTicket.value.key,
                            protocolo: this.formulario_subTicket.value.protocolo,
                            status: 'Tramitado',
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            key_lotacao: this.key_lotacao,
                            key_lotacao_tramitacao: this.formulario_subTicket.value.key_lotacao,
                            key_usuario_destino: null,
                            key_puc: null,
                            time: Date.now(),
                            canal: this.formulario_subTicket.value.origem,
                            conteudo: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, '')
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                        let dados_body = {
                            protocolo: dados.protocolo,
                            messageNumber: 1,
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            nome_usuario: this.nomeUsuario,
                            key_lotacao: this.formulario_subTicket.value.key_lotacao,
                            nome_lotacao: this.formulario_subTicket.value.nome_lotacao,
                            telefone: this.formulario_subTicket.value.id_canal,
                            type: "chat",
                            value: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        };
                        this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + dados.protocolo, "chat_body", dados_body).then(res => {
                            this.onAtualizarHistoricoTicket(dados.protocolo, this.formulario_subTicket.value.key);
                        })
                        this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario_subTicket.value.protocolo).pipe(take(1)).subscribe(res => {
                            let data = JSON.parse(JSON.stringify(res));
                            if (data.length > 0) {
                                let atendimento = {
                                    key: data[0].key,
                                    status: "Tramitado",
                                    menu: this.formulario_subTicket.value.tipo_ticket,
                                    protocolo: this.formulario_subTicket.value.protocolo,
                                    origem: this.formulario_subTicket.value.canal,
                                    ticket: this.formulario_subTicket.value.key,
                                    tipo_ticket: this.formulario_subTicket.value.tipo_ticket,
                                    key_lotacao: this.formulario_subTicket.value.key_solicitante_lotacao,
                                    key_puc: this.formulario_subTicket.value.key_puc,
                                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                    key_solicitante: this.formulario_subTicket.value.key_solicitante,
                                    key_catalogo: this.formulario_subTicket.value.key_catalogo,
                                    key_item: this.formulario_subTicket.value.key_item,
                                    key_subitem: this.formulario_subTicket.value.key_subitem,
                                    time: Date.now(),
                                    nome: this.formulario_subTicket.value.nome_solicitante,
                                    tempo: null
                                }
                                this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                            } else {
                                let atendimento = {
                                    status: "Tramitado",
                                    menu: this.formulario_subTicket.value.tipo_ticket,
                                    protocolo: this.formulario_subTicket.value.protocolo,
                                    origem: this.formulario_subTicket.value.canal,
                                    ticket: this.formulario_subTicket.value.key,
                                    tipo_ticket: this.formulario_subTicket.value.tipo_ticket,
                                    key_lotacao: this.formulario_subTicket.value.key_solicitante_lotacao,
                                    key_puc: this.formulario_subTicket.value.key_puc,
                                    key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                    key_solicitante: this.formulario_subTicket.value.key_solicitante,
                                    key_catalogo: this.formulario_subTicket.value.key_catalogo,
                                    key_item: this.formulario_subTicket.value.key_item,
                                    key_subitem: this.formulario_subTicket.value.key_subitem,
                                    time: Date.now(),
                                    nome: this.formulario_subTicket.value.nome_solicitante,
                                    tempo: null
                                }
                                //  this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/atendimento", atendimento, "atendimento");
                                this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento);
                                this.formulario_subTicket.reset();
                            }
                        })
                        //            this.tramitarTicketVoip = false;
                        //            this.ticketVoip = false;
                        //            this.myChat = false;
                        //            this.onMostraAtendimento();
                    });
                });
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Não foi possivel gravar o subtickt' });
            }
        });
    }
    onEncerrarSubTicket() {
        // console.log(this.formulario_subTicket.value)
        this.formulario_subTicket.patchValue({
            key_puc: this.key_central,
            key_solicitante: this.formulario_ticket.value.id_canal,
            id_canal: this.formulario_ticket.value.id_canal,
        })
        if (!this.formulario_subTicket.valid) {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Por favor, preencha todos os campos(*) obrigatórios para continuar.' });
        } else {
            this.confirmationService.confirm({
                message: 'Confirma o encerramento do subticket?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                acceptIcon: 'pi pi-check',
                rejectLabel: 'Não',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    //    this.onFechar();
                    this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Atendimento encerrado.' });
                    this.subTicket = false;
                    this.genProvider.onIncrementKeyZeroDate('dados/' + this.parametros.path, "protocolo", 6).then((keyValue: any) => {
                        this.formulario_subTicket.patchValue({
                            key: null,
                            protocolo: keyValue,
                            time: Date.now(),
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            ticketPrincipal: this.formulario.value.ticket,
                            qtd_interacao: 1,
                            status: "Finalizado",
                            ultima_interacao: Date.now(),
                            fim: Date.now(),
                            tempo: this.tempoAtendimento,
                            conteudoTexto: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                            email: this.formulario.value.email,
                            telefone: this.formulario.value.telefone
                        })

                        this.genProvider.onSaveIncrementZeroDate("dados/" + this.parametros.path + "/ticket", this.formulario_subTicket.value, "ticket", 6).then(ticket => {

                            let msg = "Título: " + this.formulario_subTicket.value.titulo_ticket + this.service.ctrl
                            msg += 'Ticket: #' + this.formulario_subTicket.value.ticket + this.service.ctrl
                            msg += 'Data da abertura: ' + new DatePipe('pt-BR').transform(this.formulario_subTicket.value.data_criacao, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Status: ' + this.formulario_subTicket.value.status + this.service.ctrl
                            msg += 'Status do ticket: ' + this.formulario_subTicket.value.status_ticket + this.service.ctrl
                            msg += 'Data do encerramento: ' + new DatePipe('pt-BR').transform(this.formulario_subTicket.value.fim, 'd, MMMM yyyy - HH:mm') + this.service.ctrl
                            msg += 'Aberta por : ' + this.formulario_subTicket.value.nome + this.service.ctrl
                            msg += 'Detalhe: ' + this.formulario_subTicket.value.conteudo + this.service.ctrl + this.service.ctrl
                            msg += '<b>Esta é uma mensagem automática, não responda.</b>'
                            if (this.formulario_subTicket.value.email) {
                                let assunto = "Chamado do Ticket " + this.formulario_subTicket.value.ticket;
                                let email = {
                                    contato: this.formulario_subTicket.value.email,
                                    assunto: assunto,
                                    mensagem: msg
                                }
                                this.service.sendMail(email)
                            }

                            let dados_ticket_body = {
                                status: 'Enviada',
                                key_lotacao: this.key_lotacao,
                                key_usuario: this.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                nome_lotacao: this.nome_lotacao,
                                type: 'chat',
                                ticket: ticket,
                                value: this.formulario_subTicket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                tipo: 'publica'
                            }
                            let data_movto = {
                                ticket: this.formulario_subTicket.value.ticket,
                                protocolo: this.formulario_subTicket.value.protocolo,
                                status: 'Finalizado',
                                key_lotacao: this.key_lotacao,
                                key_usuario: this.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                nome_lotacao: this.nome_lotacao,
                                key_lotacao_tramitacao: null,
                                key_usuario_destino: null,
                                key_puc: null,
                                time: Date.now(),
                                canal: this.formulario_subTicket.value.canal,
                                conteudo: this.formulario_subTicket.value.conteudoTexto
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ticket_movimentacao', data_movto);
                            // this.genProvider.onSaveObjeto('dados', this.parametros.path + '/ticket/' + this.formulario_subTicket.value.key, "ticket_body", dados_ticket_body);
                            let dados = {
                                protocolo: this.formulario_subTicket.value.protocolo,
                                status: 'Finalizado',
                                origem: this.formulario_subTicket.value.canal,
                                novo: '0',
                                menu: 'SubTicket Finalizado',
                                image: 'assets/icon/ticket.png',
                                key_puc: this.formulario_subTicket.value.key_puc,
                                key_catalogo: this.formulario_subTicket.value.key_catalogo,
                                key_item: this.formulario_subTicket.value.key_item,
                                key_subitem: this.formulario_subTicket.value.key_subitem,
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                key_lotacao: this.formulario_subTicket.value.key_lotacao,
                                type: 'chat',
                                ticket: this.formulario_subTicket.value.ticket,
                                value: this.formulario_subTicket.value.conteudo.replace(/<[^>]*>?/gm, ''),
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                                id_canal: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.telefone),
                                key_solicitante: this.validaCampoProvider.transPadraoWhatsapp(this.formulario_pessoa.value.key),
                                nome_solicitante: this.formulario_pessoa.value.nome,
                                nome: this.formulario_pessoa.value.nome,
                                tempo: this.tempoAtendimento,
                            }
                            this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/chat", dados.protocolo, dados);
                            let dados_body = {
                                protocolo: this.formulario_subTicket.value.protocolo,
                                messageNumber: 1,
                                key_lotacao: this.key_lotacao,
                                key_usuario: this.key_usuario,
                                nome_usuario: this.nomeUsuario,
                                nome_lotacao: this.nome_lotacao,
                                telefone: this.formulario_subTicket.value.id_canal,
                                type: "chat",
                                value: this.formulario_subTicket.value.conteudoTexto,
                                time: Date.now(),
                                data_criacao: Date.now(),
                                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm")
                            };
                            this.genProvider.onSaveObjeto('dados', this.parametros.path + '/chat/' + this.formulario_subTicket.value.protocolo, "chat_body", dados_body).then(res => {
                                this.onAtualizarHistoricoTicket(this.formulario_subTicket.value.protocolo, this.formulario_subTicket.value.key);
                            })
                            let atendimento = {
                                status: "Finalizado",
                                origem: this.formulario_subTicket.value.origem,
                                menu: this.formulario_subTicket.value.tipo_ticket,
                                tipo_ticket: this.formulario_subTicket.value.tipo_ticket,
                                key_lotacao: this.formulario_subTicket.value.key_solicitante_lotacao,
                                key_puc: this.formulario_subTicket.value.key_puc,
                                key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                                key_solicitante: this.formulario_subTicket.value.key_solicitante,
                                key_catalogo: this.formulario_subTicket.value.key_catalogo,
                                key_item: this.formulario_subTicket.value.key_item,
                                key_subitem: this.formulario_subTicket.value.key_subitem,
                                time: Date.now(),
                                nome: this.formulario_subTicket.value.nome_solicitante,
                                tempo: this.tempoAtendimento
                            }
                            this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                        });
                    })
                },
                reject: () => {
                    this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
                }
            });
        }

    }
    onUpload(event) {
        if (event) {
            for (let file of event.currentFiles) {
                this.uploadedFiles.push(file);
            }
        }
        //   this.messageService.add({ severity: 'info', summary: 'Arquivo adicionado', detail: '' });
    }
    onUploadSubTicket(val) {

    }
    onCancelaTicket(data) {
        this.confirmationService.confirm({
            message: 'Confirma cancelamento do ticket #' + data.ticket,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Ticket Cancelado.' });
                let dados_ticket = {
                    key: data.key,
                    status: 'Cancelado',
                    status_ticket: 'Cancelado',
                    tramitacao: "0",
                    key_usuario_cancelamento: this.parametros.key_usuario,
                    time: Date.now(),
                    data_envio: new DatePipe('pt-BR').transform(
                        Date.now(),
                        'd, MMMM yyyy - HH:mm'
                    ),
                    integrado: '0'
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'ticket', dados_ticket);
                let dados_chat = {
                    key: data.protocolo,
                    status: 'Cancelado',
                    novo: '0',
                    key_usuario_cancelamento: this.parametros.key_usuario,
                    time: Date.now(),
                    data_envio: new DatePipe('pt-BR').transform(
                        Date.now(),
                        'd, MMMM yyyy - HH:mm'
                    )
                }
                this.genProvider.onSaveObjeto('dados', this.path, 'chat', dados_chat);
                this.genProvider.onSaveObjeto('dados', this.path, 'atendimento', dados_chat);
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });


    }
    offLoads() {
        //  this.unSub$.next()
        //  this.unSub$.complete()
    }
    onLoads() {
        //   this.ngOnInit();
    }
    onMacro() {
        this.mostraMacro = true
    }
    onAddMacro(e) {
        let macro = this.macro.filter(obj => {
            return obj.key == e;
        });
        this.formulario_ticket.patchValue({
            conteudo: this.formulario_ticket.value.conteudo + this.service.ctrl + macro[0].mensagem
        })
        this.mostraMacro = false;
    }
    trimAll(value) {
        return value.replace(/\s/g, '')
    }
    onLoadMacro() {
        this.genProvider.onGetAll('dados/' + this.path + "/macro", 'titulo').pipe(take(1)).subscribe(data => {
            this.macro = JSON.parse(JSON.stringify(data));
        })
    }
    onLoadPessoa() {
        if (this.busca) {
            this.unSub$.unsubscribe()
            this.loading = true;
            this.listaPessoa = []
            let joins = [
                { key: "key_cargo", table: "cargo" },
                { key: "key_lotacao", table: "lotacao" }
            ]
            // this.genProvider.onGetLike('dados/' + this.path + '/pessoa', 'nome', 'nome', this.busca, joins).subscribe(data => {
            this.genProvider.onSqlQuery('dados/' + this.path, "select * from pessoa where nome like '" + this.busca.toUpperCase() + "%'", joins).then(data => {
                this.listaPessoa = JSON.parse(JSON.stringify(data));
                console.log(this.listaPessoa)
                if (this.listaPessoa.length > 0) {
                    this.listaPessoa = this.listaPessoa.map(res => {
                        res.codigo = parseInt(res.key)
                        if (res.lotacao) {
                            if (res.lotacao) {
                                res.nome_lotacao = res.lotacao.nome;
                            }
                        } else {
                            res.nome_lotacao = '';
                        }
                        //        res.origem = res.lotacao.origem;
                        if (res.cargo) {
                            res.nome_cargo = res.cargo.nome;
                        } else {
                            res.nome_cargo = '';
                        }
                        if (res.telefone) {
                            if (res.telefone.length == 15) {
                                res.celular = res.telefone;
                            } else {
                                res.celular = this.validaCampoProvider.validaTelefonePromo(res.telefone);
                            }
                        }
                        return res;
                    });
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.messageService.add({ severity: 'info', summary: 'Atenção', detail: 'Não foi encontrado nenhum resultado para: ' + this.busca.toUpperCase() });
                }
            })
        }
    }
    onSendConversa() {
        this.onFinalizaChatUser('559180333070');
    }
    onNotificaOnOff() {
        this.notificacaoSonora = !this.notificacaoSonora;
        this.onNotificacao();
    }
    onNotificacao() {
        if (this.notificacaoSonora) {
            this.audio.nativeElement.play();
        }
    }
    onEncerarChat(status) {
        this.confirmationService.confirm({
            message: 'Confirma ' + status,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptIcon: 'pi pi-check',
            rejectLabel: 'Não',
            rejectIcon: 'pi pi-times',
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Concluido.' });
                let dados_chat = {
                    status: 'Finalizado',
                    menu: status,
                    key: this.formulario.value.protocolo,
                    key_usuario: this.key_usuario,
                    key_lotacao: this.key_lotacao,
                    time: Date.now(),
                    value: status,
                    tempo: this.tempoAtendimento
                }
                this.genProvider.onSaveObjeto("dados", this.parametros.path, "chat", dados_chat);

                this.genProvider.onGetfilter('dados/' + this.parametros.path + '/atendimento', 'protocolo', this.formulario.value.protocolo).pipe(take(1)).subscribe(res => {
                    let data = JSON.parse(JSON.stringify(res));
                    if (data.length > 0) {
                        let atendimento = {
                            key: data[0].key,
                            status: status,
                            time: Date.now(),
                            key_agente: this.parametros.key_usuario,
                    key_usuario: this.parametros.key_usuario,
                            menu: status,
                            tempo: this.tempoAtendimento,
                        }
                        this.genProvider.onSaveObjeto('dados', this.parametros.path, "atendimento", atendimento);
                    } else {
                        let atendimento = {
                            status: "Finalizado",
                            tipo: this.formulario.value.tipo,
                            protocolo: this.formulario.value.protocolo,
                            ticket: null,
                            menu: status,
                            key_usuario: this.key_usuario,
                            key_lotacao: this.key_lotacao,
                            key_puc: this.key_central,
                            //   key_solicitante: this.formulario.value.telefone,
                            nome_solicitante: this.formulario.value.nome,
                            origem: this.formulario.value.origem,
                            id_canal: this.formulario.value.id_canal,
                            //    telefone: this.formulario.value.telefone,
                            data_criacao: Date.now(),
                            data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                            time: Date.now(),
                            tempo: this.tempoAtendimento,
                        };
                        //this.genProvider.onSaveIncrementTime("dados/" + this.parametros.path + "/atendimento", atendimento, "atendimento");
                        this.genProvider.onSaveDocKey("dados/" + this.parametros.path + "/atendimento", atendimento.protocolo, atendimento);

                    }
                    let dados_ponto = {
                        key: this.ponto[0].key,
                        protocolo: '',
                        atendimento_status: "Livre",
                        canal: '',
                    }
                    this.genProvider.onSaveObjeto('dados', this.parametros.path, 'ponto', dados_ponto);
                })
                this.myChat = false;
                this.onFinalizaChatUser(this.formulario_ticket.value.id_canal);
                this.onFreeAtendente();
                this.onGetFiltroChat('Na Fila');
                this.onFinalTempoAtendimento();
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Registro cancelado' });
            }
        });
    }
    // funcionalizades p/ geolozalizacao

    onConsultaCEP(cep) {
        let valor = cep.replace(/[^0-9]/g, '');
        if (valor.length != 8) {
            this.formulario_ticket.patchValue({
                cep: ''
            });
            this.messageService.add({ severity: 'info', summary: 'Erro!!', detail: 'Ops! CEP ' + valor + ', inválido' });
        } else {
            this.validaCampoProvider.validaCEP(cep).subscribe((data: any) => {
                if (data.cep) {
                    data.rua = data.logradouro
                    this.populaDadosForm(data);
                    //          this.errorCep = false;
                } else {
                    this.formulario_ticket.patchValue({
                        cep: ''
                    });
                    this.messageService.add({ severity: 'info', summary: 'Erro!!', detail: 'Ops! CEP ' + valor + ', inválido' });

                }
            }, error => alert(error));
        }
    }
    populaDadosForm(dados) {
        //this.formulario.setValue({});
        this.formulario_ticket.patchValue({
            rua: dados.rua,
            cep: dados.cep,
            complemento: dados.complemento,
            bairro: dados.bairro,
            cidade: dados.localidade,
            estado: dados.uf,
            localizacao:
                dados.rua +
                ' , ' +
                dados.bairro +
                ' , ' +
                dados.cidade +
                ', ' +
                dados.estado,
            local: dados.rua +
                ' , ' +
                dados.bairro +
                ' , ' +
                dados.cidade +
                ', ' +
                dados.estado,
        });
        this.onAddressMap();
    }
    onAddressMap() {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(
            { address: this.formulario_ticket.value.local },
            (results, status) => {
                if (results) {
                    if (results[0]) {
                        this.localizacao = results[0].formatted_address;
                        this.lat = results[0].geometry.location.lat();
                        this.lng = results[0].geometry.location.lng();
                        console.log(results[0])

                        if (results[0].address_components.length == 4) {
                            this.formulario_ticket.patchValue({
                                localizacao: results[0].formatted_address,
                                rua: results[0].address_components[1].long_name || ' ',
                                numero: results[0].address_components[0].long_name || ' ',
                                bairro: results[0].address_components[2].long_name || ' ',
                                cidade: results[0].address_components[3].long_name || ' ',
                                estado: '',
                                cep: '',
                                lat: this.lat,
                                lng: this.lng

                            });
                        } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
                            this.formulario_ticket.patchValue({
                                localizacao: results[0].formatted_address,
                                rua: results[0].address_components[1].long_name || ' ',
                                numero: results[0].address_components[0].long_name || ' ',
                                bairro: results[0].address_components[2].long_name || ' ',
                                cidade: results[0].address_components[3].long_name || ' ',
                                estado: results[0].address_components[4].long_name || ' ',
                                cep: results[0].address_components[5].long_name || ' ',
                                lat: this.lat,
                                lng: this.lng
                            });
                        } else if (results[0].address_components.length >= 7) {
                            this.formulario_ticket.patchValue({
                                localizacao: results[0].formatted_address,
                                rua: results[0].address_components[1].long_name || ' ',
                                numero: results[0].address_components[0].long_name || ' ',
                                bairro: results[0].address_components[2].long_name || ' ',
                                cidade: results[0].address_components[3].long_name || ' ',
                                estado: results[0].address_components[4].long_name || ' ',
                                cep: results[0].address_components[6].long_name || ' ',
                                lat: this.lat,
                                lng: this.lng
                            });
                        } else if (results[0].address_components.length < 4) {
                            this.formulario_ticket.patchValue({
                                localizacao: '',
                                rua: '',
                                numero: '',
                                bairro: '',
                                cidade: '',
                                estado: '',
                                cep: '',
                                lat: this.lat,
                                lng: this.lng
                            });
                        }
                    }
                }
            }
        );
    }
    getLocal() {
        this.formulario_ticket.patchValue({
            localizacao:
                this.formulario_ticket.value.rua +
                ' , ' +
                this.formulario_ticket.value.numero +
                ', ' +
                this.formulario_ticket.value.bairro +
                ' , ' +
                this.formulario_ticket.value.cidade +
                ', ' +
                this.formulario_ticket.value.estado
        });
        //  console.log(this.formulario.value.localizacao);
        this.onAddressMap();
    }
    onMap() {
        if ((this.formulario_ticket.value.lat) && (this.formulario_ticket.value.lng)) {
            this.onClickMapChat(parseFloat(this.formulario_ticket.value.lat), parseFloat(this.formulario_ticket.value.lng))
        }
    }
    onClickMap(e) {
        this.lat = e.coords.lat;
        this.lng = e.coords.lng;
        this.formulario_ticket.patchValue({
            latitude: e.coords.lat,
            longitude: e.coords.lng
        });
        let geocoder = new google.maps.Geocoder();
        let latLng = new google.maps.LatLng(this.lat, this.lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (results[0].address_components.length == 4) {
                this.formulario_ticket.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: '',
                    cep: '',
                    lat: this.lat,
                    lng: this.lng

                });
            } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
                this.formulario_ticket.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: '',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length >= 7) {
                this.formulario_ticket.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: results[0].address_components[6].long_name || ' ',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length < 4) {
                this.formulario_ticket.patchValue({
                    localizacao: '',
                    rua: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    cep: '',
                    lat: '',
                    lng: ''
                });
            }
        });
    }
    onClickMapChat(lat, lng) {
        this.lat = lat;
        this.lng = lng;
        let geocoder = new google.maps.Geocoder();
        let latLng = new google.maps.LatLng(this.lat, this.lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (results[0].address_components.length == 4) {
                this.formulario_ticket.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: '',
                    cep: '',
                    lat: this.lat,
                    lng: this.lng

                });
            } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
                this.formulario_ticket.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: results[0].address_components[5].long_name || ' ',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length >= 7) {
                this.formulario_ticket.patchValue({
                    localizacao: results[0].formatted_address,
                    local: results[0].formatted_address,
                    rua: results[0].address_components[1].long_name || ' ',
                    numero: results[0].address_components[0].long_name || ' ',
                    bairro: results[0].address_components[2].long_name || ' ',
                    cidade: results[0].address_components[3].long_name || ' ',
                    estado: results[0].address_components[4].long_name || ' ',
                    cep: results[0].address_components[6].long_name || ' ',
                    lat: this.lat,
                    lng: this.lng
                });
            } else if (results[0].address_components.length < 4) {
                this.formulario_ticket.patchValue({
                    localizacao: '',
                    rua: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    cep: ''
                });
            }
        });
    }
    onGetPuc() {
        let unSub = this.genProvider.onGetKey('dados/' + this.path + '/puc', this.parametros.key_puc).subscribe(res => {
            unSub.unsubscribe();
            let temp = JSON.parse(JSON.stringify(res));

        })
    }
    onTemplateShow(temp, valid) {
        this.titulo_pergunta = ''
        this.acao = [];
        this.value = null
        this.validacao = null
        this.type = null;
        this.list = false;
        this.formSend = null;
        let i = this.cont_assistente
        if (i + 1 > temp.length) {
            console.log("Finaliza")
            let msg = {
                status: 'Recebida',
                type: "chat",
                value: 'Script de atendimento finalizado, acesse agora a aba de Relato e continue com o atendimento.',
                time: Date.now(),
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                valid: false
            }
            this.chat_assistente.push(msg)
            this.formSend = null;
            console.log(this.chat_assistente)
            setTimeout(() => {
                try {
                    this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                } catch (err) { }
            }, 2000);
            let temp_list = this.chat_assistente.filter(res => {
                return res.valid
            })
            temp_list.sort(function (a, b) {
                if (a.time > b.time) {
                    return 1;
                }
                if (a.time < b.time) {
                    return -1;
                }
                return 0;
            });
            let texto = '';
            temp_list.forEach(e => {
                texto = texto + e.value + this.service.ctrl;
            });
            this.formulario_ticket.patchValue({
                conteudo: texto.replace(/\n/g, '<br>'),
                conteudoTexto: texto
            })
        } else {
            while (temp[i].status == 'oculto' || temp[i].validacao == 'documento' || temp[i].validacao == 'imagem' || temp[i].validacao == 'video' || temp[i].validacao == 'audio') {
                i++
                if (i + 1 > temp.length) {
                    break;
                }
            }
            if (i + 1 > temp.length) {
                let msg = {
                    status: 'Recebida',
                    type: "chat",
                    value: 'Script de atendimento finalizado, acesse agora a aba de Relato e continue com o atendimento.',
                    time: Date.now(),
                    data_criacao: Date.now(),
                    data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                    valid: false
                }
                this.chat_assistente.push(msg)
                this.formSend = null;
                setTimeout(() => {
                    try {
                        this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                    } catch (err) { }
                }, 2000);
                let temp_list = this.chat_assistente.filter(res => {
                    return res.valid
                })
                temp_list.sort(function (a, b) {
                    if (a.time > b.time) {
                        return 1;
                    }
                    if (a.time < b.time) {
                        return -1;
                    }
                    return 0;
                });
                let texto = '';
                temp_list.forEach(e => {
                    texto = texto + e.value + this.service.ctrl;
                });
                this.formulario_ticket.patchValue({
                    conteudo: texto.replace(/\n/g, '<br>'),
                    conteudoTexto: texto
                })

            } else {
                let msg: any = [];
                let template = temp[i]
                this.cont_assistente = i;
                this.msg_template = [];
                if (template.type == 'text') {
                    msg = {
                        status: 'Recebida',
                        type: "chat",
                        value: template.texto,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        valid: valid
                    }
                } else if (template.type == 'reply') {
                    let btn_item = []
                    template.rows.forEach(b => {
                        let bt = {
                            type: "reply",
                            reply: {
                                id: b.id,
                                title: b.title
                            }
                        }
                        btn_item.push(bt)
                    })
                    this.msg_template = {
                        header: template.header,
                        title: template.body,
                        body: template.body,
                        footer: template.footer,
                        rows: btn_item
                    };
                    this.list_rows = template.rows;
                    msg = {
                        status: 'Recebida',
                        type: "chat",
                        value: template.body,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        valid: valid
                    }
                    //   this.chat_assistente.push(msg)
                    setTimeout(() => {
                        try {
                            this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                        } catch (err) { }
                    }, 1000);
                    this.titulo_pergunta = template.header
                    this.acao = template.rows || null
                    this.type = template.type || null;
                    this.value = template.value || null
                    this.validacao = null;
                    this.formSend = null;
                    this.list = true;
                } else if (template.type == 'list') {
                    this.msg_template = {
                        header: template.header,
                        body: template.body,
                        footer: template.footer,
                        title: template.title,
                        button: template.button,
                        list: true,
                        rows: template.rows
                    };
                    this.list_rows = template.rows;
                    msg = {
                        status: 'Recebida',
                        type: "chat",
                        value: template.header,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        valid: valid
                    }
                    //  this.chat_assistente.push(msg)
                    this.formSend = null;
                    this.list = true;
                    setTimeout(() => {
                        try {
                            this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                        } catch (err) { }
                    }, 1000);
                    this.titulo_pergunta = template.header
                    this.acao = template.rows || null
                    this.type = template.type || null;
                    this.value = template.value || null
                    this.validacao = null
                } else if (template.type == 'location') {
                    this.msg_template = {
                        header: template.texto,
                    };
                    msg = {
                        status: 'Recebida',
                        type: "chat",
                        value: template.texto,
                        time: Date.now(),
                        data_criacao: Date.now(),
                        data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                        valid: valid
                    }
                    //     this.chat_assistente.push(msg)
                    this.location = true;
                    setTimeout(() => {
                        try {
                            this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                        } catch (err) { }
                    }, 1000);
                    this.titulo_pergunta = template.header
                    this.acao = template.rows || null
                    this.type = template.type || null;
                    this.value = template.value || null
                    this.validacao = null
                }
                this.validacao = template.validacao
                this.acao = template.rows || null
                this.type = template.type || null;
                this.value = template.value || null
                this.formSend = null;
                this.chat_assistente.push(msg)
                setTimeout(() => {
                    try {
                        this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                    } catch (err) { }
                }, 1000);
            }
        }


    }
    onTemplateShowAll(temp, valid) {
        this.titulo_pergunta = ''
        this.acao = [];
        this.value = null
        this.validacao = null
        this.type = null;
        this.list = false;
        this.formSend = null;
        let i = this.cont_assistente

        let msg: any = [];
        let template = temp[i]
        this.cont_assistente = i;
        this.msg_template = [];
        if (template.type == 'text') {
            msg = {
                status: 'Recebida',
                type: "chat",
                value: template.texto,
                time: Date.now(),
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                valid: valid
            }
        } else if (template.type == 'reply') {
            let btn_item = []
            template.rows.forEach(b => {
                let bt = {
                    type: "reply",
                    reply: {
                        id: b.id,
                        title: b.title
                    }
                }
                btn_item.push(bt)
            })
            console.log(template)
            this.msg_template = {
                header: template.header,
                title: template.body,
                body: template.body,
                footer: template.footer,
                rows: btn_item
            };
            this.list_rows = template.rows;
            msg = {
                status: 'Recebida',
                type: "chat",
                value: template.body,
                time: Date.now(),
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                valid: valid
            }
            //   this.chat_assistente.push(msg)
            setTimeout(() => {
                try {
                    this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                } catch (err) { }
            }, 1000);
            this.titulo_pergunta = template.header
            this.acao = template.rows || null
            this.type = template.type || null;
            this.value = template.value || null
            this.validacao = null;
            this.formSend = null;
            this.list = true;
            console.log(template)
        } else if (template.type == 'list') {
            this.msg_template = {
                header: template.header,
                body: template.body,
                footer: template.footer,
                title: template.title,
                button: template.button,
                list: true,
                rows: template.rows
            };
            this.list_rows = template.rows;
            msg = {
                status: 'Recebida',
                type: "chat",
                value: template.header,
                time: Date.now(),
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                valid: valid
            }
            //  this.chat_assistente.push(msg)
            this.formSend = null;
            this.list = true;
            setTimeout(() => {
                try {
                    this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                } catch (err) { }
            }, 1000);
            this.titulo_pergunta = template.header
            this.acao = template.rows || null
            this.type = template.type || null;
            this.value = template.value || null
            this.validacao = null
            console.log(template)
        } else if (template.type == 'location') {
            this.msg_template = {
                header: template.texto,
            };
            msg = {
                status: 'Recebida',
                type: "chat",
                value: template.texto,
                time: Date.now(),
                data_criacao: Date.now(),
                data_hora: new DatePipe("pt-BR").transform(Date.now(), "d, MMMM yyyy - HH:mm"),
                valid: valid
            }
            //     this.chat_assistente.push(msg)
            this.location = true;
            setTimeout(() => {
                try {
                    this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
                } catch (err) { }
            }, 1000);
            this.titulo_pergunta = template.header
            this.acao = template.rows || null
            this.type = template.type || null;
            this.value = template.value || null
            this.validacao = null
            console.log(template)
        }
        console.log(template)
        this.validacao = template.validacao
        this.acao = template.rows || null
        this.type = template.type || null;
        this.value = template.value || null
        this.formSend = null;
        this.chat_assistente.push(msg)
        setTimeout(() => {
            try {
                this.objDivAssistente.nativeElement.scrollTop = (this.objDivAssistente.nativeElement.scrollHeight);
            } catch (err) { }
        }, 1000);
    }
    onAtualizaList(event) {
        console.log(event)
        this.formSend = event.value
    }
    onCloseMap() {
        console.log(this.formulario_ticket.value)
        this.location = false;
        this.formSend = this.formulario_ticket.value.localizacao;
    }
    onDisplayFormDinamico(key) {

        this.template = [];
        let unSub = this.genProvider.onGetAll('dados/' + this.parametros.path + '/template/' + key + '/template_body', 'numero').subscribe(data => {
            unSub.unsubscribe();
            let temp = JSON.parse(JSON.stringify(data))
            let template = temp.filter(res => {
                return res.status != 'cancelado'
            });
            this.template = template.map(col => ({ title: col.field.toUpperCase(), field: col.field, numero: col.numero, type: col.type, rows: col.rows, noDisplay: col.noDisplay }));
            console.log(this.template)
            /// gerar o formualrio dinamico
            if (template.length > 0) {
                let tmp = template.filter(res => {
                    return res.noDisplay != true
                })

                let colsDados = tmp.map(col => ({
                    [col.field.toLowerCase()]: ''
                }
                ));
                let a = JSON.stringify(colsDados)
                let b = a.replace(/\{/g, '');
                let c = b.replace(/\}/g, '');
                let d = c.replace(/\[/g, '{');
                let e = d.replace(/\]/g, '}');
                var obj = JSON.parse(e);
                this.formulario_dinamico = this.formBuilder.group(obj);
                console.log(this.formulario_dinamico)
            }
        });

    }
    onSaveFormDinamicoVoip(data, ticket) {
        let msg = {
            protocolo: ticket.protocolo,
            key_puc: ticket.key_puc,
            key_catalogo: ticket.key_catalogo,
            key_item: ticket.key_item,
            key_subitem: ticket.key_subitem,
            key_ticket: ticket.key,
            key_lotacao: ticket.key_lotacao,
            status: ticket.status,
            situacao: "Atualizado",
            data_hora: new DatePipe('pt-BR').transform(
                Date.now(),
                'd, MMMM yyyy - HH:mm'
            ),
            time: Date.now(),
            key_template: this.key_template || null,
            origem: 'voip',
            relato: this.formulario_ticket.value.conteudoTexto,
            ...data
        };
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat_dinamico', msg)
        this.formulario_dinamico.reset();
        this.key_template = null;
        this.template = [];
    }
    onSaveFormDinamicoChat(data, ticket) {
        let msg = {
            key: data.key,
            protocolo: ticket.protocolo,
            key_puc: ticket.key_puc,
            key_catalogo: ticket.key_catalogo,
            key_item: ticket.key_item,
            key_subitem: ticket.key_subitem,
            key_ticket: ticket.key,
            key_lotacao: ticket.key_lotacao,
            status: ticket.status,
            situacao: "Atualizado",
            data_hora: new DatePipe('pt-BR').transform(
                Date.now(),
                'd, MMMM yyyy - HH:mm'
            ),
            time: Date.now(),
            key_template: this.key_template,
            origem: 'voip',
            relato: ticket.conteudoTexto,
            ...data
        };
        this.genProvider.onSaveObjeto('dados', this.parametros.path, 'chat_dinamico', msg)
        this.formulario_dinamico.reset();
        this.key_template = null;
        this.template = [];
    }
    onIntegracaoTicket(data) {
        let res = data
        if (res.status.toLowerCase() == "novo") {
            this.onNotificacao();
        }
        if (res.status) {
            res.status = res.status.toLowerCase()
        }
        if (res.status_ticket) {
            res.status_ticket = res.status_ticket.toLowerCase()
        }
        if (res.key_lotacao) {
            let lotacao = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
            if (lotacao) {
                res.nome_lotacao = lotacao.nome
                res.urlApi = lotacao.urlApi
            } else {
                res.nome_lotacao = ''
                res.urlApi = null
            }
        } else {
            res.nome_lotacao = '';
        }
        res.nome_catalogo = this.genProvider.onGetTableKey(this.catalogo, res.key_catalogo).nome
        let item = this.genProvider.onGetTableKey(this.itemCatalogo, res.key_item);
        if (item) {
            res.item = item.nome
            if ((res.key_subitem) && (res.key_catalogo) && (res.key_item)) {
                try {
                    let unSub = this.genProvider.onGetKey('dados/' + this.path + '/catalogo/' + res.key_catalogo + '/item/' + res.key_item + '/subitem', res.key_subitem).subscribe(data => {
                        unSub.unsubscribe();
                        let subitem = JSON.parse(JSON.stringify(data));
                        if (subitem) {
                            res.subitem = subitem.nome;
                        }
                    })
                } catch (e) {
                    console.log(e);
                }
            }
        }
        if (res.key_usuario) {
            let agente = this.genProvider.onGetTableKey(this.agente, res.key_usuario)
            if (agente) {
                res.agente = agente.nome
            } else {
                res.agente = '';
            }
        } else {
            res.agente = '';
        }
        if (res.enviar_resposta_solicitante_email) {
            res.resposta = "email"
        } else {
            res.resposta = "whatsapp"
        }
        if (res.qtd_interacao) {
            res.ultima_interacaoM = new DatePipe("pt-BR").transform(res.ultima_interacao, "d/MM/yyy - HH:mm");
        }
        res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM/yyyy - HH:mm");
        res.prazo_resolucaoM = new DatePipe("pt-BR").transform(res.prazo_resolucao, "d/MM/yyyy - HH:mm");
        console.log(res)
        this.service.onIntegracaoTicket(res);
    }
    // fim
}
/*

{
  "instanceId": "558001231234",
  "token": "bWF0cml4d2hhdHNhcGk6V3lCdzZxLnU5cXtKKHxaNVQqS04=",
  "ticket": "20220811.000001.000189",
  "key_solicitante": "559180333070",
  "nome_solicitante": "Hyper Channel",
  "protocolo_atendimento": "20220719.000001.125043591",
  "tipo_ticket": "Incidente",
  "titulo_ticket": "Chamado central",
  "status": "inserir",
  "situacao": "registrada",
  "status_ticket": "novo",
  "data_criacao": 1660231469429,
  "data_hora": "11, agosto 2022 - 11:24",
  "time": 1660231469429,
  "canal": "whatsapp",
  "lat": null,
  "lng": null,
  "local": null,
  "bairro": null,
  "cidade": null,
  "estado": null,
  "cep": null,
  "texto": "Informe qual cliente (nome da empresa)\nTt\nInforme seu nome agora\nGg\nInforme seu cargo na empresa\nGg\nInforme um e-mail válido.\nPaulospcoelho@gmail.com\nDemanda\nInformação\nInforme agora com detalhes sobre o assunto? (Você pode mandar texto, áudio, vídeo e fotos) quanto mais detalhes você informar melhor para nossa equipe.\nPaiva\n\nSim\noi paulo\nposso ajudar\nvano las\nok\noi paulo\nInforme qual cliente (nome da empresa)<br>Tt<br>Informe seu nome agora<br>Gg<br>Informe seu cargo na empresa<br>Gg<br>Informe um e-mail válido.<br>Paulospcoelho@gmail.com<br>Demanda<br>Informação<br>Informe agora com detalhes sobre o assunto? (Você pode mandar texto, áudio, vídeo e fotos) quanto mais detalhes você informar melhor para nossa equipe.<br>Paiva<br><br>Sim<br>oi paulo<br>posso ajudar<br>vano las<br>ok<br>oi paulo<br> FIM DO CHAT <br>\n***************** FIM DO CHAT *****************\n",
  "html": "Informe qual cliente (nome da empresa)<br>Tt<br>Informe seu nome agora<br>Gg<br>Informe seu cargo na empresa<br>Gg<br>Informe um e-mail válido.<br>Paulospcoelho@gmail.com<br>Demanda<br>Informação<br>Informe agora com detalhes sobre o assunto? (Você pode mandar texto, áudio, vídeo e fotos) quanto mais detalhes você informar melhor para nossa equipe.<br>Paiva<br><br>Sim<br>oi paulo<br>posso ajudar<br>vano las<br>ok<br>oi paulo<br>Informe qual cliente (nome da empresa)<br>Tt<br>Informe seu nome agora<br>Gg<br>Informe seu cargo na empresa<br>Gg<br>Informe um e-mail válido.<br>Paulospcoelho@gmail.com<br>Demanda<br>Informação<br>Informe agora com detalhes sobre o assunto? (Você pode mandar texto, áudio, vídeo e fotos) quanto mais detalhes você informar melhor para nossa equipe.<br>Paiva<br><br>Sim<br>oi paulo<br>posso ajudar<br>vano las<br>ok<br>oi paulo<br> FIM DO CHAT <br><br>***************** FIM DO CHAT *****************<br>",
  "files": [],
  "agente": "Administrador",
  "key_catalogo": "1",
  "nome_catalogo": "Atendimento",
  "key_item": "59",
  "nome_item": "Chamado central",
  "key_subitem": "1",
  "nome_subitem": "Chamado"
}
*/
