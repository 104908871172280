/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Subscription } from 'rxjs';
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { take } from 'rxjs/operators';

declare var google;
@Component({
  selector: 'app-lotacao',
  templateUrl: './lotacao.component.html',
  styleUrls: ['./lotacao.component.scss'],
  providers: [ConfirmationService]
})
export class LotacaoComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;

  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  unSub: Subscription[] = [];
  puc: any;
  formulario_puc: FormGroup;
  lotacao_puc: any = [];
  permissao: any = true;
  tabMenuitens: any = [];
  page: string;
  Data: any[];
  data: TreeNode[];
  data1: TreeNode[];
  data2: TreeNode[];
  selectedNode: TreeNode;
  nivel: any;
  loading: any;
  lat: any;
  lng: any;
  localizacao: any;
  tipo: any;
  qtd_upload: number;
  busca: string;
  recno: any = [];
  unSub$: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validaCampoProvider: ValidaCampoProvider
  ) {
    this.qtd_upload = 9;
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      key_subordinada: '',
      key_tree: '',
      nome: [null, Validators.required],
      sigla: '',
      titular: '',
      telefone: '',
      lat: '',
      lng: '',
      cep: '',
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      email: '',
      localizacao: '',
      local: '',
      codigo: '',
      status: [null, Validators.required],
      key_tipo: [null, Validators.required],
      time: '',
      urlApi:''
    });
    this.formulario_puc = this.formBuilder.group({
      key: null,
      key_puc: ''
    });
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  onCombo() {
    this.recno = [
      { nome: '10', qtd: 9 },
      { nome: '50', qtd: 49 },
      { nome: '100', qtd: 99 },
      { nome: '200', qtd: 199 },
      { nome: '300', qtd: 299 },
      { nome: '400', qtd: 399 },
      { nome: '500', qtd: 499 },
      { nome: '1000', qtd: 999 },
      { nome: 'Todos', qtd: 5000 }
    ]
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.cols = [
      { header: 'Nome', field: 'nome' },
      { header: 'Sigla', field: 'sigla' },
      { header: 'Código', field: 'codigo' },
      { header: 'Subordinação', field: 'nome_lotacao' },
      //      { header: 'Tree', field: 'key_tree' },
      { header: 'Titular', field: 'titular' },
      { header: 'E-mail Principal', field: 'email' },
      { header: 'Telefone', field: 'telefone' },
    ];
    this.tabMenuitens = [
      { id: '1', label: 'Dados', icon: 'pi pi-fw pi-globe' },
      { id: '2', label: 'Localização', icon: 'pi pi-fw pi-map' },
      { id: '3', label: 'Central de Serviço', icon: 'pi pi-fw pi-desktop' }
    ];
    this.page = "1";
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }

  ngOnInit() {
    this.onCombo();
    this.onLoad();
    this.onLoadPuc();
    this.onLoadTipo()

  }
  onNodeSelect(event) {
    this.messageService.add({ severity: 'success', summary: 'Node Selected', detail: event.node.label });
  }
  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unSub$) {
      this.unSub$.unsubscribe()
    }
  }
  onTab(e) {
    this.page = e.activeItem.id
  }
  onLoadLotacao() {
    if (this.busca) {
      this.unSub$.unsubscribe()
      this.loading = true;
      this.lista = []
      let joins = [
        { key: 'key_subordinada', table: 'lotacao' }
      ];
      let sql = "select * from lotacao where nome like '" + this.busca.toUpperCase() + "%'";
      this.genProvider.onSqlQuery('dados/' + this.path, sql, joins).then(data => {
        let lista = JSON.parse(JSON.stringify(data))
        this.lista = lista.map(res => {
          if (res.lotacao) {
            res.nome_lotacao = res.lotacao.nome
          } else {
            res.nome_lotacao = ''
          }
          return res;
        });
        this.loading = false;
      },
        err => console.log(err)
      );
    } else {
      this.loading = false;
      this.messageService.add({ severity: 'info', summary: 'Atenção', detail: 'Não foi encontrado nenhum resultado para: ' + this.busca.toUpperCase() });
    }
  }
  onLoad() {
    this.loading = true;
    let joins = [
      { key: 'key_subordinada', table: 'lotacao' }
    ];
    this.unSub$ = this.genProvider.onGetAllPageDesc('dados/' + this.path + '/lotacao', "time", this.qtd_upload + 1, joins).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data))
      this.lista = lista.map(res => {
        if (res.lotacao) {
          res.nome_lotacao = res.lotacao.nome
        } else {
          res.nome_lotacao = ''
        }
        return res;
      });
      this.loading = false;
    },
      err => console.log(err)
    );
  }
  onCreateData(vetor) {
    vetor.sort(function (a, b) {
      if (a.nivel < b.nivel) {
        return 1;
      }
      if (a.nivel > b.nivel) {
        return -1;
      }
      return 0;
    });

    this.nivel = vetor[0].nivel

    vetor.sort(function (a, b) {
      if (a.nivel > b.nivel) {
        return 1;
      }
      if (a.nivel < b.nivel) {
        return -1;
      }
      return 0;
    });
    console.log(vetor)
    // .replace(/\D/g, '')
    let array = vetor.map(res => ({
      nivel: res.nivel,
      label: res.sigla,
      id: res.key_tree,
      expanded: true
    }));
    console.log(this.nivel)
    //   for (let index = 0; index < this.nivel.length; index++) {
    //     const element = array[index];
    //   }
    let niveis = array.filter(obj => obj.nivel == 1)
    let dd = niveis.map(item => ({
      label: item.label,
      nivel: item.nivel,
      type: 'person',
      styleClass: 'p-person',
      expanded: true,
      id: item.id,
      children: array.filter(obj =>
      // obj.nivel == item.nivel

      {
        if (
          (obj.id.toLocaleLowerCase().indexOf(item.id) >= 0) && (obj.nivel >= item.nivel + 1)
        ) {
          return true;
        }
        return false;
      }
      )
    }));

    niveis = array.filter(obj => obj.nivel == 2)
    let dd3 = dd[0].children.map(item => ({
      label: item.label,
      nivel: item.nivel,
      type: 'person',
      styleClass: 'p-person',
      expanded: true,
      id: item.id,
      children: array.filter(obj =>
      // obj.nivel == item.nivel

      {
        if (
          (obj.id.toLocaleLowerCase().indexOf(item.id) >= 0) && (obj.nivel == item.nivel + 1)
        ) {
          return true;
        }
        return false;
      }
      )
    }));


    this.data = dd;
    console.log(this.data)
    console.log(this.data1)
  }
  onSubordinacao(key) {
    /*
    let res = this.lista.filter(obj => {
      return obj.key == key
    });
    return res[0]
    */
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo';
    this.onMostraFrm();
    this.permissao = false;
    this.lotacao_puc = [];
    this.formulario.patchValue({
      lat: -3.120,
      lng: -60.02,
      time: Date.now()
    })

  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event)
    this.titulo = 'Editar';
    this.onLoadlotacao_Puc(event.key)
    this.onMostraFrm();
    this.permissao = false;

  }

  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.genProvider.remove('dados/' + this.path + '/lotacao', event.key, 'key_lotacao').then(res=>{
          if (res!=null){
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada, este registro está relaciondo à '+ res +' tabelas!' });
          }else {
            this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
          }
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      this.onSubmit();
    }
  }
  onSubmit() {
    this.onMostraFrm();
    this.genProvider.onSaveIncrement("dados/" + this.path + "/lotacao", this.formulario.value, "lotacao").then(t => {
      if (this.formulario.value.key) {
        this.atualizarLista(this.formulario.value)
      }
      /*
      if (!this.formulario.value.key_subordinada) {
        this.formulario.patchValue({
          key_subordinada: t,
          key_tree: t
        });
        this.genProvider.onSaveIncrement("dados/" + this.path + "/lotacao", this.formulario.value, "lotacao");
      } else {
        this.formulario.patchValue({
          key_tree: this.formulario.value.key_tree + '.' + t
        });
        this.genProvider.onSaveIncrement("dados/" + this.path + "/lotacao", this.formulario.value, "lotacao");
      }
      */
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    // const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "Lotações")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },

      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onLoadPuc() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/puc', 'nome').subscribe(data => {
      this.puc = data;
      this.puc = this.puc.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  onLoadTipo() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/tipo', 'nome').subscribe(data => {
      this.tipo = data;
      this.tipo = this.tipo.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  onLotacao(key) {
    let res = this.lista.filter(obj => {
      return obj.key == key
    });

  }
  gravarPuc() {
    let res = this.lotacao_puc.filter(obj => {
      return obj.key_puc == this.formulario_puc.value.key_puc
    });
    //  console.log (res)
    //  console.log (this.formulario_puc.value)
    if (res.length > 0) {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Esta cetral de serviços já esta cadastrada para esta Unidade Operacional, por favor selecione outra.' });
    } else {
      if ((this.formulario.valid) && (this.formulario_puc.valid) && (this.formulario.value.key)) {
        this.genProvider.onSaveIncrement('dados/' + this.path + "/lotacao/" + this.formulario.value.key + '/puc', this.formulario_puc.value, "puc").then(t => {
          this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
          this.formulario_puc.reset()
        }).catch(e => {
          this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
          console.log(e);
        });
      } else if ((this.formulario.valid) && (this.formulario_puc.valid) && (!this.formulario.value.key)) {
        this.genProvider.onSaveIncrement("dados/" + this.path + "/lotacao", this.formulario.value, "lotacao").then(t => {
          this.onLoadlotacao_Puc(t)
          this.formulario.patchValue({
            key: t
          })
          this.genProvider.onSaveIncrement('dados/' + this.path + "/lotacao/" + this.formulario.value.key + '/puc', this.formulario_puc.value, "puc").then(t => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
            this.formulario_puc.reset()
          }).catch(e => {
            this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
            console.log(e);
          });
        })
      }
    }

  }
  onEditPuc(event) {
    this.formulario_puc.patchValue(event)
  }
  onDeletePuc(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
        this.genProvider.remove('dados/' + this.path + "/lotacao/" + this.formulario.value.key + '/puc', event.key);
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });
  }
  onLoadlotacao_Puc(key) {
    this.unSub.push(this.genProvider.onGet('dados/' + this.path + "/lotacao/" + key, 'puc').subscribe(data => {
      this.lotacao_puc = data;
      if (this.lotacao_puc.length > 0) {
        this.lotacao_puc = this.lotacao_puc.map(res => {
          var puc = this.genProvider.onGetTableKey(this.puc, res.key_puc);
          res.nome = puc.nome;
          return res;
        });
      }
    },
      err => console.log(err)
    ));
  }
  onTelefone(t) {
    if (t.length > 0) {
      this.formulario.patchValue({
        telefone: this.validaCampoProvider.formataTelefone(t)
      })
    }
  }
  onSigla() {
    this.formulario.patchValue({
      sigla: this.formulario.value.sigla.toUpperCase()
    })
  }
  onConsultaCEP(cep) {
    let valor = cep.replace(/[^0-9]/g, '');
    if (valor.length != 8) {
      this.formulario.patchValue({
        cep: ''
      });
      this.messageService.add({ severity: 'info', summary: 'Erro!!', detail: 'Ops! CEP ' + valor + ', inválido' });
    } else {
      this.validaCampoProvider.validaCEP(cep).subscribe((data: any) => {
        if (data.cep) {
          data.rua = data.logradouro
          this.populaDadosForm(data);
          //          this.errorCep = false;
        } else {
          this.formulario.patchValue({
            cep: ''
          });
          this.messageService.add({ severity: 'info', summary: 'Erro!!', detail: 'Ops! CEP ' + valor + ', inválido' });

        }
      }, error => alert(error));
    }
  }
  populaDadosForm(dados) {
    //this.formulario.setValue({});
    this.formulario.patchValue({
      rua: dados.rua,
      cep: dados.cep,
      complemento: dados.complemento,
      bairro: dados.bairro,
      cidade: dados.localidade,
      estado: dados.uf,
      localizacao:
        dados.rua +
        ' , ' +
        dados.bairro +
        ' , ' +
        dados.cidade +
        ', ' +
        dados.estado,
      local: dados.rua +
        ' , ' +
        dados.bairro +
        ' , ' +
        dados.cidade +
        ', ' +
        dados.estado,
    });
    this.onAddressMap();
  }
  getLocal() {
    this.formulario.patchValue({
      localizacao:
        this.formulario.value.rua +
        ' , ' +
        this.formulario.value.numero +
        ', ' +
        this.formulario.value.bairro +
        ' , ' +
        this.formulario.value.cidade +
        ', ' +
        this.formulario.value.estado
    });
    //  console.log(this.formulario.value.localizacao);
    this.onAddressMap();
  }
  onAddressMapCep() {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: this.formulario.value.local },
      (results, status) => {
        this.lat = results[0].geometry.location.lat();
        this.lng = results[0].geometry.location.lng();
        this.localizacao = results[0].formatted_address || '';
        //   console.log (this.localizacao)
      }
    );
  }
  onClickMap(e) {
    this.lat = e.coords.lat;
    this.lng = e.coords.lng;
    this.formulario.patchValue({
      latitude: e.coords.lat,
      longitude: e.coords.lng
    });
    let geocoder = new google.maps.Geocoder();
    let latLng = new google.maps.LatLng(this.lat, this.lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (results[0].address_components.length == 4) {
        this.formulario.patchValue({
          localizacao: results[0].formatted_address,
          local: results[0].formatted_address,
          rua: results[0].address_components[1].long_name || ' ',
          numero: results[0].address_components[0].long_name || ' ',
          bairro: results[0].address_components[2].long_name || ' ',
          cidade: results[0].address_components[3].long_name || ' ',
          estado: '',
          cep: '',
          lat: this.lat,
          lng: this.lng

        });
      } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
        this.formulario.patchValue({
          localizacao: results[0].formatted_address,
          local: results[0].formatted_address,
          rua: results[0].address_components[1].long_name || ' ',
          numero: results[0].address_components[0].long_name || ' ',
          bairro: results[0].address_components[2].long_name || ' ',
          cidade: results[0].address_components[3].long_name || ' ',
          estado: results[0].address_components[4].long_name || ' ',
          cep: '',
          lat: this.lat,
          lng: this.lng
        });
      } else if (results[0].address_components.length >= 7) {
        this.formulario.patchValue({
          localizacao: results[0].formatted_address,
          local: results[0].formatted_address,
          rua: results[0].address_components[1].long_name || ' ',
          numero: results[0].address_components[0].long_name || ' ',
          bairro: results[0].address_components[2].long_name || ' ',
          cidade: results[0].address_components[3].long_name || ' ',
          estado: results[0].address_components[4].long_name || ' ',
          cep: results[0].address_components[6].long_name || ' ',
          lat: this.lat,
          lng: this.lng
        });
      } else if (results[0].address_components.length < 4) {
        this.formulario.patchValue({
          localizacao: '',
          rua: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: '',
          cep: '',
          lat: '',
          lng: ''
        });
      }
    });
  }
  onMap() {
    if ((this.formulario.value.lat) && (this.formulario.value.lng)) {
      this.onClickMapChat(parseFloat(this.formulario.value.lat), parseFloat(this.formulario.value.lng))
    }
  }
  onClickMapChat(lat, lng) {
    this.lat = lat;
    this.lng = lng;
    let geocoder = new google.maps.Geocoder();
    let latLng = new google.maps.LatLng(this.lat, this.lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (results[0].address_components.length == 4) {
        this.formulario.patchValue({
          localizacao: results[0].formatted_address,
          local: results[0].formatted_address,
          rua: results[0].address_components[1].long_name || ' ',
          numero: results[0].address_components[0].long_name || ' ',
          bairro: results[0].address_components[2].long_name || ' ',
          cidade: results[0].address_components[3].long_name || ' ',
          estado: '',
          cep: '',
          lat: this.lat,
          lng: this.lng

        });
      } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
        this.formulario.patchValue({
          localizacao: results[0].formatted_address,
          local: results[0].formatted_address,
          rua: results[0].address_components[1].long_name || ' ',
          numero: results[0].address_components[0].long_name || ' ',
          bairro: results[0].address_components[2].long_name || ' ',
          cidade: results[0].address_components[3].long_name || ' ',
          estado: results[0].address_components[4].long_name || ' ',
          cep: results[0].address_components[5].long_name || ' ',
          lat: this.lat,
          lng: this.lng
        });
      } else if (results[0].address_components.length >= 7) {
        this.formulario.patchValue({
          localizacao: results[0].formatted_address,
          local: results[0].formatted_address,
          rua: results[0].address_components[1].long_name || ' ',
          numero: results[0].address_components[0].long_name || ' ',
          bairro: results[0].address_components[2].long_name || ' ',
          cidade: results[0].address_components[3].long_name || ' ',
          estado: results[0].address_components[4].long_name || ' ',
          cep: results[0].address_components[6].long_name || ' ',
          lat: this.lat,
          lng: this.lng
        });
      } else if (results[0].address_components.length < 4) {
        this.formulario.patchValue({
          localizacao: '',
          rua: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: '',
          cep: ''
        });
      }
    });
  }
  onLoadMap() {
    let geocoder = new google.maps.Geocoder();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.formulario.patchValue({
          lat: this.lat,
          lng: this.lng
        })
      });
    }
  }
  onAddressMap() {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: this.formulario.value.local },
      (results, status) => {
        if (results) {
          if (results[0]) {
            this.localizacao = results[0].formatted_address;
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
            console.log(results[0])

            if (results[0].address_components.length == 4) {
              this.formulario.patchValue({
                localizacao: results[0].formatted_address,
                rua: results[0].address_components[1].long_name || ' ',
                numero: results[0].address_components[0].long_name || ' ',
                bairro: results[0].address_components[2].long_name || ' ',
                cidade: results[0].address_components[3].long_name || ' ',
                estado: '',
                cep: '',
                lat: this.lat,
                lng: this.lng

              });
            } else if ((results[0].address_components.length == 5) || (results[0].address_components.length == 6)) {
              this.formulario.patchValue({
                localizacao: results[0].formatted_address,
                rua: results[0].address_components[1].long_name || ' ',
                numero: results[0].address_components[0].long_name || ' ',
                bairro: results[0].address_components[2].long_name || ' ',
                cidade: results[0].address_components[3].long_name || ' ',
                estado: results[0].address_components[4].long_name || ' ',
                cep: results[0].address_components[5].long_name || ' ',
                lat: this.lat,
                lng: this.lng
              });
            } else if (results[0].address_components.length >= 7) {
              this.formulario.patchValue({
                localizacao: results[0].formatted_address,
                rua: results[0].address_components[1].long_name || ' ',
                numero: results[0].address_components[0].long_name || ' ',
                bairro: results[0].address_components[2].long_name || ' ',
                cidade: results[0].address_components[3].long_name || ' ',
                estado: results[0].address_components[4].long_name || ' ',
                cep: results[0].address_components[6].long_name || ' ',
                lat: this.lat,
                lng: this.lng
              });
            } else if (results[0].address_components.length < 4) {
              this.formulario.patchValue({
                localizacao: '',
                rua: '',
                numero: '',
                bairro: '',
                cidade: '',
                estado: '',
                cep: '',
                lat: this.lat,
                lng: this.lng
              });
            }
          }
        }
      }
    );
  }
  atualizaDados() {
    let c = 0;
    this.lista.sort(function (a, b) {
      if (a.codigo > b.codigo) {
        return 1;
      }
      if (a.codigo < b.codigo) {
        return -1;
      }
      return 0;
    });
    console.log(this.lista)
    let startIntervalo = setInterval(() => {
      let data = this.lista[c]
      delete data['codigo'];
      data.time = Date.now();
      this.genProvider.onSaveIncrement("dados/" + this.path + "/lotacao", data, "lotacao").then(t => { });
      c++
      console.log(c)
      if (c >= this.lista.length) {
        clearInterval(startIntervalo);
      }
    }, 1000);
  }
  onNome() {
    this.formulario.patchValue({
      nome: this.formulario.value.nome.toUpperCase()
    })
  }
  atualizarLista(data) {
    if (this.lista.length > 0) {
      let res = this.lista.filter(obj => {
        return obj.key == data.key;
      });
      const index = this.lista.indexOf(res[0]);
      if (index >= 0) {
        this.lista.splice(index, 1);
        this.lista.push(data);
      }
    }
  }
  onAtualizaEmail(){
    this.unSub$.unsubscribe();
    let c = 0;

    let lista = this.lista
    console.log(lista)
    let startIntervalo = setInterval(() => {
      let data = lista[c]
      data.email = data.email1
      this.genProvider.onSaveIncrement("dados/" + this.path + "/lotacao", data, "lotacao").then(t => { });
      c++
      console.log(c)
      if (c >= lista.length) {
        clearInterval(startIntervalo);
      }
    }, 1000);
  }
}
