import { NgModule } from '@angular/core';
import { CamelcasePipe } from './camelcase/camelcase';
import { FiltroarrayPipe } from './filtroarray/filtroarray';
import { SafeHtmlPipe } from './safe/safe.pipe';

@NgModule({
	declarations: [CamelcasePipe,
    FiltroarrayPipe, SafeHtmlPipe],
	imports: [],
	exports: [CamelcasePipe,
    FiltroarrayPipe, SafeHtmlPipe]
})
export class PipesModule {}
