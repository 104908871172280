import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs';

@Injectable()
export class GetImagemProvider {
  imagem: string;
  formatado: any;
  constructor() //    private camera: Camera,
  //    public alertCtrl: AlertController,
  //    public toast: ToastController
  {
    console.log('Pagar photo Provider');
  }
/*
  getBase64Image(img:HTMLImageElement) {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      const res = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
      resolve(res);
    });
  }
*/

private getBase64Image(img:HTMLImageElement) {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL("image/png");
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

public  getBase64ImagePNGFromURL(url:string) {
    return new Promise(resolve => {
      const img = new Image();
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          resolve('data:image/png;base64,' +  this.getBase64Image(img));
        };
        img.onerror = (err) => {
          resolve(err);
        };
      } else {
        resolve('data:image/png;base64,' + this.getBase64Image(img));
      }
    });
  }
  /*
  getPhoto(opc) {
    if (opc == 'Camera') {
      return new Promise(resolve => {
        const options: CameraOptions = {
          quality: 70,
          destinationType: this.camera.DestinationType.DATA_URL, // base 64
          encodingType: this.camera.EncodingType.JPEG,
          sourceType: 1,
          allowEdit: true,
          mediaType: this.camera.MediaType.PICTURE,
          targetWidth: 612,
          targetHeight: 612,
          saveToPhotoAlbum: true,
          correctOrientation: true
        };
        this.camera.getPicture(options).then(
          imageData => {
            resolve('data:image/jpeg;base64,' + imageData);
          },
          err => {
            this.toast
              .create({
                message: 'Erro ao capitura da imagem, tente novamente.',
                duration: 3000,
                position: 'top'
              })
              .present();
            resolve('assets/images/camera.png');
          }
        );
      });
    } else if ((opc = 'Galeria')) {
      return new Promise(resolve => {
        const options: CameraOptions = {
          quality: 70,
          destinationType: this.camera.DestinationType.DATA_URL, // base 64
          encodingType: this.camera.EncodingType.JPEG,
          sourceType: 2,
          allowEdit: true,
          mediaType: this.camera.MediaType.PICTURE,
          targetWidth: 612,
          targetHeight: 612,
          saveToPhotoAlbum: true,
          correctOrientation: true
        };
        this.camera.getPicture(options).then(
          imageData => {
            resolve('data:image/jpeg;base64,' + imageData);
          },
          err => {
            console.log('3');
            this.toast
              .create({
                message: 'Erro ao capitura da imagem, tente novamente.',
                duration: 3000,
                position: 'top'
              })
              .present();
            resolve('assets/images/camera.png');
          }
        );
      });
    }
    // fim
  }
  */
}
