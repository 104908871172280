// tslint:disable
import { HttpClient, HttpHeaders } from '@angular/common/http';

//import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ValidaCepProvider {
  constructor(public http: HttpClient) {
    console.log('ValidaCepProvider Provider');
  }

  validaCEP(cep) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
      "method": "post"
   });
   let options = {
      headers: headers
   }
    cep = cep.replace(/\D/g, '');
    if (cep != '') {
      var validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        return this.http.get(`https://www.viacep.com.br/ws/${cep}/json`, options).map(
          dados => {
            console.log (dados.toString())
            return dados.toString();

          },
          error => {
            console.log('erro no retorno ', error);
            return 'CEP Invalido! ' + error;
          }
        );
      }
    }
  }
  sendZap(zap) {
    console.log(zap)

    return new Promise(resolve => {
      console.log("Before API")
      this.http.get("https://api.whatsapp.com/send?phone=5591980333070&text=I'm%20interested%20in%20your%20car%20for%20sale").map(response => response.toString()).subscribe(data => {
        resolve(data);
        console.log("After API",data)
      }, err => {
      });
    });

    /*
    let headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
//    let baseApiPath ='https://api.whatsapp.com/send?phone=5591'+zap+'&text=paulopaiva';
    let baseApiPath ='https://wa.me/5591980333070?text= paulo paiva';
    let options = new RequestOptions({ headers: headers, method: 'POST' });
    this.http.post(baseApiPath, options).map((res: Response) => {
        console.log(res)
      });
  /*






    this.http.post('https://api.whatsapp.com/send?phone=5591'+zap+'&text=paulopaiva').map(
          dados => {
           console.log(dados)
          },
          error => {
            console.log('erro no retorno ', error);
          }
        );
    */
      }


}
