export const navigationCad = [
    {nome: 'Tipo de Unidade Operacional', icon: 'pi pi-fw pi-check-square', routerLink: ['tipo']},
    {nome: 'Unidade Operacional (Lotação)', icon: 'pi pi-fw pi-check-square', routerLink: ['lotacao']},
    {nome: 'Central de Serviço (PUC)', icon: 'pi pi-fw pi-check-square', routerLink: ['puc']},
    {nome: 'Catálogo / Carta', icon: 'pi pi-fw pi-check-square', routerLink: ['catalogo']},
    {nome: 'Cargos', icon: 'pi pi-fw pi-check-square', routerLink: ['cargo']},
    {nome: 'Pessoas', icon: 'pi pi-fw pi-check-square', routerLink: ['pessoa']},
    {nome: 'Níveis de Serviços', icon: 'pi pi-fw pi-check-square', routerLink: ['sla']},
    {nome: 'Macro', icon: 'pi pi-fw pi-check-square', routerLink: ['macro']},
    {nome: 'Tipo de Encerramento', icon: 'pi pi-fw pi-check-square', routerLink: ['encerrarAtendimento']},
    {nome: 'Resp. Rápidas (atendimento)', icon: 'pi pi-comments', routerLink: ['resposta']},
    {nome: 'Intervalos (Ponto)', icon: 'fa fa-hourglass-half', routerLink: ['intervalos']},
    {nome: 'Usuários (Logins)', icon: 'fa fa-user', routerLink: ['usuario']}
];
