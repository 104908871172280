/* tslint:disable */
import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { GenProvider } from '../../providers/gen/gen';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'

@Component({
  selector: 'app-relIntervalo',
  templateUrl: './relIntervalo.component.html',
  styleUrls: ['./relIntervalo.component.scss']
})
export class RelIntervaloComponent implements OnInit {
  inicio: any;
  final: any;
  @ViewChild('dtLista') dtLista: Table;
  loading:any;
  filtro: any;
  lista: any;
  relacao: any;
  atuacao: any;
  membro: any;
  dados: any;
  qtdCad: any;
  path: any;
  datarelacao: any;
  dataatuacao: any;
  listaCoordenadores: any;

  isSexo: any;
  isTelefone: any;
  isDt_Nasc: any;
  isTipo: any;
  isRelacao: any;
  isEndereco: any;
  isZona: any;
  isSecao: any;
  isColegio: any;
  isBairro: any;
  membro_nome: any;
  key_coordenador: any;
  nome_coordenador: any;
  nome_lideranca: any;
  listaLideranca: any;
  key_lideranca: any;
  parametros: any;
  cols: any;
  exportColumns: any;

  constructor(
    private router: Router,
    private genProvider: GenProvider
  ) {
    this.parametros = this.genProvider.parametros;
    this.path = this.genProvider.parametros.path;
    this.listaCoordenadores = [];
    this.lista = [];
  }

  ngOnInit() {

    this.cols = [
      { header: 'Data', field: 'dia' },
      { header: 'Agente', field: 'nome' },
      { header: 'Intervalo', field: 'intervalo' },
      { header: 'Entrada', field: 'entrada' },
      { header: 'Saída', field: 'saida' },
      { header: 'Total', field: 'total' },
      { header: 'Status', field: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  onLoad() {
    if ((this.inicio) && (this.final)) {
      let inicio = new Date(this.inicio).getTime()
      let fim = new Date(this.final).getTime()
      let unSub = this.genProvider.onGetPeriodo('dados/' + this.path + '/pontoIntervalo', 'time', inicio, fim).subscribe(data => {
        unSub.unsubscribe()
        let lista = JSON.parse(JSON.stringify(data));
        this.lista = lista.map(res => ({
          dia: res.dia,
          nome: res.nome,
          intervalo: res.intervalo,
          entrada: new DatePipe("pt-BR").transform(res.entrada, "HH:mm:ss"),
          saida: new DatePipe("pt-BR").transform(res.saida, "HH:mm:ss"),
          total: res.total,
          status: res.status
        }));
      },
        err => console.log(err)
      );
    }
  }
  exportExcel() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(lista);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lista");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, 'Intervalos')
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      columnStyles: {
        0: { halign: 'left', fillColor: [220, 220, 220] }
      },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
}
