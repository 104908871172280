import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GenProvider } from '../../providers/gen/gen';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs-compat/Subscription';
import { ServiceProvider } from 'src/providers/service/service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder} from '@angular/forms'
import { single } from '../dashticket/data';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs-compat';
import { Http } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dtFila') dtFila: Table;
  @ViewChild('dtAtendimento') dtAtendimento: Table;
  @ViewChild('dtDenuncia') dtDenuncia: Table;
  @ViewChild('dtTickets') dtTickets: Table;
  options: RequestOptions;
  // chart
  productSales: any[]
  productSalesMulti: any[]
  formatString: any;
  formatNumber: any;

  view: any[] = [700, 370];
  progressBar: any;
  // options
  legendTitle: string = 'Dias';
  legendTitleMulti: string = 'Months';
  legendPosition: string = 'below'; // ['right', 'below']
  legend: boolean = true;

  xAxis: boolean = true;
  yAxis: boolean = true;

  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  maxXAxisTickLength: number = 30;
  maxYAxisTickLength: number = 30;
  trimXAxisTicks: boolean = false;
  trimYAxisTicks: boolean = false;
  rotateXAxisTicks: boolean = false;

  xAxisTicks: any[] = ['Genre 1', 'Genre 2', 'Genre 3', 'Genre 4', 'Genre 5', 'Genre 6', 'Genre 7']
  yAxisTicks: any[] = [100, 500, 1000, 1500, 2000, 2500, 3000]

  animations: boolean = true; // animations on load

  showGridLines: boolean = true; // grid lines

  showDataLabel: boolean = true; // numbers on bars



  schemeType: string = 'ordinal'; // 'ordinal' or 'linear'

  activeEntries: any[] = ['book']
  barPadding: number = 30
  tooltipDisabled: boolean = false;

  yScaleMax: number = 1000;

  roundEdges: boolean = false;



  single: any[];


  // options
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#007BFF', '#28A745', '#17A2B8', '#FFC107', '#343A40', '#DC3545']
  };
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;

  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;
  view2: any[] = [700, 300];
  multi: any[];
  key_lotacao: any;
  key_agente: any;
  key_item: any;
  key_subitem: any;
  filaAgente: any;
  path: any;
  parametros: any;
  mostraUsuario: any;
  key: any;
  chatFila: any;
  loading: boolean;
  chatAtendimento: any;
  fila: number = 0;
  percFila: number = 0;
  percAtendimento: number = 0;
  percEspera: number = 0;
  percPendente: number = 0;
  conversas: any;
  atendimento: any = 0;
  espera: any = 0;
  pendente: any = 0;
  chat_msg: any[];
  mostraMgs: boolean;
  chat: any;
  msgResp: any;
  msgPendente: any;
  ponto: any = [];
  mostraMgsFila: boolean;
  descricao: any;
  novaMsg: any = 0;
  percNovaMsg: number = 0;
  detalhe: any;
  dados: any;
  midia: { type: any; file: any; lat: any; lng: any; };
  mostraMidia: boolean;
  distribuida: number = 0;
  percDistribuida: number = 0;
  emAtendimento: any;
  data: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };
  protocolo: any;
  dataChart: any;
  isStart: any;
  unSub: Subscription[] = [];
  unMsg: Subscription;
  days: any;
  encerrado: any;
  denuncias: any;
  inicio: any;
  final: any;
  mostraMgsAvaliada: boolean;
  situacao: any;
  origem: any;
  denunciaProtocolo: any;
  protocolo2: any;
  idTicket: any;
  dtInicio: any;
  dtFinal: any;
  tickets: any;
  lotacao: any = []
  puc: any;
  ticket_body: any;
  nome_responsavel: any;
  mostraTicket: boolean;
  sla: any;
  subItemCatalogo: any = [];
  itemCatalogo: any = [];
  tipoTicket: any;
  catalogo: any;
  formulario_consulta_ticket: FormGroup;
  formulario_pessoa: FormGroup;
  cols_ticket: { header: string; field: string; width: string; }[];
  usuarios: any;
  listaLotacao: any[];
  listaCanal: any[];
  listaAgente: any[];
  listaPrimeiroNivel: any[];
  modal_agente: any;
  modal_tools: any;
  dias: any = [];
  qtd_dias: any;
  temp: any;
  key_catalogo: string;
  unSub$ = new Subject();
  catalogoAll: any;
  titulo: string;
  listaItem: any[];
  listaDia: any[];
  listastatus: any[];
  myBtnFiltro: boolean;
  tempAll: any;
  listaSubitem: any[];
  pessoa_lotacao: any = [];
  subItem: any = [];
  nome_lotacao: any;
  unMsgAtendimento: Subscription;
  tempAtendimento: any;
  tempAllAtendimento: any;
  status: any = [];
  menu: any = [];
  canal: any = [];
  dia: any = [];
  agente: any;
  agentes: any = [];
  pessoas: any;
  timeBot: number;
  tempCatalogo: any = [];
  item: any;
  usuarioAnonimo: any;
  pucs: any;
  constructor(
    private genProvider: GenProvider,
    private router: Router,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private formBuilder: FormBuilder,
    public http: Http,
    private service: ServiceProvider) {
    Object.assign(this, { single });
    this.midia = {
      type: '',
      file: '',
      lat: 0,
      lng: 0
    }
    this.multi = [
        {
          "name": "Germany",
          "series": [
            {
              "name": "1990",
              "value": 62000000
            },
            {
              "name": "2010",
              "value": 73000000
            },
            {
              "name": "2011",
              "value": 89400000
            }
          ]
        },
      
        {
          "name": "USA",
          "series": [
            {
              "name": "1990",
              "value": 250000000
            },
            {
              "name": "2010",
              "value": 309000000
            },
            {
              "name": "2011",
              "value": 311000000
            }
          ]
        },
      
        {
          "name": "France",
          "series": [
            {
              "name": "1990",
              "value": 58000000
            },
            {
              "name": "2010",
              "value": 50000020
            },
            {
              "name": "2011",
              "value": 58000000
            }
          ]
        },
        {
          "name": "UK",
          "series": [
            {
              "name": "1990",
              "value": 57000000
            },
            {
              "name": "2010",
              "value": 62000000
            }
          ]
        }
      ];
    this.progressBar = 'determinate'
    this.myBtnFiltro = false;
    this.filaAgente = this.genProvider.parametros.filaAgente
    this.usuarioAnonimo = this.genProvider.parametros.usuarioAnonimo
    this.path = this.genProvider.parametros.path;
    this.parametros = this.genProvider.parametros;
    this.mostraUsuario = this.genProvider.parametros.mostraUsuario;

  }
  onClose() {
    this.router.navigate(['']);
  }

  ngOnDestroy() {
    console.log("destroying child...")
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unMsg != undefined) {
      this.unMsg.unsubscribe();
    }
    this.unSub$.next();
    this.unSub$.complete();
    clearInterval(this.timeBot);
  }
  onCombos() {
    this.dias = [
      { nome: '1 dia', qtd: 1 },
      { nome: '3 dias', qtd: 3 },
      { nome: '5 dias', qtd: 5 },
      { nome: 'Uma semana', qtd: 7 },
      { nome: 'Duas semanas', qtd: 14 },
      { nome: 'Três semanas', qtd: 21 },
      { nome: 'Um mês', qtd: 30 },
    ]
  }
  ngOnInit() {
    this.onLoadLotacao();
    this.onLoadLogin()
    this.onLoadParamentro();
    this.onLoadPuc();
    this.onCombos();
    this.onLoadLastAtendimento();
    this.primengConfig.ripple = true;
  }
  onLoadLotacao() {
    this.genProvider.onGetAll('dados/' + this.path + '/lotacao', "nome").pipe(takeUntil(this.unSub$)).subscribe(data => {
      this.lotacao = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    );
  }
  onLoadPuc() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/puc', "nome").subscribe(data => {
      this.puc = JSON.parse(JSON.stringify(data));
    },
      err => console.log(err)
    ));
  }
  onLoadUsuario() {
    this.unSub.push(this.genProvider.onGetFilterOrder('login', 'nome', 'path', this.parametros.path).subscribe(data => {
      let lista = JSON.parse(JSON.stringify(data));
      this.agente = [];
      lista.forEach(e => {
        //  if (e.nivel != "99") {
        this.agente.push(e)
        //  }
      });
    },
      err => console.log(err)
    ));

  }
  onLoadParamentro() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/parametros', 'token').subscribe(
      data => {
        let dados = JSON.parse(JSON.stringify(data));
        if (dados.length > 0) {
          this.key = dados[0].key;
          this.onLoadUsuario()
        }
      },
      err => console.log(err)
    ));
  }
  encId(str) {
    var encoded = "";
    for (let i = 0; i < str.length; i++) {
      var a = (i);
      var b = a ^ 1;    // bitwise XOR with any number, e.g. 123
      encoded = encoded + (b);
    }
    return encoded;
  }
  onLoadLogin() {
    let unSub = this.genProvider.onGetAll('login', 'nome').subscribe(data => {
      unSub.unsubscribe();
      this.usuarios = JSON.parse(JSON.stringify(data));
    })
  }
  onSelectAtendimento(dados, filtro?: any): void {
    let data: any = [];
    if (!dados.name) {
      data = {
        name: dados
      }
    } else {
      data = {
        name: dados.name
      }
    }
    if (filtro == 'agente') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.nome_agente == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    } else if (filtro == 'menu') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.menu == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    } else if (filtro == 'status') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.status == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    } else if (filtro == 'dia') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.dia == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    }
    else if (filtro == 'canal') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.canal == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    }
    else if (filtro == 'puc') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.nome_puc == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    }
    else if (filtro == 'lotacao') {
      this.tempAtendimento = this.tempAllAtendimento.filter(obj => {
        return obj.nome_lotacao == data.name.toUpperCase()
      })
      this.onDataChartAtendimento(this.tempAtendimento)
    }
  }
  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }
  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  onLoadLastAtendimento(ini?: any, fim?: any, qtd_dias?: any) {
    this.progressBar = 'indeterminate'
    this.myBtnFiltro = true;
    let dias = 1;
    if (this.unMsgAtendimento) {
      this.unMsgAtendimento.unsubscribe();
    }
    if (!ini) {
      if (qtd_dias) {
        dias = qtd_dias
        this.titulo = "Últimos " + qtd_dias + " dias"
      } else {
        this.titulo = "Últimas 24 horas"
      }
      let time = new Date();
      let inicio = new Date();
      inicio.setDate(time.getDate() - dias);
      let dia = Date.parse(inicio.toString());
      this.unMsgAtendimento = this.genProvider.onGetPeriodo('dados/' + this.path + '/atendimento', 'data_criacao', dia).subscribe(data => {
        let temp = JSON.parse(JSON.stringify(data));
        //   this.unMsgAtendimento.unsubscribe()
        if (temp.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe atendimento para consulta.' });
        } else {
          this.tempAtendimento = temp.map(res => {
            res.dia = new DatePipe("pt-BR").transform(res.data_criacao, "dd-MM");
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
            if (res.key_usuario) {
              let nome$ = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario)
              if (nome$) {
                res.nome_agente = nome$.nome.toUpperCase()
              }
            }
           if (res.key_puc){
            let nome$ = this.genProvider.onGetTableKey(this.puc, res.key_puc)
            if (nome$) {
              res.nome_puc = nome$.nome.toUpperCase()
            }
           }
            if (res.key_lotacao) {
              let nome$ = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (nome$) {
                res.nome_lotacao = nome$.nome.toUpperCase()
              }
            
           } else {
              let nome$ = this.genProvider.onGetTableKey(this.lotacao, this.parametros.key_lotacao)
              if (nome$) {
                res.nome_lotacao = nome$.nome.toUpperCase()
              }
            }
            if (res.menu) {
              res.menu = res.menu.toUpperCase()
            }
            if (res.origem) {
              res.canal = res.origem.toUpperCase()
            }
            return res;
          });
          this.tempAllAtendimento = this.tempAtendimento
          this.onDataChartAtendimento(this.tempAtendimento)
          this.progressBar = 'determinate'
          this.myBtnFiltro = false;
          this.modal_tools = false;
        }
      });
    } else {
      let inicio = new Date(ini).getTime()
      let final = new Date(fim).getTime()
      this.titulo = "Periodo de " + new DatePipe("pt-BR").transform(inicio, "dd/MM") + " à " + new DatePipe("pt-BR").transform(final, "dd/MM")
      this.unMsgAtendimento = this.genProvider.onGetPeriodo('dados/' + this.path + '/atendimento', 'data_criacao', inicio, final).subscribe(data => {
        let temp = JSON.parse(JSON.stringify(data));
        this.unMsgAtendimento.unsubscribe()
        if (temp.length == 0) {
          this.messageService.add({ severity: 'success', summary: 'Atenção!!', detail: 'Não existe atendimento para consulta.' });
        } else {
          this.tempAtendimento = temp.map(res => {
            res.dia = new DatePipe("pt-BR").transform(res.data_criacao, "dd-MM");
            res.data_criacaoM = new DatePipe("pt-BR").transform(res.data_criacao, "d/MM - HH:mm");
            let nome$ = this.genProvider.onGetTableKey(this.usuarios, res.key_usuario)
            if (nome$) {
              res.nome_agente = nome$.nome.toUpperCase()
            }
            if (res.key_lotacao) {
              nome$ = this.genProvider.onGetTableKey(this.lotacao, res.key_lotacao)
              if (nome$) {
                res.nome_lotacao = nome$.nome
              }
            } else {
              nome$ = this.genProvider.onGetTableKey(this.lotacao, this.parametros.key_lotacao)
              if (nome$) {
                res.nome_lotacao = nome$.nome.toUpperCase()
              }
            }
            if (res.key_puc){
              let nome$ = this.genProvider.onGetTableKey(this.puc, res.key_puc)
              if (nome$) {
                res.nome_puc = nome$.nome.toUpperCase()
              }
             }
            if (res.menu) {
              res.menu = res.menu.toUpperCase()
            }
            if (res.origem) {
              res.canal = res.origem.toUpperCase()
            }
            return res;
          });
          this.tempAllAtendimento = this.tempAtendimento;
          this.onDataChartAtendimento(this.tempAtendimento)
          this.progressBar = 'determinate'
          this.myBtnFiltro = false;
          this.modal_tools = false;
        }
      });
    }

  }
  onDataChartAtendimento(temp) {
    this.canal = [];
    this.menu = [];
    this.status = [];
    let c = 0;
    let i = 0;
    let listaArray = temp;
    let listaTemp: any = [];
    listaArray = temp.filter(obj => {
      return obj.status != 'Cancelado'
    })
    listaArray.sort(function (a, b) {
      if (a.origem > b.origem) {
        return 1;
      }
      if (a.origem < b.origem) {
        return -1;
      }
      return 0;
    });
    let origem = listaArray[0].origem;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      i++
      c++
      if (origem != e.origem) {
        listaTemp.push({
          name: origem.toUpperCase(),
          value: c - 1
        })
        origem = e.origem
        c = 1;
      } else if (listaArray.length == i) {
        if (e.origem) {
          origem = e.origem.toUpperCase()
        } else {
          origem = ''
        }
        listaTemp.push({
          name: origem.toUpperCase(),
          value: c
        })
      }
    });
    this.canal = listaTemp;
    listaTemp = [];
    // fim
    listaArray = temp.filter(obj => {
      return obj.menu
    })
    listaArray.sort(function (a, b) {
      if (a.menu > b.menu) {
        return 1;
      }
      if (a.menu < b.menu) {
        return -1;
      }
      return 0;
    });
    let menu = listaArray[0].menu;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      i++
      c++
      if (menu != e.menu) {
        //console.log(e.menu, i, c)
        listaTemp.push({
          name: menu.toUpperCase(),
          value: c - 1
        })
        menu = e.menu
        c = 1;
      } else if (listaArray.length == i) {
        //console.log(e.menu, i, c)
        menu = e.menu
        listaTemp.push({
          name: menu.toUpperCase(),
          value: c
        })
      }
    });
    this.menu = listaTemp;
    listaTemp = [];
    // lista por agente
    let nome$: any = [];
    let nome = '';
    listaArray.sort(function (a, b) {
      if (a.key_usuario > b.key_usuario) {
        return 1;
      }
      if (a.key_usuario < b.key_usuario) {
        return -1;
      }
      return 0;
    });
    let key = listaArray[0].key_usuario
    if (key) {
      nome$ = this.genProvider.onGetTableKey(this.usuarios, key)
      if (nome$) {
        nome = nome$.nome.toUpperCase()
      }
    }
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      c++
      i++
      if (key != e.key_usuario) {
        listaTemp.push({
          key: e.key_usuario,
          name: nome.toUpperCase(),
          value: c - 1
        })
        key = e.key_usuario
        if (key) {
          nome$ = this.genProvider.onGetTableKey(this.usuarios, key)
          if (nome$) {
            nome = nome$.nome.toUpperCase()
          }
        }
        c = 1;
      } else if (listaArray.length == i) {
        key = e.key_usuario
        if (key) {
          nome$ = this.genProvider.onGetTableKey(this.usuarios, key)
          if (nome$) {
            nome = nome$.nome.toUpperCase()
          }
        }

        listaTemp.push({
          key: e.key_usuario,
          name: nome.toUpperCase(),
          value: c
        })
      }
    });
    this.agentes = listaTemp;
    listaTemp = [];
    // fim
    // lista por dia
    listaArray.sort(function (a, b) {
      if (a.dia > b.dia) {
        return 1;
      }
      if (a.dia < b.dia) {
        return -1;
      }
      return 0;
    });
    let dia = listaArray[0].dia;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      c++
      i++
      if (dia != e.dia) {
        listaTemp.push({
          name: dia.toUpperCase(),
          value: c - 1
        })
        dia = e.dia
        c = 1;
      } else if (listaArray.length == i) {
        dia = e.dia
        listaTemp.push({
          name: dia.toUpperCase(),
          value: c
        })
      }
    });
    this.dia = listaTemp;
    listaTemp = [];
    // fim
    // lista por status
    listaArray.sort(function (a, b) {
      if (a.status > b.status) {
        return 1;
      }
      if (a.status < b.status) {
        return -1;
      }
      return 0;
    });

    let status = listaArray[0].status;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      c++
      i++
      if (status != e.status) {
        listaTemp.push({
          name: status.toUpperCase(),
          value: c - 1
        })
        status = e.status
        c = 1;
      } else if (listaArray.length == i) {
        status = e.status
        listaTemp.push({
          name: status.toUpperCase(),
          value: c
        })
      }
    });
    this.status = listaTemp;
    listaTemp = [];
    // fim
    // lista por lotacao
    listaArray.sort(function (a, b) {
      if (a.nome_lotacao > b.nome_lotacao) {
        return 1;
      }
      if (a.nome_lotacao < b.nome_lotacao) {
        return -1;
      }
      return 0;
    });
    let nome_lotacao = listaArray[0].nome_lotacao;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      c++
      i++
      if (nome_lotacao != e.nome_lotacao) {
        listaTemp.push({
          name: nome_lotacao.toUpperCase(),
          value: c - 1
        })
        nome_lotacao = e.nome_lotacao
        c = 1;
      } else if (listaArray.length == i) {
        nome_lotacao = e.nome_lotacao
        listaTemp.push({
          name: nome_lotacao.toUpperCase(),
          value: c
        })
      }
    });
    this.listaLotacao = listaTemp;
    listaTemp = [];
    // fim
    // lista por puc
    listaArray.sort(function (a, b) {
      if (a.nome_puc > b.nome_puc) {
        return 1;
      }
      if (a.nome_puc < b.nome_puc) {
        return -1;
      }
      return 0;
    });

    let nome_puc = listaArray[0].nome_puc;
    c = 0;
    i = 0;
    listaArray.forEach(e => {
      c++
      i++
      if (nome_puc != e.nome_puc) {
        listaTemp.push({
          name: nome_puc.toUpperCase(),
          value: c - 1
        })
        nome_puc = e.nome_puc
        c = 1;
      } else if (listaArray.length == i) {
        nome_puc = e.nome_puc
        listaTemp.push({
          name: nome_puc.toUpperCase(),
          value: c
        })
      }
    });
    this.pucs = listaTemp;
    listaTemp = [];
    
  }

  showFiltro() {
    this.progressBar = 'determinate'
    this.myBtnFiltro = false;
    this.modal_tools = true;
  }


}


