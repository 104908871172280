/* tslint:disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { GenProvider } from 'src/providers/gen/gen';
import { Table } from 'primeng/table';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable'
import { ValidaCampoProvider } from 'src/providers/valida-campo/valida-campo';
import { Subscription } from 'rxjs/Rx';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pessoa',
  templateUrl: './pessoa.component.html',
  styleUrls: ['./pessoa.component.scss'],
  providers: [ConfirmationService]
})
export class PessoaComponent implements OnInit {
  @ViewChild('dtLista') dtLista: Table;
  @Input() closedBtn: boolean = false;
  mostraFrm: boolean = false;
  path: any;
  formulario: FormGroup;
  parametro: any;
  lista: any;
  titulo: string;
  cols: any = [];
  exportColumns: any = [];
  status: any = [];
  lotacao: any;
  tipo: any[];
  unSub: Subscription[] = [];
  cargo: any;
  permissao: any = true;
  loading: any;
  page: any = [];
  qtd_upload: any;
  busca: string;
  unSub$: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private genProvider: GenProvider,
    private router: Router,
    public storage: AngularFireStorage,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validaCampo: ValidaCampoProvider
  ) {
 //   console.log (this.closedBtn)
    this.qtd_upload = 9;
    this.path = this.genProvider.parametros.path;
    this.formulario = this.formBuilder.group({
      key: null,
      nome: [null, Validators.required],
      tipo: [null, Validators.required],
      telefone: [null, Validators.required],
      status: [null, Validators.required],
      email: '',
      origem: '',
      key_lotacao: '',
      key_cargo: '',
      matricula: '',
      ramal: '',
      facebook: '',
      instagram: '',
      busca: '',
      time: ''

    });
    this.titulo = 'Pessoa';
  }

  onClose() {
    this.router.navigate(['']);
  }

  onMostraFrm() {
    this.mostraFrm = !this.mostraFrm;
  }
  onCombo() {
    this.status = [
      { nome: 'Ativo' },
      { nome: 'Desativado' }
    ]
    this.tipo = [
      { nome: 'Interno' },
      { nome: 'Externo' }
    ]
    this.cols = [
      { header: 'Código', field: 'key' },
      { header: 'Nome', field: 'nome' },
      { header: 'Tipo', field: 'tipo' },
      { header: 'Unidade Operacional', field: 'nome_lotacao' },
      { header: 'Cargo', field: 'nome_cargo' },
      { header: 'Celular', field: 'celular' },
      { header: 'E-mail', field: 'email' },
      { header: 'Origem', field: 'origem' },
      { header: 'Status', field: 'status' }
    ];
    this.page = [
      { nome: '10', qtd: 9 },
      { nome: '50', qtd: 49 },
      { nome: '100', qtd: 99 },
      { nome: '200', qtd: 199 },
      { nome: '300', qtd: 299 },
      { nome: '400', qtd: 399 },
      { nome: '500', qtd: 499 },
      { nome: '1000', qtd: 999 },
      { nome: 'Todos', qtd: 5000 }
    ]
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  ngOnInit() {
    this.onCombo();
    this.onLoadlotacao();
    this.onLoadCargo();
    this.onLoad();
  }
  ngOnDestroy() {
    this.unSub.forEach(s => {
      s.unsubscribe();
    });
    if (this.unSub$) {
      this.unSub$.unsubscribe()
    }
  }
  onLoadPessoa() {
    if (this.busca) {
      this.unSub$.unsubscribe()
      this.loading = true;
      this.lista = []
      let joins = [
        { key: "key_cargo", table: "cargo" },
        { key: "key_lotacao", table: "lotacao" }
      ]
      this.genProvider.onSqlQuery('dados/' + this.path, "select * from pessoa where nome like '" + this.busca.toUpperCase() + "%'", joins).then(data => {
        this.lista = JSON.parse(JSON.stringify(data));
        if (this.lista.length > 0) {
          this.lista = this.lista.map(res => {
            res.codigo = parseInt(res.key)
            if (res.lotacao) {
              if (res.lotacao) {
                res.nome_lotacao = res.lotacao.nome;
              }
            } else {
              res.nome_lotacao = '';
            }
            //        res.origem = res.lotacao.origem;
            if (res.cargo) {
              res.nome_cargo = res.cargo.nome;
            } else {
              res.nome_cargo = '';
            }
            if (res.telefone) {
              if (res.telefone.length == 15) {
                res.celular = res.telefone;
              } else {
                res.celular = this.validaCampo.validaTelefonePromo(res.telefone);
              }
            }
            return res;
          });
          this.loading = false;
        } else {
          this.loading = false;
          this.messageService.add({ severity: 'info', summary: 'Atenção', detail: 'Não foi encontrado nenhum resultado para: ' + this.busca.toUpperCase() });
        }
      })
    }
  }
  onLoad() {
    this.loading = true;
    let joins = [
      { key: "key_cargo", table: "cargo" },
      { key: "key_lotacao", table: "lotacao" }
    ]
    this.unSub$ = this.genProvider.onGetAllPageDesc('dados/' + this.path + '/pessoa', "time", this.qtd_upload + 1, joins).subscribe(data => {
      this.unSub$.unsubscribe()
      this.lista = data;
      this.lista = this.lista.map(res => {
        res.codigo = parseInt(res.key)
        if (res.lotacao) {
          if (res.lotacao) {
            res.nome_lotacao = res.lotacao.nome;
          }
        } else {
          res.nome_lotacao = '';
        }
        //        res.origem = res.lotacao.origem;
        if (res.cargo) {
          res.nome_cargo = res.cargo.nome;
        } else {
          res.nome_cargo = '';
        }
        if (res.telefone) {
          if (res.telefone.length == 15) {
            res.celular = res.telefone;
          } else {
            res.celular = this.validaCampo.validaTelefonePromo(res.telefone);
          }
        }
        //        res.telefone = this.validaCampo.formataTelefonePadrao(res.telefone);
        return res;
      });
      // console.log(this.lista)
      this.loading = false;
    },
      err => console.log(err)
    );
  }
  onNovo() {
    this.formulario.reset();
    this.titulo = 'Novo';
    this.onMostraFrm();
    this.formulario.patchValue({
      tipo: "Interno",
      origem: "Painel",
      time: Date.now()
    });
    this.permissao = false;

  }
  onEdit(event: any) {
    this.formulario.reset();
    this.formulario.patchValue(event);
    this.titulo = 'Editar';
    this.onMostraFrm();
    this.permissao = false;
  }

  onDeleteConfirm(event) {
    this.confirmationService.confirm({
      message: 'Confirma a Exclusão de ' + event.nome,
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      acceptIcon: 'pi pi-check',
      rejectLabel: 'Não',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.genProvider.remove('dados/' + this.path + '/pessoa', event.key, 'key_pessoa').then(res=>{
          if (res!=null){
            this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada, este registro está relaciondo à '+ res +' tabelas!' });
          }else {
            this.messageService.add({ severity: 'info', summary: 'Confirmado', detail: 'Exclusão confirmada' });
          }
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'Exclusão cancelada' });
      }
    });

  }
  onSave() {
    if (this.formulario.valid) {
      let data = this.formulario.value
      let busca = data.nome.toLowerCase();
      if (data.telefone) {
        busca += data.telefone
      }
      if (data.telefone) {
        busca += data.telefone
      }
      if (data.email) {
        busca += data.email.toLowerCase();
      }
      if (data.matricula) {
        busca += data.matricula
      }
      this.formulario.patchValue({
        busca: busca
      })
      this.onSubmit();
    }
  }
  onSubmit() {
    this.onMostraFrm();
    //console.log(this.formulario.value)
    this.genProvider.onSaveIncrement("dados/" + this.path + "/pessoa", this.formulario.value, "pessoa").then(t => {
      if (this.formulario.value.key) {
        this.atualizarLista(this.formulario.value)
      }
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados Gravados.' });
      this.formulario.reset();
      this.onLoad();
    }).catch(e => {
      this.messageService.add({ severity: 'info', summary: 'Atenção!!', detail: 'Atenção, não foi possível enviar seus dados, por favor verifique a sua conexão de internet.' });
      // console.log(e);
    });
  }
  exportPdf() {
    let lista: any;
    if (this.dtLista.filteredValue) {
      lista = this.dtLista.filteredValue
    } else {
      lista = this.lista
    }
    const doc = new jsPDF('l', 'mm', 'a4');
    doc.setFontType('normal');
    doc.setFont('arial');
    doc.setFontSize(25)
    doc.setFontType('bold');
    doc.text(14, 10, "pessoas")
    doc.setFontSize(7);
    doc.setFontStyle('normal');
    //doc.text("Periodo de " + new DatePipe("pt-BR").transform(this.inicio, "d, MMMM yyyy - HH:mm") + " à " + new DatePipe("pt-BR").transform(this.final, "d, MMMM yyyy - HH:mm"), 14, 15);
    doc.autoTable({
      //      columnStyles: {
      //       0: { halign: 'left', fillColor: [220, 220, 220] }
      //     },
      //      margin: { top: 20 },
      showFoot: 'lastPage',
      startY: 20,
      didDrawPage: (data) => {
        doc.text(data.pageNumber.toString(), data.cursor.x + 2, data.cursor.y + 2, 10, 10)
      },
      columns: this.exportColumns,
      body: lista,
      // foot: this.listaFoot
    });
    doc.save('lista.pdf');
  }
  onLoadlotacao() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/lotacao', 'nome').subscribe(data => {
      this.lotacao = data;
    },
      err => console.log(err)
    ));
  }
  onLoadCargo() {
    this.unSub.push(this.genProvider.onGetAll('dados/' + this.path + '/cargo', 'nome').subscribe(data => {
      this.cargo = data;
      this.cargo = this.cargo.map(res => {
        res.nomeDescricao = res.nome + " - " + res.descricao
        return res;
      });
    },
      err => console.log(err)
    ));
  }
  atualizaDados() {
    let c = 0;
    this.lista.sort(function (a, b) {
      if (a.codigo > b.codigo) {
        return 1;
      }
      if (a.codigo < b.codigo) {
        return -1;
      }
      return 0;
    });
    console.log(this.lista)
    let startIntervalo = setInterval(() => {

      let data = this.lista[c]

      delete data['lotacao'];
      delete data['cargo'];
      delete data['codigo'];
      let busca = data.nome.toLowerCase();
      if (data.telefone) {
        busca += data.telefone
      }
      if (data.telefone) {
        busca += data.telefone
      }
      if (data.email) {
        busca += data.email.toLowerCase();
      }
      if (data.matricula) {
        busca += data.matricula
      }
      data.busca = busca
      data.time = Date.now();
      this.genProvider.onSaveIncrement("dados/" + this.path + "/pessoa", data, "pessoa").then(t => { });
      c++
      console.log(c)
      if (c >= this.lista.length) {
        clearInterval(startIntervalo);
      }
    }, 1000);
  }
  atualizaDadosItem() {
    let unSub = this.genProvider.onGetAll('dados/' + this.path + '/item', "numero").pipe(take(1)).subscribe(data => {
      unSub.unsubscribe()
      let lista = JSON.parse(JSON.stringify(data));
      let c = 0;
        console.log(lista)
        let startIntervalo = setInterval(() => {
          let data = lista[c]
          data.numero = parseInt(data.numero)
          this.genProvider.onSaveIncrement("dados/" + this.path + "/item", data, "item").then(t => { });
          c++
          console.log(c)
          if (c >= lista.length) {
            clearInterval(startIntervalo);
          }
        }, 1000);
    });
  }
  atualizarLista(data) {
    if (this.lista.length > 0) {
      let res = this.lista.filter(obj => {
        return obj.key == data.key;
      });
      const index = this.lista.indexOf(res[0]);
      if (index >= 0) {
        this.lista.splice(index, 1);
        this.lista.push(data);
      }
    }
  }
  onNome() {
    if (this.formulario.value.nome) {
      this.formulario.patchValue({
        nome: this.formulario.value.nome.toUpperCase()
      });
    }
  }
  onAtualizaNome(){
    let c =0;
    let startIntervalo = setInterval(() => {
      let data = this.lista[c]
      data.nome = data.nome.toUpperCase();
      this.genProvider.onSaveIncrement("dados/" + this.path + "/pessoa", data, "pessoa").then(t => { });
      c++
      console.log(c)
      if (c >= this.lista.length) {
        clearInterval(startIntervalo);
      }
    }, 1000);
  }
 }


