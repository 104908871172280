import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the CamelcasePipe pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
 * Angular Pipes.
 */
@Pipe({
  name: 'camelCase',
})
export class CamelcasePipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, aarg?:any):any {
    let values = value.split(' ');
    let result =' ';
    for (let v of values){
      result += this.capitaliza(v)+' ';
    }
    return result;
  }
  capitaliza (value: string){
   return value.substr(0,1).toUpperCase() +
   value.substr(1).toLowerCase();

  }
}
