import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import {DividerModule} from 'primeng/divider';

// Application Components
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppSideBarComponent } from './app.sidebar.component';
import { AppSideBarTabContentComponent } from './app.sidebartabcontent.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

// Demo pages
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { WidgetsComponent } from './utilities/widgets.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './login/app.login.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';

// Demo services
import { CountryService } from './demo/service/countryservice';
import { CustomerService } from './demo/service/customerservice';
import { EventService } from './demo/service/eventservice';
import { IconService } from './demo/service/iconservice';
import { NodeService } from './demo/service/nodeservice';
import { PhotoService } from './demo/service/photoservice';
import { ProductService } from './demo/service/productservice';

// Application adicionais e services
import { MenuService } from './app.menu.service';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);
import { FirebaseConfig } from '../firebase/firebase.config';
import { MessageService } from 'primeng/api';
import { AuthService } from './login/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { GenProvider } from '../providers/gen/gen';
import { ServiceProvider } from '../providers/service/service';
import { ValidaCampoProvider } from '../providers/valida-campo/valida-campo';
import { ValidaCepProvider } from '../providers/valida-cep/valida-cep';
import { GetImagemProvider } from '../providers/getImagem/getImagem';
import { EditorModule } from 'primeng/editor';
import { CaptchaModule } from 'primeng/captcha';
import { AvatarModule } from 'primeng/avatar';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PainelControlComponent } from '../app/painelControl/painelControl.component'
import { AgmCoreModule } from '@agm/core';
import { RelPontoComponent } from './relPonto/relPonto.component';
import { CallComponent } from './call/call.component';
import { SplitterModule } from 'primeng/splitter';
import { TimelineModule } from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { RelAtendimentoComponent } from './relAtendimento/relAtendimento.component';
import { RelAtendimentoDiarioComponent } from './relAtendimentoDiario/relAtendimentoDiario.component';
import { RelIntervaloComponent } from './relIntervalo/relIntervalo.component';
import { TicketComponent } from './ticket/ticket.component';
import { GMapModule } from 'primeng/gmap';
import { DepartamentoComponent } from './departamento/departamento.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { ImportDataComponent } from './importData/importData.component';
import { CargoComponent } from './cargo/cargo.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { PucComponent } from './puc/puc.component';
import { SlaComponent } from './sla/sla.component';
import { LotacaoComponent } from './lotacao/lotacao.component';
import { PessoaComponent } from './pessoa/pessoa.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { PipesModule } from 'src/pipes/pipes.module';
import { SafeHtmlPipe } from 'src/pipes/safe/safe.pipe';
import { RelTicketLotacaoComponent } from './relTicketLotacao/relTicketLotacao.component';
import { Pessoa_lotacaoComponent } from './pessoa_lotacao/pessoa_lotacao.component';
import { DashticketComponent } from './dashticket/dashticket.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { MacroComponent } from './macro/macro.component';
import { HttpModule } from '@angular/http';
import { TipoComponent } from './tipo/tipo.component';
import { EncerrarAtendimentoComponent } from './encerrarAtendimento/encerrarAtendimento.component';
import { BotComponent } from './bot/bot.component';
import { CallcenterComponent } from './callcenter/callcenter.component';
import { RelCatalogoComponent } from './relCatalogo/relCatalogo.component';
import { AtendimentoComponent } from './atendimento/atendimento.component';
import { HomeComponent } from './home/home.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { DashAtendimentoComponent } from './dashAtendimento/dashAtendimento.component';
import { PainelControlTicketComponent } from './painelControlTicket/painelControlTicket.component';
import { RelQuantificacaoComponent } from './relQuantificacao/relQuantificacao.component';
import { RelTicketGeralComponent } from './relTicketGeral/relTicketGeral.component';

@NgModule({
    imports: [
        HttpModule,
        GMapModule,
        FormsModule,
        ReactiveFormsModule,
        SplitterModule,
        TimelineModule,
        TagModule,
        BadgeModule,
        InputMaskModule,
        ConfirmDialogModule,
        ProgressBarModule,
        EditorModule,
        AvatarModule,
        CaptchaModule,
        EditorModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        PipesModule,
        ProgressSpinnerModule,
        AngularFireModule.initializeApp(environment.FirebaseConfig),
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDhuzWbN3NmGyiiW7H0aDw_FJyIMaXzIRc'
        }),
        DividerModule
    ],
    declarations: [
        PainelControlComponent,
        PainelControlTicketComponent,
        RelPontoComponent,
        CallComponent,
        RelAtendimentoComponent,
        RelAtendimentoDiarioComponent,
        RelIntervaloComponent,
        RelTicketLotacaoComponent,
        DepartamentoComponent,
        PessoaComponent,
        CatalogoComponent,
        CargoComponent,
        PucComponent,
        SlaComponent,
        LotacaoComponent,
        UsuarioComponent,
        TableDemoComponent,
        TicketComponent,
        ImportDataComponent,
        DashboardComponent,
        DashticketComponent,
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppSideBarComponent,
        AppSideBarTabContentComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        Pessoa_lotacaoComponent,
        MacroComponent,
        TipoComponent,
        EncerrarAtendimentoComponent,
        BotComponent,
        CallcenterComponent,
        RelCatalogoComponent,
        AtendimentoComponent,
        HomeComponent, 
        EmpresasComponent,
        DashAtendimentoComponent,
        DashboardComponent,
        RelQuantificacaoComponent,
        RelTicketGeralComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        MessageService,
        AuthService,
        AuthGuard,
        GenProvider,
        ServiceProvider,
        GetImagemProvider,
        ValidaCampoProvider,
        ValidaCepProvider,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'//'en-EN'//'pt-BR'
        },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        MenuService,
        SafeHtmlPipe
    ],
    entryComponents: [
        AppComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    /*
    constructor(private injector: Injector) {
        const el = createCustomElement(AppComponent, { injector })
        customElements.define('hyperchannel-widget', el)
    }
    ngDoBootstrap() { }
   // npm run build:component -> executar
   // http-server
   */
}
